import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { OrganizationChart } from "primereact/organizationchart";
import { SelectButton } from "primereact/selectbutton";
import { useEffect, useState } from "react";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";

const wilayahs = [
  "Wilayah 1",
  "Wilayah 2",
  "Wilayah 3",
  "Wilayah 4",
  "Wilayah 5",
  "Wilayah 6",
  "Wilayah 7",
];

const depots = [
  "Depot 201",
  "Depot 202",
  "Depot 203",
  "Depot 204",
  "Depot 205",
];

const semesters = ["Semester 1", "Semester 2"];

const configDepots = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Periode",
    field: "periode",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Total",
    field: "nilai",
    type: "number_plain",
    align: "right",
    table: true,
  },
];

const configDepotPembandings = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Periode",
    field: "periode",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Total",
    field: "nilai",
    type: "number_plain",
    align: "right",
    table: true,
  },
];

const configKelompoks = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Kelompok",
    field: "kelompok",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Target",
    field: "target",
    type: "number_plain",
    align: "right",
    table: true,
  },
];

const configKelompokPembandings = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Kelompok",
    field: "kelompok",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Target",
    field: "target",
    type: "number_plain",
    align: "right",
    table: true,
  },
];

const TargetBatangPage = () => {
  const [selectedWilayah, setSelectedWilayah] = useState(wilayahs[0]);
  const [selectedDepot, setSelectedDepot] = useState(depots[0]);
  const [selectedSemester, setSelectedSemester] = useState(semesters[0]);
  const [selectedSemesterPembanding, setSelectedSemesterPembanding] = useState(
    semesters[0]
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDatePembanding, setSelectedPembanding] = useState(null);

  const [listDataDepot, setListDataDepot] = useState([]);
  const [listDataDepotPembanding, setListDataDepotPembanding] = useState([]);

  const [listDataKelompok, setListDataKelompok] = useState([]);
  const [listDataKelompokPembanding, setListDataKelompokPembanding] = useState(
    []
  );

  const [orgChartData] = useState([
    {
      label: "Wilayah 2",
      expanded: true,
      data: { target: 12000, pembanding: 10000 },
      children: [
        {
          label: "Depot 201",
          expanded: true,
          data: { target: 4000, pembanding: 2500 },
        },
        {
          label: "Depot 202",
          expanded: true,
          data: { target: 2000, pembanding: 2500 },
        },
        {
          label: "Depot 203",
          expanded: true,
          data: { target: 5000, pembanding: 2500 },
        },
        {
          label: "Depot 204",
          expanded: true,
          data: { target: 1000, pembanding: 2500 },
        },
      ],
    },
  ]);

  useEffect(() => {
    setListDataDepot([
      { id: 1, no: 1, periode: "Depot 201", nilai: 4000 },
      { id: 2, no: 2, periode: "Depot 202", nilai: 2000 },
      { id: 3, no: 3, periode: "Depot 203", nilai: 5000 },
      { id: 4, no: 4, periode: "Depot 204", nilai: 1000 },
    ]);

    setListDataDepotPembanding([
      { id: 1, no: 1, periode: "Depot 201", nilai: 2500 },
      { id: 2, no: 2, periode: "Depot 202", nilai: 2500 },
      { id: 3, no: 3, periode: "Depot 203", nilai: 2500 },
      { id: 4, no: 4, periode: "Depot 204", nilai: 2500 },
    ]);

    setListDataKelompok([
      { id: 1, no: 1, kelompok: "Kelompok 1", target: 500 },
      { id: 2, no: 2, kelompok: "Kelompok 2", target: 700 },
      { id: 3, no: 3, kelompok: "Kelompok 3", target: 300 },
      { id: 4, no: 4, kelompok: "Kelompok 4", target: 500 },
      { id: 5, no: 5, kelompok: "Kelompok 5", target: 600 },
      { id: 6, no: 6, kelompok: "Kelompok 6", target: 400 },
      { id: 7, no: 7, kelompok: "Kelompok 7", target: 300 },
      { id: 8, no: 8, kelompok: "Kelompok 8", target: 700 },
    ]);

    setListDataKelompokPembanding([
      { id: 1, no: 1, kelompok: "Kelompok 1", target: 310 },
      { id: 2, no: 2, kelompok: "Kelompok 2", target: 320 },
      { id: 3, no: 3, kelompok: "Kelompok 3", target: 310 },
      { id: 4, no: 4, kelompok: "Kelompok 4", target: 310 },
      { id: 5, no: 5, kelompok: "Kelompok 5", target: 320 },
      { id: 6, no: 6, kelompok: "Kelompok 6", target: 310 },
      { id: 7, no: 7, kelompok: "Kelompok 7", target: 310 },
      { id: 8, no: 8, kelompok: "Kelompok 8", target: 310 },
    ]);
  }, []);

  const orgChartTemplate = (node) => {
    const persentase = (
      ((node.data.pembanding - node.data.target) / node.data.pembanding) *
      100.0
    ).toFixed(0);

    const naik = !(persentase > 0);

    return (
      <div className="col-start-center gap-3">
        <span className="font-semibold">{node.label}</span>
        <div className="col-center-center gap-3">
          <div className="col-center-center">
            <span className="font-semibold text-zinc-400">
              {node.data.pembanding.toLocaleString("id-ID", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
              })}
            </span>
            <Divider className="my-0" />
            <span className="font-bold text-[var(--color-primary)]">
              {node.data.target.toLocaleString("id-ID", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
              })}
            </span>
          </div>
          <span
            className={`text-sm font-semibold ${
              naik ? "text-green-500" : "text-red-400"
            }`}
          >
            {naik ? "+" : "-"}
            {Math.abs(persentase)}%
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <div />
      {/* judul */}
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <span className="text-normal font-semibold text-zinc-700">
          Target ( Batang )
        </span>
        <div className="row-end-center gap-2">
          <Dropdown
            value={selectedWilayah}
            onChange={(e) => setSelectedWilayah(e.value)}
            options={wilayahs}
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            // loading={loading}
            // onClick={() => handleReload()}
            outlined
          />
        </div>
      </div>

      {/* organization chart */}
      <div className={`elevated-card row-center-start w-100  wrap gap-4`}>
        <OrganizationChart
          value={orgChartData}
          nodeTemplate={orgChartTemplate}
        />
      </div>

      <div className="row-center-start gap-4 w-full">
        <div className="col-center-start gap-4 w-full">
          <div
            className={`elevated-card-tight-vertical row-center-center w-100 wrap gap-20`}
          >
            <span className="text-normal font-semibold text-zinc-700">
              TARGET
            </span>
          </div>
          {/* target */}
          {/* per depot */}
          <div className={`elevated-card col-start-start w-100  wrap gap-4`}>
            <div className="row-center-center w-100 gap-4">
              <SelectButton
                value={selectedSemester}
                onChange={(e) => setSelectedSemester(e.value)}
                options={semesters}
              />
              <Calendar
                className="w-[8rem]"
                showIcon
                value={selectedDate}
                view="year"
                dateFormat="yy"
                onChange={(e) => setSelectedDate(e.value)}
              />
              <Button
                icon="pi pi-refresh"
                size="small"
                // loading={loading}
                // onClick={() => handleReload()}
                outlined
              />
            </div>

            <TableAutoComponent
              selection={[]}
              // onSelectionChange={handleSelectionChange}
              onSelectionChange={() => {}}
              configs={configDepots}
              // loading={loading}
              value={listDataDepot}
              onEditRow={async (row) => {}}
              onDeleteRow={(row) => {}}
              customColumns={[]}
            />
          </div>
          {/* per kelompok */}
          <div className={`elevated-card col-start-start w-100  wrap gap-4`}>
            <div className="row-center-center w-100 gap-4">
              <Dropdown
                value={selectedDepot}
                onChange={(e) => setSelectedDepot(e.value)}
                options={depots}
              />
              <Button
                icon="pi pi-refresh"
                size="small"
                // loading={loading}
                // onClick={() => handleReload()}
                outlined
              />
            </div>

            <TableAutoComponent
              selection={[]}
              // onSelectionChange={handleSelectionChange}
              onSelectionChange={() => {}}
              configs={configKelompoks}
              // loading={loading}
              value={listDataKelompok}
              onEditRow={async (row) => {}}
              onDeleteRow={(row) => {}}
              customColumns={[]}
            />
          </div>
        </div>

        {/* pembanding */}
        <div className="col-start-center gap-4 w-full ">
          <div
            className={`elevated-card-tight-vertical row-center-center w-100 wrap gap-20 !bg-gray-100`}
          >
            <span className="text-normal font-semibold text-zinc-700">
              PEMBANDING
            </span>
          </div>

          {/* per depot */}
          <div
            className={`elevated-card row-start-start w-100 wrap gap-4 !bg-gray-100`}
          >
            <div className="row-center-center w-100 gap-4">
              <SelectButton
                value={selectedSemester}
                onChange={(e) => setSelectedSemester(e.value)}
                options={semesters}
              />
              <Calendar
                className="w-[8rem]"
                showIcon
                value={selectedDate}
                view="year"
                dateFormat="yy"
                onChange={(e) => setSelectedDate(e.value)}
              />
              <Button
                icon="pi pi-refresh"
                size="small"
                // loading={loading}
                // onClick={() => handleReload()}
                outlined
              />
            </div>

            <TableAutoComponent
              selection={[]}
              // onSelectionChange={handleSelectionChange}
              onSelectionChange={() => {}}
              configs={configDepotPembandings}
              // loading={loading}
              value={listDataDepotPembanding}
              onEditRow={async (row) => {}}
              onDeleteRow={(row) => {}}
              customColumns={[]}
            />
          </div>

          {/* per kelompok */}
          <div
            className={`elevated-card row-start-start w-100 wrap gap-4 !bg-gray-100`}
          >
            <div className="row-center-center w-100 gap-4">
              <Dropdown
                value={selectedDepot}
                onChange={(e) => setSelectedDepot(e.value)}
                options={depots}
              />
              <Button
                icon="pi pi-refresh"
                size="small"
                // loading={loading}
                // onClick={() => handleReload()}
                outlined
              />
            </div>

            <TableAutoComponent
              selection={[]}
              // onSelectionChange={handleSelectionChange}
              onSelectionChange={() => {}}
              configs={configKelompokPembandings}
              // loading={loading}
              value={listDataKelompokPembanding}
              onEditRow={async (row) => {}}
              onDeleteRow={(row) => {}}
              customColumns={[]}
            />
          </div>
        </div>
      </div>

      <div />
    </div>
  );
};

export default TargetBatangPage;

import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Chart } from "primereact/chart";
import { RadioButton } from "primereact/radiobutton";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { format as dateFnsFormat, parse } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import getLastDateOfMonth from "../../../../utils/GetLastDateOfMonth";

const defaultDate = new Date();
defaultDate.setDate(1);

const defaultDatePembanding = new Date(
  defaultDate.getTime() - 365 * 24 * 60 * 60 * 1000
);
defaultDatePembanding.setDate(1);

const arrMonthName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];

const tableDepotConfigs = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Depot",
    field: "depot",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Nilai",
    field: "nilai",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "%",
    field: "persen",
    type: "text_plain",
    align: "right",
    table: true,
  },
];

const tableProdukConfigs = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Kode",
    field: "kode",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Nama",
    field: "nama",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Nilai",
    field: "nilai",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Satuan",
    field: "uom",
    type: "text_plain",
    align: "left",
    table: true,
  },
  {
    name: "Persentase",
    field: "persen",
    type: "text_plain",
    align: "right",
    table: true,
  },
];

const tableConfigs = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Periode",
    field: "periode",
    type: "text_plain",
    align: "center",
    table: true,
    filter: true,
  },
  {
    name: "Pembanding",
    field: "pembanding",
    type: "text_plain",
    align: "center",
    table: true,
    filter: true,
  },
  {
    name: "Nilai",
    field: "nilai",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Nilai Banding",
    field: "nilai_pembanding",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Persentase",
    field: "persen",
    type: "number_plain",
    align: "right",
    table: true,
  },
];

const DetailReturBaik = () => {
  const navigate = useNavigate();
  const toast = useRef();
  const jenisNilai = [
    {
      id: "quantity",
      value: "quantity",
      label: "Quantity (Tin)",
    },
    {
      id: "rupiah",
      value: "rupiah",
      label: "Rupiah (K)",
    },
  ];
  const [selectedJenisNilai, setSelectedJenisNilai] = useState(
    jenisNilai[0].value
  );

  const jenisPeriode = [
    {
      id: "bulanan",
      value: "bulanan",
      checked: false,
      label: "Bulanan",
    },
    {
      id: "tahunan",
      value: "tahunan",
      checked: true,
      label: "Tahunan",
    },
  ];
  const [selectedJenisPeriode, setSelectedJenisPeriode] = useState(
    jenisPeriode[1].value
  );

  const jenisGolongan = [
    {
      id: "depot",
      value: "depot",
      checked: false,
      label: "Depot",
    },
    {
      id: "produk",
      value: "produk",
      checked: true,
      label: "Produk",
    },
  ];
  const [selectedJenisGolongan, setSelectedJenisGolongan] = useState(
    jenisGolongan[0].value
  );

  const [title, setTitle] = useState("");
  const [listData, setListData] = useState([]);
  const [listDataDepot, setListDataDepot] = useState([]);
  const [depotPieChartData, setDepotPieChartData] = useState([]);
  const [listDataProduk, setListDataProduk] = useState([]);
  const [tanggal, setTanggal] = useState(defaultDate);
  const [tanggalPembanding, setTanggalPembanding] = useState(
    defaultDatePembanding
  );

  const [totalNilai, setTotalNilai] = useState(0);
  const [totalNilaiBanding, setTotalNilaiBanding] = useState(0);
  const aspectRatio = 0.75;

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const { requestGet, loading } = useAPIRequest();

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  const [salesChartData, setSalesChartData] = useState({});
  const salesChartOption = {
    maintainAspectRatio: false,
    aspectRatio: aspectRatio ?? 0.7,
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
  };

  const pieChartOption = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
    },
  };

  const generatePeriodeLabel = ({ jenisPeriode, date, key }) => {
    let label = "";
    const newDate = new Date(date.getTime());
    if (jenisPeriode === "bulanan") {
      // bulanan
      newDate.setDate(parseInt(key));
      label = dateFnsFormat(newDate, "dd/MM/yyyy");
    } else {
      // tahunan
      newDate.setMonth(parseInt(key) - 1);
      label = dateFnsFormat(newDate, "MMM yyyy");
    }
    return label;
  };

  useEffect(() => {
    if (selectedDepot && selectedDepot.id !== 0) {
      setTitle(`Retur Baik ${selectedDepot.nama}`);
    } else if (selectedWilayah && selectedWilayah.id !== 0) {
      setTitle(`Retur Baik ${selectedWilayah.nama}`);
    } else {
      setTitle(`Retur Baik Seluruh Wilayah`);
    }
  }, [selectedDepot, selectedWilayah]);

  const reloadData = async () => {
    reloadReturPerPeriode();
    reloadReturPerDepot({
      jenisNilai: selectedJenisNilai,
      jenisPeriode: selectedJenisPeriode,
      tanggal: tanggal,
    });
    reloadReturPerProduk({
      jenisNilai: selectedJenisNilai,
      jenisPeriode: selectedJenisPeriode,
      tanggal: tanggal,
    });
  };

  const generateFilterPerPeriode = ({ jenisNilai, jenisPeriode, tanggal }) => {
    let filter = "data_stok_masuk.jenis_masuk:=:retur_baik";

    switch (jenisPeriode) {
      case "bulanan":
        filter += `;bulan:=:${tanggal.getMonth() + 1}`;
      case "tahunan":
        filter += `;tahun:=:${tanggal.getFullYear()}`;
        break;
    }

    let depot = null;
    let wilayah = null;
    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `;data_stok_masuk.data_depot.id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `;data_stok_masuk.data_depot.wilayah_id:=:${wilayah.id}`;
    }
    return filter;
  };

  const reloadReturPerPeriodeSingle = async ({
    jenisNilai,
    jenisPeriode,
    tanggal,
  }) => {
    const filter = generateFilterPerPeriode({
      jenisNilai,
      jenisPeriode,
      tanggal,
    });

    const params = { prefix: "retur", method: "quantity", filter };

    if (jenisNilai === "rupiah") {
      params["method"] = "harga";
    }

    return requestGet({
      fullUrl: "api/dashboard/kawil",
      params,
      onSuccess: ({ pagination, message, data }) => {},
      onError: ({ message, data }) => {},
    });
  };

  const reloadReturPerPeriode = async () => {
    setListData(null);
    setSalesChartData(null);

    const newData = await reloadReturPerPeriodeSingle({
      jenisNilai: selectedJenisNilai,
      jenisPeriode: selectedJenisPeriode,
      tanggal: tanggal,
    });

    const oldData = await reloadReturPerPeriodeSingle({
      jenisNilai: selectedJenisNilai,
      jenisPeriode: selectedJenisPeriode,
      tanggal: tanggalPembanding,
    });

    const arrLabel = [];
    const arrNew = [];
    const arrOld = [];
    const keys = Object.keys(newData);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      arrLabel.push(key);
      if (selectedJenisNilai === "rupiah") {
        arrNew.push(newData[key] ?? 0);
        arrOld.push(oldData[key] ?? 0);
      } else if (selectedJenisNilai === "quantity") {
        arrNew.push((newData[key] ?? 0) / 100);
        arrOld.push((oldData[key] ?? 0) / 100);
      }
    }
    let labelNew = "";
    let labelOld = "";
    switch (selectedJenisPeriode) {
      case "bulanan":
        const thisMonth = new Date();
        const lastMonth = new Date(
          thisMonth.getTime() - 30 * 24 * 60 * 60 * 1000
        );
        labelNew = `Bulan (${thisMonth.toLocaleString("id-ID", {
          month: "long",
        })} ${thisMonth.getFullYear()})`;
        labelOld = `Pembanding (${lastMonth.toLocaleString("id-ID", {
          month: "long",
        })} ${lastMonth.getFullYear()})`;
        break;
      case "tahunan":
        const thisYear = new Date();
        const lastYear = new Date(
          thisYear.getTime() - 365 * 24 * 60 * 60 * 1000
        );
        labelNew = `Tahun (${thisYear.getFullYear()})`;
        labelOld = `Pembanding (${tanggalPembanding.getFullYear()})`;
        break;
    }
    setSalesChartData({
      labels:
        selectedJenisPeriode === "tahunan"
          ? arrMonthName
          : arrLabel.map((e) => "" + e),
      datasets: [
        {
          type: "bar",
          label: labelOld,
          data: arrOld,
          tension: 0.3,
          backgroundColor: "rgba(63,63,70,0.22)",
        },
        {
          label: labelNew,
          data: arrNew,
          tension: 0.3,
          backgroundColor: documentStyle.getPropertyValue("--blue-400"),
        },
      ],
    });
    let totalNilaiTmp = 0;
    let totalNilaiBandingTmp = 0;
    const newListData = [];
    for (let i = 0; i < arrLabel.length; i++) {
      const key = arrLabel[i];
      const data = {
        id: i,
        no: i + 1,
        periode: generatePeriodeLabel({
          jenisPeriode: selectedJenisPeriode,
          date: tanggal,
          key,
        }),
        pembanding: generatePeriodeLabel({
          jenisPeriode: selectedJenisPeriode,
          date: tanggalPembanding,
          key,
        }),
        nilai: newData[key],
        nilai_pembanding: oldData[key],
      };
      totalNilaiTmp += newData[key] ?? 0;
      totalNilaiBandingTmp += oldData[key] ?? 0;
      newListData.push(data);
    }
    setListData(newListData);
    setTotalNilai(totalNilaiTmp);
    setTotalNilaiBanding(totalNilaiBandingTmp);
  };

  const generateFilterPerDepot = ({ jenisNilai, jenisPeriode, tanggal }) => {
    let filter = "jenis_masuk:=:retur_baik";

    switch (jenisPeriode) {
      case "bulanan":
        filter += `;tanggal_transaksi:>=:${dateFnsFormat(
          tanggal,
          "yyyy-MM"
        )}-01 00:00:00`;
        let thisMonthLastDate = getLastDateOfMonth({ date: tanggal });
        filter += `;tanggal_transaksi:<=:${dateFnsFormat(
          thisMonthLastDate,
          "yyyy-MM-dd"
        )} 23:59:59`;
        break;
      case "tahunan":
        filter += `;tanggal_transaksi:>=:${dateFnsFormat(
          tanggal,
          "yyyy"
        )}-01-01 00:00:00`;
        filter += `;tanggal_transaksi:<=:${dateFnsFormat(
          tanggal,
          "yyyy"
        )}-12-31 23:59:59`;
        break;
    }

    let depot = null;
    let wilayah = null;
    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `;data_depot.id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `;wilayah_id:=:${wilayah.id}`;
    }
    return filter;
  };

  const reloadReturPerDepot = async ({ jenisNilai, jenisPeriode, tanggal }) => {
    setListDataDepot(null);
    setDepotPieChartData(null);

    const filter = generateFilterPerDepot({
      jenisNilai,
      jenisPeriode,
      tanggal,
    });

    const params = { prefix: "retur_per_depot", method: "quantity", filter };

    if (jenisNilai === "rupiah") {
      params["method"] = "harga";
    }

    return requestGet({
      fullUrl: "api/dashboard/kawil",
      params,
      onSuccess: ({ pagination, message, data }) => {
        // extract data from API response
        const newListDataDepot = [];
        const arrDepotLabel = [];
        const arrDepotValue = [];
        let total = 0;
        for (let i = 0; i < data.length; i++) {
          const d = data[i];
          newListDataDepot.push({
            id: i + 1,
            no: i + 1,
            depot: d.depot_nama,
            nilai: d.total,
          });
          arrDepotLabel.push(d.depot_nama);
          arrDepotValue.push(d.total);
          total += d.total;
        }
        // refine persen
        for (const d of newListDataDepot) {
          let persen = 0;
          if (total === 0) {
            persen = 0;
          } else {
            persen = (d.nilai / total) * 100.0;
          }
          d.persen = `${persen}%`;
        }

        // fill data to depot table
        setListDataDepot(newListDataDepot);
        // fill data to depot pie chart
        setDepotPieChartData({
          labels: arrDepotLabel,
          datasets: [
            {
              data: arrDepotValue,
              backgroundColor: [
                documentStyle.getPropertyValue("--blue-500"),
                documentStyle.getPropertyValue("--yellow-500"),
                documentStyle.getPropertyValue("--green-500"),
                documentStyle.getPropertyValue("--red-500"),
                documentStyle.getPropertyValue("--gray-500"),
                documentStyle.getPropertyValue("--brown-500"),
              ],
              hoverBackgroundColor: [
                documentStyle.getPropertyValue("--blue-400"),
                documentStyle.getPropertyValue("--yellow-400"),
                documentStyle.getPropertyValue("--green-400"),
                documentStyle.getPropertyValue("--red-400"),
                documentStyle.getPropertyValue("--gray-400"),
                documentStyle.getPropertyValue("--brown-400"),
              ],
            },
          ],
        });
      },
      onError: ({ message, data }) => {},
    });
  };

  const generateFilterPerProduk = ({ jenisNilai, jenisPeriode, tanggal }) => {
    let filter = "jenis_masuk:=:retur_baik";

    switch (jenisPeriode) {
      case "bulanan":
        filter += `;tanggal_transaksi:>=:${dateFnsFormat(
          tanggal,
          "yyyy-MM"
        )}-01 00:00:00`;
        let thisMonthLastDate = getLastDateOfMonth({ date: tanggal });
        filter += `;tanggal_transaksi:<=:${dateFnsFormat(
          thisMonthLastDate,
          "yyyy-MM-dd"
        )} 23:59:59`;
        break;
      case "tahunan":
        filter += `;tanggal_transaksi:>=:${dateFnsFormat(
          tanggal,
          "yyyy"
        )}-01-01 00:00:00`;
        filter += `;tanggal_transaksi:<=:${dateFnsFormat(
          tanggal,
          "yyyy"
        )}-12-31 23:59:59`;
        break;
    }

    let depot = null;
    let wilayah = null;
    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `;data_depot.id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `;wilayah_id:=:${wilayah.id}`;
    }
    return filter;
  };

  const reloadReturPerProduk = async ({
    jenisNilai,
    jenisPeriode,
    tanggal,
  }) => {
    setListDataProduk(null);

    const filter = generateFilterPerProduk({
      jenisNilai,
      jenisPeriode,
      tanggal,
    });

    const params = { prefix: "retur_per_produk", method: "quantity", filter };

    if (jenisNilai === "rupiah") {
      params["method"] = "harga";
    }

    return requestGet({
      fullUrl: "api/dashboard/kawil",
      params,
      onSuccess: ({ pagination, message, data }) => {
        let newListDataProduk = [];
        let total = 0;
        for (let i = 0; i < data.length; i++) {
          const d = data[i];
          newListDataProduk.push({
            id: i + 1,
            no: i + 1,
            kode: d.produk_kode,
            nama: d.produk_nama,
            nilai: d.total,
            uom: "Tin",
            persen: 0,
          });
          total += d.total;
        }
        // sort array
        newListDataProduk = newListDataProduk.sort(
          (o1, o2) => o2.nilai - o1.nilai
        );
        // refine persen & numbering
        let no = 1;
        for (const d of newListDataProduk) {
          let persen = 0;
          if (total === 0) {
            persen = 0;
          } else {
            persen = (d.nilai / total) * 100.0;
          }
          d.persen = `${persen.toLocaleString("id-ID", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}%`;
          d.no = no++;
        }
        // fill data to depot table
        setListDataProduk(newListDataProduk);
      },
      onError: ({ message, data }) => {},
    });
  };

  const handleReload = () => {
    reloadData();
  };

  const generateTotalLabel = ({ label, value }) => {
    return (
      <div className="col-center-end min-w-[7.2rem]">
        <span className="font-bold text-xl text-right">
          {value.toLocaleString("id-ID", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}
        </span>
        <span className="font-bold text-slate-400 text-xs"> {label} </span>
      </div>
    );
  };

  const customColumns = [
    {
      field: "persen",
      component: (rowData) => {
        let diff = rowData.nilai - rowData.nilai_pembanding;
        let diffPercent = 0;
        if (rowData.nilai_pembanding === 0) {
          diffPercent = 100;
        } else {
          diffPercent = (diff / rowData.nilai_pembanding) * 100;
        }
        let minus = diffPercent < 0;
        return (
          <div
            className={`row-end-center font-semibold gap-2 ${
              minus ? "text-red-400" : "text-green-600"
            } `}
          >
            <span className="">{`${diffPercent.toFixed(0)}%`}</span>
            {minus ? (
              <i className="pi pi-arrow-down-right" />
            ) : (
              <i className="pi pi-arrow-up-right" />
            )}
          </div>
        );
      },
    },
  ];

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <Toast ref={toast} />
      <div></div>
      <div className="elevated-card-no-padding w-100 row-start-start relative wrap gap-8">
        <div className="row-start-start wrap gap-8 w-100 p-[1rem]">
          <div className="col-start-start gap-6 grow w-100 sm:w-48">
            <div className={`col-start-start w-100 gap-3`}>
              <label className="font-bold text-sm text-zinc-500">
                Jenis Nilai
              </label>
              <div className={`row-start-center w-100 gap-6`}>
                {jenisNilai.map((el) => {
                  return (
                    <div key={el.value} className={`row-start-center gap-0`}>
                      <RadioButton
                        value={el.value}
                        inputId={el.value}
                        name={el.name}
                        onChange={(event) => {
                          setSelectedJenisNilai(event.value);
                        }}
                        checked={el.value === selectedJenisNilai}
                      />
                      <label htmlFor={el.value} className="text-zinc-500 ml-2">
                        {el.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={`col-start-start w-100 gap-3`}>
              <label className="font-bold text-sm text-zinc-500">
                Jenis Periode
              </label>
              <div className={`row-start-center w-100 gap-6`}>
                {jenisPeriode.map((el) => {
                  return (
                    <div key={el.value} className={`row-start-center gap-0`}>
                      <RadioButton
                        value={el.value}
                        inputId={el.value}
                        name={el.name}
                        onChange={(event) => {
                          setSelectedJenisPeriode(event.value);
                        }}
                        checked={el.value === selectedJenisPeriode}
                      />
                      <label htmlFor={el.value} className="text-zinc-500 ml-2">
                        {el.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-start-start gap-3 grow w-100 sm:w-48">
            <div className={`col-start-start w-100 gap-1`}>
              <label
                htmlFor={"tanggal"}
                className="font-bold text-sm text-zinc-500"
              >
                Periode
              </label>
              <Calendar
                id="tanggal"
                value={tanggal}
                view={selectedJenisPeriode === "bulanan" ? "month" : "year"}
                showButtonBar
                selectionMode="single"
                dateFormat={selectedJenisPeriode === "bulanan" ? "MM yy" : "yy"}
                hourFormat="24"
                showIcon
                onChange={(e) => {
                  setTanggal(e.value);
                }}
                className={`dateInput w-[16rem]`}
                locale="id"
              />
            </div>

            <div className={`col-start-start w-100 gap-1`}>
              <label
                htmlFor={"tanggal"}
                className="font-bold text-sm text-zinc-500"
              >
                Pembanding
              </label>
              <Calendar
                id="tanggalPembanding"
                value={tanggalPembanding}
                view={selectedJenisPeriode === "bulanan" ? "month" : "year"}
                showButtonBar
                selectionMode="single"
                dateFormat={selectedJenisPeriode === "bulanan" ? "MM yy" : "yy"}
                showIcon
                hourFormat="24"
                onChange={(e) => {
                  setTanggalPembanding(e.value);
                }}
                className={`dateInput w-[16rem]`}
                locale="id"
              />
            </div>
          </div>
          <div className="col-start-start gap-3 grow sm:w-48"></div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20 !pl-3`}
      >
        <div className="row-between-center">
          <div className="row-start-center gap-2">
            <Button
              icon="pi pi-arrow-left"
              text
              className="max-h-[1.4rem]"
              onClick={handleClickBack}
            />
            <span className="text-normal font-semibold text-zinc-700">
              {title}
            </span>
          </div>
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        {salesChartData !== null ? (
          <Chart
            className={`w-full`}
            type="bar"
            data={salesChartData}
            options={salesChartOption}
          />
        ) : (
          <div className="col-start-start w-full gap-3">
            <Skeleton className="w-full" height="2rem"></Skeleton>
            <Skeleton className="w-full" height="1rem"></Skeleton>
            <Skeleton className="w-full" height="2rem"></Skeleton>
            <Skeleton className="w-full" height="1rem"></Skeleton>
            <Skeleton className="w-full" height="2rem"></Skeleton>
            <Skeleton className="w-full" height="1rem"></Skeleton>
            <Skeleton className="w-full" height="2rem"></Skeleton>
            <Skeleton className="w-full" height="1rem"></Skeleton>
            <Skeleton className="w-full" height="2rem"></Skeleton>
            <Skeleton className="w-full" height="1rem"></Skeleton>
            <Skeleton className="w-full" height="2rem"></Skeleton>
          </div>
        )}
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          {listData !== null ? (
            <TableAutoComponent
              onSelectionChange={() => {}}
              configs={tableConfigs}
              loading={loading}
              value={listData}
              onEditRow={async (row) => {}}
              onDeleteRow={(row) => {}}
              customColumns={customColumns}
            />
          ) : (
            <div className="col-start-start w-full gap-3">
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
            </div>
          )}
        </div>
      </div>

      <div className="elevated-card-tight-vertical w-100 row-between-center">
        <span className="text-lg font-semibold">Grandtotal</span>
        <div className="row-end-center w-100 sm:gap-2 mr-2">
          {generateTotalLabel({ label: "NILAI", value: totalNilai })}
          {generateTotalLabel({
            label: "NILAI BANDING",
            value: totalNilaiBanding,
          })}
        </div>
      </div>

      <div className="row-center-start w-full gap-4">
        {/* chart per depot */}
        <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
          <span className="text-sm font-semibold text-zinc-700 ">
            Chart Retur Baik Per Depot
          </span>
          <div className="row-center-center w-full">
            {depotPieChartData !== null ? (
              <Chart
                type="pie"
                data={depotPieChartData}
                options={pieChartOption}
                className="sm:w-30rem"
              />
            ) : (
              <div className="col-start-start w-full gap-3">
                <Skeleton className="w-full" height="19.2rem"></Skeleton>
              </div>
            )}
          </div>
        </div>
        {/* table per depot */}
        <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
          <span className="text-sm font-semibold text-zinc-700 ">
            Tabel Retur Baik Per Depot
          </span>
          {listDataDepot !== null ? (
            <TableAutoComponent
              onSelectionChange={() => {}}
              configs={tableDepotConfigs}
              loading={loading}
              value={listDataDepot}
              onEditRow={async (row) => {}}
              onDeleteRow={(row) => {}}
              customColumns={[]}
            />
          ) : (
            <div className="col-start-start w-full gap-3">
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
            </div>
          )}
        </div>
      </div>

      {/* detail Produk */}
      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <span className="text-sm font-semibold text-zinc-700 ">
          Retur Baik Berdasarkan Produk
        </span>
        {listDataProduk !== null ? (
          <TableAutoComponent
            onSelectionChange={() => {}}
            configs={tableProdukConfigs}
            loading={loading}
            value={listDataProduk}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={[]}
          />
        ) : (
          <div className="col-start-start w-full gap-3">
            <Skeleton className="w-full" height="1.5rem"></Skeleton>
            <Skeleton className="w-full" height="1.5rem"></Skeleton>
            <Skeleton className="w-full" height="1.5rem"></Skeleton>
          </div>
        )}
      </div>

      <div></div>
    </div>
  );
};

export default DetailReturBaik;

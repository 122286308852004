import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideNoteDialog } from "../../../store/ui-slice";
import { Editor } from "primereact/editor";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

const title = "My Notes";

const NoteEditorDialog = (props) => {
  const { visible, onHide, editorData, setEditorData, onSave } = props;

  // const [noteTitle, setNoteTitle] = useState("");
  // const [noteMesssages, setNoteMessages] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleOnSave = async (event) => {
    event.preventDefault();
    if (onSave) {
      onSave(editorData);
    }
    // console.log("REF", editorRef);
    // console.log("SAVE NOTES", content);
  };

  const dialogFooter = (
    <React.Fragment>
      <div className="row-end-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Simpan"
          icon="pi pi-save"
          loading={loading}
          onClick={(e) => {
            handleOnSave(e);
          }}
        />
      </div>
    </React.Fragment>
  );

  const handleChangeTitle = (event) => {
    setEditorData({ ...editorData, title: event.target.value });
  };

  const handleChangeBody = (event, index) => {
    const newBodyArray = [...editorData.body];
    newBodyArray[index] = event.target.value;
    setEditorData({ ...editorData, body: newBodyArray });
  };

  const handleAddNote = (event) => {
    event.preventDefault();
    const newBodyArray = [...editorData.body];
    newBodyArray.push("");
    setEditorData({ ...editorData, body: newBodyArray });
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "45rem" }}
      header={"Tambah / Edit Note"}
      modal
      footer={dialogFooter}
      onHide={onHide}
    >
      <div className="col-start-center relative h-100 w-full gap-3">
        <div className="col-start-start w-100 gap-3 w-full">
          <div className="col-start-start w-full gap-1">
            <label
              htmlFor="judul"
              className="font-semibold text-sm text-zinc-400"
            >
              Judul
            </label>
            <InputTextarea
              className="w-full"
              value={editorData.title}
              autoFocus
              onChange={handleChangeTitle}
            />
          </div>
        </div>
        <div className="col-start-start w-100 gap-3 w-full">
          <div className="col-start-start w-full gap-1">
            <label
              htmlFor="notes"
              className="font-semibold text-sm text-zinc-400"
            >
              Notes:
            </label>
            <div className="col-start-start w-full gap-2">
              {editorData.body &&
                editorData.body.map((el, index) => (
                  <InputTextarea
                    key={index}
                    className="w-full"
                    value={editorData.body[index]}
                    onChange={(event) => handleChangeBody(event, index)}
                  />
                ))}
            </div>
            <Button
              className="mt-2"
              label="Tambah Note"
              icon="pi pi-plus"
              outlined
              onClick={handleAddNote}
            />
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default NoteEditorDialog;

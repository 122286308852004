import classes from "./TableAutoEditorElementComponent.module.css";
import { format as dateFnsFormat, parse } from "date-fns";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

const TableAutoEditorElementTextPlain = ({ config }) => {
  return (opts) => {
    return (
      <InputText
        value={opts.value}
        onChange={(e) => {
          opts.editorCallback(e.target.value);
        }}
        // onChange={(e) => opts.editorCallback(e.target.value)}
      />
    );
  };
};

const TableAutoEditorElementTextPassword = ({ config }) => {
  return (opts) => {
    return (
      <Password
        value={opts.value}
        onChange={(e) => opts.editorCallback(e.target.value)}
        toggleMask
      />
    );
  };
};

const TableAutoEditorElementTextDropDown = ({ config }) => {
  const { options } = config;
  return (opts) => {
    return (
      <Dropdown
        value={opts.value}
        onChange={(e) => opts.editorCallback(e.value)}
        options={options}
        appendTo={"self"}
        optionLabel="name"
        className="w-100"
      />
    );
  };
};

const TableAutoEditorElementNumberPlain = ({ config }) => {
  const { min_fraction_digits, max_fraction_digits, min, max } = config;
  return (opts) => {
    return (
      <InputNumber
        value={opts.value}
        locale="id-ID"
        showButtons
        buttonLayout="horizontal"
        minFractionDigits={min_fraction_digits ? min_fraction_digits : 0}
        maxFractionDigits={max_fraction_digits ? max_fraction_digits : 0}
        min={min ? min : -999999999999}
        max={max ? max : 999999999999}
        onChange={(e) => opts.editorCallback(e.value)}
      />
    );
  };
};

const TableAutoEditorElementNumberDropDown = ({ config }) => {
  const { options } = config;
  return (opts) => {
    return (
      <Dropdown
        value={opts.value}
        onChange={(e) => opts.editorCallback(e.value)}
        options={options}
        optionLabel="name"
        className="w-100"
      />
    );
  };
};

const TableAutoEditorElementBooleanPlain = ({ config }) => {
  const { label_true, label_false } = config;
  const labelTrue = label_true ?? "Ya";
  const labelFalse = label_false ?? "Tidak";
  return (opts) => {
    return (
      <div className="row-start-center gap-2">
        <InputSwitch
          checked={opts.value > 0 ? true : false}
          onChange={(e) => {
            opts.editorCallback(e.value ? 1 : 0);
          }}
        />
        {opts.value > 0 ? labelTrue : labelFalse}
      </div>
    );
  };
};

const TableAutoEditorElementDate = ({ config }) => {
  const { prime_format, min, max, show_time, time, month, year } = config;
  return (opts) => {
    const propValue = opts.value;
    let value = null;
    if (propValue && propValue !== null) {
      if (propValue.length === 10) {
        value = parse(propValue, "yyyy-MM-dd", new Date());
      } else if (propValue.length === 16) {
        value = parse(propValue, "yyyy-MM-dd HH:mm", new Date());
      } else {
        value = parse(propValue, "yyyy-MM-dd HH:mm:ss", new Date());
      }
    }

    return (
      <Calendar
        value={value ?? null}
        showIcon
        showButtonBar
        minDate={min}
        maxDate={max}
        dateFormat={prime_format ?? "dd/mm/yy"}
        showTime={show_time}
        hourFormat="24"
        timeOnly={time}
        view={month ? "month" : year ? "year" : "date"}
        onChange={(e) => {
          const jsDate = e.value;
          if (jsDate && jsDate !== null) {
            const formattedDate = dateFnsFormat(jsDate, "yyyy-MM-dd HH:mm:ss");
            opts.editorCallback(formattedDate);
          } else {
            opts.editorCallback(null);
          }
        }}
        locale="id"
      />
    );
  };
};

export {
  TableAutoEditorElementTextPlain,
  TableAutoEditorElementTextPassword,
  TableAutoEditorElementTextDropDown,
  TableAutoEditorElementNumberPlain,
  TableAutoEditorElementNumberDropDown,
  TableAutoEditorElementBooleanPlain,
  TableAutoEditorElementDate,
};

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import DialogDetailKodeProduksiWilayah from "./DialogDetailKodeProduksiWilayah";
import DialogDetailKodeProduksiDepot from "./DialogDetailKodeProduksiDepot";
import { Skeleton } from "primereact/skeleton";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import { format as dateFnsFormat, parse } from "date-fns";

const StokByKodeProduksi = (props) => {
  const {
    title,
    type, // wilayah | depot
  } = props;

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const [datas, setDatas] = useState(null);
  const [detailDialog, setDetailDialog] = useState({
    show: false,
    data: null,
    title: "",
  });
  const { requestGet, loading } = useAPIRequest();

  useEffect(() => {
    reloadData();
  }, []);

  const generateFilter = () => {
    const lastYear = new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000);
    const lastYearISO = lastYear.toISOString();
    const lastYearMysql = lastYearISO.slice(0, 10);

    let filter = ``;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
      case "controller":
        wilayah =
          selectedWilayah && selectedWilayah.id > 0 ? selectedWilayah : null;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `depot_id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `wilayah_id:=:${wilayah.id}`;
    }

    return filter;
  };

  const reloadData = async () => {
    const filter = generateFilter();
    setDatas(null);

    return await requestGet({
      fullUrl: "api/dashboard/kawil",
      params: {
        prefix: "stok_per_kode_produksi",
        filter,
        page: 1,
        take: 400,
        order: "tanggal_produksi",
        order_method: "ASC",
        with_produk: 1,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          const newDatas = [];
          for (let i = 0; i < data.length; i++) {
            const { total, tanggal_produksi, data_produk, produk_id } = data[i];

            const tanggalProduksi = new Date(tanggal_produksi);
            const tanggalExpired = new Date(
              tanggalProduksi.getTime() + 180 * 24 * 60 * 60 * 1000
            );
            const diffInTime = tanggalExpired.getTime() - new Date().getTime();
            const diffInDay = Math.floor(diffInTime / (1000 * 60 * 60 * 24));

            newDatas.push({
              produk_id,
              no: i + 1,
              deskripsi: data_produk.nama_lengkap,
              quantity: total / 100.0,
              satuan: "Tin",
              konversi: 100,
              kode_produksi: dateFnsFormat(tanggalProduksi, "dd/MM/yyyy"),
              expired: diffInDay,
            });
          }
          setDatas(newDatas);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const handleClickDetail = (e, data) => {
    e.preventDefault();
    // console.log("Clicked", data);
    setDetailDialog({
      ...detailDialog,
      data: data,
      show: true,
      title: data.deskripsi,
    });
    // setShowDetailKodeProduksi(true);
  };

  const quantityBodyTemplate = (rowData) => {
    if (rowData.quantity) {
      return rowData.quantity.toLocaleString("id-ID", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      });
    }
    return 0;
  };

  const expiredBodyTemplate = (rowData) => {
    if (rowData.expired || rowData.expired === 0) {
      if (rowData.expired <= 30) {
        return <Tag value={`${rowData.expired} Hari`} severity={"danger"} />;
      } else if (rowData.expired <= 60) {
        return <Tag value={`${rowData.expired} Hari`} severity={"warning"} />;
      } else {
        return `${rowData.expired} Hari`;
      }
    }
    return "";
  };

  const detailActionBodyTemplate = (rowData) => {
    return (
      <Button
        type="button"
        icon="pi pi-search"
        className="p-button-sm p-button-text"
        size="small"
        onClick={(e) => handleClickDetail(e, rowData)}
        style={{ width: "32px !important", height: "32px !important" }}
      />
    );
  };

  return (
    <div className="col-start-start grow-1 gap-3 w-full">
      {type === "wilayah" && (
        <DialogDetailKodeProduksiWilayah
          title={detailDialog.title}
          data={detailDialog.data}
          visible={detailDialog.show}
          onHide={() => setDetailDialog({ ...detailDialog, show: false })}
        />
      )}
      {type === "depot" && (
        <DialogDetailKodeProduksiDepot
          title={detailDialog.title}
          data={detailDialog.data}
          visible={detailDialog.show}
          onHide={() => setDetailDialog({ ...detailDialog, show: false })}
        />
      )}
      <span className="text-sm font-semibold text-zinc-700">{title}</span>
      {datas ? (
        <DataTable
          className="w-full"
          value={datas}
          resizableColumns
          scrollable
          scrollHeight="20.5rem"
        >
          <Column field="no" header="#" />
          <Column field="deskripsi" header="Produk" />
          <Column
            field="quantity"
            header="Qty"
            body={quantityBodyTemplate}
            alignHeader={"right"}
            style={{ textAlign: "right" }}
          />
          <Column field="satuan" header="UOM" />
          <Column
            field="kode_produksi"
            header="Kode Produksi"
            alignHeader={"center"}
            style={{ textAlign: "center" }}
          />
          <Column
            field="expired"
            header="Expired"
            body={expiredBodyTemplate}
            alignHeader={"right"}
            style={{ textAlign: "right" }}
          />
          <Column
            field="action"
            header=""
            body={detailActionBodyTemplate}
            alignHeader={"center"}
            style={{ textAlign: "center" }}
          />
        </DataTable>
      ) : (
        <div className="col-start-start w-full gap-3">
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
        </div>
      )}
    </div>
  );
};

export default StokByKodeProduksi;

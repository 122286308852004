import classes from "./ProdukEditorDialog.module.css";

import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import useCrudEditorControl from "../../../../custom_hooks/complex/useCrudEditorControl";

import configs from "./ProdukConfigs";
import ProdukKategoriSelectorDialog from "../../../../dialogs/selectors/ProdukKategoriSelectorDialog";
import { TabPanel, TabView } from "primereact/tabview";
import ProdukKelompokTargetSelectorDialog from "../../../../dialogs/selectors/ProdukKelompokTargetSelectorDialog";

const title = configs.dialog.title;

const ProdukEditorDialog = (props) => {
  const {
    generateSelectorDialog,
    handleInputChange,
    handleMultipleInputChange,
    elements,
    validator,
  } = useCrudEditorControl({
    value: props.value,
    setValue: props.setValue,
    configs: props.configs,
    error: props.error,
    customElements: props.customElements,
  });

  const convertValue = () => {
    return { ...props.value };
  };

  const validation = () => {
    return validator({
      configs: props.configs,
      value: props.value,
      onError: (error) => props.setError(error),
      isEmpty: "kategori_id kode nama ",
    });
  };

  // add_generate_selector_dialog
  generateSelectorDialog({
    field: "kategori_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <ProdukKategoriSelectorDialog
          single={false}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });
  generateSelectorDialog({
    field: "produk_kelompok_target_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <ProdukKelompokTargetSelectorDialog
          single={false}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });


  const dialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={props.onHide}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={(e) => {
          const valid = validation();
          if (valid) {
            const newValue = convertValue();
            props.onSave(e, newValue);
          }
        }}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "48rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={title}
      modal
      className={`p-fluid`}
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <div className={`${classes.wrapper} col-start-start relative h-100`}>
        <TabView className="w-100">
          <TabPanel header="General">
            <div className={`row-start-start w-100`}>
              <div className="col-start-start w-100 gap-3">
                {elements.kategori_id}
                {elements.produk_kelompok_target_id}
                {elements.kode}
                {elements.nama}
              </div>
              <Divider style={{ height: "20rem" }} layout="vertical" />
              <div className="col-start-start w-100 gap-3">
                  {elements.rekening}
                <div className="row-between-center w-100 gap-3">
                  {elements.saldo}
                  {elements.bandrol}
                </div>
                {elements.nama_lengkap}
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Satuan">
            <div className={`row-start-start w-100`}>
              {elements.data_konversis}
            </div>
          </TabPanel>
        </TabView>

        {props.loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ProdukEditorDialog;

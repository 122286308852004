import classes from "./StokMutasiMainPage.module.css";
import { TabView, TabPanel } from "primereact/tabview";
import StokMutasiArsipPage from "./StokMutasiArsipPage";

import configs from "./StokMutasiConfigs";
import StokMutasiEditorPage from "./StokMutasiEditorPage";
import useMDMainPageControl from "../../../../../custom_hooks/complex/useMDMainPageControl";

const labelNew = configs.master.labelNew;
const title = configs.master.title;

const StokMutasiMainPage = (props) => {
  const {
    addNewEditor,
    removeSelectedEditor,
    changeLabeEditor,
    requestFocus,
    handleTabChange,
    activeIndexTab,
    listEditorData,
  } = useMDMainPageControl({ title, labelNew });

  return (
    <div className={`${classes.wrapper} col-start-start p-4 gap-4 w-100`}>
      <TabView
        activeIndex={activeIndexTab}
        onTabChange={(e) => handleTabChange(e.index)}
        className="w-100"
        renderActiveOnly={false}
      >
        <TabPanel key={0} header="Arsip" leftIcon="pi pi-book mr-2">
          <StokMutasiArsipPage
            addNewEditor={addNewEditor}
          ></StokMutasiArsipPage>
        </TabPanel>
        {listEditorData.map((editorValue) => {
          return (
            <TabPanel
              key={editorValue.id > 0 ? editorValue.id : editorValue.unique}
              header={editorValue.label}
            >
              <StokMutasiEditorPage
                removeSelectedEditor={removeSelectedEditor}
                changeLabeEditor={(newLabel) => {
                  changeLabeEditor(newLabel, editorValue.unique);
                }}
                requestFocus={() => requestFocus(editorValue.unique)}
                id={editorValue.id}
              />
            </TabPanel>
          );
        })}
      </TabView>
    </div>
  );
};

export default StokMutasiMainPage;

import { RadioButton } from "primereact/radiobutton";
import { useState } from "react";

const RadioButtonGroup = (props) => {
  const { items, name, onChange, value } = props;

  return (
    <div className="row-start-start gap-5">
      {items.map((e) => (
        <div className="row-start-start" key={e.value}>
          <RadioButton
            key={e.value}
            inputId={e.id}
            name={name}
            value={e.value}
            checked={value === e.value}
            onChange={(val) => {
              if (onChange) {
                onChange(val.value);
              }
            }}
          />
          <label className="text-sm ml-2" htmlFor={e.id}>
            {e.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioButtonGroup;

import { subDays, addDays } from "date-fns";

const OutletConfigs = {
  master: {
    title: "Outlet",
    apiName: "masterdata/outlet",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: { 
      // add_params
      with_jurusan: 1,
    },
  },
  dialog: {
    title: "Outlet",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Jurusan",
      field: "jurusan_id",
      type: "object",
      object_name: "data_jurusan",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Kode",
      field: "kode",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Nama",
      field: "nama",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Jenis Agen",
      field: "jenis_agen",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Alamat",
      field: "alamat",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Telepon",
      field: "telepon",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Contact Person",
      field: "contact_person",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
  ],
};

export default OutletConfigs;

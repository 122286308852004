import { Button } from "primereact/button";
import useEditorControl from "../../../../custom_hooks/complex/useEditorControl";
import configs from "./ManualInputConfigs";
import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import onErrorToast from "../../../../utils/OnErrorToast";
import onSuccessToast from "../../../../utils/OnSuccessToast";
import { Divider } from "primereact/divider";

const apiName = "setting";
let apiSuffix;
const customElements = [];
const isForm = false;

const listFieldName = [
  "bon_kredit_total",
  "bon_kredit_belum_tagih_hari_ini",
  "bon_kredit_belum_tagih_semalam",
  "bon_kredit_belum_jatuh_tempo",
  "bon_kredit_jatuh_tempo_hari_ini",
  "bon_kredit_jatuh_tempo_semalam",
];
// const listFieldName = {"bon_kredit_total":10,"bon_kredit_belum_tagih_hari_ini":20,"bon_kredit_belum_tagih_semalam":30,"bon_kredit_belum_jatuh_tempo":40,"bon_kredit_jatuh_tempo_hari_ini":50,"bon_kredit_jatuh_tempo_semalam":60};

const ManualInputPage = (props) => {
  const toast = useRef(null);
  const {
    // methods
    onSave,
    onFetchData,
    handleInputChange,
    handleMultipleInputChange,
    // states
    value,
    setValue,
    setError,
    loading,
    elements,
    //utils
    validator,
  } = useEditorControl({
    configs,
    isForm,
    apiName,
    apiSuffix,
    customElements,
  });

  const fetchData = async () => {
    onFetchData({
      id: 1,
      onError: ({ message, data }) => {
        onErrorToast({ toast: toast, message: message, data: data });
      },
      onSuccess: ({ message, data }) => {
        if (data) {
          const parsedData = JSON.parse(data.value_long_text.toString());
          const newValue = {};
          for (const field in parsedData) {
            newValue[field] = parsedData[field];
          }
          setValue(newValue);
        }
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validation = () => {
    let valid = true;
    return valid;
  };

  const convertValue = () => {
    const newValue = {};
    for (const field of listFieldName) {
      if (value[field]) {
        newValue[field] = value[field];
      }
    }
    return {
      id: 1,
      label: "manual_input",
      value_type: "text",
      value_long_text: JSON.stringify(newValue),
    };
  };

  const handleOnSave = async (event) => {
    event.preventDefault();
    const valid = validation();
    if (valid) {
      const newValue = convertValue();

      const data = await onSave({
        newValue,
        onError: ({ message, data }) => {
          onErrorToast({ toast: toast, message: message, data: data });
        },
        onSuccess: ({ data, message }) => {
          onSuccessToast({
            toast: toast,
            message: "Manual input berhasil di-update",
            data: data,
          });
        },
      });
    }
  };

  return (
    <div className={`col-start-start p-4 gap-4 w-100`}>
      <Toast ref={toast} />
      <div className="elevated-card col-center-center w-100 gap-3">
        <div className={`row-start-start w-100 gap-2`}>
          <div className="col-start-start gap-3">
            {elements.bon_kredit_total}
            {elements.bon_kredit_belum_tagih_hari_ini}
            {elements.bon_kredit_belum_tagih_semalam}
          </div>
          <Divider className="h-[14.5rem]" layout="vertical" />
          <div className="col-start-start gap-3">
            {elements.bon_kredit_belum_jatuh_tempo}
            {elements.bon_kredit_jatuh_tempo_hari_ini}
            {elements.bon_kredit_jatuh_tempo_semalam}
          </div>
        </div>
        <div className="row-end-center w-100 pt-4">
          <Button
            icon="pi pi-save"
            loading={loading}
            label="Simpan"
            onClick={handleOnSave}
          />
        </div>
      </div>
    </div>
  );
};

export default ManualInputPage;

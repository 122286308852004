import classes from "./TransportEditorDialog.module.css";

import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import useCrudEditorControl from "../../../../custom_hooks/complex/useCrudEditorControl";

import configs from "./TransportConfigs";
import GudangSelectorDialog from "../../../../dialogs/selectors/GudangSelectorDialog";
import DepotSelectorDialog from "../../../../dialogs/selectors/DepotSelectorDialog";

const title = configs.dialog.title;

const TransportEditorDialog = (props) => {
  const {
    generateSelectorDialog,
    handleInputChange,
    handleMultipleInputChange,
    elements,
    validator,
  } = useCrudEditorControl({
    value: props.value,
    setValue: props.setValue,
    configs: props.configs,
    error: props.error,
    customElements: props.customElements,
  });

  const convertValue = () => {
    return { ...props.value };
  };

  const validation = () => {
    return validator({
      configs: props.configs,
      value: props.value,
      onError: (error) => props.setError(error),
      isEmpty: "depot_id gudang_id ",
    });
  };

  // add_generate_selector_dialog
  generateSelectorDialog({
    field: "depot_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <DepotSelectorDialog
          single={false}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "gudang_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <GudangSelectorDialog
          single={false}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  const dialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={props.onHide}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={(e) => {
          const valid = validation();
          if (valid) {
            const newValue = convertValue();
            props.onSave(e, newValue);
          }
        }}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "45rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={title}
      modal
      className={`p-fluid`}
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <div className="col-start-center relative h-100">
        <div className={`${classes.wrapper} col-start-start w-100 gap-3`}>
          <div className="row-start-start w-100 gap-3">
            {elements.depot_id}
            {elements.gudang_id}
          </div>
          <div className="row-start-start w-100 gap-3">
            {elements.jenis_transport}
            {elements.merek}
          </div>
          <div className="row-start-start w-100 gap-3">
            {elements.nomor_mesin}
            {elements.nomor_rangka}
          </div>
          <div className="row-start-start w-100 gap-3">
            {elements.tahun}
            {elements.tanggal_beli}
            {elements.tanggal_stnk}
          </div>
          <div className="row-start-start w-100 gap-3">
            {elements.jumlah_roda}
            {elements.jenis_bbm}
            {elements.jenis_mobil}
          </div>
          <div className="row-start-start w-100 gap-3">
            {elements.speedo_awal}
            {elements.speedo_akhir}
          </div>
          <div className="row-start-start w-100 gap-3">
            {elements.nama}
            {elements.nomor_polisi}
          </div>
          <div className="row-start-start w-100 gap-3">
            {elements.dalam_kota}
            {elements.luar_kota}
          </div>
          <div className="row-start-start w-100 gap-3">{elements.status}</div>
          <div className="row-start-start w-100 gap-3">
            {elements.keterangan}
          </div>
          <div className="col-start-start"></div>
        </div>
        {props.loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default TransportEditorDialog;

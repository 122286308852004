import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Editor } from "primereact/editor";
import { Image } from "primereact/image";
import { useEffect, useState } from "react";

const ChatElement = (props) => {
  const { user, message } = props.chat;
  return (
    <div
      className={`${user ? "col-start-start" : "col-start-end"} w-full gap-2`}
    >
      {message.map(({ id, content, time, first }) => (
        <div key={id} className="row-start-start gap-2">
          <div className="row-center-center min-w-[2.5rem]">
            {user && first && user.image && <Avatar image={user.image} />}
          </div>
          <div
            className={`col-start-start shadow rounded ${
              user ? "bg-white" : "bg-[#d9f7d0]"
            } px-3 py-1`}
          >
            {user && first && user.name && (
              <span
                className={`font-bold `}
                style={{ color: user.color ?? "rgb(39,39,42)" }}
              >
                {user.name}
              </span>
            )}
            <span> {content} </span>
            <span className="text-xs font-semibold text-right text-zinc-400 w-full pr-1">
              {" "}
              {time}{" "}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
  //   return <span>{message.content}</span>;
};

const ChatList = ({ list }) => {
  return (
    <div className="col-start-start w-full bg-[#EFEBE9] px-2 py-3 gap-5">
      {list.map((chat) => (
        <ChatElement key={chat.id} chat={chat} />
      ))}
    </div>
  );
};

const MessageContent = () => {
  const [replyContent, setReplyContent] = useState("");
  const [listChats, setListChats] = useState([]);

  // #4CAF50
  // #03A9F4
  // #00BCD4
  // #FF9800
  // #FF5722
  // #607D8B

  useEffect(() => {
    setListChats([
      {
        id: 1,
        user: {
          name: "Eliot",
          image:
            "https://primefaces.org/cdn/primereact/images/avatar/asiyajavayant.png",
          color: "#4CAF50",
        },
        message: [
          {
            id: 1,
            first: true,
            content: "Advise if this project is profitable",
            time: "08/03/2024 16:10",
          },
          {
            id: 2,
            content: "Please inform ASAP",
            time: "08/03/2024 16:10",
          },
          {
            id: 3,
            content: "After that, arrange meeting",
            time: "08/03/2024 16:11",
          },
        ],
      },
      {
        id: 2,
        user: {
          name: "Zoe",
          image:
            "https://primefaces.org/cdn/primereact/images/avatar/onyamalimba.png",
          color: "#00BCD4",
        },
        message: [
          {
            id: 1,
            first: true,
            content: "Sounds Good",
            time: "08/03/2024 16:20",
          },
        ],
      },
      {
        id: 3,
        user: null,
        message: [
          {
            id: 1,
            first: true,
            content: "Ok, I will do my best",
            time: "08/03/2024 16:22",
          },
        ],
      },
      {
        id: 4,
        user: {
          name: "Eliot",
          image:
            "https://primefaces.org/cdn/primereact/images/avatar/ionibowcher.png",
          color: "#4CAF50",
        },
        message: [
          {
            id: 1,
            first: true,
            content: "Thank you, good luck",
            time: "08/03/2024 16:25",
          },
        ],
      },
    ]);
  }, []);

  return (
    <div className="col-start-start w-full">
      <div className="row-between-center w-full">
        <div className="row-start-center gap-3">
          <Avatar
            image={
              "https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
            }
            size="large"
            shape="circle"
          />
          <div className="col-start-start">
            <span className="leading-1 font-semibold">{"Jhon Doe"}</span>
            <span className="text-sm text-zinc-500">{"Kadepot 201"}</span>
          </div>
        </div>
        <div className="col-end-end">
          <span className="text-sm font-semibold text-zinc-400">
            08-03-2024 10:30 (10 hari yang lalu)
          </span>
        </div>
      </div>
      <span className="text-xl font-bold text-zinc-700 pt-8 pb-6">
        Permintaan Mutasi Stok Dari Depot 201 ke 202
      </span>
      {/* content */}
      <div className="col-start-start pb-5">
        <p> Hello Orlando, </p>
        <br />
        <p>
          Please see the project status in the pdf I attached. The Project looks
          good, and we are confident that we will complete it on time.
        </p>
        <br />
        <p>Regards,</p>
        <p>Jhon Doe</p>
      </div>
      <Divider className="my-1" />
      {/* attachment */}
      <div className="col-start-start pt-2">
        <span className="font-semibold text-sm">2 Attachment</span>
        <div className="row-start-start py-3 gap-3">
          <div className="row-center-center shadow p-2">
            <Image
              src="https://images.pexels.com/photos/20360845/pexels-photo-20360845/free-photo-of-a-white-tree-with-blue-sky-in-the-background.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              width="140"
            />
          </div>
          <div className="row-center-center shadow p-2">
            <Image
              src="https://images.pexels.com/photos/793124/pexels-photo-793124.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              width="140"
            />
          </div>
        </div>
      </div>
      <Divider className="my-1" />
      <ChatList list={listChats} />
      <Divider className="my-1" />
      <div className="col-start-start py-2 w-full gap-2">
        <Editor
          className="w-full"
          spellCheck={false}
          value={replyContent}
          onTextChange={(e) => setReplyContent(e.htmlValue)}
          style={{ height: "150px", width: "100%" }}
        />
        <div className="row-end-center w-full">
          <Button icon="pi pi-send" severity="secondary" label="Send Chat" />
        </div>
      </div>

      <Divider className="my-1" />
      {/* control button */}
      <div className="row-start-start pt-3 w-full gap-2">
        <Button icon="pi pi-check" label="Approve & Continue" />
        <Button icon="pi pi-check" label="Finish" />
      </div>
    </div>
  );
};

export default MessageContent;

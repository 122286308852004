import RadialChart from "../../../../../components/chart/RadialChart";
import { getSalesClassColor, getSalesColor } from "../TargetRealisasiPage";
import { Skeleton } from "primereact/skeleton";

const SemesterComponent = (props) => {
  const { target, prediksi, realisasi, loading, disable } = props;

  // console.log(`${target} - ${[prediksi]} - ${realisasi} - ${loading}`);

  return (
    <div className="col-start-center gap-8 w-100 h-[24rem]">
      {!loading ? (
        <div className="col-start-center gap-8 w-100 h-full">
          {target > 0 && (
            <RadialChart
              radius={75}
              target={(prediksi / target) * 100.0}
              value={(realisasi / target) * 100.0}
              dimension={180}
              color={getSalesColor((realisasi / target) * 100)}
            />
          )}
          <div className="col-between-center gap-2 h-full ">
            <div className="col-start-center gap-2 h-full">
              <div className="col-start-center">
                <span className="text-xs font-semibold text-zinc-400">
                  Target
                </span>
                <span
                  className={`text-xl font-semibold ${
                    disable ? "text-zinc-400" : ""
                  }`}
                >
                  {target.toLocaleString("id", { maximumFractionDigits: 0 })}K
                  Btg
                </span>
              </div>
              <div className="col-start-center">
                <span className="text-xs font-semibold text-zinc-400">
                  Prediksi
                </span>
                <span
                  className={`text-xl font-semibold ${
                    disable ? "text-zinc-400" : "text-[#818cf8]"
                  } `}
                >
                  {prediksi.toLocaleString("id", { maximumFractionDigits: 0 })}K
                  Btg
                </span>
              </div>
            </div>
            <div className="col-start-center">
              <span className="text-xs font-semibold text-zinc-400">
                Realisasi
              </span>
              <span
                className={`text-xl font-semibold ${
                  disable
                    ? "text-zinc-400"
                    : getSalesClassColor((realisasi / target) * 100.0)
                }`}
              >
                {realisasi.toLocaleString("id", { maximumFractionDigits: 0 })}K
                Btg
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-center-center gap-4 pt-2">
          <Skeleton shape="circle" width="10rem" height="10rem" />
          <Skeleton width="12rem" height="3rem" />
          <Skeleton width="12rem" height="3rem" />
          <Skeleton width="12rem" height="3rem" />
        </div>
      )}
    </div>
  );
};

export default SemesterComponent;

import { Button } from "primereact/button";
import { format as dateFnsFormat, parse } from "date-fns";

const NoteSingle = (props) => {
  const { title, created_at, updated_at, body, onEdit, onDelete } = props;

  const date = updated_at ?? created_at;
  const parsedDate = new Date(date);
  const dateText = dateFnsFormat(parsedDate, "dd/MM/yy HH:mm");
  const notes = body ? body.split("|||") : [];

  return (
    <div className="sticky-note col-start-start w-full bg-[#FFF7D1] border rounded shadow pb-1">
      <div className="col-start-start bg-[#FFF2AB] w-full min-h-[2rem] px-2 py-1">
        <div className="row-end-center gap-1 w-full">
          <span className="text-xs font-semibold text-right text-[#999999] mr-2">
            {dateText}
          </span>
          <Button
            className="text-[#999999]"
            icon="pi pi-pencil"
            style={{ maxHeight: "1.8rem", maxWidth: "1.8rem" }}
            rounded
            text
            onClick={(event) => {
              event.preventDefault();
              if (onEdit) {
                onEdit();
              }
            }}
          />
          <Button
            className="text-[#999999]"
            icon="pi pi-trash"
            style={{ maxHeight: "1.8rem", maxWidth: "1.8rem" }}
            rounded
            text
            onClick={(event) => {
              event.preventDefault();
              if (onDelete) {
                onDelete();
              }
            }}
          />
        </div>
        <div className="row-between-center w-full">
          <span className="text-[#333333] font-semibold">{title}</span>
        </div>
      </div>
      <div className="col-start-start px-2 py-2">
        <ul className="list-disc pl-5 text-[#333333]">
          {notes.map((el, index) => (
            <li key={index} className="text-[#333333] text-[0.88rem] py-1">
              {el}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NoteSingle;

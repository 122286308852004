import classes from "./barcode-dialog.module.css";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useRef, useState } from "react";
import useAPIRequest from "../../../../../../custom_hooks/simple/useAPIRequest";
import onErrorToast from "../../../../../../utils/OnErrorToast";
import { Toast } from "primereact/toast";
import onSuccessToast from "../../../../../../utils/OnSuccessToast";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import BarcodeDialogTotalComponent from "../../../../../../components/sales/BarcodeDialogTotalComponent";

const deleteBgColor = "rgb(254 226 226)";

const BarcodeDialog = (props) => {
  const toast = useRef(null);
  const inputText = useRef(null);

  const { lockSalesMode, rowData, onChangeListData, id } = props;
  const [barcodeInput, setBarcodeInput] = useState("");
  const [deleteMode, setDeleteMode] = useState(false);
  const [listData, setListData] = useState([]);

  const { requestPost, requestDelete, loading } = useAPIRequest({
    url: "api/transaksi/barcode",
  });

  useEffect(() => {    
    if (props.visible) {
      setBarcodeInput("");
      setDeleteMode(rowData.id);
    }
  }, [props.visible]);

  const generateListDataFromRawList = (rawList) => {
    const newList = [...rawList].reverse();
    let urutan = 1;
    for (const data of newList) {
      data.urutan = urutan++;
    }
    return newList;
  };

  useEffect(() => {
    if (rowData && rowData.data_barcodes && rowData.data_barcodes.length > 0) {
      const newList = generateListDataFromRawList(rowData.data_barcodes);
      setListData(newList);
    } else {
      setListData([]);
    }
  }, [rowData]);

  const saveBarcode = async ({ barcode, detailId }) => {
    console.log("HANDLE ADD BARCODE");
    const body = {
      stok_masuk_id: id,
      barcode: barcode,
    };
    await requestPost({
      apiUrl: "add",
      isForm: false,
      body,
      onError: ({ message, data }) => {
        setBarcodeInput("");
        onErrorToast({ toast: toast, message: message, data: data });
        inputText.current.focus();
      },
      onSuccess: ({ message, data }) => {
        console.log(message, data);
        if (data.data_stok_masuk_detil) {
          onChangeListData({
            detailId: data.data_stok_masuk_detil.id,
            newRow: data.data_stok_masuk_detil,
          });
          if (data.data_stok_masuk_detil.data_barcodes) {
            const newList = generateListDataFromRawList(
              data.data_stok_masuk_detil.data_barcodes
            );
            setListData(newList);
          }
        }
        onSuccessToast({
          toast: toast,
          message: "Barcode Berhasil Ditambahkan",
          data: data,
        });
        setBarcodeInput("");
        inputText.current.focus();
      },
    });
  };

  const removeBarcode = async ({ id }) => {
    await requestDelete({
      apiUrl: "delete",
      ids: [id],
      onError: ({ message, data }) => {
        onErrorToast({ toast: toast, message: message, data: data });
      },
      onSuccess: ({ message, data }) => {
        console.log(message, data);
        const newRowData = { ...rowData };
        newRowData.quantity = newRowData.quantity - 1;
        const foundIndex = newRowData.data_barcodes.findIndex(
          (e) => e.id === id
        );
        if (foundIndex >= 0) {
          newRowData.data_barcodes.splice(foundIndex, 1);
          // const newList = generateListDataFromRawList(newRowData.data_barcodes);
          // setListData(newList);
        }
        onChangeListData({
          detailId: newRowData.id,
          newRow: newRowData,
        });
        onSuccessToast({
          toast: toast,
          message: "Barcode berhasil dihapus",
          data: data,
        });
        setBarcodeInput("");
        inputText.current.focus();
      },
    });
  };

  const handleRemoveBarcode = ({ barcode, barcodeId }) => {
    if (listData.length === 0) {
      onErrorToast({
        toast,
        message: "Barcode tidak dapat ditemukan",
        data: {},
      });
      inputText.current.focus();
      return;
    }
    if (barcode) {
      const foundIndex = listData.findIndex((e) => e.barcode === barcode);
      if (foundIndex < 0) {
        onErrorToast({
          toast,
          message: "Barcode tidak dapat ditemukan",
          data: {},
        });
        inputText.current.focus();
        return;
      }
    }
    let id = -1;
    if (barcodeId && barcodeId > 0) {
      id = barcodeId;
    } else if (barcode && barcode.length > 0) {
      const foundData = listData.find((e) => e.barcode === barcode);
      if (foundData) {
        id = foundData.id;
      }
    }
    if (id > 0) {
      const accept = async () => {
        await removeBarcode({ id });
      };
      const reject = () => {};
      confirmDialog({
        message: "Menghapus barcode, lanjutkan?",
        header: "Konfirmasi",
        icon: "pi pi-exclamation-triangle",
        acceptClassName: "p-button-danger",
        accept,
        reject,
      });
    } else {
      onErrorToast({
        toast,
        message: "Barcode tidak dapat ditemukan",
        data: {},
      });
    }
  };

  const handleInputChange = (event) => {
    setBarcodeInput(event.target.value);
  };

  const handleProcessBarcode = async (event) => {
    event.preventDefault();
    const newBarcodeInput = barcodeInput.trim();
    if (newBarcodeInput.length === 0) {
      onErrorToast({ toast, message: "Barcode tidak boleh kosong", data: {} });
      inputText.current.focus();
      return;
    }
    if (deleteMode) {
      await handleRemoveBarcode({ barcode: newBarcodeInput });
    } else {
      await saveBarcode({ barcode: newBarcodeInput });
    }
  };

  const handleOnKeyDown = (event) => {
    // when enter key down
    if (event.keyCode === 13) {
      handleProcessBarcode(event);
    }
  };

  const deleteBodyTemplate = (row) => {
    return (
      <Button
        text
        label="Delete"
        severity="danger"
        icon="pi pi-trash"
        onClick={(e) => {
          handleRemoveBarcode({ barcodeId: row.id });
        }}
        style={{ width: "6rem", height: "1.8rem" }}
      />
    );
  };

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "40rem" }}
      headerStyle={{ backgroundColor: deleteMode ? deleteBgColor : "white" }}
      contentStyle={{ backgroundColor: deleteMode ? deleteBgColor : "white" }}
      header={
        <div className="row-start-center gap-3">
          <i className="pi pi-qrcode"></i>
          <span>{deleteMode ? "Hapus Barcode" : "Scan Barcode"}</span>
        </div>
      }
      modal
      className={`p-fluid`}
      onHide={props.onHide}
    >
      <div className="col-start-end w-100 py-5 px-2 relative h-100 gap-3">
        <Toast ref={toast} />
        <div className="col-start-start w-100 gap-4">
          <div className="row-start-center gap-1 w-100 left-0">
            <div className="row-center-center grow">
              <InputText
                ref={inputText}
                className="text-center h-[3.5rem]"
                autoFocus
                value={barcodeInput}
                onChange={handleInputChange}
                onKeyDown={handleOnKeyDown}
              ></InputText>
            </div>
            <Button
              // className="w-[3.5rem]"
              icon="pi pi-caret-right"
              size="large"
              severity={deleteMode ? "danger" : "primary"}
              style={{
                minHeight: "3.5rem",
                minWidth: "3.5rem",
              }}
              onClick={handleProcessBarcode}
            ></Button>
            <div className="col-center-center gap-2 px-2 w-[8rem]">
              <span
                className={`text-center ${
                  deleteMode ? `text-red-500` : `text-slate-400`
                } text-sm font-semibold leading-none`}
              >
              </span>
            </div>
          </div>
          <DataTable
            value={listData}
            stripedRows
            columnResizeMode="expand"
            size="small"
            className={`${classes.customTable} w-100`}
            // tableClassName="jancuk"
            // tableStyle={{  }}
            scrollable
            scrollHeight="26.5rem"
            key={"id"}
            style={{ maxHeight: "25rem", minHeight: "25rem" }}
          >
            <Column field="urutan" header="No"></Column>
            <Column field="barcode" header="Barcode"></Column>
            {deleteMode && (
              <Column
                headerStyle={{ textAlign: "center" }}
                header=""
                body={deleteBodyTemplate}
              ></Column>
            )}
          </DataTable>
        </div>
        <BarcodeDialogTotalComponent total={listData.length} />
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default BarcodeDialog;

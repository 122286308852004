import { subDays, addDays } from "date-fns";

const TransportConfigs = {
  master: {
    title: "Transport",
    apiName: "masterdata/transport",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: {
      // add_params
      with_depot: 1,
      with_gudang: 1,
    },
  },
  dialog: {
    title: "Transport",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Depot",
      field: "depot_id",
      type: "object",
      object_name: "data_depot",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Gudang",
      field: "gudang_id",
      type: "object",
      object_name: "data_gudang",
      object_field: "nama",
      editor: true,
      table: false,
      filter: true,
    },
    {
      name: "Jenis",
      field: "jenis_transport",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Merek",
      field: "merek",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Nomor Polisi",
      field: "nomor_polisi",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Nama",
      field: "nama",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Nomor Mesin",
      field: "nomor_mesin",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Nomor Rangka",
      field: "nomor_rangka",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Tahun",
      field: "tahun",
      type: "date_year",
      align: "center",
      editor: true,
      format: "yyyy",
      object_name: "",
      object_field: "",
      table: true,
    },
    {
      name: "Tanggal Beli",
      field: "tanggal_beli",
      type: "date",
      align: "center",
      table: false,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
      object_name: "",
      object_field: "",
    },
    {
      name: "Tanggal STNK",
      field: "tanggal_stnk",
      type: "date",
      align: "center",
      table: false,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
      object_name: "",
      object_field: "",
    },
    {
      name: "Jenis BBM",
      field: "jenis_bbm",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Dalam Kota",
      field: "dalam_kota",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: true,
    },
    {
      name: "Speedo Awal",
      field: "speedo_awal",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: false,
    },
    {
      name: "Speedo Akhir",
      field: "speedo_akhir",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: false,
    },
    {
      name: "Keterangan",
      field: "keterangan",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Jenis Mobil",
      field: "jenis_mobil",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Jumlah Roda",
      field: "jumlah_roda",
      table: false,
      filter: true,
      editor: true,
      type: "number_plain",
      align: "right",
      object_name: "",
      object_field: "",
    },
    {
      name: "Status",
      field: "status",
      type: "number_dropdown",
      editor: true,
      filter: true,
      align: "center",
      default_value: 1,
      options: [
        { name: "OFF", value: 0 },
        { name: "OK", value: 1 },
        { name: "Rusak", value: 2 },
      ],
      table: true,
    },
    {
      name: "Luar Kota",
      field: "luar_kota",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: false,
    },
  ],
};

export default TransportConfigs;

import { Divider } from "primereact/divider";
import React from "react";
import { FaArrowDown, FaArrowUp, FaMinus } from "react-icons/fa6";

const DashboardHeaderBadge = (props) => {
  const {
    disabled,
    className,
    icon,
    color,
    onClick,
    title,
    notifType, //up, down, neutral
    notifLabel,
  } = props;

  let { labels, values } = props;

  if (!labels) {
    labels = [];
  }
  if (!values) {
    values = [];
  }

  const newIcon = React.cloneElement(icon ?? <FaMinus />, {
    color: "white",
  });

  const newColor = color ?? "var(--color-primary)";

  let notifColor = "text-zinc-600";
  if (notifType === "up") {
    notifColor = "text-green-500";
  } else if (notifType === "down") {
    notifColor = "text-red-400";
  }

  const contents = [];
  for (let i = 0; i < labels.length; i++) {
    const label = labels[i];
    const value = values[i];

    if (contents.length > 0) {
      contents.push(
        <Divider key={i + "-d"} layout="vertical" className="h-[3.8rem]" />
      );
    }

    contents.push(
      <div key={i} className="col-center-center">
        <span className="text-xl font-semibold text-zinc-600">
          {typeof value === "number" ? value.toLocaleString("id") : value}
        </span>
        <span className="text-sm text-zinc-600 text-center">{label}</span>
      </div>
    );
  }

  return (
    <div
      className={`elevated-card-tight-vertical col-start-start gap-3 cursor-pointer hover:translate-y-[-0.3rem] active:translate-y-0 active:shadow transition-all duration-300 ${className}`}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      style={
        disabled
          ? {
              boxShadow: "none",
              backgroundColor: "rgb(228,228,231)",
            }
          : {}
      }
    >
      <div className="row-start-center gap-2">
        {
          <div
            className={`row-center-center ${
              disabled ? `bg-zinc-400` : ``
            } w-[1.8rem] h-[1.8rem] rounded`}
            style={disabled ? {} : { backgroundColor: newColor }}
          >
            {newIcon}
          </div>
        }
        <span
          className={`font-semibold text-lg`}
          style={{ color: disabled ? "rgb(161,161,170)" : newColor }}
        >
          {title ?? "-"}
        </span>
      </div>
      <div className="row-center-center w-full gap-3">{contents}</div>
      {notifType && notifLabel && (
        <div className="row-center-center w-full gap-2">
          {notifType === "up" && (
            <FaArrowUp className={`${notifColor}`} size={"0.7rem"} />
          )}
          {notifType === "down" && (
            <FaArrowDown className={`${notifColor}`} size={"0.7rem"} />
          )}
          <span
            className={`${
              notifLabel ? notifColor : "text-[rgb(228,228,231)]"
            } text-sm text-center`}
          >
            {notifLabel ?? "-"}
          </span>
        </div>
      )}
    </div>
  );
  // const {
  //   disabled,
  //   className,
  //   icon,
  //   color,
  //   onClick,
  //   title,
  //   labelLeft,
  //   labelRight,
  //   valueLeft,
  //   valueRight,
  //   notifType, //up, down, neutral
  //   notifLabel,
  // } = props;

  // const newIcon = React.cloneElement(icon ?? <FaMinus />, {
  //   color: "white",
  // });

  // const newColor = color ?? "var(--color-primary)";

  // let notifColor = "text-zinc-600";
  // if (notifType === "up") {
  //   notifColor = "text-green-500";
  // } else if (notifType === "down") {
  //   notifColor = "text-red-400";
  // }

  // return (
  //   <div
  //     className={`elevated-card-tight-vertical col-start-start gap-3 cursor-pointer hover:translate-y-[-0.3rem] active:translate-y-0 active:shadow transition-all duration-300 ${className}`}
  //     onClick={(e) => {
  //       if (onClick) {
  //         onClick(e);
  //       }
  //     }}
  //     style={
  //       disabled
  //         ? {
  //             boxShadow: "none",
  //             backgroundColor: "rgb(228,228,231)",
  //           }
  //         : {}
  //     }
  //   >
  //     <div className="row-start-center gap-2">
  //       {
  //         <div
  //           className={`row-center-center ${
  //             disabled ? `bg-zinc-400` : ``
  //           } w-[1.8rem] h-[1.8rem] rounded`}
  //           style={disabled ? {} : { backgroundColor: newColor }}
  //         >
  //           {newIcon}
  //         </div>
  //       }
  //       <span
  //         className={`font-semibold text-lg`}
  //         style={{ color: disabled ? "rgb(161,161,170)" : newColor }}
  //       >
  //         {title ?? "-"}
  //       </span>
  //     </div>
  //     <div className="row-center-center w-full gap-3">
  //       <div className="col-center-center">
  //         <span className="text-xl font-semibold text-zinc-600">
  //           {valueLeft}
  //         </span>
  //         <span className="text-sm text-zinc-600 text-center">{labelLeft}</span>
  //       </div>
  //       <Divider layout="vertical" className="h-[3.8rem]" />
  //       <div className="col-center-center">
  //         <span className="text-xl font-semibold text-zinc-600">
  //           {valueRight}
  //         </span>
  //         <span className="text-sm text-zinc-600 text-center">
  //           {labelRight}
  //         </span>
  //       </div>
  //     </div>
  //     <div className="row-center-center w-full gap-2">
  //       {notifType === "up" && (
  //         <FaArrowUp className={`${notifColor}`} size={"0.7rem"} />
  //       )}
  //       {notifType === "down" && (
  //         <FaArrowDown className={`${notifColor}`} size={"0.7rem"} />
  //       )}
  //       <span
  //         className={`${
  //           notifLabel ? notifColor : "text-[rgb(228,228,231)]"
  //         } text-sm text-center`}
  //       >
  //         {notifLabel ?? "-"}
  //       </span>
  //     </div>
  //   </div>
  // );
};

export default DashboardHeaderBadge;

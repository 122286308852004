import { Image } from "primereact/image";
import { MY_URL } from "../../../../configs/constants";
import { Button } from "primereact/button";
import { FaExpand } from "react-icons/fa6";
import { Card } from "primereact/card";
import { Timeline } from "primereact/timeline";

const qrUrl = `${MY_URL}/qrcode.png`;

const DetailField = ({ prompt, value }) => {
  return (
    <div className="col-start-start gap-0">
      <span className="text-sm font-semibold text-zinc-400">{prompt}</span>
      <span>{value}</span>
    </div>
  );
};

const TraceQR = () => {
  const events = [
    {
      status: "Produksi",
      date: "15/10/2020 10:30",
      icon: "pi pi-cog",
      color: "#9C27B0",
    },
    {
      status: "Langsir",
      date: "15/10/2020 14:00",
      icon: "pi pi-truck",
      color: "#673AB7",
    },
    {
      status: "IN: Gudang AB",
      date: "15/10/2020 14:00",
      number: "# 151020-AB-IN-00021",
      icon: "pi pi-database",
      color: "#FF9800",
    },
    {
      status: "OUT: Gudang AB",
      date: "19/10/2020 16:15",
      number: "# 151020-AB-OUT-00021",
      icon: "pi pi-truck",
      color: "#673AB7",
    },
    {
      status: "IN: Depot 201",
      date: "19/10/2020 14:00",
      number: "# 151020-DP-IN-00021",
      icon: "pi pi-database",
      color: "#FF9800",
    },
    {
      status: "OUT: Mobil Sales",
      date: "20/10/2020 16:15",
      number: "# 151020-DP-OUT-00021",
      icon: "pi pi-truck",
      color: "#673AB7",
    },
    {
      status: "Terjual",
      date: "20/10/2020 10:00",
      number: "# 0000213",
      outlet: "(NA) Terang Jaya",
      icon: "pi pi-shopping-cart",
      color: "#607D8B",
    },
  ];

  const customizedMarker = (item) => {
    return (
      <span
        className="col-center-center rounded-full w-[2rem] h-[2rem] align-items-center justify-content-center text-white border-circle z-1 shadow-1"
        style={{ backgroundColor: item.color }}
      >
        <i className={item.icon}></i>
      </span>
    );
  };

  const customizedContent = (item) => {
    return (
      <Card
        title={item.status}
        subTitle={
          <div className="">
            {item.number}
            {item.number && <br />} {item.date}
            <br />
            {item.outlet}
          </div>
        }
      >
        {item.description ?? ""}
      </Card>
    );
  };

  return (
    <div className="col-start-start p-4 w-full gap-4">
      <div className="elevated-card-tight-vertical row-start-start w-full">
        <span className="text-normal font-semibold text-zinc-700">
          Trace QR Produk
        </span>
      </div>
      <div className="row-start-start gap-4 w-full h-[21rem]">
        <div className="col-start-start gap-4 min-w-[18.2rem]">
          <div className="elevated-card-tight-vertical row-start-start w-full">
            <Image src={qrUrl} width="250" />
          </div>
          <Button className="w-full min-h-[3rem]" raised>
            <div className="row-center-center gap-3 w-full">
              <FaExpand /> SCAN QR
            </div>
          </Button>
        </div>
        {/* Detail produk */}
        <div className="elevated-card row-start-start w-full h-full gap-8">
          <div className="col-start-start gap-4">
            <DetailField
              prompt={"Nama Produk"}
              value={"Marcopolo White Mild 16s (HLP)"}
            />
            <DetailField prompt={"Kode"} value={"D225"} />
            <DetailField prompt={"Tgl. Produksi"} value={"10/03/2024"} />
            <DetailField prompt={"Umur"} value={"4 Bulan"} />
            <DetailField prompt={"Di Produksi"} value={"LOKAL"} />
          </div>
          <div className="col-start-start gap-4">
            <DetailField
              prompt={"Nama Produk"}
              value={"Marcopolo White Mild 16s (HLP)"}
            />
            <DetailField prompt={"Kode"} value={"D225"} />
            <DetailField prompt={"Tgl. Produksi"} value={"10/03/2024"} />
            <DetailField prompt={"Umur"} value={"4 Bulan"} />
            <DetailField prompt={"Di Produksi"} value={"LOKAL"} />
          </div>
        </div>
      </div>

      {/* Tracing Timeline */}
      <div className="w-full pt-4">
        <Timeline
          value={events}
          align="alternate"
          className="customized-timeline"
          marker={customizedMarker}
          content={customizedContent}
        />
      </div>
      <div />
    </div>
  );
};

export default TraceQR;

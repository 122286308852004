import { useRef, useState } from "react";
import BarcodeDialog from "./barcode-dialog";
import { Button } from "primereact/button";
import onErrorToast from "../../../../../../utils/OnErrorToast";
import { Toast } from "primereact/toast";

const BarcodeButton = (props) => {
  const { edited, partial } = props;
  const toast = useRef(null);
  const [dialogVisible, setDialogVisible] = useState(false);

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (props.rowData.id <= 0) {
      onErrorToast({
        toast: toast,
        message: "Bon harus di-Save terlebih dahulu",
        data: {},
      });
    } else {
      setDialogVisible(true);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <BarcodeDialog
        partial={partial}
        rowData={props.rowData}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        onChangeListData={props.onChangeListData}
      />
      <Button
        icon={partial ? "pi pi-star" : "pi pi-qrcode"}
        outlined={!partial}
        className="mr-2"
        severity="secondary"
        style={{ width: "40px", height: "40px" }}
        onClick={props.onClick}
        // onClick={(e) => handleButtonClick(e)}
      />
    </>
  );
};

export default BarcodeButton;

import { useEffect, useState } from "react";
import { SelectButton } from "primereact/selectbutton";
import RadioButtonGroup from "../../../components/sales/RadioButtonGroup";
import ReturBaikTahunBerjalan from "./component-home/ReturBaikTahunBerjalan";
import ReturUsangTahunBerjalan from "./component-home/ReturUsangTahunBerjalan";
import SalesBulanBerjalan from "./component-home/SalesBulanBerjalan";
import TargetBulanBerjalan from "./component-home/TargetBulanBerjalan";
import MiniDepotSales from "./component-home/MiniDepotSales";
import MiniDepotRetur from "./component-home/MiniDepotRetur";
import DashboardHeaderBadge from "./component-home/DashboardHeaderBadge";
import { FaCartPlus, FaShop } from "react-icons/fa6";
import SalesByQuantity from "./component-home/SalesByQuantity";
import DisabledCard from "./component-home/DisabledCard";
import StokByKodeProduksi from "./component-home/StokByKodeProduksi";
// import DialogDetailKodeProduksi from "./component-home/DialogDetailKodeProduksi";

const HomeDefault = (props) => {
  const jenisNilai = [
    {
      id: "quantity",
      value: "quantity",
      checked: false,
      label: "Quantity (Tin)",
    },
    {
      id: "rupiah",
      value: "rupiah",
      checked: true,
      label: "Rupiah (K)",
    },
  ];

  const [depots, setDepots] = useState([]);
  const filterDepotOptions = ["Penjualan", "Retur"];
  const [filterDepot, setFilterDepot] = useState(filterDepotOptions[0]);

  useEffect(() => {
    const newDepots = [];
    const depotName = [
      "Depot 201",
      "Depot 203",
      "Depot 204",
      "Depot 205",
      "Depot 206",
      "Depot 207",
      "Depot 208",
      "Depot 209",
      "Depot 210",
    ];
    const depotSalesTarget = depotName.map(
      (e) => Math.floor(Math.random() * 900) + 99
    );
    const depotSalesValue = depotName.map(
      (e) => Math.floor(Math.random() * 900) + 99
    );
    const depotReturTarget = depotName.map(
      (e) => Math.floor(Math.random() * 900) + 99
    );
    const depotReturValue = depotName.map(
      (e) => Math.floor(Math.random() * 900) + 99
    );
    for (let i = 0; i < depotName.length; i++) {
      newDepots.push({
        id: i,
        name: depotName[i],
        salesTarget: depotSalesTarget[i] * 1000,
        salesValue: depotSalesValue[i] * 1000,
        salesPercent: (depotSalesValue[i] / depotSalesTarget[i]) * 100.0,
        returValue: depotReturValue[i] * 1000,
        returTarget: depotReturTarget[i] * 1000,
        returPercent: (depotReturValue[i] / depotReturTarget[i]) * 100.0,
      });
    }
    setDepots(newDepots);
  }, []);

  const handleClickOutletAktif = (e) => {
    console.log("Dashboard Header Badge Click");
  };

  const handleClickDepotSales = (depot) => {
    console.log("Depot Sales Click", depot);
  };

  const handleClickDepotRetur = (depot) => {
    console.log("Depot Retur Click", depot);
  };

  return (
    <div className="col-start-start p-4 w-full gap-4">
      <h1>Hello, This Page is under constructions</h1>
    </div>
  );
};

export default HomeDefault;

import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../configs/constants";

const encryptString = (value) => {
  if (!value || value === null) return null;

  const secret = SECRET_KEY;
  const cipherText = CryptoJS.AES.encrypt(value, secret);
  return cipherText;
};

const decryptString = (value) => {
  if (!value || value === null) return null;

  const secret = SECRET_KEY;
  const decodedText = CryptoJS.AES.decrypt(value, secret).toString(
    CryptoJS.enc.Utf8
  );
  return decodedText;
};

export { encryptString, decryptString };

import classes from "./TableControlButtonComponent.module.css";
import { Button } from "primereact/button";

const TableControlButtonComponent = (props, { className }) => {
  return (
    <div className={`row-between-center gap-2 w-100`}>
      <div className="row-start-center gap-2">
        <Button icon="pi pi-plus" label="Tambah Baru" onClick={props.onAdd} />
        <Button
          icon="pi pi-trash"
          severity="danger"
          text
          label="Hapus"
          onClick={props.onDelete}
        />
      </div>
      <div className="row-start-center">
        <Button
          rounded
          outlined
          icon="pi pi-refresh"
          onClick={props.onReload}
          style={{ width: "40px", height: "40px" }}
        />
      </div>
    </div>
  );
};

export default TableControlButtonComponent;

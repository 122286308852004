import axios from "axios";
import { HOST_URL, MY_URL } from "../configs/constants";
import { logoutLocalStorage } from "../store/auth-slice";

const installErrorHandler401 = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      // Return the response for successful requests
      return response;
    },
    (error) => {
      // console.log("API MIDDLEWARE ERROR", error);
      // Handle specific status codes
      if (error.response && error.response.status === 401) {
        logoutLocalStorage();
        window.location.replace(`${MY_URL}/login`);
      } else {
        // For other errors, continue to propagate the error
        return Promise.reject(error);
      }
    }
  );
};

const generateFetcher = (token) => {
  let axiosInstance;
  if (token) {
    axiosInstance = axios.create({
      baseURL: HOST_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    axiosInstance = axios.create({
      baseURL: HOST_URL,
    });
  }
  installErrorHandler401(axiosInstance);
  // install middleware
  return axiosInstance;
};

const convertBodyToFormData = (jsonBody) => {
  const formData = new FormData();
  for (const key in jsonBody) {
    const value = jsonBody[key];
    if (value instanceof File) {
      formData.append(key, value);
    } else if (value instanceof Map || value instanceof Object) {
      for (const secondaryKey in value) {
        const newKey = `${key}[${secondaryKey}]`;
        const newValue = value[secondaryKey];
        formData.append(newKey, newValue);
      }
    } else {
      formData.append(key, value);
    }
  }
  return formData;
};

export const post = async ({ token, url, isForm, body }) => {
  const fetcher = generateFetcher(token);
  if (!isForm) {
    const response = await fetcher.post(url, body);
    return response;
  } else {
    const formData = convertBodyToFormData(body);
    const response = await fetcher.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  }
};

export const get = async ({ token, url, params }) => {
  const fetcher = generateFetcher(token);
  const response = await fetcher.get(url, { params: params });
  return response;
};

export const getOne = async ({ token, url }) => {
  const fetcher = generateFetcher(token);
  const response = await fetcher.get(url);
  return response;
};

export const del = async ({ token, url, body }) => {
  const fetcher = generateFetcher(token);
  const response = await fetcher.delete(url, { data: body });
  return response;
};

export const apiGet = async ({ token, apiName, onError, params }) => {
  try {
    const response = await get({
      token: token,
      url: `/api/${apiName}/data`,
      params: params,
    });
    if (response) {
      const { success, message, data, pagination } = response.data;
      if (success) {
        return { pagination, message, data };
      }
    }
  } catch (error) {
    const errorResponse = error.response;
    // const { data, message } = errorResponse.data;
    if (errorResponse) {
      onError(errorResponse.data);
    } else {
      onError({ message: "Can't connect to server : No Connection" });
    }
  }
  return null;
};

export const apiGetOne = async ({ token, apiName, onError, id }) => {
  try {
    const response = await getOne({
      token: token,
      url: `/api/${apiName}/one/${id}`,
    });
    if (response) {
      const { success, message, data } = response.data;
      if (success) {
        return { message, data };
      }
    }
  } catch (error) {
    const errorResponse = error.response;
    // const { data, message } = errorResponse.data;
    if (errorResponse) {
      onError(errorResponse.data);
    } else {
      onError({ message: "Can't connect to server : No Connection" });
    }
  }
  return null;
};

export const apiPost = async ({
  token,
  apiName,
  isForm,
  onError,
  value,
  urlSuffix = "/save",
}) => {
  try {
    const body = value;
    const response = await post({
      token: token,
      isForm: isForm,
      url: `/api/${apiName}${urlSuffix}`,
      body: body,
    });
    if (response) {
      const { success, message, data } = response.data;
      if (success) {
        return { message, data };
      }
    }
  } catch (error) {
    const errorResponse = error.response;
    if (errorResponse) {
      const { data, message } = errorResponse.data;
      onError({ message: message, data: data === null ? {} : data });
    } else {
      onError({ message: "Can't connect to server : No Connection" });
    }
  }
  return null;
};

export const apiDel = async ({ token, apiName, onError, ids }) => {
  try {
    const body = { data: ids };
    const response = await del({
      token: token,
      url: `/api/${apiName}/delete`,
      body: body,
    });
    if (response) {
      const { success, message, data } = response.data;
      if (success) {
        return { message, data };
      }
    }
  } catch (error) {
    const errorResponse = error.response;
    // const { data, message } = errorResponse.data;
    if (errorResponse) {
      onError(errorResponse.data);
    } else {
      onError({ message: "Can't connect to server : No Connection" });
    }
  }
  return null;
};

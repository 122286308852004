import { FaShop } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import BadgeAktivitasWilayah from "./component-home/BadgeAktivitasWilayah";
import BadgeInfoWilayah from "./component-home/BadgeInfoWilayah";
import BadgeOutletAktif from "./component-home/BadgeOutletAktif";
import DashboardHeaderBadge from "./component-home/DashboardHeaderBadge";
import DepotPerformanceTable from "./component-home/DepotPerformanceTable";
import HeaderValueType from "./component-home/HeaderValueType";
import ReturBaikTahunBerjalan from "./component-home/ReturBaikTahunBerjalan";
import ReturUsangTahunBerjalan from "./component-home/ReturUsangTahunBerjalan";
import SalesBulanBerjalan from "./component-home/SalesBulanBerjalan";
import SalesByQuantity from "./component-home/SalesByQuantity";
import StokByKodeProduksi from "./component-home/StokByKodeProduksi";
import TargetBulanBerjalan from "./component-home/TargetBulanBerjalan";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Button } from "primereact/button";
import { setWilayah } from "../../../store/app-slice";

const HomeKawil = (props) => {
  const { back } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const listWilayah = useSelector((state) => state.app.listWilayah);

  const wilayah = useSelector((state) => state.app.wilayah) ?? {
    id: 0,
    nama: "-",
  };

  useEffect(() => {
    if (id) {
      window.scrollTo(0, 0);
    }
  }, []);

  const handleClickOutletAktif = (e) => {
    console.log("Dashboard Header Badge Click");
  };

  const handleClickDepotSales = (depot) => {
    console.log("Depot Sales Click", depot);
  };

  const handleClickDepotRetur = (depot) => {
    console.log("Depot Retur Click", depot);
  };

  const handleClickBack = () => {
    dispatch(setWilayah(listWilayah[0]));
  };

  return (
    <div className="col-start-start p-4 w-full gap-4">
      <div
        className={`elevated-card-tight-vertical row-start-start w-full ${
          back ? "!pl-3" : ""
        }`}
      >
        {" "}
        {back && (
          <Button
            icon="pi pi-arrow-left"
            text
            className="max-h-[1.4rem]"
            onClick={handleClickBack}
          />
        )}
        <HeaderValueType title={wilayah.nama} />
      </div>
      <div className="row-between-start w-full gap-4 flex-wrap md:flex-nowrap">
        <BadgeOutletAktif
          className="w-full md:w-1/2 lg:w-1/4"
          icon={<FaShop />}
          onClick={(e) => handleClickOutletAktif(e)}
          title={"Outlet Aktif"}
        />
        <DashboardHeaderBadge disabled className="w-full md:w-1/2 lg:w-1/4" />
        <DashboardHeaderBadge disabled className="w-full md:w-1/2 lg:w-1/4" />
        <DashboardHeaderBadge disabled className="w-full md:w-1/2 lg:w-1/4" />
      </div>
      <div className="row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <div className="elevated-card col-start-start grow-1">
          <BadgeInfoWilayah title={`Info ${wilayah.nama}`} />
        </div>
        <div className="elevated-card col-start-start grow-1">
          <BadgeAktivitasWilayah title={`Aktivitas Hari Ini`} />
        </div>
      </div>

      <div className="row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <div className="elevated-card col-start-start grow-1">
          <SalesBulanBerjalan
            title={`D&P Bulan Berjalan ${wilayah.nama}`}
            aspectRatio={0.73}
          />
        </div>
      </div>
      <div className="row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <div className="elevated-card row-start-start w-full">
          <SalesByQuantity
            title={`Produk Terbaik Di ${wilayah.nama}`}
            aspectRatio={0.73}
          />
        </div>
        <div className="elevated-card row-start-start w-full">
          <TargetBulanBerjalan title={`D&P VS Target ${wilayah.nama}`} />
        </div>
      </div>
      <div className="row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <div className="elevated-card row-start-start w-full">
          <ReturBaikTahunBerjalan
            title={`Retur Baik Tahun Berjalan ${wilayah.nama}`}
            aspectRatio={0.73}
          />
        </div>
        <div className="elevated-card row-start-start w-full">
          <ReturUsangTahunBerjalan
            title={`Retur Usang Berjalan ${wilayah.nama}`}
            aspectRatio={0.73}
          />
        </div>
      </div>
      <div className="elevated-card row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <StokByKodeProduksi
          type="wilayah"
          title={`Stok Per Kode Produksi Di ${wilayah.nama}`}
        />
      </div>

      <div className="elevated-card-tight-vertical row-start-start w-full ">
        <div className="row-between-center w-full flex-wrap gap-2 ">
          <span className="text-sm font-semibold text-zinc-700">
            List Depot Di {wilayah.nama}
          </span>
          <div className="row-end-center"></div>
        </div>{" "}
      </div>
      <div className="row-between-start wrap w-full gap-4">
        <DepotPerformanceTable title={"Salesman Depot 201"} />
      </div>
      {/* )} */}
      {/* {filterDepot === "Retur" && (
        <div className="row-between-start wrap w-full gap-4">
          {depots.map((element) => {
            return (
              <MiniDepotRetur
                key={element.id}
                data={element}
                onClick={(e) => {
                  handleClickDepotRetur(element);
                }}
              />
            );
          })}
        </div>
      )} */}
    </div>
  );
};

export default HomeKawil;

import GaugeComponent from "react-gauge-component";
import { Skeleton } from "primereact/skeleton";

const BulananComponent = (props) => {
  const { value, prediksi, start, targets, loading, disable } = props;

  let newValue = value;
  let newPrediksi = prediksi;
  const newTargets = [...targets];

  const subArcs = [];
  const colors = disable
    ? [
        "rgb(212,212,212)",
        "rgb(212,212,212)",
        "rgb(212,212,212)",
        "rgb(212,212,212)",
      ]
    : ["#EA4228", "#F58B19", "#F5CD19", "#5BE12C"];
  const limits = [];
  let lastTarget = targets[targets.length - 1];
  // let last = targets[targets.length - 1];

  if (start && start > 0) {
    // newValue += start;
    newPrediksi += start;
    for (let i = 0; i < targets.length; i++) {
      newTargets[i] = targets[i] + start;
    }
    lastTarget = newTargets[targets.length - 1];
    subArcs.push({
      limit: Math.ceil((start / lastTarget) * 100),
      color: "rgb(212,212,212)",
      showTick: false,
    });
  }

  for (const target of newTargets) {
    limits.push((target / lastTarget) * 100);
  }
  const limitSpace = 25;
  newPrediksi = Math.ceil((newPrediksi / lastTarget) * 100);
  newValue = Math.ceil((newValue / lastTarget) * 100);

  for (let i = 0; i < colors.length; i++) {
    let limit = limits[i];

    if (
      prediksi > 0 &&
      newPrediksi < limit &&
      limit - limitSpace < newPrediksi
    ) {
      subArcs.push({
        limit: newPrediksi - 1,
        color: colors[i],
        showTick: false,
      });
      subArcs.push({
        limit: newPrediksi,
        color: "var(--text-color)",
        showTick: false,
      });
    }
    subArcs.push({
      limit: limit,
      color: colors[i],
      showTick: false,
    });
  }

  let valueColor = "";
  if (disable) {
    valueColor = "rgb(161 161 170)";
  } else if (targets.length >= 1 && value < newTargets[0]) {
    valueColor = "rgb(239,68,68)";
  } else if (targets.length >= 2 && value < newTargets[1]) {
    valueColor = "rgb(249,115,22)";
  } else if (targets.length >= 3 && value < newTargets[2]) {
    valueColor = "rgb(234,179,8)";
  } else if (targets.length >= 4 && value < newTargets[3]) {
    valueColor = "rgb(34,197,94)";
  }

  return (
    <div className="col-start-center gap-5">
      {!loading ? (
        <div className="col-start-center gap-5">
          <div className="col-center-center w-[12rem] h-[8rem]">
            <GaugeComponent
              // style={{ height: 350 }}
              value={newValue}
              type="radial"
              className="w-full"
              marginInPercent={{
                top: 0.08,
                bottom: 0.0,
                left: 0.07,
                right: 0.07,
              }}
              arc={{
                padding: 0.01,
                subArcs: subArcs,
              }}
              pointer={{
                elastic: true,
                animationDelay: 0,
              }}
              labels={{
                valueLabel: {
                  fontSize: 42,
                  style: { textShadow: "none", fill: valueColor },
                },
                tickLabels: {
                  type: "outer",
                  // ticks: [{ value: 50 }],
                  // defaultTickValueConfig: {
                  //   formatTextValue: toMinggu,
                  //   style: { fontSize: "0.8rem" },
                  // },
                  defaultTickLineConfig: {
                    hide: true,
                  },
                },
              }}
            />
          </div>
          <div className="row-center-center gap-1 ">
            <span
              className={`text font-semibold`}
              style={{ color: valueColor }}
            >
              {value.toLocaleString("id", { maximumFractionDigits: 0 })}
            </span>
            <span
              className={`text-sm font-semibold ${
                disable ? "text-zinc-400" : ""
              }`}
            >
              /
            </span>
            <span
              className={`text-sm font-semibold ${
                disable ? "text-zinc-400" : ""
              }`}
            >
              {targets[targets.length - 1].toLocaleString("id", {
                maximumFractionDigits: 0,
              })}
            </span>
            {start > 0 && <span className="text-sm font-semibold">+</span>}
            {start > 0 && (
              <span className="text-sm font-semibold text-neutral-400">
                {start.toLocaleString("id", { maximumFractionDigits: 0 })}
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className="col-center-center gap-4 pt-2">
          <Skeleton shape="circle" width="7.1rem" height="7.1rem" />
          <Skeleton width="12rem" height="1.95rem" />
        </div>
      )}
    </div>
  );
};

export default BulananComponent;

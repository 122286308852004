import classes from "./MenuTitleComponent.module.css";

const MenuTitleComponent = (props) => {
  const { label } = props;

  return (
    <div className={`${classes.wrapper} ${props.className} row-center-center w-100 gap-2`}>
      <img className={`${classes.logoImage}`} alt="logo" src={props.logoUrl} ></img>
      {label} 
    </div>
  );
};

export default MenuTitleComponent;

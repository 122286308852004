import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post, get, getOne } from "./../services/service-api";
import { decryptString, encryptString } from "../utils/EncryptDecryptData";
import { TAG } from "../configs/constants";

const tag = TAG;

const token = decryptString(localStorage.getItem(tag + "token"));
const tokenFileManager = decryptString(
  localStorage.getItem(tag + "token_file_manager")
);
const user = decryptString(localStorage.getItem(tag + "user"));
const employee = decryptString(localStorage.getItem(tag + "employee"));
const permissionText = decryptString(localStorage.getItem(tag + "permissions"));
const permissionArray =
  permissionText !== null ? permissionText.split(";") : [];

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    user: user ? JSON.parse(user) : null,
    employee: employee ? JSON.parse(employee) : null,
    token: token,
    tokenFileManager: tokenFileManager,
    error: null,
    errorDetail: null,
    permissions: permissionArray,
  },
  reducers: {
    loginBegin(state) {
      state.loading = true;
      state.user = null;
      state.permissions = null;
      state.employee = null;
      state.token = null;
      state.tokenFileManager = null;
      state.error = null;
      state.errorDetail = null;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.user = action.payload.user;
      state.permissions = action.payload.permissions;
      state.employee = action.payload.employee;
      state.token = action.payload.token;
      state.tokenFileManager = action.payload.tokenFileManager;
      state.error = null;
      state.errorDetail = null;
    },
    loginFailed(state, action) {
      state.loading = false;
      state.user = null;
      state.permissions = null;
      state.employee = null;
      state.token = null;
      state.tokenFileManager = null;
      state.error = action.payload.error;
      state.errorDetail = action.payload.errorDetail;
    },
    checkTokenBegin(state) {
      state.loading = true;
      state.errorDetail = null;
    },
    checkTokenSuccess(state, action) {
      state.loading = false;
    },
    checkTokenFailed(state, action) {
      state.loading = false;
      state.user = null;
      state.permissions = null;
      state.employee = null;
      state.token = null;
      state.tokenFileManager = null;
      state.error = action.payload.error;
      state.errorDetail = action.payload.errorDetail;
    },
    logout(state) {
      state.user = null;
      state.permissions = null;
      state.token = null;
      state.employee = null;
      state.tokenFileManager = null;
    },
  },
});

const authActions = authSlice.actions;

const logoutLocalStorage = () => {
  localStorage.setItem(tag + "user", null);
  localStorage.setItem(tag + "permissions", null);
  localStorage.setItem(tag + "token", null);
  localStorage.setItem(tag + "token_file_manager", null);
  localStorage.setItem(tag + "employee", null);
  localStorage.setItem(tag + "list-wilayah", null);
  localStorage.setItem(tag + "list-depot", null);
};

const logout = () => {
  return (dispatch) => {
    dispatch(authActions.logout());
    logoutLocalStorage();
  };
};

const login = ({ username, password, loginToken }) => {
  return async (dispatch) => {
    dispatch(authActions.loginBegin());
    try {
      let response;
      if (loginToken && loginToken !== null && loginToken.length > 0) {
        response = await get({
          url: "/api/loginwithtoken",
          params: { access_token: loginToken },
        });
      } else {
        response = await post({
          url: "/api/login",
          body: { username: username, password: password },
        });
      }
      const {
        access_token,
        file_manager_access_token,
        permissions,
        message,
        data,
        data_center,
      } = response.data;
      dispatch(
        authActions.loginSuccess({
          user: data,
          token: access_token,
          tokenFileManager: file_manager_access_token,
          permissions: permissions.join(";"),
          employee: data.data_employee,
        })
      );

      localStorage.setItem(tag + "user", encryptString(JSON.stringify(data)));
      localStorage.setItem(tag + "token", encryptString(access_token));
      localStorage.setItem(
        tag + "permissions",
        encryptString(permissions.join(";"))
      );
      localStorage.setItem(
        tag + "token_file_manager",
        encryptString(file_manager_access_token)
      );
      localStorage.setItem(
        tag + "employee",
        encryptString(JSON.stringify(data.data_employee))
      );
      return response.data;
    } catch (error) {
      console.log("LOGIN ERROR", error);
      const errorResponse = error.response;
      const { data, message } = errorResponse.data;
      dispatch(authActions.loginFailed({ error: message }));
      return null;
    }
  };
};

const checkToken = () => {
  return async (dispatch, getState) => {
    dispatch(authActions.checkTokenBegin());
    // try {
    //   const token = getState().auth.token;
    //   if (token) {
    //     dispatch(authActions.checkTokenBegin());
    //     const response = await post({
    //       token: token,
    //       url: `/api/me`,
    //       body: {},
    //     });
    //   }
    dispatch(authActions.checkTokenSuccess());
    // } catch (error) {
    //   const errorResponse = error.response;
    //   const { data, message } = errorResponse.data;
    //   dispatch(authActions.checkTokenFailed({ error: message }));
    // }
  };
};

export { authActions, login, logout, logoutLocalStorage, checkToken };
export default authSlice;

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { FaBars, FaChevronRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonAvatar from "../../components/components/buttons/ButtonAvatar";
import useAPIRequest from "../../custom_hooks/simple/useAPIRequest";
import {
  setDepot,
  setListDepot,
  setListWilayah,
  setWilayah,
} from "../../store/app-slice";
import {
  showNoteDialog,
  showTaskListDialog,
  toggleSideMenuVisibility,
} from "../../store/ui-slice";
import classes from "./NavBarComponent.module.css";

const NavBarComponent = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { requestGet, requestGetOne, requestPost, requestDelete, loading } =
    useAPIRequest();

  const permissions = useSelector((state) => state.auth.permissions);
  const user = useSelector((state) => state.auth.user);
  const depot = useSelector((state) => state.app.depot);
  const wilayah = useSelector((state) => state.app.wilayah);
  const listDepot = useSelector((state) => state.app.listDepot);
  const listWilayah = useSelector((state) => state.app.listWilayah);

  const taskListContent = useSelector((state) => state.ui.taskListContents);
  const noteCount = useSelector((state) => state.ui.noteCount);

  const [disableSelectWilayah, setDisableSelectWilayah] = useState(false);

  const [taskListCount, setTaskListCount] = useState(0);

  const [disableSelectDepot, setDisableSelectDepot] = useState(false);

  const pathSegments = location.pathname.split("/");

  const handleHamburger = (e) => {
    dispatch(toggleSideMenuVisibility());
  };

  const handleNote = (e) => {
    dispatch(showNoteDialog());
  };

  const handleTaskList = (e) => {
    dispatch(showTaskListDialog());
  };

  const handleMessage = (e) => {
    navigate("/message");
  };

  useEffect(() => {
    if (user.title === "kawil") {
      if (
        depot &&
        (location.pathname === "/" ||
          location.pathname.startsWith("/kawil/detail/depot"))
      ) {
        if (!depot.id || depot.id === 0) {
          navigate("/");
        } else {
          navigate(`/kawil/detail/depot/${depot.id}`);
        }
      }
    }
  }, [depot]);

  useEffect(() => {
    if (user.title === "controller") {
      if (
        wilayah &&
        (location.pathname === "/" ||
          location.pathname.startsWith("/controller/detail/wilayah"))
      ) {
        if (!wilayah.id || wilayah.id === 0) {
          navigate("/");
        } else {
          navigate(`/controller/detail/wilayah/${wilayah.id}`);
        }
      }
    }
  }, [wilayah]);

  useEffect(() => {
    if (taskListContent && taskListContent.length > 0) {
      setTaskListCount(
        taskListContent.filter(
          (el) => el.to_id === user.id && el.finished_at === null
        ).length
      );
    } else {
      setTaskListCount("");
    }
  }, [taskListContent]);

  useEffect(() => {
    let defaultWilayah = {};

    if (user.title === "kawil") {
      if (user.data_wilayah) {
        setDisableSelectWilayah(true);
        dispatch(setListWilayah([user.data_wilayah]));
        dispatch(setWilayah(user.data_wilayah));
      }
      // get all depot data
      const defaultDepot = { id: 0, nama: "Semua Depot" };
      requestGet({
        fullUrl: `api/masterdata/depot/data`,
        onSuccess: ({ pagination, message, data }) => {
          setDisableSelectDepot(false);
          dispatch(setListDepot([defaultDepot, ...data]));
          dispatch(setDepot(defaultDepot));
        },
        onError: ({ message, data }) => {
          dispatch(setListDepot([]));
        },
      });
    } else if (user.title === "kadepot") {
      if (user.data_wilayah) {
        setDisableSelectWilayah(true);
        dispatch(setListWilayah([user.data_wilayah]));
        dispatch(setWilayah(user.data_wilayah));
      }
      if (user.data_depot) {
        setDisableSelectDepot(true);
        dispatch(setListDepot([user.data_depot]));
        dispatch(setDepot(user.data_depot));
      }
    } else if (user.title === "controller") {
      // get all wilayah data
      const defaultWilayah = { id: 0, nama: "Semua Wilayah" };
      requestGet({
        fullUrl: `api/masterdata/wilayah/data`,
        onSuccess: ({ pagination, message, data }) => {
          setDisableSelectWilayah(false);
          dispatch(setListWilayah([defaultWilayah, ...data]));
          dispatch(setWilayah(defaultWilayah));
        },
        onError: ({ message, data }) => {
          dispatch(setListWilayah([]));
        },
      });
      // get all depot data
      const defaultDepot = { id: 0, nama: "Semua Depot" };
      requestGet({
        fullUrl: `api/masterdata/depot/data`,
        onSuccess: ({ pagination, message, data }) => {
          setDisableSelectDepot(false);
          dispatch(setListDepot([defaultDepot, ...data]));
          dispatch(setDepot(defaultDepot));
        },
        onError: ({ message, data }) => {
          dispatch(setListDepot([]));
        },
      });
    }
  }, []);

  const handleDefaultAvatarClick = (e) => {
    console.log("Handle Avatar Click");
  };
  const handleAvatarClick = props.onCLickAvatar || handleDefaultAvatarClick;

  const handleDefaultNotifClick = (e) => {
    console.log("Handle Notif Click");
  };
  const handleNotifClick = props.onClickNotif || handleDefaultNotifClick;

  const handleChangeWilayah = (event) => {
    dispatch(setWilayah(event.value));
  };

  const handleChangeDepot = (event) => {
    dispatch(setDepot(event.value));
  };

  return (
    <nav
      className={`${classes.wrapper} row-between-center ps-3 pe-3 w-100 ${
        props.className ?? ""
      } fixed`}
      style={props.style ?? {}}
    >
      <div className={`row-start-center gap-3`}>
        <div
          className={`${classes.hamburgerButton} row-center-center`}
          onClick={handleHamburger}
        >
          <FaBars />
        </div>
        <div className="row-start-center gap-1 sm:gap-3">
          <Dropdown
            value={wilayah}
            disabled={disableSelectWilayah}
            onChange={handleChangeWilayah}
            options={listWilayah}
            optionLabel="nama"
            placeholder="Pilih Wilayah"
            className={`${classes.customDropDown}`}
          />
          <FaChevronRight className="text-zinc-500" size="1.3rem" />
          <Dropdown
            disabled={disableSelectDepot}
            value={depot}
            onChange={handleChangeDepot}
            options={listDepot}
            optionLabel="nama"
            placeholder="Pilih Depot"
            className={`${classes.customDropDown}`}
          />
        </div>
        {/* )} */}
      </div>
      <div className="row-end-center gap-5 ">
        <div className="row-center-center gap-3">
          <div className="row-start-start hidden sm:flex gap-3">
            <Button
              className="hidden sm:flex"
              label="Notes"
              icon="pi pi-bookmark"
              outlined
              badge={noteCount > 0 ? noteCount : ""}
              badgeClassName="p-badge-danger"
              style={{ height: "2.2rem" }}
              onClick={handleNote}
            />
            <Button
              className="hidden sm:flex"
              label="Task List"
              icon="pi pi-check-square"
              outlined
              badge={taskListCount}
              badgeClassName="p-badge-danger"
              style={{ height: "2.2rem" }}
              onClick={handleTaskList}
            />
          </div>
          {/* <Button
            className="hidden sm:flex"
            label="Messages"
            icon="pi pi-envelope"
            outlined
            badge=""
            badgeClassName="p-badge-danger"
            style={{ height: "2.2rem" }}
            onClick={handleMessage}
          /> */}
        </div>
        <ButtonAvatar
          className="hidden sm:block"
          onClick={handleAvatarClick}
          src={props.avatarSrc}
        />
      </div>
    </nav>
  );
};

export default NavBarComponent;

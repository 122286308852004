import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import SemesterComponent from "./components/SemesterComponent";
import BulananComponent from "./components/BulananComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  setDepot,
  setListDepot,
  setListWilayah,
  setWilayah,
} from "../../../../store/app-slice";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";

// const semesters = ["Semester 1", "Semester 2"];

const toMinggu = (value) => {
  if (value % 25 !== 0) {
    return "";
  }
  const newValue = value / 25.0;
  return ``;
  // return `M-${newValue.toFixed(0)}`;
};

export const getSalesColor = (value) => {
  if (value < 25) {
    // return "text-red-500";
    return "rgb(239 68 68)";
  } else if (value >= 25 && value < 50) {
    // return "text-orange-500";
    return "rgb(249 115 22)";
  } else if (value >= 50 && value < 75) {
    // return "text-yellow-500";
    return "rgb(234 179 8)";
  } else {
    // return "text-green-500";
    return "rgb(34 197 94)";
  }
};

export const getSalesClassColor = (value) => {
  if (value < 25) {
    return "text-red-500";
  } else if (value >= 25 && value < 50) {
    return "text-orange-500";
  } else if (value >= 50 && value < 75) {
    return "text-yellow-500";
  } else {
    return "text-green-500";
  }
};

const TargetRealisasiPage = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const listDepot = useSelector((state) => state.app.listDepot);
  const listWilayah = useSelector((state) => state.app.listWilayah);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const [disableSelectWilayah, setDisableSelectWilayah] = useState(false);
  const [disableSelectDepot, setDisableSelectDepot] = useState(false);

  const { requestGet, loading } = useAPIRequest();

  const [semester1, setSemester1] = useState({
    target: 0,
    prediksi: 0,
    realisasi: 0,
  });
  const [semester1Bulanan, setSemester1Bulanan] = useState([]);

  const [semester2, setSemester2] = useState({
    target: 0,
    prediksi: 0,
    realisasi: 0,
  });
  const [semester2Bulanan, setSemester2Bulanan] = useState([]);

  useEffect(() => {
    let defaultWilayah = {};

    if (user.title === "kawil") {
      if (user.data_wilayah) {
        setDisableSelectWilayah(true);
      }
      // get all depot data
      setDisableSelectDepot(false);
    } else if (user.title === "kadepot") {
      if (user.data_wilayah) {
        setDisableSelectWilayah(true);
      }
      if (user.data_depot) {
        setDisableSelectDepot(true);
      }
    }

    reloadData();
  }, []);

  const generateFilter = () => {
    let filter = ``;
    const now = new Date();
    filter += `tahun:=:${now.getFullYear()}`;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `${filter.length > 0 ? ";" : ""}depot_id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `${filter.length > 0 ? ";" : ""}wilayah_id:=:${wilayah.id}`;
    }

    // console.log("FILTER = ", filter);

    return filter;
  };

  const resetAllState = () => {
    setSemester1({
      target: 0,
      prediksi: 0,
      realisasi: 0,
    });
    setSemester1Bulanan([]);
    setSemester2({
      target: 0,
      prediksi: 0,
      realisasi: 0,
    });
    setSemester2Bulanan([]);
  };

  const reloadData = async () => {
    const filter = generateFilter();

    resetAllState();
    return await requestGet({
      fullUrl: "api/dashboard/kawil",
      params: {
        prefix: "target_realisasi",
        filter,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          const { semester_1: sm1, semester_2: sm2 } = data;

          const divider = 1000.0;
          const now = new Date();
          let totalRealisasiSm1 = 0,
            totalRealisasiSm2 = 0;
          const sm2Disable = now.getMonth() + 1 < 7;

          // semester 1
          const newSm1Bulanan = [];
          for (const key in sm1.bulan) {
            const item = sm1.bulan[key];
            if (key <= now.getMonth() + 1) {
              newSm1Bulanan.push({
                bulan: key,
                value: item.realisasi / divider,
                prediksi: item.prediksi_per_bulan / divider,
                target: item.total_target_batang_per_bulan / divider,
                start: item.start / divider,
                disable: false,
              });
              totalRealisasiSm1 += item.realisasi / divider;
            } else {
              newSm1Bulanan.push({
                bulan: key,
                value: 0,
                prediksi: 0,
                target: item.total_target_batang_per_bulan / divider,
                start: 0,
                disable: true,
              });
            }
          }
          setSemester1Bulanan(newSm1Bulanan);
          setSemester1({
            target: sm1.total_target_batang / divider,
            prediksi: sm1.prediksi / divider,
            realisasi: totalRealisasiSm1,
            disable: false,
          });

          // semester 2
          const newSm2Bulanan = [];
          for (const key in sm2.bulan) {
            const item = sm2.bulan[key];
            if (key <= now.getMonth() + 1) {
              newSm2Bulanan.push({
                bulan: key,
                value: item.realisasi / divider,
                prediksi: item.prediksi_per_bulan / divider,
                target: item.total_target_batang_per_bulan / divider,
                start: item.start / divider,
                disable: false,
              });
              totalRealisasiSm2 += item.realisasi / divider;
            } else {
              newSm2Bulanan.push({
                bulan: key,
                value: 0,
                prediksi: 0,
                target: item.total_target_batang_per_bulan / divider,
                start: 0,
                disable: true,
              });
            }
          }
          setSemester2Bulanan(newSm2Bulanan);
          setSemester2({
            target: sm2.total_target_batang / divider,
            prediksi: sm2.prediksi / divider,
            realisasi: totalRealisasiSm2,
            disable: sm2Disable,
          });
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const handleChangeWilayah = (event) => {
    dispatch(setWilayah(event.value));
  };

  const handleChangeDepot = (event) => {
    dispatch(setDepot(event.value));
  };

  const handleReload = (event) => {
    reloadData();
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <div />

      {/* judul */}
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <span className="text-normal font-semibold text-zinc-700">
          Realisasi Target
        </span>
        <div className="row-end-center gap-2">
          {/* <Dropdown
            value={selectedWilayah}
            onChange={(e) => setSelectedWilayah(e.value)}
            options={wilayahs}
          /> */}
          <Dropdown
            value={selectedWilayah}
            disabled={disableSelectWilayah}
            onChange={handleChangeWilayah}
            options={listWilayah}
            optionLabel="nama"
            placeholder="Pilih Wilayah"
            // className={`${classes.customDropDown}`}
          />
          {/* <Dropdown
            value={selectedDepot}
            onChange={(e) => setSelectedDepot(e.value)}
            options={depots}
          /> */}
          <Dropdown
            disabled={disableSelectDepot}
            value={selectedDepot}
            onChange={handleChangeDepot}
            options={listDepot}
            optionLabel="nama"
            placeholder="Pilih Depot"
            // className={`${classes.customDropDown}`}
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            // loading={loading}
            onClick={(e) => {
              e.preventDefault();
              handleReload(e);
            }}
            outlined
          />
        </div>
      </div>

      <div className="row-start-center gap-4 w-full">
        <div
          className={`elevated-card col-center-start w-[15rem] gap-2 ${
            semester1.disable
              ? "!bg-gray-50 !shadow-none border border-inherit"
              : ""
          }`}
        >
          <span
            className={`text-sm font-semibold ${
              semester1.disable ? "text-zinc-400" : "text-zinc-700"
            }`}
          >
            Semester 1
          </span>
          <SemesterComponent
            loading={loading}
            target={semester1.target}
            prediksi={semester1.prediksi}
            realisasi={semester1.realisasi}
            disable={semester1.disable}
          />
        </div>
        <div className="row-start-start gap-4 w-3/4 flex-wrap">
          {semester1Bulanan.map((item, index) => {
            let targetMingguan = item.target / 4;
            let target = 0;
            const listTarget = [];
            for (let i = 0; i < 4; i++) {
              target += targetMingguan;
              listTarget.push(target);
            }

            return (
              <div
                className={`elevated-card-tight-vertical ${
                  item.disable
                    ? "!bg-gray-50 !shadow-none border border-inherit"
                    : ""
                }`}
                key={item.bulan}
              >
                <div className="row-start-start w-full">
                  <span
                    className={`text-sm font-semibold ${
                      item.disable ? "text-zinc-400" : "text-zinc-700"
                    } w-full`}
                  >
                    {`Bulan ${item.bulan}`}
                  </span>
                </div>
                <BulananComponent
                  loading={loading}
                  value={item.value}
                  prediksi={item.prediksi}
                  targets={listTarget}
                  start={item.start}
                  disable={item.disable}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="row-start-center gap-4 w-full">
        <div
          className={`elevated-card col-center-start w-[15rem] gap-2 ${
            semester2.disable
              ? "!bg-gray-50 !shadow-none border border-inherit"
              : ""
          }`}
        >
          <span
            className={`text-sm font-semibold ${
              semester2.disable ? "text-zinc-400" : "text-zinc-700"
            }`}
          >
            Semester 2
          </span>
          <SemesterComponent
            loading={loading}
            target={semester2.target}
            prediksi={semester2.prediksi}
            realisasi={semester2.realisasi}
            disable={semester2.disable}
          />
        </div>
        <div className="row-start-start gap-4 w-3/4 flex-wrap">
          {semester2Bulanan.map((item, index) => {
            let targetMingguan = item.target / 4;
            let target = 0;
            const listTarget = [];
            for (let i = 0; i < 4; i++) {
              target += targetMingguan;
              listTarget.push(target);
            }

            return (
              <div
                className={`elevated-card-tight-vertical ${
                  item.disable
                    ? "!bg-gray-50 !shadow-none border border-inherit"
                    : ""
                }`}
                key={item.bulan}
              >
                <div className="row-start-start w-full">
                  <span
                    className={`text-sm font-semibold ${
                      item.disable ? "text-zinc-400" : "text-zinc-700"
                    } w-full`}
                  >
                    {`Bulan ${item.bulan}`}
                  </span>
                </div>
                <BulananComponent
                  loading={loading}
                  value={item.value}
                  prediksi={item.prediksi}
                  targets={listTarget}
                  start={item.start}
                  disable={item.disable}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div />
    </div>
  );
};

export default TargetRealisasiPage;

import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { format as dateFnsFormat, parse } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import TransportSelectorDialog from "../../../../dialogs/selectors/TransportSelectorDialog";
import DepotSelectorDialog from "../../../../dialogs/selectors/DepotSelectorDialog";
import WilayahSelectorDialog from "../../../../dialogs/selectors/WilayahSelectorDialog";

const defaultDate = new Date();
defaultDate.setDate(1);

const tableConfigs = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Tanggal",
    field: "tanggal",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Depot",
    field: "depot",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Grup",
    field: "grup",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Deskripsi",
    field: "deskripsi",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Total",
    field: "total",
    type: "number_plain",
    align: "right",
    table: true,
    filter: true,
  },
];

const STOK_DEPOT = "stok-depot";
const STOK_TRANSPORT = "stok-transport";
const STOK_DEPOT_TRANSPORT = "stok-depot-transport";
const STOK_SEMUA_GUDANG = "stok-semua-gudang";
const STOK_WILAYAH = "stok-wilayah";
const STOK_SELURUH_WILAYAH = "stok-seluruh-wilayah";
const STOK_GUDANG_PRODUKSI = "stok-gudang-produksi";
const STOK_GUDANG_AB = "stok-gudang-ab";

const ReportBiaya = () => {
  let { prefix } = useParams();
  const navigate = useNavigate();
  const toast = useRef();

  const [title, setTitle] = useState("");
  const [listData, setListData] = useState([]);

  const firstDateOfMonth = new Date();
  firstDateOfMonth.setDate(1);
  const lastDateOfMonth = new Date();
  lastDateOfMonth.setMonth(lastDateOfMonth.getMonth() + 1);
  lastDateOfMonth.setDate(0);

  const [tanggal, setTanggal] = useState([firstDateOfMonth, lastDateOfMonth]);

  const [visibleTransportSelector, setVisibleTransportSelector] =
    useState(false);
  const [selectedTransport, setSelectedTransport] = useState(null);

  const [visibleDepotSelector, setVisibleDepotSelector] = useState(false);
  const [selectedDepot, setSelectedDepot] = useState(null);

  const [visibleWilayahSelector, setVisibleWilayahSelector] = useState(false);
  const [selectedWilayah, setSelectedWilayah] = useState(null);

  const [visibleGrupBiayaSelector, setVisibleGrupBiayaSelector] =
    useState(false);
  const [selectedGrupBiaya, setSelectedGrupBiaya] = useState(null);

  const [totalNilai, setTotalNilai] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);

  const { requestGet, loading } = useAPIRequest();

  const generateFilter = () => {
    let filter = "";

    let dateStart = tanggal[0];
    let dateEnd = tanggal[1];

    if ( dateStart ) 
      filter += `tanggal_transaksi:>=:${dateFnsFormat(dateStart, "yyyy-MM-dd")}`;

    if ( dateEnd ) 
      filter += `;tanggal_transaksi:<=:${dateFnsFormat(dateEnd, "yyyy-MM-dd")}`;

    if (prefix === STOK_DEPOT && selectedDepot) {
      filter += `;depot_id:=:${selectedDepot.id}`;
    } else if (prefix === STOK_DEPOT_TRANSPORT && selectedDepot) {
      filter += `;depot_id:=:${selectedDepot.id}`;
    } else if (prefix === STOK_WILAYAH) {
      filter += `;data_depot.wilayah_id:=:${selectedWilayah.id}`;
    }

    return filter;
  };

  // function to create random date
  const randomDate = (start, end) => {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  };

  const arrGrup = [
    "Biaya Pergaulan",
    "Biaya Operasional",
    "Biaya Inventaris",
    "Biaya Lainnya",
  ];
  const arrDeskripsi = [
    "Uang Air",
    "Uang Listrik",
    "Uang Internet",
    "Uang Makan",
    "Uang Transport",
    "Uang Parkir",
    "Uang Tol",
    "Uang Sewa",
    "Uang Gaji",
    "Uang Bonus",
    "Uang THR",
    "Uang Lainnya",
  ];

  useEffect(() => {
    // reloadData();
    const arrayBiaya = [];

    for (let i = 0; i < 30; i++) {
      arrayBiaya.push({
        id: i + 1,
        no: i + 1,
        tanggal: dateFnsFormat(
          randomDate(firstDateOfMonth, lastDateOfMonth),
          "dd/MM/yyyy"
        ),
        wilayah: "Wilayah 1",
        depot: "Depot 1",
        grup: arrGrup[Math.floor(Math.random() * arrGrup.length)],
        deskripsi:
          arrDeskripsi[Math.floor(Math.random() * arrDeskripsi.length)],
        total: Math.floor(Math.random() * 1000000),
      });
    }

    setListData(arrayBiaya);
  }, []);

  useEffect(() => {
    const total = listData.reduce((acc, curr) => acc + curr.total, 0);
    setTotalNilai(total);
  }, [listData]);

  useEffect(() => {
    setTitle(`Biaya ${selectedTransport ? "- " + selectedTransport.nama : ""}`);
  }, [prefix, selectedTransport, selectedDepot, selectedWilayah]);

  const reloadData = async () => {
    setListData([]);
    setTotalNilai(0);

    const filter = generateFilter();

    const params = {
      with_depot: 1,
      with_grup_biaya: 1,
      page: 1,
      take: 1000,
      filter,
    };

    return requestGet({
      fullUrl: "api/masterdata/biaya/data",
      params,
      onSuccess: ({ pagination, message, data }) => {
        const arrayBiaya = [];

        data.forEach((item, index) => {
          arrayBiaya.push({
            id: item.id,
            no: index + 1,
            tanggal: item.tanggal_transaksi,
            depot: item.data_depot.nama,
            grup: item.data_grup_biaya.nama,
            deskripsi: item.keterangan,
            total: item.jumlah,
          });
        });

        setListData(arrayBiaya);
      },
      onError: ({ message, data }) => {},
    });
  };

  const handleReload = () => {
    reloadData();
  };

  const generateTotalLabel = ({ label, value }) => {
    return (
      <div className="col-center-end min-w-[7.2rem]">
        <span className="font-bold text-xl text-right">
          {value.toLocaleString("id-ID", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}
        </span>
        <span className="font-bold text-slate-400 text-xs"> {label} </span>
      </div>
    );
  };

  const customColumns = [
    {
      field: "persen",
      component: (rowData) => {
        let persen = (rowData.nilai / rowData.nilai_pembanding) * 100.0;
        let minus = rowData.nilai < rowData.nilai_pembanding;
        if (!rowData.nilai_pembanding) {
          persen = 100;
          minus = false;
        }
        return (
          <div
            className={`row-end-center font-semibold ${
              minus ? "text-red-400" : "text-green-600"
            } `}
          >
            <span className="">{`${minus ? "- " : ""}${persen.toFixed(
              0
            )} %`}</span>
          </div>
        );
      },
    },
  ];

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleConfirmTransportSelectorBox = (e, value) => {
    e.preventDefault();
    if (value && value.length > 0) {
      setSelectedTransport(value[0]);
      setVisibleTransportSelector(false);
    }
  };

  const handleConfirmDepotSelectorBox = (e, value) => {
    e.preventDefault();
    if (value && value.length > 0) {
      setSelectedDepot(value[0]);
      setVisibleDepotSelector(false);
    }
  };

  const handleConfirmWilayahSelectorBox = (e, value) => {
    e.preventDefault();
    if (value && value.length > 0) {
      setSelectedWilayah(value[0]);
      setVisibleWilayahSelector(false);
    }
  };

  const generateSelector = () => {
    if (prefix === STOK_TRANSPORT) {
      return (
        <TransportSelectorDialog
          visible={visibleTransportSelector}
          onHide={() => setVisibleTransportSelector(false)}
          onConfirm={handleConfirmTransportSelectorBox}
        />
      );
    } else if (prefix === STOK_DEPOT || prefix === STOK_DEPOT_TRANSPORT) {
      return (
        <DepotSelectorDialog
          visible={visibleDepotSelector}
          onHide={() => setVisibleDepotSelector(false)}
          onConfirm={handleConfirmDepotSelectorBox}
        />
      );
    } else if (prefix === STOK_WILAYAH) {
      return (
        <WilayahSelectorDialog
          visible={visibleWilayahSelector}
          onHide={() => setVisibleWilayahSelector(false)}
          onConfirm={handleConfirmWilayahSelectorBox}
        />
      );
    }
    return <></>;
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <Toast ref={toast} />
      <div></div>
      {generateSelector()}
      <div className="elevated-card-no-padding w-100 row-start-start relative wrap gap-8">
        <div className="row-start-start wrap gap-8 w-100 p-[1rem]">
          {/* field selector tanggal */}
          <div className={`col-start-start gap-1`}>
            <label
              htmlFor={"tanggal"}
              className="font-bold text-sm text-zinc-500"
            >
              Tanggal
            </label>
            <Calendar
              id="tanggal"
              value={tanggal}
              showButtonBar
              selectionMode="range"
              hourFormat="24"
              showIcon
              onChange={(e) => {
                setTanggal(e.value);
              }}
              className={`dateInput w-[17rem]`}
              locale="id"
            />
          </div>
          {/* field selector wilayah */}
          <div className={`col-start-start gap-1`}>
            <label
              htmlFor={"wilayah"}
              className="font-bold text-sm text-zinc-500"
            >
              Wilayah
            </label>
            <Button
              className={`w-[12rem]`}
              outlined
              onClick={(e) => {
                e.preventDefault();
                setVisibleDepotSelector(true);
              }}
            >
              <div className="row-between-center w-100">
                {selectedDepot ? selectedDepot.nama : "-"}{" "}
                <i className="pi pi-search" />
              </div>
            </Button>
          </div>
          {/* field selector depot */}
          <div className={`col-start-start gap-1`}>
            <label
              htmlFor={"depot"}
              className="font-bold text-sm text-zinc-500"
            >
              Depot
            </label>
            <Button
              className={`w-[12rem]`}
              outlined
              onClick={(e) => {
                e.preventDefault();
                setVisibleWilayahSelector(true);
              }}
            >
              <div className="row-between-center w-100">
                {selectedWilayah ? selectedWilayah.nama : "-"}{" "}
                <i className="pi pi-search" />
              </div>
            </Button>
          </div>
          {/* grup biaya selector depot */}
          <div className={`col-start-start gap-1`}>
            <label
              htmlFor={"grup_biaya"}
              className="font-bold text-sm text-zinc-500"
            >
              Grup Biaya
            </label>
            <Button
              className={`w-[17rem]`}
              outlined
              onClick={(e) => {
                e.preventDefault();
                setVisibleGrupBiayaSelector(true);
              }}
            >
              <div className="row-between-center w-100">
                {selectedGrupBiaya ? selectedGrupBiaya.nama : "-"}{" "}
                <i className="pi pi-search" />
              </div>
            </Button>
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <div className="row-between-center">
          <div className="row-start-center gap-2">
            <Button
              icon="pi pi-arrow-left"
              text
              className="max-h-[1.4rem]"
              onClick={handleClickBack}
            />
            <span className="text-normal font-semibold text-zinc-700">
              {title}
            </span>
          </div>
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            onSelectionChange={() => {}}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={customColumns}
          />
        </div>
      </div>

      <div className="elevated-card-tight-vertical w-100 row-between-center">
        <span className="text-lg font-semibold">Grandtotal</span>
        <div className="row-end-center w-100 sm:gap-2 mr-2">
          {generateTotalLabel({ label: "RUPIAH", value: totalNilai })}
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default ReportBiaya;

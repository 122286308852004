import { Link } from "react-router-dom";

const LinkTitleComponent = (props) => {
  const { title, href, suffixComponent, color } = props;

  return (
    <div className="row-between-center w-full">
      {!href && (
        <span className={`text-sm font-semibold ${color ?? "text-zinc-700"}`}>
          {title}
        </span>
      )}
      {href && (
        <Link
          className="p-button p-button-text h-[1.6rem] "
          to={{ pathname: href }}
          style={{ color: "var(--primary-color)" }}
        >
          <div className="row-start-center gap-2">
            <span className="text-sm font-semibold text-zinc-700">{title}</span>
            <i className="pi pi-angle-double-right" />
          </div>
        </Link>
      )}
      {suffixComponent}
    </div>
  );
};

export default LinkTitleComponent;

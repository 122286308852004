const StokMutasiReturBaikConfigs = {
  master: {
    title: "Stok Mutasi Retur Baik",
    labelNew: "*BON BARU*",
    apiName: "transaksi/stokmutasi",
    orderBy: "id",
    orderMethod: "DESC",
    additionalParams: {
      // add_params
      filter: "jenis_mutasi:=:retur_baik",
      with_depot: 1,
      with_gudang_dari: 1,
      with_gudang_ke: 1,
      with_transport: 1,
      with_sales: 1,
      with_detils: 1,
    },
  },
  detail: {
    detail_field: "data_detils",
    columns: [
      // add_arsip_configs
      {
        name: "No",
        field: "urutan",
        type: "number_plain",
        align: "center",
        table: true,
      },
      {
        name: "Barcode",
        field: "quantity_barcode",
        type: "number_plain",
        table: true,
      },
      {
        name: "Produk",
        field: "produk_id",
        type: "object",
        object_name: "data_produk",
        object_field: "nama",
        editor: true,
        table: true,
        filter: true,
      },
      {
        name: "Quantity",
        field: "quantity",
        type: "number_plain",
        editor: true,
        align: "right",
        object_name: "",
        object_field: "",
        table: true,
      },
      {
        name: "Satuan",
        field: "satuan",
        type: "object_dropdown_local",
      },
      {
        name: "Satuan",
        type: "object_dropdown_local",
        field: "satuan_dropdown",
        label_field: "satuan",
        source: "data_produk.data_konversis",
        editor: true,
      },
      {
        name: "Konversi",
        field: "konversi",
        type: "number_plain",
        editor: true,
        align: "right",
        object_name: "",
        object_field: "",
        table: true,
      },
      {
        name: "Tanggal Produksi",
        field: "tanggal_produksi",
        type: "date",
        align: "center",
        table: true,
        editor: true,
        format: "dd/MM/yyyy",
        prime_format: "d MM yy",
        showTime: 1,
        object_name: "",
        object_field: "",
      },
    ],
  },
  filters: [
    {
      name: "Tanggal Transaksi",
      field: "tanggal_transaksi",
      type: "date_range",
      format: "dd/MM/yyyy",
      prime_format: "dd/mm/yy",
      default_value: [new Date(), new Date()],
      range: true,
    },
  ],
  columns: [
    // add_configs
    {
      name: "Asal",
      field: "gudang_dari_id",
      type: "object",
      object_name: "data_gudang_dari",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Tujuan",
      field: "gudang_ke_id",
      type: "object",
      object_name: "data_gudang_ke",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Transport",
      field: "transport_id",
      type: "object",
      object_name: "data_transport",
      object_field: "nama",
      editor: false,
      table: false,
      filter: true,
    },
    {
      name: "Sales",
      field: "sales_id",
      type: "object",
      object_name: "data_sales",
      object_field: "name",
      editor: false,
      table: false,
      filter: true,
    },
    {
      name: "Tanggal Transaksi",
      field: "tanggal_transaksi",
      type: "date",
      align: "center",
      table: true,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
      object_name: "",
      object_field: "",
    },
    {
      name: "Nomor",
      field: "nomor",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Jenis Mutasi",
      field: "jenis_mutasi",
      type: "text_plain",
      object_name: "",
      object_field: "",
      default_value: "retur_baik",
      editor: true,
    },
    {
      name: "Catatan",
      field: "catatan",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
  ],
};

export default StokMutasiReturBaikConfigs;

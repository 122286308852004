const getLastDateOfMonth = ({ date }) => {
  let thisMonthLastDate = new Date(date.getTime());
  thisMonthLastDate.setDate(1);
  thisMonthLastDate = new Date(
    thisMonthLastDate.getTime() + 32 * 24 * 60 * 60 * 1000
  );
  thisMonthLastDate.setDate(0);
  return thisMonthLastDate;
};
export default getLastDateOfMonth;

const sum = (list) => {
  return list.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
};

export const getTrendingLineClassColor = (arr) => {
  const first = arr[0];
  const last = arr[arr.length - 1];

  if (first > last) {
    return "--red-400";
  } else if (first < last) {
    return "--green-400";
  } else {
    return "--gray-400";
  }
};

const generateTrendingLine = ({ arrX, arrY }) => {
  const listData = [];

  const meanX = sum(arrX) / arrX.length;
  const meanY = sum(arrY) / arrY.length;

  const deviationX = arrX.map((x) => x - meanX);
  const deviationY = arrY.map((y) => y - meanY);

  const squaredDeviationX = deviationX.map((x) => x ** 2);

  const slope =
    sum(deviationX.map((x, i) => x * deviationY[i])) / sum(squaredDeviationX);

  const yIntercept = meanY - slope * meanX;

  for (let i = 0; i < arrX.length; i++) {
    const x = arrX[i];
    const y = slope * x + yIntercept;

    listData.push(y);
  }

  return listData;
};

export default generateTrendingLine;

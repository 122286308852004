import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import GaugeComponent from "react-gauge-component";
import LinkTitleComponent from "../../../../components/sales/LinkTitleComponent";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";

let targetPrinted = false;
let loaded = false;

const listJenisTarget = [
  { name: "Target Bulanan", value: "bulanan" },
  { name: "Target Semester", value: "semester" },
];

const TargetBulanBerjalan = (props) => {
  const [selectedJenisTarget, setSelectedJenisTarget] = useState(
    listJenisTarget[0].value
  );
  const [subArcData, setSubArcData] = useState([]);
  const [value, setValue] = useState(0);

  const [totalRealisasi, setTotalRealisasi] = useState(0);
  const [totalTarget, setTotalTarget] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const { requestGet, loading } = useAPIRequest();

  const { title, sales_id } = props;

  const toMinggu = (value) => {
    if (value % 25 !== 0) {
      if (!targetPrinted) {
        targetPrinted = true;
        return "[P]";
      } else {
        return "";
      }
    }
    const newValue = value / 25.0;
    return `M-${newValue.toFixed(0)}`;
  };

  // For Testing purpose only
  const testingCopilot = () => {
    // json which contains list of 20 persons object with name and age
    const persons = [
      { name: "John", age: 30 },
      { name: "Doe", age: 25 },
      { name: "Clark", age: 35 },
      { name: "Bruce", age: 40 },
      { name: "Peter", age: 45 },
      { name: "Tony", age: 50 },
      { name: "Steve", age: 55 },
      { name: "Natasha", age: 60 },
      { name: "Clint", age: 65 },
      { name: "Wanda", age: 70 },
      { name: "Vision", age: 75 },
      { name: "Thor", age: 80 },
      { name: "Loki", age: 85 },
      { name: "Hulk", age: 90 },
      { name: "Fury", age: 95 },
      { name: "Nick", age: 100 },
      { name: "Maria", age: 105 },
      { name: "Pietro", age: 110 },
      { name: "Scott", age: 115 },
      { name: "Hope", age: 120 },
    ];

    // Follow to print all the person whose age. are more than 50.
    // persons.filter(person => person.age > 50).forEach(person => console.log(person.name));

    persons
      .filter((person) => person.age > 100)
      .forEach((person) => console.log(person.name));
  };

  const toBulan = (value) => {
    if (value >= 0 && value <= 0.001) {
      return "B-0";
    } else if (value >= 16.67 && value <= 16.671) {
      return "B-1";
    } else if (value >= 33.34 && value <= 33.341) {
      return "B-2";
    } else if (value >= 50.01 && value <= 50.011) {
      return "B-3";
    } else if (value >= 66.68 && value <= 66.681) {
      return "B-4";
    } else if (value >= 83.35 && value <= 83.351) {
      return "B-5";
    } else if (value >= 100) {
      return "B-6";
    } else {
      if (!targetPrinted) {
        targetPrinted = true;
        return "[P]";
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    reloadData({ jenisTarget: listJenisTarget[0].value });
  }, []);

  const generateFilter = ({ jenisTarget }) => {
    const today = new Date();

    let filter = `tahun:=:${today.getFullYear()};bulan:=:${
      today.getMonth() + 1
    }`;
    let depot = null;
    let wilayah = null;

    if (sales_id) {
      filter += `;sales_id:=:${sales_id}`;
    } else {
      switch (user.title) {
        case "kadepot":
          depot = user.data_depot;
          wilayah = user.data_wilayah;
          break;
        case "kawil":
          wilayah = user.data_wilayah;
          depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
          break;
        case "controller":
          wilayah =
            selectedWilayah && selectedWilayah.id > 0 ? selectedWilayah : null;
          depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
          break;
      }
      if (depot) {
        filter += `;depot_id:=:${depot.id}`;
      } else if (wilayah) {
        filter += `;wilayah_id:=:${wilayah.id}`;
      }
    }

    // console.log("FILTER TARGET BULAN BERJALAN", filter);

    return filter;
  };

  const generateSubArc = ({ prediksiPercent, jenisTarget }) => {
    const subArc = [];

    if (jenisTarget === "bulanan") {
      const colors = ["#EA4228", "#EA4228", "#F58B19", "#F5CD19", "#5BE12C"];
      let limit = 0;
      for (let i = 0; i < 5; i++) {
        if (
          prediksiPercent >= limit &&
          prediksiPercent < limit + 25 &&
          prediksiPercent < 99
        ) {
          if (limit !== prediksiPercent) {
            subArc.push({
              limit: limit,
              color: colors[i],
              showTick: true,
            });
          }
          subArc.push({
            limit: prediksiPercent,
            color: limit === prediksiPercent ? colors[i] : colors[i + 1],
            showTick: true,
          });
          subArc.push({
            limit: prediksiPercent + 1,
            color: "var(--text-color)",
            showTick: true,
          });
        } else {
          subArc.push({ limit, color: colors[i], showTick: true });
        }
        limit += 25;
      }
    } else if (jenisTarget === "semester") {
      const colors = [
        "#EA4228",
        "#EA4228",
        "#E57373",
        "#F58B19",
        "#F5CD19",
        "#A5D6A7",
        "#5BE12C",
      ];
      let limit = 0;
      for (let i = 0; i < 7; i++) {
        if (
          prediksiPercent >= limit &&
          prediksiPercent < limit + 16.67 &&
          prediksiPercent < 99
        ) {
          if (limit !== prediksiPercent) {
            subArc.push({
              limit: limit,
              color: colors[i],
              showTick: true,
            });
          }
          subArc.push({
            limit: prediksiPercent,
            color: limit === prediksiPercent ? colors[i] : colors[i + 1],
            showTick: true,
          });
          subArc.push({
            limit: prediksiPercent + 1,
            color: "var(--text-color)",
            showTick: true,
          });
        } else {
          subArc.push({ limit, color: colors[i], showTick: true });
        }
        limit += 16.67;
        if (limit > 100) {
          limit = 100;
        }
      }
    }
    return subArc;
  };

  const reloadData = async ({ jenisTarget }) => {
    const filter = generateFilter({ jenisTarget });

    setValue(0);
    setTotalTarget(0);
    setTotalRealisasi(0);
    setSubArcData([]);
    return await requestGet({
      fullUrl: sales_id ? "api/dashboard/kadepot" : "api/dashboard/kawil",
      params: {
        prefix: "dp_vs_target",
        method: jenisTarget === "bulanan" ? "bulan" : "semester",
        filter,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          // console.log("DATA TARGET BULAN BERJALAN", data);
          const {
            bulan,
            method,
            prediksi,
            prediksi_per_bulan,
            realisasi,
            tahun,
            total_target_batang,
            total_target_batang_per_bulan,
          } = data;

          // realisasi -> Stok keluar perbulan / semester, per tanggal hari ini dari (bulan dari awal bulan) (semester dari awal semester)
          // total_target_batang -> Total 1 semester (6 bulan)
          // total_target_batang_per_bulan -> total_target_batang / 6
          // prediksi -> Prediksi 1 semester
          // prediksi_per_bulan -> Prediksi 1 semester / 6

          let prediksiPercent = 0;
          let realisasiPercent = 0;
          if (jenisTarget === "bulanan") {
            realisasiPercent =
              (realisasi / total_target_batang_per_bulan) * 100;
            prediksiPercent =
              (prediksi_per_bulan / total_target_batang_per_bulan) * 100;

            setTotalRealisasi(realisasi);
            setTotalTarget(total_target_batang_per_bulan);
          } else {
            realisasiPercent = (realisasi / total_target_batang) * 100;
            prediksiPercent = (prediksi / total_target_batang) * 100;

            setTotalRealisasi(realisasi);
            setTotalTarget(total_target_batang);
          }
          setValue(realisasiPercent);
          const subArc = generateSubArc({ prediksiPercent, jenisTarget });
          setSubArcData(subArc);

          loaded = true;

          setTimeout(() => {
            // // to fix bug for gaguge height
            const radialGauge = document.querySelector(".radial-gauge > svg");
            if (radialGauge) {
              radialGauge.setAttribute("height", "212.8121337890625");
            }
          }, 300);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const handleChangeDropdown = (event) => {
    targetPrinted = false;
    // loaded = false;
    setSelectedJenisTarget(event.value);
    reloadData({ jenisTarget: event.value });
  };

  return (
    <div className="col-start-start w-full gap-3 h-full">
      <div className="row-between-center w-full">
        <LinkTitleComponent
          title={title}
          href={"/target/target-realisasi"}
          suffixComponent={
            <Dropdown
              options={listJenisTarget}
              value={selectedJenisTarget}
              onChange={handleChangeDropdown}
              optionLabel="name"
            />
          }
        />
      </div>
      <div className="col-start-center w-full h-full">
        <div className="col-start-center pt-3"></div>
        <div className="row-center-center h-[230px]">
          {loaded && (
            <GaugeComponent
              value={value}
              type="radial"
              className="w-full h-[230px]"
              marginInPercent={{
                top: 0.08,
                bottom: 0.0,
                left: 0.07,
                right: 0.07,
              }}
              arc={{
                padding: 0.01,
                subArcs: subArcData,
              }}
              pointer={{
                elastic: true,
                animationDelay: 500,
              }}
              labels={{
                valueLabel: {
                  fontSize: 42,
                  style: { textShadow: "none", fill: "var(--blue-500)" },
                },
                tickLabels: {
                  type: "outer",
                  ticks: [{ value: 50 }],
                  defaultTickValueConfig: {
                    // formatTextValue: toMinggu,
                    formatTextValue:
                      selectedJenisTarget === "bulanan" ? toMinggu : toBulan,
                    style: { fontSize: "0.8rem" },
                  },
                  // defaultTickLineConfig: {
                  //   hide: true,
                  // },
                },
              }}
            />
          )}
        </div>
        <div className={`row-center-center gap-2 pt-5`}>
          <span className={`font-semibold text-xl`}>
            {Math.trunc(totalRealisasi / 1000.0).toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
            K Btg
          </span>
          <span>/</span>
          <span className="font-semibold text-zinc-400 text-xl">
            {Math.trunc(totalTarget / 1000.0).toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
            K Btg
          </span>
        </div>
      </div>
    </div>
  );
};

export default TargetBulanBerjalan;

import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import DepotPerformanceTable from "../../home/component-home/DepotPerformanceTable";

const title = "List Depot";

const DetailListDepot = () => {
  const navigate = useNavigate();

  const handleClickDepot = (id) => {
    navigate(`/kawil/detail/depot/${id}`);
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <div></div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20 !pl-3`}
      >
        <div className="row-between-center">
          <div className="row-start-center gap-2">
            <Button
              icon="pi pi-arrow-left"
              text
              className="max-h-[1.4rem]"
              onClick={handleClickBack}
            />
            <span className="text-normal font-semibold text-zinc-700">
              {title}
            </span>
          </div>
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            severity="success"
            disabled
          />
          <Button icon="pi pi-print" size="small" severity="success" disabled />
          {/* <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          /> */}
        </div>
      </div>

      <DepotPerformanceTable
        title={"Salesman Depot 201"}
        onDepotClick={handleClickDepot}
      />

      <div></div>
    </div>
  );
};

export default DetailListDepot;

import classes from "./FilterComponent.module.css";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { FaFilter, FaAnglesDown, FaAnglesUp, FaPlus } from "react-icons/fa6";
import MenuSeparator from "../../components/menu/MenuSeparator";
import FilterComponentNumeric from "./FilterComponentNumeric";
import FilterComponentDate from "./FilterComponentDate";
import FilterComponentText from "./FilterComponentText";

const FilterComponentElement = (props) => {
  const { type } = props;

  switch (type) {
    case "number_plain":
    case "number_formatted":
    case "number_min_max":
    case "number_dropdown":
    case "number_radiobutton":
      return <FilterComponentNumeric {...props} />;
    case "date":
    case "date_time":
    case "date_datetime":
    case "date_min_max":
      return <FilterComponentDate {...props} />;
    default:
      return <FilterComponentText {...props} />;
  }
};

const getDefaultFilter = (options) => {
  const defaultFilter = [];
  for (let i = 0; i < options.length; i++) {
    const { filter_default } = options[i];
    if (filter_default) {
      defaultFilter.push(options[i]);
    }
  }

  return defaultFilter;
};

const FilterComponent = (props) => {
  const options = props.configs;

  const defaultFilter = getDefaultFilter(options);
  // const [selectedFilter, setSelectedFilter] = useState(options[0]);

  const [selectedFilter, setSelectedFilter] = useState(
    defaultFilter.length === 1 ? defaultFilter[0] : options[0]
  );
  const [hideAdvanceFilter, setHideAdvanceFilter] = useState(true);

  const [selectedOrFilter, setSelectedOrFilter] = useState(
    defaultFilter.length > 1 ? defaultFilter : []
  );
  const [filterOrValue, setFilterOrValue] = useState({});

  const [filterAndValue, setFilterAndValue] = useState({});

  const handleChangeFilterOrValue = ({ value, operator }) => {
    const fields = [];
    if (selectedOrFilter.length > 0) {
      for (const config of selectedOrFilter) {
        fields.push(config.field);
      }
    } else {
      fields.push(selectedFilter.field);
    }
    const newFilterValue = {};
    for (const field of fields) {
      newFilterValue[field] = { operator, value: value.map((e) => e.trim()) };
    }
    setFilterOrValue(newFilterValue);
  };

  const handleChangeFilterAndValue = ({ field, value, operator }) => {
    const newFilterValue = { ...filterAndValue };
    newFilterValue[field] = { operator, value: value.map((e) => e.trim()) };
    setFilterAndValue(newFilterValue);
  };

  const generateCompareMethod = (advance) => {
    if (advance) {
      return "and";
    }
    return "or";
  };

  const generateAlias = (advance) => {
    const filterArray = advance ? filterAndValue : filterOrValue;
    const result = {};
    for (const field in filterArray) {
      for (const config of props.configs) {
        if (
          field === config.field &&
          config.type === "object" &&
          config.object_name &&
          config.object_field
        ) {
          result[field] = {
            name: config.object_name,
            field: config.object_field,
          };
        }
      }
    }
    return result;
  };

  const generateFilter = (advance) => {
    const filterArray = advance ? filterAndValue : filterOrValue;
    const result = [];
    for (const field in filterArray) {
      const singleFilter = {};
      singleFilter.field = field;
      singleFilter.config = props.configs.find((e) => e.field === field);
      singleFilter.value = filterArray[field];
      result.push(singleFilter);
    }
    return result;
  };

  const generateCompleteFilter = (advance) => {
    return {
      compare_method: generateCompareMethod(advance),
      alias: generateAlias(advance),
      filter: generateFilter(advance),
    };
  };

  const handleSearch = (e) => {
    const filterResult = generateCompleteFilter(false);
    props.onFilter(filterResult);
  };

  const handleSearchOnEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch(e);
    }
  };

  const handleAdvanceSearch = (e) => {
    const filterResult = generateCompleteFilter(true);
    props.onFilter(filterResult);
  };

  const handleAdvanceSearchOnEnter = (e) => {
    if (e.key === "Enter") {
      handleAdvanceSearch(e);
    }
  };

  const handleChangeSelectedFilter = (e) => {
    const config = options.find((el) => el.field === e.value);
    setSelectedFilter(config);

    const newFilterValue = {};
    newFilterValue[config.field] = { operator: "none", value: [""] };
    setFilterOrValue(newFilterValue);
  };

  const handleOptionFilterClick = (option) => {
    if (selectedOrFilter.indexOf(option) < 0) {
      setSelectedOrFilter((arr) => [...arr, option]);
    }
  };

  const handleOrFilterClick = (option) => {
    const filtered = selectedOrFilter.filter((filter) => filter !== option);
    setSelectedOrFilter((arr) => filtered);
  };

  const handleAdvanceFilterVisibilityClick = () => {
    setHideAdvanceFilter(!hideAdvanceFilter);
  };

  const optionFilterTemplate = (option) => {
    return (
      <div className="row-between-center">
        <div>{option.name}</div>
        {(option.type.includes("text") ||
          option.type.includes("object") ||
          option.type.includes("bool")) && (
          <Button
            className="row-center-center"
            rounded
            outlined
            style={{ width: "1.5rem", height: "1.5rem", padding: "0" }}
            onClick={() => handleOptionFilterClick(option)}
          >
            <FaPlus />
          </Button>
        )}
      </div>
    );
  };

  const listOrFilterGenerator = () => {
    const newList = [];
    for (let i = 0; i < selectedOrFilter.length; i++) {
      const el = selectedOrFilter[i];
      const button = (
        <Button
          key={i}
          label={el.name}
          severity="info"
          outlined
          onClick={() => handleOrFilterClick(el)}
        />
      );
      newList.push(button);
    }
    return newList;
  };
  const listOrFilter = listOrFilterGenerator();

  const listAdvanceFilterGenerator = () => {
    const list = [];
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      let isFull = false;
      if (option.type.includes("number") || option.type.includes("date")) {
        isFull = true;
      }
      list.push(
        <div
          key={i}
          className={`col-start-start gap-1 ${isFull ? "w-full" : ""}`}
        >
          <label
            className={`${classes.advanceFilterLabel}`}
            htmlFor={option.value}
          >
            {option.name}
          </label>
          <FilterComponentElement
            type={option.type}
            id={option.field}
            and
            onEnter={handleAdvanceSearchOnEnter}
            onChangeFilterValue={handleChangeFilterAndValue}
            field={option.field}
          />
        </div>
      );
    }
    return list;
  };
  const listAdvanceFilter = listAdvanceFilterGenerator();

  return (
    <div className={`${classes.wrapper} w-100 col-start-start gap-2 pt-0`}>
      <div className={`row-start-center gap-1 w-100 wrap`}>
        <div className={`${classes.iconContainer}`}>
          <FaFilter className={`${classes.icon}`} size="20" />
        </div>
        <Dropdown
          value={selectedFilter.value}
          itemTemplate={optionFilterTemplate}
          onChange={(e) => handleChangeSelectedFilter(e)}
          options={options}
          optionLabel="name"
          placeholder="Select a Filter"
          className="grow sm:grow-0"
          style={{ minWidth: "200px" }}
        />
        <Button
          severity="secondary"
          outlined
          onClick={handleAdvanceFilterVisibilityClick}
          style={{ maxWidth: "40px" }}
        >
          {hideAdvanceFilter && <FaAnglesDown />}
          {!hideAdvanceFilter && <FaAnglesUp />}
        </Button>
        <div
          className={`row-start-center gap-1 ${
            listOrFilter.length > 0 ? "px-1" : ""
          }`}
        >
          {listOrFilter}
        </div>
        <div className="row-start-center grow">
          <FilterComponentElement
            type={selectedFilter.type}
            or
            onEnter={handleSearchOnEnter}
            onChangeFilterValue={handleChangeFilterOrValue}
            field={selectedFilter.field}
          />
        </div>
        <Button
          className={`${classes.confirmButton}`}
          style={{ maxWidth: "64px" }}
          label="Search"
          onClick={handleSearch}
        />
      </div>
      {!hideAdvanceFilter && <MenuSeparator />}
      {!hideAdvanceFilter && (
        <div className="row-between-end w-100 gap-3">
          <div className="row-start-start gap-3 wrap">{listAdvanceFilter}</div>
          <Button
            className={`${classes.confirmButton}`}
            label="Search"
            onClick={handleAdvanceSearch}
          />
        </div>
      )}
    </div>
  );
};

export default FilterComponent;

import { Chart } from "primereact/chart";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import { Skeleton } from "primereact/skeleton";
import LinkTitleComponent from "../../../../components/sales/LinkTitleComponent";

const yearReducer = 0;

const ReturBaikTahunBerjalan = (props) => {
  const { aspectRatio, title, sales_id } = props;
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const { requestGet, loading } = useAPIRequest();

  const [salesChartData, setSalesChartData] = useState(null);

  const salesChartOption = {
    maintainAspectRatio: false,
    aspectRatio: aspectRatio ?? 0.7,
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
          callback: (value) => {
            let newValue = value / 100.0;
            return `${newValue.toLocaleString("id-ID")} Tin`;
          },
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
  };

  useEffect(() => {
    reloadData();
  }, []);

  const generateFilter = ({ month, year }) => {
    let filter = `tahun:=:${year};data_stok_masuk.jenis_masuk:=:retur_baik`;

    let depot = null;
    let wilayah = null;

    if (!sales_id) {
      switch (user.title) {
        case "kadepot":
          depot = user.data_depot;
          wilayah = user.data_wilayah;
          break;
        case "kawil":
          wilayah = user.data_wilayah;
          depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
          break;
        case "controller":
          wilayah =
            selectedWilayah && selectedWilayah.id > 0 ? selectedWilayah : null;
          depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
          break;
      }
      if (depot) {
        filter += `;data_stok_masuk.data_depot.id:=:${depot.id}`;
      } else if (wilayah) {
        filter += `;data_stok_masuk.data_depot.wilayah_id:=:${wilayah.id}`;
      }
    }

    // console.log("FILTER RETUR BAIK", filter);

    return filter;
  };

  const reloadNewData = async () => {
    const today = new Date();

    const filter = generateFilter({
      month: today.getMonth() + 1,
      year: today.getFullYear() + yearReducer,
    });

    return await requestGet({
      fullUrl: "api/dashboard/kawil",
      params: { prefix: "retur", filter },
      onSuccess: ({ pagination, message, data }) => {},
      onError: ({ message, data }) => {},
    });
  };

  const reloadOldData = async () => {
    const today = new Date();

    const filter = generateFilter({
      month: today.getMonth(),
      year: today.getFullYear() + yearReducer - 1,
    });

    return await requestGet({
      fullUrl: "api/dashboard/kawil",
      params: { prefix: "retur", filter },
      onSuccess: ({ pagination, message, data }) => {},
      onError: ({ message, data }) => {},
    });
  };

  const reloadDataBySales = async () => {
    setSalesChartData(null);
    const today = new Date();

    let filter = generateFilter({
      month: today.getMonth() + 1,
      year: today.getFullYear() + yearReducer,
    });
    filter += `;sales_id:=:${sales_id}`;

    // console.log("FILTER RETUR BAIK", filter);

    return requestGet({
      fullUrl: "api/dashboard/kadepot",
      params: { prefix: "sales_retur", filter },
      onSuccess: ({ pagination, message, data }) => {},
      onError: ({ message, data }) => {},
    });
  };

  const reloadData = async () => {
    setSalesChartData(null);
    let newData = [];
    let oldData = [];

    if (sales_id) {
      const salesData = await reloadDataBySales();
      newData = salesData.currentYear;
      oldData = salesData.lastYear;
    } else {
      newData = await reloadNewData();
      oldData = await reloadOldData();
    }

    if (newData !== null && oldData !== null) {
      const arrLabel = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];
      const arrNew = [];
      const arrOld = [];
      const keys = Object.keys(newData);
      for (let i = 0; i < 12; i++) {
        const key = keys[i];

        if (valueType === "rupiah") {
          arrNew.push((newData[key] ?? 0) / 1000);
          arrOld.push((oldData[key] ?? 0) / 1000);
        } else if (valueType === "quantity") {
          arrNew.push(newData[key] ?? 0);
          arrOld.push(oldData[key] ?? 0);
        }
      }

      const thisYear = new Date();
      const lastYear = new Date(thisYear.getTime() - 365 * 24 * 60 * 60 * 1000);
      const labelNew = `Tahun ini (${thisYear.getFullYear()})`;
      const labelOld = `Tahun lalu (${lastYear.getFullYear()})`;

      setSalesChartData({
        labels: arrLabel,
        datasets: [
          {
            type: "bar",
            label: labelOld,
            data: arrOld,
            tension: 0.3,
            backgroundColor: "rgba(63,63,70,0.22)",
          },
          {
            label: labelNew,
            data: arrNew,
            tension: 0.3,
            backgroundColor: documentStyle.getPropertyValue("--blue-400"),
          },
        ],
      });
    }

    // console.log("OLD DATA", oldData);
    // console.log("NEW DATA", newData);
  };

  return (
    <div className="col-start-start grow-1 gap-3 w-full">
      <LinkTitleComponent title={title} href={"/detail/retur-baik"} />
      {salesChartData ? (
        <Chart
          style={{ width: "100%" }}
          type="bar"
          data={salesChartData}
          options={salesChartOption}
        />
      ) : (
        <div className="col-start-start w-full gap-3">
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="1rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="1rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="1rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="1rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
        </div>
      )}
    </div>
  );
};

export default ReturBaikTahunBerjalan;

import { format as dateFnsFormat, parse } from "date-fns";

const createFilterText = (filterMap) => {
  const generateOperatorSymbol = (operatorText) => {
    switch (operatorText) {
      case "less-than":
        return "<=";
      case "greater-than":
        return ">=";
      case "none":
        return "contains";
      case "equals":
        return "=";
      case "greater-than":
        return ">=";
      case "contains":
        return "contains";
    }
    return "-";
  };

  let result = "";
  const { compare_method, filter, alias } = filterMap;
  const connector = compare_method === "or" ? "||" : ";";

  for (const singleFilter of filter) {
    const { config, field, value } = singleFilter;

    if (result.length > 0) result += connector;

    let newField = field;
    if (alias[field]) {
      newField = alias[field].name
        ? `${alias[field].name}.${alias[field].field}`
        : `${alias[field].field}`;
    }

    switch (config.type) {
      case "number_plain":
      case "number_formatted":
      case "number_min_max":
      case "number_dropdown":
      case "number_radiobutton":
        if (value.operator === "between") {
          if (value.value[1] && value.value[1] !== null) {
            result += `${newField}:>=:${value.value[0]};${newField}:<=:${value.value[1]}`;
          } else {
            result += `${newField}:=:${value.value[0]}`;
          }
        } else {
          result += `${newField}:${generateOperatorSymbol(value.operator)}:${
            value.value[0]
          }`;
        }
        break;
      case "date":
      case "date_time":
      case "date_range":
      case "date_datetime":
      case "date_min_max":
        if (value.operator === "between") {
          if (value.value[1] && value.value[1] !== null) {
            result += `${newField}:>=:${dateFnsFormat(
              value.value[0],
              "yyyy-MM-dd"
            )};${newField}:<=:${dateFnsFormat(value.value[1], "yyyy-MM-dd")}`;
          } else {
            result += `${newField}:=:${dateFnsFormat(
              value.value[0],
              "yyyy-MM-dd"
            )}`;
          }
        } else {
          result += `${newField}:${generateOperatorSymbol(
            value.operator
          )}:${dateFnsFormat(value.value[0], "yyyy-MM-dd")}`;
        }
        break;
      default:
        // TEXT OR OBJECT
        result += `${newField}:${generateOperatorSymbol(value.operator)}:${
          value.value[0]
        }`;
    }
  }
  return result;
};

export default createFilterText;

import { format as dateFnsFormat } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLayoutDashboardTitle } from "../../store/ui-slice";

const useMDMainPageControl = ({ title, labelNew }) => {
  const dispatch = useDispatch();

  const [listEditorData, setListEditorData] = useState([]);
  const [activeIndexTab, setActiveIndexTab] = useState(0);

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  useEffect(() => {
    if (activeIndexTab > listEditorData.length) {
      setActiveIndexTab(listEditorData.length);
    }
  }, [listEditorData]);

  const addNewEditor = (editorData) => {
    if (editorData.id && editorData.id > 0) {
      const foundItem = listEditorData.find(
        (element) => element.id === editorData.id
      );
      if (!foundItem) {
        setListEditorData([
          ...listEditorData,
          {
            id: editorData.id,
            label: editorData.label,
            unique: editorData.id,
          },
        ]);
      } else {
        setActiveIndexTab(listEditorData.indexOf(foundItem) + 1);
      }
    } else {
      const foundItem = listEditorData.find(
        (element) => element.unique === editorData.unique
      );
      if (!foundItem) {
        setListEditorData([
          ...listEditorData,
          {
            id: 0,
            label: labelNew,
            unique: `${dateFnsFormat(new Date(), "dd#MM#yyyy#HH#mm#ss")}@${
              listEditorData.length
            }`,
          },
        ]);
      }
    }
  };

  const removeSelectedEditor = () => {
    const newEditorData = [...listEditorData];
    newEditorData.splice(activeIndexTab - 1, 1);
    setListEditorData(newEditorData);
  };

  const changeLabeEditor = (newLabel, unique) => {
    const newListEditorData = [...listEditorData];
    const foundEditorData = newListEditorData.find(
      (el) => el.unique === unique
    );

    if (foundEditorData && foundEditorData !== null) {
      foundEditorData.label = newLabel;
    }
    setListEditorData(newListEditorData);
  };

  const requestFocus = (unique) => {
    let index = -1;
    for (let i = 0; i < listEditorData.length; i++) {
      const el = listEditorData[i];
      if (el.unique === unique) {
        index = i;
      }
    }
    setActiveIndexTab(index + 1);
  };

  const handleTabChange = (index) => {
    setActiveIndexTab(index);
  };

  return {
    // methods
    addNewEditor,
    listEditorData,
    removeSelectedEditor,
    changeLabeEditor,
    requestFocus,
    handleTabChange,
    activeIndexTab,
    // states
    //utils
  };
};

export default useMDMainPageControl;

import classes from "./ButtonAvatar.module.css";
import { Avatar } from "primereact/avatar";

const ButtonAvatar = (props) => {
  return (
    <Avatar
      className={`${classes.wrapper} ${props.className ?? ""}`}
      shape="circle"
      onClick={props.onClick}
      image={props.src}
    />
  );
};

export default ButtonAvatar;

import { InputNumber } from "primereact/inputnumber";
import classes from "./FilterComponent.module.css";
import { useState } from "react";
import { SelectButton } from "primereact/selectbutton";

const FilterComponentNumeric = (props) => {
  const { onChangeFilterValue, onEnter, field, or, and } = props;

  const operators = [
    { icon: "pi pi-chevron-left", value: "less-than" },
    { icon: "pi pi-ellipsis-h", value: "between" },
    { icon: "pi pi-chevron-right", value: "greater-than" },
  ];

  const [operator, setOperator] = useState(operators[1].value);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);

  const genereateFilterValue = (v1, v2) => {
    if (operator === "between") {
      return [v1, v2];
    }
    return [v1];
  };

  const handleChangeOperator = (event) => {
    if (event.value && event.value != null) {
      setOperator(event.value);
    } else {
      setOperator(operators[1].value);
    }

    const filterValue = genereateFilterValue(value1, value2);
    if (or) {
      onChangeFilterValue({ value: filterValue, operator: event.value });
    } else if (and) {
      onChangeFilterValue({
        field,
        value: filterValue,
        operator: event.value,
      });
    }
  };

  const handleInput1Change = (event) => {
    setValue1(event.value);
    const filterValue = genereateFilterValue(event.value, value2);
    if (or) {
      onChangeFilterValue({ value: filterValue, operator });
    } else if (and) {
      onChangeFilterValue({
        field,
        value: filterValue,
        operator: operator,
      });
    }
  };

  const handleInput2Change = (event) => {
    setValue2(event.value);
    const filterValue = genereateFilterValue(value1, event.value);
    if (or) {
      onChangeFilterValue({ value: filterValue, operator });
    } else if (and) {
      onChangeFilterValue({
        field,
        value: filterValue,
        operator: operator,
      });
    }
  };

  return (
    <div className="row-start-center w-full">
      <SelectButton
        className="min-w-[8rem]"
        value={operator}
        onChange={(e) => handleChangeOperator(e)}
        itemTemplate={(option) => <i className={option.icon}></i>}
        optionLabel="value"
        options={operators}
      />
      <div className="row-start-center w-full gap-2">
        <InputNumber
          value={value1}
          locale="id-ID"
          showButtons
          buttonLayout="horizontal"
          minFractionDigits={0}
          maxFractionDigits={2}
          onChange={(e) => handleInput1Change(e)}
          className={`${classes.numericButton}`}
          style={{ width: "10rem" }}
        />
        {operator === "between" && <span>s/d</span>}
        {operator === "between" && (
          <InputNumber
            value={value2}
            locale="id-ID"
            showButtons
            buttonLayout="horizontal"
            minFractionDigits={0}
            maxFractionDigits={2}
            onChange={(e) => handleInput2Change(e)}
            className={`${classes.numericButton}`}
            style={{ width: "10rem" }}
          />
        )}
      </div>
    </div>
  );
};

export default FilterComponentNumeric;

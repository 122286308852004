import React from "react";

const BarcodeDialogTotalComponent = (props) => {
  const { total, height } = props;

  return (
    <div
      className={`row-center-center absolute bg-zinc-300 shadow-md px-3 py-1 rounded ${
        height ?? "top-[6.15rem]"
      } mr-6 gap-5 z-10`}
    >
      <span className="text-md font-semibold">TOTAL</span>
      <div className="row-end-center gap-2">
        <span className="text-lg font-bold">{total}</span>
        <span className="pi pi-qrcode"></span>
      </div>
    </div>
  );
};

export default BarcodeDialogTotalComponent;

import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { useEffect, useState } from "react";
import {
  FaCoins,
  FaHandHoldingDollar,
  FaMoneyBillWave,
  FaReceipt,
  FaShop,
  FaTruck,
} from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setDepot } from "../../../store/app-slice";
import BadgeAktivitasDepot from "./component-home/BadgeAktivitasDepot";
import BadgeInfoDepot from "./component-home/BadgeInfoDepot";
import BadgeOutletAktif from "./component-home/BadgeOutletAktif";
import DashboardHeaderBadge from "./component-home/DashboardHeaderBadge";
import HeaderValueType from "./component-home/HeaderValueType";
import JurusanPerformanceTable from "./component-home/JurusanPerformanceTable";
import OutletPerformanceTable from "./component-home/OutletPerformanceTable";
import ReturBaikTahunBerjalan from "./component-home/ReturBaikTahunBerjalan";
import ReturUsangTahunBerjalan from "./component-home/ReturUsangTahunBerjalan";
import SalesBulanBerjalan from "./component-home/SalesBulanBerjalan";
import SalesByQuantity from "./component-home/SalesByQuantity";
import SalesmanPerformanceTable from "./component-home/SalesmanPerformanceTable";
import StokByKodeProduksi from "./component-home/StokByKodeProduksi";
import TargetBulanBerjalan from "./component-home/TargetBulanBerjalan";
import BadgeJumlahBon from "./component-home/BadgeJumlahBon";
import BadgeBonKredit from "./component-home/BadgeBonKredit";
import BadgeBonJatuhTempo from "./component-home/BadgeBonJatuhTempo";
import BadgeCekStokKendaraan from "./component-home/BadgeCekStokKendaraan";
import BadgeBiayaDepot from "./component-home/BadgeBiayaDepot";

const HomeKadepot = (props) => {
  const { back } = props;
  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();
  const depot = useSelector((state) => state.app.depot) ?? { id: 0, nama: "-" };
  const listDepot = useSelector((state) => state.app.listDepot);

  const filterDetailOptions = ["Salesman", "Jurusan", "Outlet"];
  const [filterDetail, setFilterDetail] = useState(filterDetailOptions[0]);

  useEffect(() => {
    if (id) {
      window.scrollTo(0, 0);
    }
  }, []);

  const handleClickOutletAktif = (e) => {
    console.log("Dashboard Header Badge Click");
  };

  const handleClickCekStokKendaraan = (e) => {
    navigate("/transaksi/kendaraanpulang");
  };

  const handleClickBiayaDepot = (e) => {
    navigate("/report/report-biaya");
  };

  const handleClickDepotSales = (depot) => {
    console.log("Depot Sales Click", depot);
  };

  const handleClickDepotRetur = (depot) => {
    console.log("Depot Retur Click", depot);
  };

  const handleClickBack = () => {
    dispatch(setDepot(listDepot[0]));
  };

  return (
    <div className="col-start-start p-4 w-full gap-4">
      <div
        className={`elevated-card-tight-vertical row-start-start w-full ${
          back ? "!pl-3" : ""
        }`}
      >
        <div className="row-start-center gap-2 w-full">
          {back && (
            <Button
              icon="pi pi-arrow-left"
              text
              className="max-h-[1.4rem]"
              onClick={handleClickBack}
            />
          )}
          <HeaderValueType title={depot.nama} />
        </div>
      </div>
      <div className="row-between-start w-full gap-4 flex-wrap md:flex-nowrap">
        <BadgeOutletAktif
          className="w-full md:w-1/2 lg:w-1/4"
          icon={<FaShop />}
          onClick={(e) => handleClickOutletAktif(e)}
          title={"Outlet Aktif"}
        />
        <BadgeJumlahBon
          className="w-full md:w-1/2 lg:w-1/4"
          icon={<FaReceipt />}
          onClick={(e) => handleClickOutletAktif(e)}
          title={"Jumlah Bon"}
        />
        <BadgeBonKredit
          className="w-full md:w-1/2 lg:w-1/4"
          icon={<FaMoneyBillWave />}
          onClick={(e) => handleClickOutletAktif(e)}
          title={"Bon Kredit"}
        />
        <BadgeBonJatuhTempo
          className="w-full md:w-1/2 lg:w-1/4"
          icon={<FaHandHoldingDollar />}
          onClick={(e) => handleClickOutletAktif(e)}
          title={"Bon Jatuh Tempo"}
        />
      </div>
      <div className="row-between-start w-full gap-4 flex-wrap md:flex-nowrap">
        <BadgeCekStokKendaraan
          className="w-full md:w-1/2 lg:w-1/4"
          icon={<FaTruck />}
          onClick={(e) => handleClickCekStokKendaraan(e)}
          title={"Cek Stok"}
        />
        <BadgeBiayaDepot
          className="w-full md:w-1/2 lg:w-1/4"
          icon={<FaCoins />}
          onClick={(e) => handleClickBiayaDepot(e)}
          title={"Biaya Bulanan"}
        />
        <DashboardHeaderBadge disabled className="w-full md:w-1/2 lg:w-1/4" />
        <DashboardHeaderBadge disabled className="w-full md:w-1/2 lg:w-1/4" />
      </div>
      <div className="row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <div className="elevated-card col-start-start grow-1">
          <BadgeInfoDepot title={`Info ${depot.nama}`} />
        </div>
        <div className="elevated-card col-start-start grow-1">
          <BadgeAktivitasDepot title={`Aktivitas Hari Ini`} />
        </div>
      </div>
      <div className="elevated-card col-start-start w-full">
        <SalesBulanBerjalan
          title={`D&P Bulan Berjalan ${depot.nama}`}
          aspectRatio={0.73}
        />
      </div>
      <div className="row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <div className="elevated-card row-start-start w-full">
          <SalesByQuantity
            title={`Produk Terbaik Di ${depot.nama}`}
            aspectRatio={0.73}
          />
        </div>
        <div className="elevated-card row-start-start w-full">
          <TargetBulanBerjalan title={`D&P VS Target ${depot.nama}`} />
        </div>
      </div>
      <div className="row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <div className="elevated-card row-start-start w-full">
          <ReturBaikTahunBerjalan
            title={`Retur Baik Tahun Berjalan ${depot.nama}`}
            aspectRatio={0.73}
          />
        </div>
        <div className="elevated-card row-start-start w-full">
          <ReturUsangTahunBerjalan
            title={`Retur Usang Berjalan ${depot.nama}`}
            aspectRatio={0.73}
          />
        </div>
      </div>
      <div className="elevated-card row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <StokByKodeProduksi
          type="depot"
          title={`Stok Per Kode Produksi Di ${depot.nama}`}
        />
      </div>
      <div className="elevated-card-tight-vertical row-start-start w-full ">
        <div className="row-between-center w-full flex-wrap gap-2 ">
          <span className="text-sm font-semibold text-zinc-700">
            List Salesman & Jurusan Di {depot.nama}
          </span>
          <div className="row-end-center">
            <SelectButton
              value={filterDetail}
              onChange={(e) => setFilterDetail(e.value)}
              options={filterDetailOptions}
              onClick={(e) => e.preventDefault()}
            />
          </div>
        </div>{" "}
      </div>
      {filterDetail === "Salesman" && (
        <div className="row-between-start wrap w-full gap-4">
          <SalesmanPerformanceTable title={`Salesman ${depot.nama}`} />
        </div>
      )}
      {filterDetail === "Jurusan" && (
        <div className="row-between-start wrap w-full gap-4">
          <JurusanPerformanceTable title={`Jurusan ${depot.nama}`} />
        </div>
      )}
      {filterDetail === "Outlet" && (
        <div className="row-between-start wrap w-full gap-4">
          <OutletPerformanceTable title={`Outlet ${depot.nama}`} />
        </div>
      )}
    </div>
  );
};

export default HomeKadepot;

import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import configs from "./StokKeluarConfigs";
import generateEditorConfigs from "../../../../../utils/EditorConfigGenerator";
import useMDEditorDialogControl from "../../../../../custom_hooks/complex/useMDEditorDialogControl";
// add_import_selector_dialog
import ProdukSelectorDialog from "../../../../../dialogs/selectors/ProdukSelectorDialog";

const title = "Edit Detil";
const configEditors = generateEditorConfigs(configs.detail.columns);

const StokKeluarDetilDetailEditorDialog = (props) => {
  const { value, setValue, error, setError, onHide, visible, onSave } = props;

  const { elements, generateSelectorDialog, validator } =
    useMDEditorDialogControl({
      configs,
      value,
      setValue,
      error,
    });

  const convertValue = () => {
    const newValue = { ...props.value };

    newValue.konversi = newValue.satuan_dropdown.konversi ?? 0;
    newValue.satuan = newValue.satuan_dropdown.satuan ?? "";
    return newValue;
  };

  const validation = () => {
    return validator({
      configs: configEditors,
      value: value,
      onError: (error) => setError(error),
      isEmpty: "produk_id tanggal_produksi satuan konversi ",
    });
  };

  // add_generate_selector_dialog
  generateSelectorDialog({
    field: "produk_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <ProdukSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  const handleSave = (event) => {
    event.preventDefault();
    const valid = validation();
    if (valid) {
      const newValue = convertValue();
      onSave(event, newValue);
    }
  };

  const dialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={onHide} />
      <Button
        label="Save"
        style={{ marginRight: "0" }}
        icon="pi pi-check"
        onClick={handleSave}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={title}
      modal
      className={`p-fluid`}
      footer={dialogFooter}
      onHide={onHide}
    >
      <div className="col-start-center w-100 relative h-100 gap-3">
        {elements.produk_id}
        <div className="row-between-center w-100 gap-3">
          {elements.quantity}
          {elements.satuan_dropdown}
        </div>
        <div className="row-between-center w-100 gap-3">
          {elements.harga}
          {elements.diskon_type}
          {elements.diskon_nilai}
        </div>
        {elements.tanggal_produksi}
        {elements.bonus}
      </div>
    </Dialog>
  );
};

export default StokKeluarDetilDetailEditorDialog;

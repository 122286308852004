import React, { Children, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import classes from "./MenuGroupItemComponent.module.css";
import { FaChevronRight, FaChevronDown } from "react-icons/fa6";

const MenuGroupItemComponent = (props) => {
  const { label, icon } = props;
  const [expanded, setExpanded] = useState(false);
  const [active, setActive] = useState(false);

  const handleClick = (e) => {
    setExpanded(!expanded);
  };

  //get last route path
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastPathSegment = pathSegments[pathSegments.length - 1];

  // const checkIfChildIsActive = () => {
  //   //loop all child to access to attribute
  //   const childTos = Children.map(props.children, (child) => {
  //     if (React.isValidElement(child)) {
  //       const childProps = child.props;
  //       const to = childProps["to"];
  //       return to;
  //     }
  //   });

  //   let childActive = false;
  //   for (const to of childTos) {
  //     const toSegments = to.split("/");
  //     const lastToSegment = toSegments[toSegments.length - 1];

  //     childActive = lastPathSegment === lastToSegment;
  //     if (childActive) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const checkIfChildIsActive = useCallback(() => {
    let childActive = false;

    //loop all child to access to attribute
    const childTos = Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        const childProps = child.props;
        const to = childProps["to"];
        return to;
      }
    });

    for (const to of childTos) {
      const toSegments = to.split("/");
      const lastToSegment = toSegments[toSegments.length - 1];

      childActive = lastPathSegment === lastToSegment;
      if (childActive) {
        break;
      }
    }
    if (childActive) {
      setExpanded(true);
      setActive(true);
    } else {
      setActive(false);
    }
    // return false;
  }, [props.children, lastPathSegment]);

  useEffect(() => {
    checkIfChildIsActive();
  }, [checkIfChildIsActive]);

  return (
    <div className="px-2">
      <div
        className={`${
          active ? classes.wrapperActive : classes.wrapper
        } col-start-start cursor-pointer w-full pb-2 px-2 m-0 select-none`}
      >
        <div className="row-between-center w-100 px-2" onClick={handleClick}>
          <div className={`${classes.headerContainer} row-start-center gap-2`}>
            <div className={`${classes.iconContainer} row-center-center`}>
              {icon && icon}
            </div>
            {label}
          </div>
          {expanded ? (
            <FaChevronRight className="transition-transform  ease-in-out duration-200 rotate-90" />
          ) : (
            <FaChevronRight className="transition-transform  ease-in-out duration-200 " />
          )}
        </div>

        <ul className={`col-start-start w-100`}>
          {expanded && props.children}
        </ul>
      </div>
    </div>
  );
};

export default MenuGroupItemComponent;

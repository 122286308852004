import { Calendar } from "primereact/calendar";
import { Avatar } from "primereact/avatar";
import { useEffect, useState } from "react";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import ScanBarcodeDialog from "./ScanBarcodeDialog";

const tableConfigs = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Kode",
    field: "kode",
    type: "text_plain",
    align: "left",
    table: true,
  },
  {
    name: "Nama Produk",
    field: "nama",
    type: "text_plain",
    align: "left",
    table: true,
  },
  {
    name: "Qty",
    field: "quantity",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Qty Cek",
    field: "quantity_cek",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Checked",
    field: "cek",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Satuan",
    field: "satuan",
    type: "text_plain",
    align: "left",
    table: true,
  },
];

const customColumns = [
  {
    field: "cek",
    component: (rowData) => {
      return (
        <div className="row-center-center">
          {rowData.cek ? (
            <Avatar
              className="bg-green-300 text-white"
              shape="circle"
              icon="pi pi-check"
            />
          ) : (
            "-"
          )}
        </div>
      );
    },
  },
];

const KendaraanPulangSinglePage = () => {
  const navigate = useNavigate();
  const [visibleScanBarcodeDialog, setVisibleScanBarcodeDialog] =
    useState(false);
  const [selectedTanggal, setSelectedTanggal] = useState(new Date());
  const [listData, setListData] = useState();
  const loading = false;

  useEffect(() => {
    setListData([
      {
        id: 1,
        no: 1,
        kode: "A110",
        nama: "American Spirit FK (HLP)",
        quantity: 1050,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 2,
        no: 2,
        kode: "F218",
        nama: "Gunung Gula International SK 16s (HLP)",
        quantity: 1150,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 3,
        no: 3,
        kode: "D218",
        nama: "Gunung Gula Red International 16s (HLP)",
        quantity: 795,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 4,
        no: 4,
        kode: "D212",
        nama: "Gunung Gula Red International SK 12s (HLP)",
        quantity: 1000,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 5,
        no: 5,
        kode: "F212",
        nama: "Gudang Prambanan SK 12s (HLP)",
        quantity: 1150,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 6,
        no: 6,
        kode: "F211",
        nama: "Gudang Prambanan SK 16s (HLP)",
        quantity: 700,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 7,
        no: 7,
        kode: "D233",
        nama: "Gudang Prambanan International FK",
        quantity: 750,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 8,
        no: 8,
        kode: "A014",
        nama: "Hero FL",
        quantity: 900,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 9,
        no: 9,
        kode: "A112",
        nama: "Hero Casual Blue 20s (HLP)",
        quantity: 900,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 10,
        no: 10,
        kode: "F227",
        nama: "Hero Casual Bold SK 12s (HLP)",
        quantity: 750,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 11,
        no: 11,
        kode: "F228",
        nama: "Hero Casual Gentle SK 16s (HLP)",
        quantity: 900,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 12,
        no: 12,
        kode: "A024",
        nama: "Hero FK (HLP)",
        quantity: 905,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 13,
        no: 13,
        kode: "E027",
        nama: "Johnson FK (HLP)",
        quantity: 930,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 14,
        no: 14,
        kode: "A051",
        nama: "Johnson FL",
        quantity: 1150,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 15,
        no: 15,
        kode: "A045",
        nama: "Kennedy FK (HLP)",
        quantity: 900,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 16,
        no: 16,
        kode: "F225",
        nama: "Marcopolo White Mild 16s (HLP)",
        quantity: 1500,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 17,
        no: 17,
        kode: "A079",
        nama: "Marcopolo FK (HLP)",
        quantity: 1250,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 18,
        no: 18,
        kode: "A069",
        nama: "Marcopolo Fresh FK (HLP)",
        quantity: 1055,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 19,
        no: 19,
        kode: "A080",
        nama: "Marcopolo Light FK (HLP)",
        quantity: 1450,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 20,
        no: 20,
        kode: "A068",
        nama: "Marcopolo Silver FK (HLP)",
        quantity: 755,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 21,
        no: 21,
        kode: "A033",
        nama: "Nero FL",
        quantity: 950,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 22,
        no: 22,
        kode: "A034",
        nama: "Nero Spesial FL",
        quantity: 800,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 23,
        no: 23,
        kode: "A047",
        nama: "Panama FL",
        quantity: 950,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 24,
        no: 24,
        kode: "F217",
        nama: "Panama SK 12s (HLP)",
        quantity: 990,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 25,
        no: 25,
        kode: "F226",
        nama: "Panama Super King 16s (HLP)",
        quantity: 900,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 26,
        no: 26,
        kode: "F013",
        nama: "Panama FK (HLP)",
        quantity: 800,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 27,
        no: 27,
        kode: "A111",
        nama: "Panama International FK",
        quantity: 1650,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 28,
        no: 28,
        kode: "F224",
        nama: "Panama Mild Balance SK 16s (HLP)",
        quantity: 985,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 29,
        no: 29,
        kode: "N003",
        nama: "Prince Filter King (HLP)",
        quantity: 950,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 30,
        no: 30,
        kode: "E014",
        nama: "Prince FL",
        quantity: 1045,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 31,
        no: 31,
        kode: "A109",
        nama: "Panama Signature SK",
        quantity: 900,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
      {
        id: 32,
        no: 32,
        kode: "E006",
        nama: "Rolex FK (HLP)",
        quantity: 1000,
        quantity_cek: 0,
        cek: false,
        satuan: "Tin",
      },
    ]);
  }, []);

  const handleReload = () => {};

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleClickScan = () => {
    setVisibleScanBarcodeDialog(true);
  };

  const handleScanBarcode = (barcode) => {
    let processIndex = 0;
    const newListData = [...listData];
    for (; processIndex < newListData.length; processIndex++) {
      if (!newListData[processIndex].cek) {
        newListData[processIndex].quantity_cek +=
          newListData[processIndex].quantity / 2;

        if (
          newListData[processIndex].quantity_cek >=
          newListData[processIndex].quantity
        ) {
          newListData[processIndex].cek = true;
        }

        break;
      }
    }
    setListData(newListData);
  };

  return (
    <div className={`col-start-start p-4 gap-4 w-100`}>
      <ScanBarcodeDialog
        visible={visibleScanBarcodeDialog}
        onHide={() => setVisibleScanBarcodeDialog(false)}
        onConfirm={(barcode) => {
          handleScanBarcode(barcode);
        }}
      />
      <div className="elevated-card row-between-center w-100 !py-4 gap-4">
        <div className="row-start-center gap-2">
          <Button
            icon="pi pi-arrow-left"
            text
            className="max-h-[1.4rem]"
            onClick={handleClickBack}
          />
          <span className="font-semibold text-zinc-700">
            Cek Stok Kendaraan Pulang
          </span>
        </div>
        <div className="row-start-center gap-2">
          <Calendar
            id="tanggal"
            value={selectedTanggal}
            showButtonBar
            selectionMode="single"
            hourFormat="24"
            showIcon
            onChange={(e) => {
              setSelectedTanggal(e.value);
            }}
            className={`dateInput w-[13rem]`}
            locale="id"
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>
      {/* <Toast ref={toast} /> */}
      <div className="elevated-card col-start-start w-100 gap-4">
        <Button
          className="w-[8rem]"
          label="Scan QR"
          icon="pi pi-qrcode"
          onClick={handleClickScan}
        />
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            onSelectionChange={() => {}}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={customColumns}
          />
        </div>
      </div>
    </div>
  );
};

export default KendaraanPulangSinglePage;

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";

import classes from "./ZirvuFileManagerDialogInputText.module.css";

const ZirvuFileManagerDialogInputText = (props, { className }) => {
  const inputRef = useRef();

  const dialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={props.onHide}
      />
      <Button
        label="Pilih"
        icon="pi pi-check"
        onClick={(e) => {
          const value = inputRef.current.value;
          props.onConfirm(e, value);
        }}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "25rem" }}
      header={props.title}
      modal
      className={`${classes.wrapperContainer} p-fluid`}
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <div
        className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
      >
        <label
          // htmlFor={field}
          className="font-bold"
        >
          {props.prompt}
        </label>
        <InputText
          ref={inputRef}
          defaultValue={props.value ?? ""}
          // id={"field"}
          autoFocus
          // value={value}
          // onChange={(e) => handleInputChange(e)}
        />
      </div>
    </Dialog>
  );
};

export default ZirvuFileManagerDialogInputText;

import { Avatar } from "primereact/avatar";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Knob } from "primereact/knob";
import { useEffect, useState } from "react";
import RadialChart from "../../../../components/chart/RadialChart";
import { Skeleton } from "primereact/skeleton";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import { useNavigate } from "react-router-dom";
import { changeDepot, changeWilayah } from "../../../../store/app-slice";

const getReturColor = (value) => {
  if (value < 25) {
    // return "text-green-500";
    return "rgb(34 197 94)";
  } else if (value >= 25 && value < 50) {
    // return "text-yellow-500";
    return "rgb(234 179 8)";
  } else if (value >= 50 && value < 75) {
    // return "text-orange-500";
    return "rgb(249 115 22)";
  } else {
    // return "text-red-500";
    return "rgb(239 68 68)";
  }
};

const getReturClassColor = (value) => {
  if (value < 25) {
    return "text-green-500";
  } else if (value >= 25 && value < 50) {
    return "text-yellow-500";
  } else if (value >= 50 && value < 75) {
    return "text-orange-500";
  } else {
    return "text-red-500";
  }
};

const getSalesColor = (value) => {
  if (value < 25) {
    // return "text-red-500";
    return "rgb(239 68 68)";
  } else if (value >= 25 && value < 50) {
    // return "text-orange-500";
    return "rgb(249 115 22)";
  } else if (value >= 50 && value < 75) {
    // return "text-yellow-500";
    return "rgb(234 179 8)";
  } else {
    // return "text-green-500";
    return "rgb(34 197 94)";
  }
};
const getSalesClassColor = (value) => {
  if (value < 25) {
    return "text-red-500";
  } else if (value >= 25 && value < 50) {
    return "text-orange-500";
  } else if (value >= 50 && value < 75) {
    return "text-yellow-500";
  } else {
    return "text-green-500";
  }
};

const WilayahPerformanceTable = (props) => {
  const { onWilayahClick } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const [datas, setDatas] = useState(null);
  const { requestGet, loading } = useAPIRequest();

  useEffect(() => {
    reloadData();
  }, []);

  const generateFilter = () => {
    const today = new Date();

    let filter = `tahun:=:${today.getFullYear()};bulan:=:${
      today.getMonth() + 1
    }`;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `;depot_id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `;wilayah_id:=:${wilayah.id}`;
    }

    return filter;
  };

  const reloadData = async () => {
    // const filter = generateFilter();
    // setDatas(null);

    // return await requestGet({
    //   fullUrl: "api/dashboard/kawil",
    //   params: {
    //     prefix: "depot",
    //     filter,
    //     page: 1,
    //     take: 20,
    //     order: "nama",
    //     order_method: "ASC",
    //     with_produk: 1,
    //   },
    //   onSuccess: ({ pagination, message, data }) => {
    //     if (data) {
    const newDatas = [];
    // for (let i = 0; i < data.length; i++) {
    for (let i = 0; i < 9; i++) {
      // const { d_p, retur_baik, retur_usang, id, nama, lokasi } = data[i];
      // const {
      //   prediksi,
      //   prediksi_per_bulan,
      //   realisasi,
      //   total_target_batang,
      //   total_target_batang_per_bulan,
      // } = d_p;
      // const limitDivider = 1000.0;

      const sales_target = Math.floor(Math.random() * 10000000) + 5000000;
      const sales_value = Math.floor(Math.random() * 10000000) + 2000000;
      const sales_prediksi = Math.floor(Math.random() * 10000000) + 5000000;

      const retur_baik_target = Math.floor(Math.random() * 100000) + 50000;
      const retur_baik_value = Math.floor(Math.random() * 100000) + 20000;

      const retur_usang_target = Math.floor(Math.random() * 100000) + 50000;
      const retur_usang_value = Math.floor(Math.random() * 100000) + 20000;

      const newData = {
        depot_nama: `Wilayah ${i + 1}`,
        depot_lokasi: "-",
        id: i + 1,
        no: i + 1,
        sales_target: sales_target,
        sales_value: sales_value,
        sales_prediksi: sales_prediksi,
        sales_percent: (sales_value / sales_target) * 100.0,
        retur_baik_value: retur_baik_value,
        retur_baik_target: retur_baik_target,
        retur_baik_percent: (retur_baik_value / retur_baik_target) * 100.0,
        retur_usang_value: retur_usang_value,
        retur_usang_target: retur_usang_target,
        retur_usang_percent: (retur_usang_value / retur_usang_target) * 100.0,
      };

      if (i === 0) {
        newData.depot_nama = "Wilayah 1";
        newData.id = 1;
      } else if (i === 1) {
        newData.depot_nama = "Wilayah 2";
        newData.id = 2;
      }

      if (newData.sales_target <= 0) {
        if (newData.sales_value === 0) {
          newData.sales_percent = 0;
        } else {
          newData.sales_percent = 100;
        }
      }
      if (newData.retur_baik_target <= 0) {
        if (newData.retur_baik_value === 0) {
          newData.retur_baik_percent = 0;
        } else {
          newData.retur_baik_percent = 100;
        }
      }
      if (newData.retur_usang_target <= 0) {
        if (newData.retur_usang_value === 0) {
          newData.retur_usang_percent = 0;
        } else {
          newData.retur_usang_percent = 100;
        }
      }

      newDatas.push(newData);
    }
    setDatas(newDatas);
    //     }
    //   },
    //   onError: ({ message, data }) => {},
    // });
  };

  const handleClickWilayah = (id) => {
    dispatch(changeWilayah(id));

    if (onWilayahClick) {
      onWilayahClick(id);
    }
    // navigate(`/kawil/detail/depot/${id}`);
  };

  const depotBodyTemplate = (rowData) => {
    const { depot_nama, depot_lokasi, id } = rowData;
    return (
      <div
        className="col-start-start gap-2 p-2 cursor-pointer active:bg-blue-100 hover:bg-blue-50 active:rounded"
        onClick={() => {
          handleClickWilayah(id);
        }}
      >
        <span className="font-semibold">{depot_nama}</span>
        <span className="text-sm text-zinc-400">{depot_lokasi}</span>
      </div>
    );
  };

  const salesTargetBodyTemplate = (rowData) => {
    let { sales_target, sales_prediksi, sales_value, sales_percent } = rowData;

    if (sales_target === 0 && sales_value === 0) {
      sales_percent = null;
    }

    return (
      <div className="col-center-center">
        {sales_percent && (
          <RadialChart
            radius={75}
            target={(sales_prediksi / sales_target) * 100.0}
            value={(sales_value / sales_target) * 100.0}
            dimension={100}
            color={getSalesColor(sales_percent)}
          />
        )}
        <div className={`row-center-center gap-2`}>
          <span
            className={`font-semibold ${getSalesClassColor(sales_percent)}`}
          >
            {Math.trunc(sales_value / 1000.0).toLocaleString("id-ID", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
            K Btg
          </span>
          <span>/</span>
          <span className="font-semibold text-zinc-400">
            {Math.trunc(sales_target / 1000.0).toLocaleString("id-ID", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
            K Btg
          </span>
        </div>
      </div>
    );
  };

  const returBaikBodyTemplate = (rowData) => {
    let { retur_baik_target, retur_baik_value, retur_baik_percent } = rowData;

    if (retur_baik_target === 0 && retur_baik_value === 0) {
      retur_baik_percent = null;
    }

    return (
      <div className="col-center-center">
        {retur_baik_percent && (
          <Knob
            value={
              retur_baik_percent > 100 ? 100 : retur_baik_percent.toFixed(0)
            }
            valueTemplate={`${retur_baik_percent.toFixed(0)}%`}
            valueColor={getSalesColor(retur_baik_percent)}
          />
        )}
        <div
          className={`row-center-center gap-2 font-semibold ${getSalesClassColor(
            retur_baik_percent
          )}`}
        >
          <span>
            {Math.trunc(retur_baik_value / 100.0).toLocaleString("id-ID", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}{" "}
            Tin
          </span>
          <span>/</span>
          <span className="font-semibold text-zinc-400">
            {Math.trunc(retur_baik_target / 100.0).toLocaleString("id-ID", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}{" "}
            Tin
          </span>
        </div>
      </div>
    );
  };

  const returUsangBodyTemplate = (rowData) => {
    let { retur_usang_target, retur_usang_value, retur_usang_percent } =
      rowData;

    if (retur_usang_target === 0 && retur_usang_value === 0) {
      retur_usang_percent = null;
    }

    return (
      <div className="col-center-center">
        {retur_usang_percent && (
          <Knob
            value={
              retur_usang_percent > 100 ? 100 : retur_usang_percent.toFixed(0)
            }
            valueTemplate={`${retur_usang_percent.toFixed(0)}%`}
            valueColor={getReturColor(retur_usang_percent)}
          />
        )}
        <div
          className={`row-center-center gap-2 font-semibold ${getReturClassColor(
            retur_usang_percent
          )}`}
        >
          <span>
            {Math.trunc(retur_usang_value / 100.0).toLocaleString("id-ID", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}{" "}
            Tin
          </span>
          <span>/</span>
          <span className="font-semibold text-zinc-400">
            {Math.trunc(retur_usang_target / 100.0).toLocaleString("id-ID", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}{" "}
            Tin
          </span>
        </div>
      </div>
    );
  };

  const biayaBodyTemplate = (rowData) => {
    let biaya_target = 0,
      biaya_value = 0,
      biaya_percent = 0;

    biaya_target = Math.floor(Math.random() * 5000000) + 5000000;
    biaya_value = Math.floor(Math.random() * 5000000) + 5000000;
    biaya_percent = (biaya_value / biaya_target) * 100.0;

    // let { retur_usang_target, retur_usang_value, retur_usang_percent } =
    //   rowData;

    // if (retur_usang_target === 0 && retur_usang_value === 0) {
    //   retur_usang_percent = null;
    // }

    return (
      <div className="col-center-center">
        {biaya_percent && (
          <Knob
            value={biaya_percent > 100 ? 100 : biaya_percent.toFixed(0)}
            valueTemplate={`${biaya_percent.toFixed(0)}%`}
            valueColor={getReturColor(biaya_percent)}
          />
        )}
        <div
          className={`row-center-center gap-2 font-semibold ${getReturClassColor(
            biaya_percent
          )}`}
        >
          <span>
            Rp{" "}
            {(biaya_value / 1000000.0).toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}{" "}
            Jt
          </span>
          <span>/</span>
          <span className="font-semibold text-zinc-400">
            Rp{" "}
            {(biaya_target / 1000000.0).toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}{" "}
            Jt
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="col-start-start elevated-card gap-3 w-full">
      {datas ? (
        <DataTable
          className="w-full"
          value={datas}
          resizableColumns
          scrollable
          scrollHeight="40.5rem"
        >
          <Column field="no" header="#" />
          <Column
            field="depot_nama"
            header="Wilayah"
            body={depotBodyTemplate}
          />
          <Column
            field="sales_value"
            alignHeader={"center"}
            header="D&P vs Target Bulanan"
            body={salesTargetBodyTemplate}
          />
          <Column
            field="retur_baik_value"
            alignHeader={"center"}
            header="Retur Baik Tahunan"
            body={returBaikBodyTemplate}
          />
          <Column
            field="retur_usang_value"
            alignHeader={"center"}
            header="Retur Usang Tahunan"
            body={returUsangBodyTemplate}
          />
          <Column
            field="biaya_value"
            alignHeader={"center"}
            header="Biaya Bulanan"
            body={biayaBodyTemplate}
          />
        </DataTable>
      ) : (
        <div className="col-start-start w-full gap-3">
          <Skeleton className="w-full" height="5rem"></Skeleton>
          <Skeleton className="w-full" height="5rem"></Skeleton>
          <Skeleton className="w-full" height="5rem"></Skeleton>
          <Skeleton className="w-full" height="5rem"></Skeleton>
          <Skeleton className="w-full" height="5rem"></Skeleton>
        </div>
      )}
    </div>
  );
};

export default WilayahPerformanceTable;

import { Outlet } from "react-router-dom";
import NavBarComponent from "../../components/nav-bar/NavBarComponent";
import SideMenuComponent from "../../components/side-menu/SideMenuComponent";
import FloatingProfilePage from "../dashboard/FloatingProfilePage";
import { FaHouse, FaBoxesStacked } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";
import classes from "./LayoutDashboardUserPage.module.css";
import { APP_NAME } from "../../configs/constants";
import { Dialog } from "primereact/dialog";
import { useState } from "react";

import { motion, AnimatePresence } from "framer-motion";

const title = APP_NAME;
const logoUrl = "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png";

const menus = [
  {
    label: "Home",
    to: "main",
    icon: <FaHouse />,
  },
];

const handleNotifClick = (e) => {
  console.log("New Notif Click");
};

const LayoutDashboardPage = () => {
  const employee = useSelector((state) => state.auth.employee);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const sideMenuIsVisible = useSelector((state) => state.ui.sideMenuIsVisible);

  const handleAvatarClick = (e) => {
    setShowProfileDialog((state) => !state);
  };

  return (
    <>
      <div className="row-start-start w-100">
        <AnimatePresence>
          {sideMenuIsVisible && (
            <SideMenuComponent menus={menus} title={title} logoUrl={logoUrl} />
          )}
        </AnimatePresence>
        <Dialog
          className="mt-6"
          showHeader={false}
          contentStyle={{ padding: "0", borderRadius: "0.5rem" }}
          position={"top-right"}
          visible={showProfileDialog}
          modal={false}
          style={{
            width: "50vw",
            marginTop: "calc(var(--height-nav-bar) + 12px )",
          }}
          draggable={false}
          resizable={false}
          onHide={() => setShowProfileDialog(false)}
        >
          <FloatingProfilePage hidden={false} />
        </Dialog>
        <motion.div
          initial={{
            marginLeft: sideMenuIsVisible ? 0 : "var(--width-side-menu)",
          }}
          animate={{
            marginLeft: sideMenuIsVisible ? "var(--width-side-menu)" : 0,
          }}
          className="col-start-start w-100"
        >
          <NavBarComponent
            className="w-100"
            showNotif={true}
            onCLickAvatar={handleAvatarClick}
            onClickNotif={handleNotifClick}
            avatarSrc={employee ? employee.image : ""}
          />
          <main className={`row-start-start ${classes.mainContainer} w-100`}>
            <Outlet />
          </main>
        </motion.div>
      </div>
    </>
  );
};

export default LayoutDashboardPage;

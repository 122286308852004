import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Avatar } from "primereact/avatar";
import { format as dateFnsFormat, parse } from "date-fns";
import { useEffect, useState } from "react";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const tableConfigs = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Plat Kendaraan",
    field: "nomor_polisi",
    type: "text_plain",
    align: "left",
    table: true,
  },
  {
    name: "Cek Stok",
    field: "cek_stok",
    type: "text_plain",
    align: "center",
    table: true,
  },
];

const KendaraanPulangMainPage = () => {
  const navigate = useNavigate();
  const [selectedTanggal, setSelectedTanggal] = useState(new Date());
  const [listData, setListData] = useState();
  const loading = false;

  const handleClickPlatMobil = (event) => {
    event.preventDefault();
    navigate("123");
  };

  const customColumns = [
    {
      field: "nomor_polisi",
      component: (rowData) => {
        return (
          <div className="row-start-center gap-2">
            <Button
              icon="pi pi-pencil"
              text
              label={rowData.nomor_polisi}
              onClick={handleClickPlatMobil}
            />
          </div>
        );
      },
    },
    {
      field: "cek_stok",
      component: (rowData) => {
        const { cek_stok } = rowData;
        return (
          <div className="row-center-center gap-2">
            {cek_stok && (
              <Avatar
                className="bg-green-300 text-white"
                shape="circle"
                icon="pi pi-check"
              />
            )}
            <span>
              {cek_stok ? dateFnsFormat(cek_stok, "dd/MM/yyyy HH:mm") : "-"}
            </span>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    setListData([
      { id: 1, no: 1, nomor_polisi: "BK 100 TT", cek_stok: new Date() },
      { id: 2, no: 2, nomor_polisi: "BK 101 TT", cek_stok: null },
      { id: 3, no: 3, nomor_polisi: "BK 102 TT", cek_stok: new Date() },
      { id: 4, no: 4, nomor_polisi: "BK 103 TT", cek_stok: new Date() },
      { id: 5, no: 5, nomor_polisi: "BK 104 TT", cek_stok: null },
      { id: 6, no: 6, nomor_polisi: "BK 105 TT", cek_stok: new Date() },
      { id: 7, no: 7, nomor_polisi: "BK 106 TT", cek_stok: null },
      { id: 8, no: 8, nomor_polisi: "BK 107 TT", cek_stok: new Date() },
      { id: 9, no: 9, nomor_polisi: "BK 108 TT", cek_stok: null },
      { id: 10, no: 10, nomor_polisi: "BK 109 TT", cek_stok: null },
      { id: 11, no: 11, nomor_polisi: "BK 110 TT", cek_stok: new Date() },
      { id: 12, no: 12, nomor_polisi: "BK 111 TT", cek_stok: null },
      { id: 13, no: 13, nomor_polisi: "BK 112 TT", cek_stok: null },
      { id: 14, no: 14, nomor_polisi: "BK 113 TT", cek_stok: null },
    ]);
  }, []);

  const handleReload = () => {};

  return (
    <div className={`col-start-start p-4 gap-4 w-100`}>
      <div className="elevated-card row-between-center w-100 !py-4 gap-4">
        <span className="font-semibold text-zinc-700">Kendaraan Pulang</span>
        <div className="row-start-center gap-2">
          <Calendar
            id="tanggal"
            value={selectedTanggal}
            showButtonBar
            selectionMode="single"
            hourFormat="24"
            showIcon
            onChange={(e) => {
              setSelectedTanggal(e.value);
            }}
            className={`dateInput w-[13rem]`}
            locale="id"
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>
      {/* <Toast ref={toast} /> */}
      <div className="elevated-card col-center-center w-100 gap-4">
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            onSelectionChange={() => {}}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={customColumns}
          />
        </div>
      </div>
    </div>
  );
};

export default KendaraanPulangMainPage;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";

const SalesByQuantity = (props) => {
  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const { title } = props;
  const [datas, setDatas] = useState(null);
  const { requestGet, loading } = useAPIRequest();

  useEffect(() => {
    reloadData();
  }, [valueType]);

  const generateFilter = () => {
    const lastYear = new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000);
    const lastYearISO = lastYear.toISOString();
    const lastYearMysql = lastYearISO.slice(0, 10);

    let filter = `data_stok_keluar.tanggal_transaksi:>=:${lastYearMysql}`;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
      case "controller":
        wilayah =
          selectedWilayah && selectedWilayah.id > 0 ? selectedWilayah : null;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `;data_stok_keluar.data_depot.id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `;data_stok_keluar.data_depot.wilayah_id:=:${wilayah.id}`;
    }

    // console.log("FILTER SALES BY QUANTITY", filter);

    return filter;
  };

  const reloadData = async () => {
    const filter = generateFilter();
    setDatas(null);

    return await requestGet({
      fullUrl: "api/dashboard/kawil",
      params: {
        prefix: "ranking_depot_penjualan_produk",
        filter,
        method: valueType,
        page: 1,
        take: 20,
        order: "total",
        order_method: "DESC",
        with_produk: 1,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          const newDatas = [];
          for (let i = 0; i < data.length; i++) {
            const { total, data_produk } = data[i];
            newDatas.push({
              no: i + 1,
              deskripsi: data_produk.nama_lengkap,
              value:
                valueType === "harga"
                  ? Math.trunc(total / 1000000.0)
                  : total / 100.0,
              satuan: valueType === "harga" ? "Rp" : "Tin",
              konversi: 100,
            });
          }
          setDatas(newDatas);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const quantityBodyTemplate = (rowData) => {
    if (rowData.value) {
      return (
        rowData.value.toLocaleString("id-ID", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        }) + (valueType === "harga" ? " Jt" : "")
      );
    }
    return 0;
  };

  return (
    <div className="col-start-start grow-1 gap-3 w-full">
      <span className="text-sm font-semibold text-zinc-700">{title}</span>
      {datas ? (
        <DataTable
          className="w-full"
          value={datas}
          resizableColumns
          scrollable
          scrollHeight="19.3rem"
        >
          <Column
            field="deskripsi"
            header="Produk"
            body={datas ? undefined : <Skeleton />}
          />
          <Column
            field="value"
            header={valueType === "harga" ? "Total" : "Qty"}
            body={quantityBodyTemplate}
            alignHeader={"right"}
            style={{ textAlign: "right" }}
          />
          <Column field="satuan" header="UOM" />
        </DataTable>
      ) : (
        <div className="col-start-start w-full gap-3">
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="1.5rem"></Skeleton>
        </div>
      )}
    </div>
  );
};

export default SalesByQuantity;

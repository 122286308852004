import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLayoutDashboardTitle } from "../../../store/ui-slice";
import { Button } from "primereact/button";
import { MY_URL } from "../../../configs/constants";
import ReportSection from "./ReportSection";

const title = "Laporan";

const dataSummary = {
  id: "0",
  title: "Summary",
  details: [
    {
      id: "0-1",
      title: "D & P",
      description: "Menampilkan penjualan seluruh wilayah",
      url: "/report/zzzz",
      class_color: "text-red-300",
    },
    {
      id: "0-2",
      title: "Posisi Saldo Stok Global",
      description:
        "Menampilkan sisa stok seluruh wilayah + Produksi + Gudang AB",
      url: "/report/report-posisi-stok/stok-semua-gudang",
    },
    {
      id: "0-3",
      title: "Posisi Saldo Stok Seluruh Wilayah",
      description: "Menampilkan sisa stok seluruh wilayah",
      url: "/report/report-posisi-stok/stok-seluruh-wilayah",
    },
    {
      id: "0-4",
      title: "Posisi Saldo Stok Produksi",
      description: "Menampilkan sisa stok produksi",
      url: "/report/report-posisi-stok/stok-gudang-produksi",
    },
    {
      id: "0-5",
      title: "Posisi Saldo Stok Gudang AB",
      description: "Menampilkan sisa stok gudang AB",
      url: "/report/report-posisi-stok/stok-gudang-ab",
    },
    {
      id: "0-6",
      title: "Pergerakan Saldo Stok",
      description:
        "Menampilkan pergerakan nilai stok (masuk, keluar) seluruh wilayah",
      url: "/report/zzzz2",
      class_color: "text-red-300",
    },
    {
      id: "0-7",
      title: "Retur Baik",
      description: "Menampilkan nilai retur baik seluruh wilayah",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
    {
      id: "0-8",
      title: "Retur Usang",
      description: "Menampilkan nilai retur baik seluruh wilayah",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
  ],
};

const dataWilayah = {
  id: "1",
  title: "Wilayah",
  details: [
    {
      id: "1-1",
      title: "D & P",
      description: "Menampilkan penjualan per wilayah",
      class_color: "text-red-300",
      url: "/report/zzzz",
    },
    {
      id: "1-2",
      title: "Posisi Saldo Stok",
      description: "Menampilkan sisa stok per wilayah (Depot + Transport)",
      url: "/report/report-posisi-stok/stok-wilayah",
    },
    {
      id: "1-3",
      title: "Pergerakan Saldo Stok",
      description:
        "Menampilkan pergerakan nilai stok (masuk, keluar) per wilayah (Depot + Transport)",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
    {
      id: "1-4",
      title: "Retur Baik",
      description: "Menampilkan nilai retur baik per wilayah",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
    {
      id: "1-5",
      title: "Retur Usang",
      description: "Menampilkan nilai retur baik per wilayah",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
  ],
};

const dataDepot = {
  id: "2",
  title: "Depot",
  details: [
    {
      id: "2-1",
      title: "D & P",
      description: "Menampilkan penjualan per depot",
      class_color: "text-red-300",
      url: "/report/zzzz",
    },
    {
      id: "2-2",
      title: "Posisi Saldo Stok Gudang",
      description: "Menampilkan sisa stok yang ada di gudang depot",
      url: "/report/report-posisi-stok/stok-depot",
    },
    {
      id: "2-3",
      title: "Posisi Saldo Stok Kendaraan",
      description: "Menampilkan sisa stok yang ada di kendaraan depot",
      url: "/report/report-posisi-stok/stok-transport",
    },
    {
      id: "2-4",
      title: "Posisi Saldo Stok Gudang + Kendaraan",
      description: "Menampilkan sisa stok yang ada di gudang + kendaraan depot",
      url: "/report/report-posisi-stok/stok-depot-transport",
    },
    {
      id: "2-5",
      title: "Pergerakan Saldo Stok Gudang",
      description:
        "Menampilkan pergerakan nilai stok (masuk, keluar) yang ada di gudang depot",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
    {
      id: "2-6",
      title: "Pergerakan Saldo Stok Kendaraan",
      description:
        "Menampilkan pergerakan nilai stok (masuk, keluar) yang ada di kendaraan depot",
      class_color: "text-red-300",
      url: "/report/report-posisi-stok-transport",
    },
    {
      id: "2-7",
      title: "Pergerakan Saldo Stok Gudang + Kendaraan",
      description:
        "Menampilkan pergerakan nilai stok (masuk, keluar) yang ada di gudang + kendaraan depot",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
    {
      id: "2-8",
      title: "Retur Baik",
      description: "Menampilkan nilai retur baik per wilayah",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
    {
      id: "2-9",
      title: "Retur Usang",
      description: "Menampilkan nilai retur baik per wilayah",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
  ],
};

const dataSalesman = {
  id: "3",
  title: "Salesman",
  details: [
    {
      id: "3-1",
      title: "D & P",
      description: "Menampilkan penjualan per salesman",
      class_color: "text-red-300",
      url: "/report/zzzz",
    },
    {
      id: "3-8",
      title: "Retur Baik",
      description: "Menampilkan nilai retur baik per salesman",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
    {
      id: "3-9",
      title: "Retur Usang",
      description: "Menampilkan nilai retur baik per salesman",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
  ],
};

const dataJurusan = {
  id: "4",
  title: "Jurusan",
  details: [
    {
      id: "4-1",
      title: "D & P",
      description: "Menampilkan penjualan per jurusan",
      class_color: "text-red-300",
      url: "/report/zzzz",
    },
    {
      id: "4-8",
      title: "Retur Baik",
      description: "Menampilkan nilai retur baik per jurusan",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
    {
      id: "4-9",
      title: "Retur Usang",
      description: "Menampilkan nilai retur baik per jurusan",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
  ],
};

const dataOutlet = {
  id: "5",
  title: "Outlet",
  details: [
    {
      id: "5-1",
      title: "D & P",
      description: "Menampilkan penjualan per outlet",
      class_color: "text-red-300",
      url: "/report/zzzz",
    },
    {
      id: "5-8",
      title: "Retur Baik",
      description: "Menampilkan nilai retur baik per outlet",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
    {
      id: "5-9",
      title: "Retur Usang",
      description: "Menampilkan nilai retur baik per outlet",
      class_color: "text-red-300",
      url: "/report/zzzz2",
    },
  ],
};

const ReportMainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  // const handleClickSummarySisaStok = (e) => {
  //   e.preventDefault();
  //   window.open(
  //     `${MY_URL}/report/report-summary-sisa-stok`,
  //     "_blank",
  //     "noreferrer"
  //   );
  // };

  return (
    <section className="row-start-start w-100 wrap p-4 gap-4">
      <div className="col-start-start gap-5 grow">
        <ReportSection {...dataSummary} key={dataSummary.id} />
        <ReportSection {...dataDepot} key={dataDepot.id} />
      </div>
      <div className="col-start-start gap-5 grow">
        <ReportSection {...dataWilayah} key={dataWilayah.id} />
        <ReportSection {...dataSalesman} key={dataSalesman.id} />
        <ReportSection {...dataJurusan} key={dataJurusan.id} />
        <ReportSection {...dataOutlet} key={dataOutlet.id} />
      </div>
    </section>
  );
};

export default ReportMainPage;

import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Chart } from "primereact/chart";
import { RadioButton } from "primereact/radiobutton";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { format as dateFnsFormat, parse } from "date-fns";
import { useNavigate } from "react-router-dom";
import generateTrendingLine, {
  getTrendingLineClassColor,
} from "../../../../utils/GenerateTrandingLine";

const defaultDate = new Date();
defaultDate.setDate(1);

const defaultDatePembanding = new Date(
  defaultDate.getTime() - 20 * 24 * 60 * 60 * 1000
);
defaultDatePembanding.setDate(1);

const arrMonthName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];

const tableConfigs = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Periode",
    field: "periode",
    type: "text_plain",
    align: "center",
    table: true,
    filter: true,
  },
  {
    name: "Pembanding",
    field: "pembanding",
    type: "text_plain",
    align: "center",
    table: true,
    filter: true,
  },
  {
    name: "Nilai",
    field: "nilai",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Nilai Banding",
    field: "nilai_pembanding",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Persentase",
    field: "persen",
    type: "number_plain",
    align: "right",
    table: true,
  },
];

const DetailDPBulanBerjalan = () => {
  const navigate = useNavigate();
  const toast = useRef();
  const jenisNilai = [
    {
      id: "quantity",
      value: "quantity",
      label: "Quantity (Tin)",
    },
    {
      id: "rupiah",
      value: "rupiah",
      label: "Rupiah (K)",
    },
  ];
  const [selectedJenisNilai, setSelectedJenisNilai] = useState(
    jenisNilai[0].value
  );

  const jenisPeriode = [
    {
      id: "bulanan",
      value: "bulanan",
      checked: false,
      label: "Bulanan",
    },
    {
      id: "tahunan",
      value: "tahunan",
      checked: true,
      label: "Tahunan",
    },
  ];
  const [selectedJenisPeriode, setSelectedJenisPeriode] = useState(
    jenisPeriode[0].value
  );

  const [title, setTitle] = useState("");
  const [listData, setListData] = useState([]);
  const [tanggal, setTanggal] = useState(defaultDate);
  const [tanggalPembanding, setTanggalPembanding] = useState(
    defaultDatePembanding
  );

  const [totalNilai, setTotalNilai] = useState(0);
  const [totalNilaiBanding, setTotalNilaiBanding] = useState(0);
  const aspectRatio = 0.75;

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const { requestGet, loading } = useAPIRequest();

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  const [salesChartData, setSalesChartData] = useState({});
  const salesChartOption = {
    maintainAspectRatio: false,
    aspectRatio: aspectRatio ?? 0.7,
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
  };

  const generateFilter = ({ jenisNilai, jenisPeriode, tanggal }) => {
    let filter = "";

    switch (jenisPeriode) {
      case "bulanan":
        filter += `bulan:=:${tanggal.getMonth() + 1}`;
      case "tahunan":
        filter += `${
          filter.length === 0 ? "" : ";"
        }tahun:=:${tanggal.getFullYear()}`;
        break;
    }

    let depot = null;
    let wilayah = null;
    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `;data_stok_keluar.data_depot.id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `;data_stok_keluar.data_depot.wilayah_id:=:${wilayah.id}`;
    }
    return filter;
  };

  const generatePeriodeLabel = ({ jenisPeriode, date, key }) => {
    let label = "";
    const newDate = new Date(date.getTime());
    if (jenisPeriode === "bulanan") {
      // bulanan
      newDate.setDate(parseInt(key));
      label = dateFnsFormat(newDate, "dd/MM/yyyy");
    } else {
      // tahunan
      newDate.setMonth(parseInt(key) - 1);
      label = dateFnsFormat(newDate, "MMM yyyy");
    }
    return label;
  };

  useEffect(() => {
    if (selectedDepot && selectedDepot.id !== 0) {
      setTitle(`D&P ${selectedDepot.nama}`);
    } else if (selectedWilayah && selectedWilayah.id !== 0) {
      setTitle(`D&P ${selectedWilayah.nama}`);
    } else {
      setTitle(`D&P Seluruh Wilayah`);
    }
  }, [selectedDepot, selectedWilayah]);

  const reloadData = async () => {
    setSalesChartData({});

    const newData = await reloadOnce({
      jenisNilai: selectedJenisNilai,
      jenisPeriode: selectedJenisPeriode,
      tanggal: tanggal,
    });

    const oldData = await reloadOnce({
      jenisNilai: selectedJenisNilai,
      jenisPeriode: selectedJenisPeriode,
      tanggal: tanggalPembanding,
    });

    const arrLabel = [];
    const arrNew = [];
    const arrOld = [];
    const keyNews = Object.keys(newData);
    const keyOlds = Object.keys(oldData);
    const keys = keyNews.length > keyOlds.length ? keyNews : keyOlds;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      arrLabel.push(key);

      if (selectedJenisNilai === "rupiah") {
        arrNew.push(newData[key]);
        arrOld.push(oldData[key]);
      } else if (selectedJenisNilai === "quantity") {
        arrNew.push(newData[key] / 100);
        arrOld.push(oldData[key] / 100);
      }
    }

    let labelNew = "";
    let labelOld = "";
    switch (selectedJenisPeriode) {
      case "bulanan":
        const thisMonth = new Date();
        const lastMonth = new Date(
          thisMonth.getTime() - 30 * 24 * 60 * 60 * 1000
        );
        labelNew = `Bulan (${thisMonth.toLocaleString("id-ID", {
          month: "long",
        })} ${thisMonth.getFullYear()})`;
        labelOld = `Pembanding (${lastMonth.toLocaleString("id-ID", {
          month: "long",
        })} ${lastMonth.getFullYear()})`;
        break;
      case "tahunan":
        const thisYear = new Date();
        const lastYear = new Date(
          thisYear.getTime() - 365 * 24 * 60 * 60 * 1000
        );
        labelNew = `Tahun (${thisYear.getFullYear()})`;
        labelOld = `Pembanding (${lastYear.getFullYear()})`;
        break;
    }

    const arrTrendingLine = generateTrendingLine({
      arrX: keys.map((e) => parseInt(e)),
      arrY: arrNew,
    });

    setSalesChartData({
      labels:
        selectedJenisPeriode === "tahunan"
          ? arrMonthName
          : arrLabel.map((e) => "" + e),
      datasets: [
        {
          label: labelNew,
          data: arrNew,
          tension: 0.3,
          borderColor: documentStyle.getPropertyValue("--blue-400"),
          backgroundColor: "rgba(96,165,250,0.2)",
        },
        {
          type: "bar",
          label: labelOld,
          data: arrOld,
          fill: false,
          tension: 0.3,
          borderColor: documentStyle.getPropertyValue("--blue-400"),
          backgroundColor: "rgba(63,63,70,0.18)",
        },
        {
          label: "Trending Line",
          data: arrTrendingLine,
          tension: 0.3,
          borderColor: documentStyle.getPropertyValue(
            getTrendingLineClassColor(arrTrendingLine)
          ),
          backgroundColor: "rgba(96,165,250,0.2)",
        },
      ],
    });

    let totalNilaiTmp = 0;
    let totalNilaiBandingTmp = 0;
    const newListData = [];
    for (let i = 0; i < arrLabel.length; i++) {
      const key = arrLabel[i];
      const data = {
        id: i,
        no: i + 1,
        periode: generatePeriodeLabel({
          jenisPeriode: selectedJenisPeriode,
          date: tanggal,
          key,
        }),
        pembanding: generatePeriodeLabel({
          jenisPeriode: selectedJenisPeriode,
          date: tanggalPembanding,
          key,
        }),
        nilai: newData[key],
        nilai_pembanding: oldData[key],
      };
      totalNilaiTmp += newData[key] ?? 0;
      totalNilaiBandingTmp += oldData[key] ?? 0;
      newListData.push(data);
    }
    setListData(newListData);
    setTotalNilai(totalNilaiTmp);
    setTotalNilaiBanding(totalNilaiBandingTmp);
  };

  const reloadOnce = async ({ jenisNilai, jenisPeriode, tanggal }) => {
    const filter = generateFilter({
      jenisNilai,
      jenisPeriode,
      tanggal,
    });

    const params = { prefix: "dp_berjalan", method: "quantity", filter };

    if (jenisNilai === "rupiah") {
      params["method"] = "harga";
    }

    return requestGet({
      fullUrl: "api/dashboard/kawil",
      params,
      onSuccess: ({ pagination, message, data }) => {},
      onError: ({ message, data }) => {},
    });
  };

  const handleReload = () => {
    reloadData();
  };

  const generateTotalLabel = ({ label, value }) => {
    return (
      <div className="col-center-end min-w-[7.2rem]">
        <span className="font-bold text-xl text-right">
          {value.toLocaleString("id-ID", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}
        </span>
        <span className="font-bold text-slate-400 text-xs"> {label} </span>
      </div>
    );
  };

  const customColumns = [
    {
      field: "persen",
      component: (rowData) => {
        let persen = (rowData.nilai / rowData.nilai_pembanding) * 100.0;
        let minus = rowData.nilai < rowData.nilai_pembanding;
        if (!rowData.nilai_pembanding) {
          persen = 100;
          minus = false;
        }
        return (
          <div
            className={`row-end-center font-semibold ${
              minus ? "text-red-400" : "text-green-600"
            } `}
          >
            <span className="">{`${minus ? "- " : ""}${persen.toFixed(
              0
            )} %`}</span>
          </div>
        );
      },
    },
  ];

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <Toast ref={toast} />
      <div></div>
      <div className="elevated-card-no-padding w-100 row-start-start relative wrap gap-8">
        <div className="row-start-start wrap gap-8 w-100 p-[1rem]">
          <div className="col-start-start gap-6 grow w-100 sm:w-48">
            <div className={`col-start-start w-100 gap-3`}>
              <label className="font-bold text-sm text-zinc-500">
                Jenis Nilai
              </label>
              <div className={`row-start-center w-100 gap-6`}>
                {jenisNilai.map((el) => {
                  return (
                    <div key={el.value} className={`row-start-center gap-0`}>
                      <RadioButton
                        value={el.value}
                        inputId={el.value}
                        name={el.name}
                        onChange={(event) => {
                          setSalesChartData({});
                          setSelectedJenisNilai(event.value);
                        }}
                        checked={el.value === selectedJenisNilai}
                      />
                      <label htmlFor={el.value} className="text-zinc-500 ml-2">
                        {el.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={`col-start-start w-100 gap-3`}>
              <label className="font-bold text-sm text-zinc-500">
                Jenis Periode
              </label>
              <div className={`row-start-center w-100 gap-6`}>
                {jenisPeriode.map((el) => {
                  return (
                    <div key={el.value} className={`row-start-center gap-0`}>
                      <RadioButton
                        value={el.value}
                        inputId={el.value}
                        name={el.name}
                        onChange={(event) => {
                          setSalesChartData({});
                          setSelectedJenisPeriode(event.value);
                        }}
                        checked={el.value === selectedJenisPeriode}
                      />
                      <label htmlFor={el.value} className="text-zinc-500 ml-2">
                        {el.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-start-start gap-3 grow w-100 sm:w-48">
            <div className={`col-start-start w-100 gap-1`}>
              <label
                htmlFor={"tanggal"}
                className="font-bold text-sm text-zinc-500"
              >
                Periode
              </label>
              <Calendar
                id="tanggal"
                value={tanggal}
                view={selectedJenisPeriode === "bulanan" ? "month" : "year"}
                showButtonBar
                selectionMode="single"
                dateFormat={selectedJenisPeriode === "bulanan" ? "MM yy" : "yy"}
                hourFormat="24"
                showIcon
                onChange={(e) => {
                  setTanggal(e.value);
                  setSalesChartData({});
                }}
                className={`dateInput w-[16rem]`}
                locale="id"
              />
            </div>

            <div className={`col-start-start w-100 gap-1`}>
              <label
                htmlFor={"tanggal"}
                className="font-bold text-sm text-zinc-500"
              >
                Pembanding
              </label>
              <Calendar
                id="tanggalPembanding"
                value={tanggalPembanding}
                view={selectedJenisPeriode === "bulanan" ? "month" : "year"}
                showButtonBar
                selectionMode="single"
                dateFormat={selectedJenisPeriode === "bulanan" ? "MM yy" : "yy"}
                showIcon
                hourFormat="24"
                onChange={(e) => {
                  setTanggalPembanding(e.value);
                  setSalesChartData({});
                }}
                className={`dateInput w-[16rem]`}
                locale="id"
              />
            </div>
          </div>
          <div className="col-start-start gap-3 grow sm:w-48"></div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <div className="row-between-center">
          <div className="row-start-center gap-2">
            <Button
              icon="pi pi-arrow-left"
              text
              className="max-h-[1.4rem]"
              onClick={handleClickBack}
            />
            <span className="text-normal font-semibold text-zinc-700">
              {title}
            </span>
          </div>
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <Chart
          className={`w-full`}
          type="line"
          data={salesChartData}
          options={salesChartOption}
        />
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            onSelectionChange={() => {}}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={customColumns}
          />
        </div>
      </div>

      <div className="elevated-card-tight-vertical w-100 row-between-center">
        <span className="text-lg font-semibold">Grandtotal</span>
        <div className="row-end-center w-100 sm:gap-2 mr-2">
          {generateTotalLabel({ label: "NILAI", value: totalNilai })}
          {generateTotalLabel({
            label: "NILAI BANDING",
            value: totalNilaiBanding,
          })}
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default DetailDPBulanBerjalan;

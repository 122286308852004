import classes from "./LoginPage.module.css";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "./../../store/auth-slice";
import { setDepot, setWilayah } from "./../../store/app-slice";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import onSuccessToast from "../../utils/OnSuccessToast";
import onErrorToast from "../../utils/OnErrorToast";
import { useNavigate } from "react-router-dom";
import { APP_NAME, MY_URL, SLC_LOGIN_URL } from "../../configs/constants";
import { Divider } from "primereact/divider";

const title = APP_NAME;
const logoUrl = `${MY_URL}/logo128.png`;
const backgroundSmallUrl = `${MY_URL}/small-background.jpg`;
const backgroundUrl = `${MY_URL}/background.png`;

const LoginPage = () => {
  const userNameInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toast = useRef(null);

  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const [errorDetail, setErrorDetail] = useState({});

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.slc_data) {
        const { access_token, data } = event.data.slc_data;
        const { name, username, email, nip } = data;
        dispatch(login({ loginToken: access_token })).then((result) => {
          if (!result || result === null) {
            navigate(
              `/sign-up?name=${name.replace(
                " ",
                "%20"
              )}&username=${username}&email=${email}&nip=${nip}&token=${access_token}`
            );
          } else if (result && result !== null) {
            navigate("/");
          }
        });
      }
    });
  }, []);

  const handleLogin = async () => {
    const username = inputUsername.trim();
    const password = inputPassword.trim();

    const responseData = await dispatch(
      login({ username: username, password: password })
    );

    if (responseData) {
      const { redirect_url } = responseData;
      // console.log("RESPONSE DATA", responseData);
      onSuccessToast({ toast: toast, message: "Login Success", data: {} });

      navigate(redirect_url);
    } else {
      onErrorToast({ toast: toast, message: "Login Failed", data: {} });
    }
  };

  const handleSlcLogin = async () => {
    // Get the screen width and height
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    // Calculate the center of the screen
    const centerX = screenWidth / 2;
    const centerY = screenHeight / 2;

    const newWindow = window.open(
      `${SLC_LOGIN_URL}?web-app-login=true`,
      "_blank",
      `width=440,height=510,left=${centerX - 200},top=${centerY - 200}`
    );
  };

  const handleOnKeyDownUsernameInput = (event) => {
    if (event.keyCode === 13) {
      passwordInputRef.current.focus();
    }
  };

  const handleOnKeyDownPasswordInput = (event) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <div
      className={`${classes.wrapper} row-center-center w-100 `}
      style={{ backgroundImage: `url(${backgroundUrl})` }}
    >
      <Toast ref={toast} />

      <div className={`${classes.floatingContainer} row-center-center`}>
        <section
          className={`${classes.loginSection} w-[22rem] sm:w-[26.25rem] px-8 sm:px-14 pb-10 pt-10 sm:pt-0 sm:pb-4 `}
        >
          <div className="row-start-center w-100 gap-3 pb-5">
            <span className={`${classes.title}`}>{title}</span>
            <img
              className={`${classes.logoImage}`}
              alt="logo"
              src={logoUrl}
            ></img>
          </div>

          <div className="col-center-start gap-2 pb-4">
            <span className={`${classes.subtitle}`}>Hey, welcome back</span>
            <span className={`${classes.subtitle2}`}>Sign in to continue</span>
          </div>
          <div className="col-center-center gap-2 mb-3">
            <div className="col-start-start gap-1 w-100 ">
              {/* <label className={`${classes.prompt}`} htmlFor="username">
                Username
              </label> */}
              <InputText
                ref={userNameInputRef}
                className={`${classes.customInput}`}
                id="username"
                placeholder="Username"
                aria-describedby="username-help"
                onChange={(e) => setInputUsername(e.target.value)}
                onKeyDown={handleOnKeyDownUsernameInput}
              />
              {errorDetail && errorDetail.username && (
                <span className={`${classes.errorLabel} `}>
                  {errorDetail.username}
                </span>
              )}
            </div>
            <div className="col-start-start gap-1 w-100">
              {/* <label className={`${classes.prompt}`} htmlFor="password">
                Password
              </label> */}
              <span></span>
              <Password
                ref={passwordInputRef}
                className={`${classes.customInput}`}
                id="password"
                placeholder="Password"
                aria-describedby="password-help"
                toggleMask
                onChange={(e) => setInputPassword(e.target.value)}
                onKeyDown={handleOnKeyDownPasswordInput}
              />
              {errorDetail && errorDetail.password && (
                <span className={`${classes.errorLabel} `}>
                  {errorDetail.password}
                </span>
              )}
            </div>
            {error && (
              <span className={`${classes.errorLabelSingle} `}>{error}</span>
            )}
          </div>
          <div className="col-center-center gap-2">
            <Button
              className={`${classes.loginButton} w-100`}
              label="LOGIN"
              loading={loading}
              onClick={handleLogin}
            />
            <div className="row-center-center">
              <Divider />
              <span className={`${classes.otherLoginPrompt}`}>
                Atau Gunakan SLC
              </span>
              <Divider />
            </div>
            <Button
              className={`${classes.loginSlcButton} w-100`}
              style={{ color: "var(--color-sttc)" }} // text
              outlined
              raised
              loading={loading}
              label="STTC LOGIN CENTER"
              onClick={handleSlcLogin}
            />
          </div>
        </section>
        <div className={`${classes.bgSection} hidden sm:block`}>
          <img src={backgroundSmallUrl} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import classes from "./RadialChart.module.css";

const RadialChart = (props) => {
  let {
    radius,
    progress,
    strokeWidth,
    colorTarget,
    dimension,
    target,
    value,
    color,
  } = props;

  if (!radius) {
    radius = 80;
  }

  if (!progress) {
    progress = 100;
  }

  if (!strokeWidth) {
    strokeWidth = 15;
  }

  if (!dimension) {
    dimension = 180;
  }

  const colorFull = "rgb(226 232 240)";

  if (!colorTarget) {
    colorTarget = "rgb(129 140 248)";
  }

  if (!color) {
    color = "rgb(129 140 248)";
  }

  const circleRadius = radius;
  const circumference = 2 * 3.14 * circleRadius;
  const strokeLength = (circumference / 100) * progress;

  const strokeTargetLength = (circumference / 100) * target;

  const circleRadiusValue = radius - strokeWidth - 5;
  const circumferenceValue = 2 * 3.14 * circleRadiusValue;
  const strokeValueLength = (circumferenceValue / 100) * value;

  return (
    <div className={`col-center-center radial-chart relative`}>
      <svg
        className="-rotate-90"
        viewBox={`0 0 180 180`}
        width={dimension}
        height={dimension}
      >
        {/* all */}
        <circle
          className="radial-chart-total"
          stroke={colorFull}
          strokeWidth={strokeWidth}
          fill="none"
          cx={`90`}
          cy={`90`}
          r={circleRadius}
        />
        {/* target */}
        <circle
          className="radial-chart-progress"
          stroke={colorTarget}
          strokeWidth={strokeWidth}
          strokeDasharray={`${strokeTargetLength},${circumference}`}
          //   strokeLinecap="round"
          fill="none"
          cx={`90`}
          cy={`90`}
          r={circleRadius}
        />
        {/* value */}
        <circle
          className="radial-chart-progress"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={`${strokeValueLength},${circumferenceValue}`}
          //   strokeLinecap="round"
          fill="none"
          cx={`90`}
          cy={`90`}
          r={circleRadiusValue}
        />
      </svg>
      <div className="absolute z-100">
        {" "}
        <span
          className={`font-semibold ${
            dimension / 200 > 1 ? "text-2xl" : "text-normal"
          }`}
        >
          {value.toFixed(0)}%
        </span>{" "}
      </div>
    </div>
  );
};

export default RadialChart;

import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useAPIRequest from "../simple/useAPIRequest";
import onErrorToast from "../../utils/OnErrorToast";
import { setLayoutDashboardTitle } from "../../store/ui-slice";
import createFilterText from "../../utils/CreateFilterText";
import onSuccessToast from "../../utils/OnSuccessToast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import generateDefaultValue from "../../utils/GenerateDefaultValue";

const useCrudControl = ({
  title,
  configs,
  isForm,
  apiName,
  orderBy,
  orderMethod,
  additionalParams,
}) => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [paging, setPaging] = useState({
    page: 1,
    take: 10,
    filter: "",
    total: 0,
    ...additionalParams,
  });
  const [editorShowed, setEditorShowed] = useState(false);
  const [editorLoading, setEditorLoading] = useState(false);
  const [editorError, setEditorError] = useState({});
  const [confirmEditorLoading, setConfirmEditorLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [selectedRows, setSelectedRows] = useState({});

  const { requestGet, requestGetOne, requestPost, requestDelete, loading } =
    useAPIRequest({
      url: `api/${apiName}`,
    });

  const reloadListData = async ({ page, take, filter }) => {
    if (loading) return;

    requestGet({
      apiUrl: `data`,
      params: {
        page: page,
        take: take,
        filter: filter,
        order: orderBy,
        order_method: orderMethod,
        ...additionalParams,
      },
      onSuccess: ({ pagination, message, data }) => {
        setPaging({
          ...paging,
          total: pagination["total"],
          page: pagination["page"],
          take: pagination["take"],
          filter: pagination["filter"],
        });
        let no = 1;
        for (let i = 0; i < data.length; i++) {
          data[i]["no"] = (page - 1) * take + no++;
        }
        setListData(data);
      },
      onError: ({ message, data }) =>
        onErrorToast({ toast: toast, message: message, data: data }),
    });

    return;
  };

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
    reloadListData({ page: 1, take: 10, filter: "" });
  }, []);

  const handleReload = async () => {
    setPaging({ ...paging, filter: "" });
    reloadListData({
      page: paging.page,
      take: paging.take,
      filter: "",
    });
  };

  const handleOnFilter = (filterMap) => {
    const filterString = createFilterText(filterMap);
    setPaging({ ...paging, filter: filterString });
    reloadListData({
      page: paging.page,
      take: paging.take,
      filter: filterString,
    });
  };

  const handleOnPage = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadListData({ page: newPage, take: newTake, filter: paging.filter });
  };

  const handleSelectionChange = (values) => {
    if (values) {
      setSelectedRows([...values]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleEditorSave = async (e, newValue) => {
    e.preventDefault();
    setEditorError({});
    if (loading) return;
    // setLoading(true);
    setConfirmEditorLoading(true);

    const data = await requestPost({
      apiUrl: "save",
      body: newValue,
      isForm: isForm,
      onError: ({ message, data }) => {
        onErrorToast({ toast: toast, message: message, data: data });
        setEditorError(data);
      },
      onSuccess: ({ data, message }) => {
        if ("id" in newValue) {
          const index = listData.findIndex((el) => el.id === newValue.id);
          const newListData = [...listData];
          newListData.splice(index, 1, data);
          setListData(newListData);
        } else {
          setListData([data, ...listData]);
        }
        onSuccessToast({ toast: toast, message: message });
        setEditorShowed(false);
      },
    });

    setConfirmEditorLoading(false);
  };

  const handleAddNew = () => {
    setEditorError({});
    setSelectedValue(generateDefaultValue({ tableConfigs: configs }));
    setEditorShowed(true);
  };

  const handleDeleteMultiple = () => {
    setEditorError({});
    const accept = async () => {
      if (loading) return;
      // setLoading(true);

      const listIds = selectedRows.map((el) => el.id);
      requestDelete({
        apiUrl: "delete",
        ids: listIds,
        onError: ({ message, data }) =>
          onErrorToast({ toast: toast, message: message, data: data }),
        onSuccess: ({ message, data }) => {
          const newListData = listData.filter((el) => !listIds.includes(el.id));
          setListData(newListData);
          onSuccessToast({ toast: toast, message: message, data: data });
        },
      });
    };
    const reject = () => {};

    confirmDialog({
      message: `Menghapus ${selectedRows.length} data, lanjutkan?`,
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const handleEditRow = async (row) => {
    setEditorError({});
    setEditorLoading(true);
    // //show editor and set default value
    setSelectedValue(
      generateDefaultValue({
        tableConfigs: configs,
      })
    );
    setEditorShowed(true);
    // //fetch data from cloud
    await requestGetOne({
      apiUrl: `one/${row.id}`,
      onSuccess: ({ data }) =>
        setSelectedValue(
          generateDefaultValue({
            tableConfigs: configs,
            editValue: data,
          })
        ),
      onError: ({ message, data }) =>
        onErrorToast({ toast: toast, message: message, data: data }),
    });
    setEditorLoading(false);
    return;
  };

  const handleDeleteRow = (row) => {
    setEditorError({});
    const accept = async () => {
      if (loading) return;
      // setLoading(true);

      requestDelete({
        apiUrl: "delete",
        ids: [row.id],
        onError: ({ message, data }) =>
          onErrorToast({ toast: toast, message: message, data: data }),
        onSuccess: ({ message, data }) => {
          const newListData = listData.filter((el) => el.id !== row.id);
          setListData(newListData);
          onSuccessToast({ toast: toast, message: message, data: data });
        },
      });
    };
    const reject = () => {};

    confirmDialog({
      message: "Menghapus 1 data, lanjutkan?",
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  return {
    // UIs
    toast,
    // states
    paging,
    setPaging,
    editorShowed,
    setEditorShowed,
    loading,
    editorLoading,
    setEditorLoading,
    confirmEditorLoading,
    setConfirmEditorLoading,
    editorError,
    setEditorError,
    listData,
    setListData,
    selectedValue,
    setSelectedValue,
    selectedRows,
    setSelectedRows,
    //methods
    reloadListData,
    handleReload,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
    handleEditorSave,
    handleAddNew,
    handleDeleteMultiple,
    handleEditRow,
    handleDeleteRow,
  };
};

export default useCrudControl;

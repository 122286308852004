const StokKeluarReturBaikConfigs = {
  master: {
    title: "Stok Keluar Retur Baik",
    labelNew: "*BON BARU*",
    apiName: "transaksi/stokkeluar",
    orderBy: "id",
    orderMethod: "DESC",
    additionalParams: {
      // add_params
      filter: "jenis_keluar:=:retur_baik",
      with_outlet: 1,
      with_transport: 1,
      with_jurusan: 1,
      with_depot: 1,
      with_gudang: 1,
      with_sales: 1,
      with_detils: 1,
    },
  },
  detail: {
    detail_field: "data_detils",
    columns: [
      // add_arsip_configs
      {
        name: "No",
        field: "urutan",
        type: "number_plain",
        align: "center",
        table: true,
      },
      {
        name: "Barcode",
        field: "quantity_barcode",
        type: "number_plain",
        table: true,
      },
      {
        name: "Produk",
        field: "produk_id",
        type: "object",
        object_name: "data_produk",
        object_field: "nama",
        editor: true,
        table: true,
        filter: true,
      },
      {
        name: "Harga",
        field: "harga",
        type: "number_plain",
        editor: true,
        align: "right",
        object_name: "",
        object_field: "",
        table: true,
      },
      {
        name: "Quantity",
        field: "quantity",
        type: "number_plain",
        editor: true,
        align: "right",
        object_name: "",
        object_field: "",
        table: true,
      },
      {
        name: "Satuan",
        field: "satuan",
        type: "object_dropdown_local",
        table: true,
      },
      {
        name: "Satuan",
        type: "object_dropdown_local",
        field: "satuan_dropdown",
        label_field: "satuan",
        source: "data_produk.data_konversis",
        editor: true,
      },
      {
        name: "Konversi",
        field: "konversi",
        type: "number_plain",
      },
      {
        name: "Subtotal",
        field: "subtotal",
        type: "number_plain",
        editor: false,
        align: "right",
        object_name: "",
        object_field: "",
        table: false,
      },
      {
        name: "Diskon Total",
        field: "diskon_total",
        type: "number_plain",
        editor: false,
        align: "right",
        object_name: "",
        object_field: "",
        table: false,
      },
      {
        name: "Diskon Type",
        field: "diskon_type",
        table: true,
        filter: true,
        editor: true,
        type: "number_dropdown",
        default_value: 1,
        options: [
          { name: "Rp", value: 1 },
          { name: "%", value: 2 },
          { name: "Unit", value: 3 },
        ],
      },
      {
        name: "Diskon Nilai",
        field: "diskon_nilai",
        type: "number_plain",
        editor: true,
        align: "right",
        object_name: "",
        object_field: "",
        table: true,
      },
      {
        name: "Grandtotal",
        field: "grandtotal",
        type: "number_plain",
        editor: false,
        align: "right",
        object_name: "",
        object_field: "",
        table: false,
      },
      {
        name: "Tanggal Produksi",
        field: "tanggal_produksi",
        type: "date",
        align: "center",
        table: true,
        editor: true,
        format: "dd/MM/yyyy",
        prime_format: "d MM yy",
        showTime: 1,
        object_name: "",
        object_field: "",
      },
      {
        name: "Bonus",
        field: "bonus",
        align: "center",
        table: true,
        editor: true,
        type: "boolean_plain",
        filter: true,
        object_name: "",
        object_field: "",
      },
    ],
  },
  filters: [
    {
      name: "Tanggal Transaksi",
      field: "tanggal_transaksi",
      type: "date_range",
      format: "dd/MM/yyyy",
      prime_format: "dd/mm/yy",
      default_value: [new Date(), new Date()],
      range: true,
    },
  ],
  columns: [
    // add_configs
    {
      name: "Outlet",
      field: "outlet_id",
      type: "object",
      object_name: "data_outlet",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Transport",
      field: "transport_id",
      type: "object",
      object_name: "data_transport",
      object_field: "nama",
      editor: true,
      table: false,
      filter: true,
    },
    {
      name: "Jurusan",
      field: "jurusan_id",
      type: "object",
      object_name: "data_jurusan",
      object_field: "nama",
      editor: true,
      table: false,
      filter: true,
    },
    {
      name: "Gudang",
      field: "gudang_id",
      type: "object",
      object_name: "data_gudang",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Sales",
      field: "sales_id",
      type: "object",
      object_name: "data_sales",
      object_field: "name",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Tanggal Transaksi",
      field: "tanggal_transaksi",
      type: "date",
      align: "center",
      table: false,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
      object_name: "",
      object_field: "",
    },
    {
      name: "Bon",
      field: "nomor",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Jenis Keluar",
      field: "jenis_keluar",
      type: "text_plain",
      default_value: "retur_baik",
      object_name: "",
      object_field: "",
      editor: true,
    },
    {
      name: "Tanggal BPB",
      field: "tanggal_bpb",
      type: "date",
      align: "center",
      table: false,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
      object_name: "",
      object_field: "",
    },
    {
      name: "No BPB",
      field: "no_bpb",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Tanggal Jalan",
      field: "tanggal_jalan",
      type: "date",
      align: "center",
      table: false,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
      object_name: "",
      object_field: "",
    },
    {
      name: "Subtotal",
      field: "subtotal",
      type: "number_plain",
      editor: false,
      align: "right",
      object_name: "",
      object_field: "",
      table: false,
    },
    {
      name: "Diskon Item",
      field: "diskon_item",
      type: "number_plain",
      editor: false,
      align: "right",
      object_name: "",
      object_field: "",
      table: false,
    },
    {
      name: "Diskon Type",
      field: "diskon_type",
      table: false,
      filter: true,
      editor: true,
      type: "number_dropdown",
      object_name: "",
      object_field: "",
      default_value: 1,
      options: [
        { name: "Rp", value: 1 },
        { name: "%", value: 2 },
        { name: "Unit", value: 3 },
      ],
    },
    {
      name: "Diskon Nilai",
      field: "diskon_nilai",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: false,
    },
    {
      name: "GrandTotal",
      field: "grand_total",
      type: "number_plain",
      editor: false,
      align: "right",
      object_name: "",
      object_field: "",
      table: true,
    },
    {
      name: "Payment Type",
      field: "payment_type",
      table: false,
      filter: true,
      editor: true,
      type: "number_dropdown",
      object_name: "",
      object_field: "",
      default_value: 1,
      options: [
        { name: "Cash", value: 1 },
        { name: "Kredit", value: 2 },
        { name: "Konsiyasi", value: 3 },
      ],
    },
    {
      name: "Tanggal Jatuh Tempo",
      field: "tanggal_jatuh_tempo",
      type: "date",
      align: "center",
      table: false,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
      object_name: "",
      object_field: "",
    },
    {
      name: "Catatan",
      field: "catatan",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
  ],
};

export default StokKeluarReturBaikConfigs;

import { Avatar } from "primereact/avatar";
import { Divider } from "primereact/divider";
import { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa6";
import MessageContent from "./MessageContent";

const MessageMaster = () => {
  const [listMessage, setListMessage] = useState([]);

  useEffect(() => {
    setListMessage([
      {
        id: 1,
        title: "Permintaan Mutasi Stok Dari Depot 201 ke 202",
        image:
          "https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png",
        type: "mutasi-stok",
        sender: "Jhon Doe",
        sender_title: "Kadepot 201",
        created_at: "08-03-2024 08:20",
        updated_at: "08-03-2024 10:30",
        readed: false,
      },
      {
        id: 2,
        title: "Permintaan Mutasi Stok Dari Depot 201 ke 202",
        type: "mutasi-stok",
        image:
          "https://primefaces.org/cdn/primereact/images/avatar/asiyajavayant.png",
        sender: "Jhon Doe",
        sender_title: "Kadepot 201",
        created_at: "08-03-2024 08:20",
        updated_at: "08-03-2024 10:30",
        readed: false,
      },
      {
        id: 3,
        title: "Permintaan Mutasi Stok Dari Depot 201 ke 202",
        type: "mutasi-stok",
        image:
          "https://primefaces.org/cdn/primereact/images/avatar/onyamalimba.png",
        sender: "Jhon Doe",
        sender_title: "Kadepot 201",
        created_at: "08-03-2024 08:20",
        updated_at: "08-03-2024 10:30",
        readed: true,
      },
      {
        id: 4,
        title: "Permintaan Mutasi Stok Dari Depot 201 ke 202",
        type: "mutasi-stok",
        image:
          "https://primefaces.org/cdn/primereact/images/avatar/ionibowcher.png",
        sender: "Jhon Doe",
        sender_title: "Kadepot 201",
        created_at: "08-03-2024 08:20",
        updated_at: "08-03-2024 10:30",
        readed: true,
      },
      {
        id: 5,
        title: "Permintaan Mutasi Stok Dari Depot 201 ke 202",
        type: "mutasi-stok",
        image:
          "https://primefaces.org/cdn/primereact/images/avatar/xuxuefeng.png",
        sender: "Jhon Doe",
        sender_title: "Kadepot 201",
        created_at: "08-03-2024 08:20",
        updated_at: "08-03-2024 10:30",
        readed: true,
      },
      {
        id: 6,
        title: "Permintaan Mutasi Stok Dari Depot 201 ke 202",
        type: "mutasi-stok",
        image:
          "https://primefaces.org/cdn/primereact/images/avatar/onyamalimba.png",
        sender: "Jhon Doe",
        sender_title: "Kadepot 201",
        created_at: "08-03-2024 08:20",
        updated_at: "08-03-2024 10:30",
        readed: true,
      },
      {
        id: 7,
        title: "Permintaan Mutasi Stok Dari Depot 201 ke 202",
        type: "mutasi-stok",
        image:
          "https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png",
        sender: "Jhon Doe",
        sender_title: "Kadepot 201",
        created_at: "08-03-2024 08:20",
        updated_at: "08-03-2024 10:30",
        readed: true,
      },
      {
        id: 8,
        title: "Permintaan Mutasi Stok Dari Depot 201 ke 202",
        type: "mutasi-stok",
        image:
          "https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png",
        sender: "Jhon Doe",
        sender_title: "Kadepot 201",
        created_at: "08-03-2024 08:20",
        updated_at: "08-03-2024 10:30",
        readed: true,
      },
    ]);
  }, []);

  const InboxMessage = ({
    image,
    sender_name,
    sender_title,
    title,
    date,
    readed,
  }) => {
    return (
      <li
        className={`col-start-start w-full pb-4 pt-5 pl-6 ${
          readed ? "pr-6" : "pr-3"
        }  gap-3 cursor-pointer ${readed ? "bg-gray-100" : "shadow"}`}
      >
        <div className="row-between-center w-full">
          <div className="row-start-center gap-3">
            <Avatar image={image} size="large" shape="circle" />
            <div className="col-start-start">
              <span className="leading-1 font-semibold">{sender_name}</span>
              <span className="text-sm text-zinc-500">{sender_title}</span>
            </div>
          </div>
          <div className="row-end-start gap-2">
            <div className="col-end-end">
              <span className="text-xs font-semibold text-zinc-400">
                08-03-2024
              </span>
              <span className="text-xs font-semibold text-zinc-400">10:30</span>
            </div>
            <div className="col-center-center pt-[1px]">
              {!readed && <FaCircle size={12} className="text-red-500" />}
            </div>
          </div>
        </div>
        <span className="text-sm leading-0 font-semibold max-w-[19rem] text-zinc-600 px-2">
          {title}
        </span>
      </li>
    );
  };

  return (
    <div className="row-start-start p-4 w-full gap-4">
      <div className="elevated-card row-start-start min-w-[22rem] !px-0 !py-0">
        <ul className="w-full divide-y divide-gray-300">
          {listMessage.map((msg) => (
            <InboxMessage
              key={msg.id}
              image={msg.image}
              sender_name={msg.sender}
              sender_title={msg.sender_title}
              title={msg.title}
              date={msg.updated_at}
              readed={msg.readed}
            />
          ))}
        </ul>
      </div>
      <div className="elevated-card row-start-start w-full">
        <MessageContent />
      </div>
    </div>
  );
};

export default MessageMaster;

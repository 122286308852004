import { subDays, addDays } from "date-fns";

const GudangConfigs = {
  master: {
    title: "Gudang",
    apiName: "masterdata/gudang",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: { 
      // add_params
    },
  },
  dialog: {
    title: "Gudang",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Kode",
      field: "kode",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Nama",
      field: "nama",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Jenis Gudang",
      field: "jenis_gudang",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Longitude",
      field: "longitude",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: true,
    },
    {
      name: "Latitude",
      field: "latitude",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: true,
    },
  ],
};

export default GudangConfigs;

import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import DepotPerformanceTable from "../../home/component-home/DepotPerformanceTable";
import configs from "./DetailListSalesConfigs";
import generateFilterConfigs from "../../../../utils/FilterConfigGenerator";
import generateTableConfigs from "../../../../utils/TableConfigGenerator";
import generateEditorConfigs from "../../../../utils/EditorConfigGenerator";
import useCrudControl from "../../../../custom_hooks/complex/useCrudControl";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { Paginator } from "primereact/paginator";
import FilterComponent from "../../../../components/complex/crud/FilterComponent";
import { Toast } from "primereact/toast";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

const title = `Bon Sales - ${format(new Date(), "dd/MM/yyyy")}`;

const apiName = configs.master.apiName;
const orderBy = configs.master.orderBy;
const orderMethod = configs.master.orderMethod;
const additionalParams = configs.master.additionalParams;
const isForm = configs.dialog.isForm;

const customEditorElements = [];

const filterConfigs = generateFilterConfigs(configs.columns);
const tableConfigs = generateTableConfigs(configs.columns);
const editorConfigs = generateEditorConfigs(configs.columns);

const customColumns = [
  {
    field: "outlet_id",
    component: (rowData) => {
      return (
        <div className="col-center-start">
          <span>{rowData.data_outlet ? rowData.data_outlet.nama : "-"}</span>
          <i className="font-semibold text-sm text-gray-400">
            {rowData.data_jurusan ? rowData.data_jurusan.nama : "-"}
          </i>
        </div>
      );
    },
  },
];

const DetailListSales = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const generateFilter = () => {
    let filter = configs.master.additionalParams.filter || "";
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `${filter.length > 0 ? ";" : ""}depot_id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `${filter.length > 0 ? ";" : ""}data_depot.wilayah_id:=:${
        wilayah.id
      }`;
    }

    // console.log("FILTER", filter);

    return filter;
  };

  const {
    toast,
    paging,
    editorShowed,
    setEditorShowed,
    loading,
    editorLoading,
    confirmEditorLoading,
    editorError,
    setEditorError,
    listData,
    selectedValue,
    setSelectedValue,
    selectedRows,
    handleReload,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
    handleEditorSave,
    handleAddNew,
    handleDeleteMultiple,
    handleEditRow,
    handleDeleteRow,
  } = useCrudControl({
    title,
    configs: configs.columns,
    isForm,
    apiName,
    orderBy,
    orderMethod,
    additionalParams: {
      with_depot: 1,
      with_sales: 1,
      with_jurusan: 1,
      with_outlet: 1,
      filter: generateFilter(),
    },
    // additionalParams: { filter: generateFilter() },
  });

  // useEffect(() => {
  //   console.log("SEARCH PARAM ", searchParams.get("today"));
  // }, []);

  const handleClickDepot = (id) => {
    navigate(`/kawil/detail/depot/${id}`);
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <Toast ref={toast} />
      <div></div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20 !pl-3`}
      >
        <div className="row-between-center">
          <div className="row-start-center gap-2">
            <Button
              icon="pi pi-arrow-left"
              text
              className="max-h-[1.4rem]"
              onClick={handleClickBack}
            />
            <span className="text-normal font-semibold text-zinc-700">
              {title}
            </span>
          </div>
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            severity="success"
            disabled
          />
          <Button icon="pi pi-print" size="small" severity="success" disabled />
          {/* <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          /> */}
        </div>
      </div>

      <div className="elevated-card w-100">
        <FilterComponent onFilter={handleOnFilter} configs={filterConfigs} />
      </div>

      <div className="col-start-center elevated-card gap-3 w-full">
        <TableAutoComponent
          showControlButton={false}
          selection={selectedRows}
          onSelectionChange={handleSelectionChange}
          configs={tableConfigs}
          loading={loading}
          value={listData}
          onEditRow={handleEditRow}
          onDeleteRow={handleDeleteRow}
          customColumns={customColumns}
        />
        <Paginator
          first={paging.take * (paging.page - 1)}
          rows={paging.take}
          totalRecords={paging.total}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={handleOnPage}
        />
      </div>

      <div></div>
    </div>
  );
};

export default DetailListSales;

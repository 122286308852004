// import logo from "./logo.svg";
import "./App.css";

import idLocale from "./assets/locale/id.json";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { addLocale } from "primereact/api";

import ErrorPage from "./pages/error/ErrorPage";
import LoginPage from "./pages/login/LoginPage";

import LayoutDashboardPage from "./pages/dashboard/LayoutDashboardPage";
import LayoutDashboardUserPage from "./pages/dashboard-user/LayoutDashboardUserPage";

//import protector
import ProtectedRoute from "./pages/protected/ProtectedRoute";
import ProtectedRouteUser from "./pages/protected/ProtectedRouteUser";

//import admin page
import SignUpPage from "./pages/sign-up/SignUpPage";

//import user page
import UserHomePage from "./pages/dashboard-user/default/UserHomePage";
import ReportMainPage from "./pages/dashboard/report/ReportMainPage";
import JabatanPage from "./pages/dashboard/master/jabatan/JabatanPage";
import GudangPage from "./pages/dashboard/master/gudang/GudangPage";
import WilayahPage from "./pages/dashboard/master/wilayah/WilayahPage";
import DepotPage from "./pages/dashboard/master/depot/DepotPage";
import TransportPage from "./pages/dashboard/master/transport/TransportPage";
import JurusanPage from "./pages/dashboard/master/jurusan/JurusanPage";
import OutletPage from "./pages/dashboard/master/outlet/OutletPage";
import UserPage from "./pages/dashboard/master/user/UserPage";
import JadwalPage from "./pages/dashboard/master/jadwal/JadwalPage";
import ProdukKategoriPage from "./pages/dashboard/master/produkkategori/ProdukKategoriPage";
import ProdukPage from "./pages/dashboard/master/produk/ProdukPage";
import StokMasukMainPage from "./pages/dashboard/transaksi/stok/stokmasuk/StokMasukMainPage";
import StokKeluarMainPage from "./pages/dashboard/transaksi/stok/stokkeluar/StokKeluarMainPage";
import StokMutasiMainPage from "./pages/dashboard/transaksi/stok/stokmutasi/StokMutasiMainPage";
import StokMasukReturBaikMainPage from "./pages/dashboard/transaksi/stokreturbaik/stokmasukreturbaik/StokMasukReturBaikMainPage";
import StokKeluarReturBaikMainPage from "./pages/dashboard/transaksi/stokreturbaik/stokkeluarreturbaik/StokKeluarReturBaikMainPage";
import StokMutasiReturBaikMainPage from "./pages/dashboard/transaksi/stokreturbaik/stokmutasireturbaik/StokMutasiReturBaikMainPage";
import StokMasukReturUsangMainPage from "./pages/dashboard/transaksi/stokreturusang/stokmasukreturusang/StokMasukReturUsangMainPage";
import StokKeluarReturUsangMainPage from "./pages/dashboard/transaksi/stokreturusang/stokkeluarreturusang/StokKeluarReturUsangMainPage";
import StokMutasiReturUsangMainPage from "./pages/dashboard/transaksi/stokreturusang/stokmutasireturusang/StokMutasiReturUsangMainPage";
import HomeKawil from "./pages/dashboard/home/HomeKawil";
import { useDispatch, useSelector } from "react-redux";
import HomeDefault from "./pages/dashboard/home/HomeDefault";
import HomeKadepot from "./pages/dashboard/home/HomeKadepot";
import DetailDPBulanBerjalan from "./pages/dashboard/detail/dp-bulan-berjalan/DetailDPBulanBerjalan";
import DetailSalesman from "./pages/dashboard/detail/salesman/DetailSalesman";
import KelompokTargetPage from "./pages/dashboard/master/kelompoktarget/KelompokTargetPage";
import TargetRealisasiPage from "./pages/dashboard/target/target-realisasi/TargetRealisasiPage";
import TargetBatangPage from "./pages/dashboard/target/target-batang/TargetBatangPage";
import TargetBungkusPage from "./pages/dashboard/target/target-bungkus/TargetBungkusPage";
import TraceQR from "./pages/dashboard/default/traceqr/TraceQR";
import MessageMaster from "./pages/dashboard/default/message/MessageMaster";
import ProtectedRouteKawil from "./pages/protected/ProtectedRouteKawil";
import { Outlet } from "react-router-dom";
import DetailReturBaik from "./pages/dashboard/detail/retur-baik/DetailReturBaik";
import DetailReturUsang from "./pages/dashboard/detail/retur-usang/DetailReturUsang";
import DetailListDepot from "./pages/dashboard/detail/list-depot/DetailListDepot";
import DetailListTransport from "./pages/dashboard/detail/list-transport/DetailListTransport";
import DetailListSalesman from "./pages/dashboard/detail/list-salesman/DetailListSalesman";
import ManualInputPage from "./pages/dashboard/transaksi/manual-input/ManualInputPage";
import DetailListSales from "./pages/dashboard/detail/list-sales/DetailListSales";
import ReportPosisiStok from "./pages/dashboard/report/summary/posisi-stok/ReportPosisiStok";
import KendaraanPulangMainPage from "./pages/dashboard/transaksi/kendaraan-pulang/KendaraanPulangMainPage";
import KendaraanPulangSinglePage from "./pages/dashboard/transaksi/kendaraan-pulang/KendaraanPulangSinglePage";
import ReportBiaya from "./pages/dashboard/report/biaya/ReportBiaya";
import HomeController from "./pages/dashboard/home/HomeController";
import ProtectedRouteController from "./pages/protected/ProtectedRouteController";
import GrupBiayaPage from "./pages/dashboard/master/grupbiaya/GrupBiayaPage";
import BiayaPage from "./pages/dashboard/master/biaya/BiayaPage";

// import ProdukKelompokTargetPage from "./pages/dashboard/Target/produkkelompoktarget/ProdukKelompokTargetPage";
// import TargetBatangWilayahPage from "./pages/dashboard/Target/targetbatangwilayah/TargetBatangWilayahPage";
// import TargetBatangDepotPage from "./pages/dashboard/Target/targetbatangdepot/TargetBatangDepotPage";
// import TargetBungkusDepotPage from "./pages/dashboard/Target/targetbungkusdepot/TargetBungkusDepotPage";
// import TargetBungkusJurusanPage from "./pages/dashboard/Target/targetbungkusjurusan/TargetBungkusJurusanPage";

// import reports

function App() {
  addLocale("id", idLocale.id);
  const user = useSelector((state) => state.auth.user);

  const getHome = () => {
    if (user) {
      if (user.title === "kawil") {
        return <HomeKawil />;
      } else if (user.title === "kadepot") {
        return <HomeKadepot />;
      } else if (user.title === "controller") {
        return <HomeController />;
      }
    }
    return <HomeDefault />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/sign-up" element={<SignUpPage />}></Route>

        <Route
          // index
          path="/"
          element={
            <ProtectedRoute>
              <LayoutDashboardPage />
            </ProtectedRoute>
          }
        >
          <Route index element={getHome()}></Route>

          <Route
            // index
            path="/controller"
            element={
              <ProtectedRouteController>
                <Outlet />
              </ProtectedRouteController>
            }
          >
            <Route path="detail">
              <Route path="wilayah/:id" element={<HomeKawil back />}></Route>{" "}
            </Route>{" "}
          </Route>

          <Route
            // index
            path="/kawil"
            element={
              <ProtectedRouteKawil>
                <Outlet />
              </ProtectedRouteKawil>
            }
          >
            <Route path="detail">
              <Route path="depot/:id" element={<HomeKadepot back />}></Route>{" "}
            </Route>{" "}
          </Route>

          <Route path="traceqr" element={<TraceQR />}></Route>
          <Route path="message" element={<MessageMaster />}></Route>

          <Route path="transaksi">
            {/* manual input */}
            <Route path="manualinput" element={<ManualInputPage />} />
            <Route
              path="kendaraanpulang"
              element={<KendaraanPulangMainPage />}
            />
            <Route
              path="kendaraanpulang/:id"
              element={<KendaraanPulangSinglePage />}
            />
            {/* manual input */}

            {/* stok */}
            <Route path="stokmasuk" element={<StokMasukMainPage />} />
            <Route path="stokkeluar" element={<StokKeluarMainPage />} />
            <Route path="stokmutasi" element={<StokMutasiMainPage />} />
            {/* stok */}

            {/* stok retur baik */}
            <Route
              path="stokmasukreturbaik"
              element={<StokMasukReturBaikMainPage />}
            />
            <Route
              path="stokkeluarreturbaik"
              element={<StokKeluarReturBaikMainPage />}
            />
            <Route
              path="stokmutasireturbaik"
              element={<StokMutasiReturBaikMainPage />}
            />
            {/* stok retur baik */}

            {/* stok retur usang */}
            <Route
              path="stokmasukreturusang"
              element={<StokMasukReturUsangMainPage />}
            />
            <Route
              path="stokkeluarreturusang"
              element={<StokKeluarReturUsangMainPage />}
            />
            <Route
              path="stokmutasireturusang"
              element={<StokMutasiReturUsangMainPage />}
            />
            {/* stok retur usang */}
          </Route>

          <Route path="master">
            <Route path="jabatan" element={<JabatanPage />} />
            <Route path="user" element={<UserPage />} />
            <Route path="gudang" element={<GudangPage />} />
            <Route path="wilayah" element={<WilayahPage />} />
            <Route path="depot" element={<DepotPage />} />
            <Route path="transport" element={<TransportPage />} />
            <Route path="jurusan" element={<JurusanPage />} />
            <Route path="outlet" element={<OutletPage />} />
            <Route path="jadwal" element={<JadwalPage />} />
            <Route path="produkkategori" element={<ProdukKategoriPage />} />
            <Route path="produk" element={<ProdukPage />} />
            <Route path="kelompoktarget" element={<KelompokTargetPage />} />
            <Route path="grupbiaya" element={<GrupBiayaPage />} />
            <Route path="biaya" element={<BiayaPage />} />
          </Route>

          {/* <Route path="target">
            <Route
              path="produkkelompoktarget"
              element={<ProdukKelompokTargetPage />}
            />
            <Route
              path="targetbatangwilayah"
              element={<TargetBatangWilayahPage />}
            />
            <Route
              path="targetbatangdepot"
              element={<TargetBatangDepotPage />}
            />
            <Route
              path="targetbungkusdepot"
              element={<TargetBungkusDepotPage />}
            />
            <Route
              path="targetbungkusjurusan"
              element={<TargetBungkusJurusanPage />}
            />
          </Route> */}

          <Route path="report">
            <Route path="report-main" element={<ReportMainPage />} />
            <Route
              path="report-posisi-stok/:prefix"
              element={<ReportPosisiStok />}
            />
            <Route path="report-biaya" element={<ReportBiaya />} />
          </Route>

          <Route path="target">
            <Route path="target-realisasi" element={<TargetRealisasiPage />} />
            <Route path="target-batang" element={<TargetBatangPage />} />
            <Route path="target-bungkus" element={<TargetBungkusPage />} />
          </Route>

          <Route path="detail">
            <Route path="list-transport" element={<DetailListTransport />} />
            <Route path="list-sales" element={<DetailListSales />} />
            <Route path="list-depot" element={<DetailListDepot />} />
            <Route path="list-salesman" element={<DetailListSalesman />} />
            <Route
              path="dp-bulan-berjalan"
              element={<DetailDPBulanBerjalan />}
            />
            <Route path="retur-baik" element={<DetailReturBaik />} />
            <Route path="retur-usang" element={<DetailReturUsang />} />
            <Route path="salesman/:id" element={<DetailSalesman />} />
          </Route>

          <Route path="setting"></Route>
        </Route>

        <Route
          // index
          path="user"
          element={
            <ProtectedRouteUser>
              <LayoutDashboardUserPage />
            </ProtectedRouteUser>
          }
        >
          <Route path="main" element={<UserHomePage />}></Route>
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

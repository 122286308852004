const SignUpConfigs = [
  {
    name: "Nama",
    field: "name",
    type: "text_plain",
    disabled: true,
  },
  {
    name: "NIP",
    field: "nip",
    type: "text_plain",
    disabled: true,
  },
  {
    name: "Permanent",
    field: "permanent",
    type: "boolean_plain",
    label_true: "Ya",
    label_false: "Tidak",
  },
  {
    name: "User Name",
    field: "data_user.username",
    type: "text_plain",
    disabled: true,
  },
  {
    name: "Email",
    field: "data_user.email",
    type: "text_plain",
    disabled: true,
  },
  {
    name: "Password",
    field: "data_user.password",
    default_value: "",
    default_value_on_edit: true,
    type: "text_password",
  },
  {
    name: "Konfirmasi Password",
    field: "data_user.cpassword",
    default_value: "",
    default_value_on_edit: true,
    type: "text_password",
  },
  {
    name: "Tanggal Lahir",
    field: "date_of_birth",
    type: "date",
    format: "dd/MM/yyyy",
    prime_format: "d MM yy",
  },
  {
    name: "Agama",
    field: "religion",
    type: "text_dropdown",
    default_value: "islam",
    options: [
      { name: "Buddha", value: "buddha" },
      { name: "Kristen", value: "kristen" },
      { name: "Islam", value: "islam" },
      { name: "Hindu", value: "hindu" },
      { name: "Kong Hu Chu", value: "kong_hu_chu" },
    ],
  },
  {
    name: "Jabatan",
    field: "jabatan_id",
    type: "object",
    object_name: "data_jabatan",
    object_field: "nama",
    editor: true,
    table: true,
    filter: true,
  },

  // {
  //   name: "Suku",
  //   field: "ethnic",
  //   type: "text_dropdown",
  //   options: [
  //     { name: "Chinese", value: "buddha" },
  //     { name: "Jawa", value: "kristen" },
  //     { name: "Batak", value: "islam" },
  //     { name: "India", value: "hindu" },
  //     { name: "Kong Hu Chu", value: "kong_hu_chu" },
  //   ],
  // },
];

export default SignUpConfigs;

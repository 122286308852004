import classes from "./MenuItem.module.css";

const MenuItem = (props, { className }) => {
  const useChild = props.children ? true : false;

  const iconPadding = props.iconPadding ?? "3px";

  return (
    <div
      className={`${classes.wrapper} ${className} row-start-center w-100 px-3`}
      onClick={props.onClick}
    >
      {useChild && props.children}
      {!useChild && props.icon && (
        <div className={`${classes.iconContainer}`} style={{paddingBottom: iconPadding}} >
          {props.icon}
        </div>
      )}
      {!useChild && props.label}
    </div>
  );
};

export default MenuItem;

import { Sidebar } from "primereact/sidebar";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { hideNoteDialog, setNoteCount } from "../../../store/ui-slice";
import NoteSingle from "./NoteSingle";
import { Button } from "primereact/button";
import NoteEditorDialog from "./NoteEditorDialog";
import useAPIRequest from "../../../custom_hooks/simple/useAPIRequest";
import onSuccessToast from "../../../utils/OnSuccessToast";
import onErrorToast from "../../../utils/OnErrorToast";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

const defaultEditorData = { title: "", body: [] };

const NoteSidebar = (props) => {
  const toastRef = useRef();
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState([]);
  const [showNoteEditor, setShowNoteEditor] = useState(false);
  const visible = useSelector((state) => state.ui.noteDialogVisible);
  const [editorData, setEditorData] = useState(defaultEditorData);

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const { requestGet, requestPost, requestDelete, loading } = useAPIRequest();

  useEffect(() => {
    reloadData();
  }, []);

  useEffect(() => {
    dispatch(setNoteCount(contents ? contents.length : 0));
  }, [contents]);

  const generateFilter = () => {
    let filter = `user_id:=:${user.id}`;

    return filter;
  };

  const reloadData = async () => {
    const filter = generateFilter();

    setContents(null);
    return await requestGet({
      fullUrl: "api/tools/note/data",
      params: {
        filter,
        page: 1,
        take: 40,
        order: "id",
        order_method: "DESC",
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          const newContents = [];
          for (let i = 0; i < data.length; i++) {
            newContents.push(data[i]);
          }
          setContents(newContents);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const newProcedure = () => {
    setEditorData({ ...defaultEditorData });
    setShowNoteEditor(true);
  };

  const deleteData = async (id) => {
    const listId = [id];
    return await requestDelete({
      fullUrl: "api/tools/note/delete",
      isForm: false,
      ids: listId,
      onSuccess: ({ message, data }) => {
        if (data) {
          const newContents = contents.filter((el) => !listId.includes(el.id));
          setContents(newContents);
          onSuccessToast({
            toast: toastRef,
            message: "Note berhasil dihapus",
            data: {},
          });
        }
      },
      onError: ({ message, data }) => {
        onErrorToast({
          toast: toastRef,
          message: "Gagal meghapus note",
          data: {},
        });
      },
    });
  };

  const save = async (data) => {
    const isEdit = data.id && data.id > 0;

    const body = {
      user_id: user.id,
      title: data.title,
      body: data.body.filter((el) => el.trim().length > 0).join("|||"),
      width: 0,
      height: 0,
      position_x: 0,
      position_y: 0,
    };

    if (isEdit) {
      body["id"] = data.id;
    }

    return await requestPost({
      fullUrl: "api/tools/note/save",
      isForm: false,
      body,
      onSuccess: ({ message, data }) => {
        if (data) {
          // const refinedData = convertContentData(data);
          if (isEdit) {
            const index = contents.findIndex((el) => el.id === data.id);
            const newListData = [...contents];
            newListData.splice(index, 1, data);
            setContents(newListData);
          } else {
            setContents([data, ...contents]);
          }
          setShowNoteEditor(false);
          onSuccessToast({
            toast: toastRef,
            message: "Note berhasil disimpan",
            data: {},
          });
        }
      },
      onError: ({ message, data }) => {
        onErrorToast({
          toast: toastRef,
          message: "Gagal menyimpan note",
          data: {},
        });
      },
    });
  };

  const editProcedure = (data) => {
    setEditorData({
      id: data.id,
      title: data.title,
      body: data.body ? data.body.split("|||") : [],
    });
    setShowNoteEditor(true);
  };

  const deleteProcedure = (data) => {
    const accept = async () => {
      deleteData(data.id);
    };

    const reject = () => {};

    confirmDialog({
      message: `Hapus note, lanjutkan?`,
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const handleOnHide = () => {
    dispatch(hideNoteDialog());
  };

  const handleAdd = () => {
    newProcedure();
  };

  const handleRefresh = () => {
    reloadData();
  };

  const handleSave = (data) => {
    save(data);
  };

  const customHeader = (
    <div className="row-between-center gap-2">
      <Button
        className="text-[#8D6E63]"
        icon="pi pi-plus"
        label="Tambah Note"
        onClick={handleAdd}
        outlined
      />
      <Button
        className="text-[#8D6E63]"
        icon="pi pi-refresh"
        onClick={handleRefresh}
        text
      />
    </div>
  );

  return (
    <Sidebar
      className="w-full sm:w-[30rem] bg-neutral-50"
      visible={visible}
      position="right"
      onHide={handleOnHide}
      header={customHeader}
    >
      <ConfirmDialog />
      <Toast ref={toastRef} position="bottom-right" />
      <NoteEditorDialog
        visible={showNoteEditor}
        editorData={editorData}
        setEditorData={setEditorData}
        onSave={handleSave}
        onHide={() => {
          setShowNoteEditor(false);
        }}
      />
      {contents && (
        <div className="col-start-start gap-4 pb-10">
          {contents.map((el) => (
            <NoteSingle
              key={el.id}
              {...el}
              onDelete={() => deleteProcedure(el)}
              onEdit={() => editProcedure(el)}
            />
          ))}
        </div>
      )}
    </Sidebar>
  );
};

export default NoteSidebar;

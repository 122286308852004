const SignUpConfigs = [
  {
    name: "Gambar",
    field: "image",
    default_value: null,
    type: "zirvu_file_manager",
    table: true,
    editor: true,
  },
  {
    name: "NIK",
    field: "nik",
    type: "text_plain",
  },
  {
    name: "Nama",
    field: "name",
    type: "text_plain",
  },
  {
    name: "User Name",
    field: "username",
    type: "text_plain",
  },
  {
    name: "Email",
    field: "email",
    type: "text_plain",
  },
  {
    name: "Password",
    field: "password",
    default_value: "",
    default_value_on_edit: true,
    type: "text_password",
  },
  {
    name: "Konfirmasi Password",
    field: "cpassword",
    default_value: "",
    default_value_on_edit: true,
    type: "text_password",
  },
  {
    name: "Tanggal Lahir",
    field: "tanggal_lahir",
    type: "date",
    format: "dd/MM/yyyy",
    prime_format: "d MM yy",
  },
  {
    name: "Agama",
    field: "agama",
    type: "text_dropdown",
    default_value: "islam",
    options: [
      { name: "Buddha", value: "buddha" },
      { name: "Kristen", value: "kristen" },
      { name: "Islam", value: "islam" },
      { name: "Hindu", value: "hindu" },
      { name: "Kong Hu Chu", value: "kong_hu_chu" },
    ],
  },
];

export default SignUpConfigs;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

// import zirvu file manager
// import "./assets/css/zirvu-filemanager.css";

// import css bootstrap
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.js";

// import custom css
import "./assets/css/font-opensans.css";
import "./assets/css/font-montserrat.css";
import "./assets/css/main.css";
import "./assets/css/index.css";
// import "./assets/css/layout.css";

// import css prime
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./assets/css/custom-ui.css";

//redux
import { Provider } from "react-redux";
import store from "./store";

//prime
import { PrimeReactProvider } from "primereact/api";

//DnD
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <PrimeReactProvider> */}
    <DndProvider backend={HTML5Backend}>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </DndProvider>
    {/* </PrimeReactProvider> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

const DetailSalesmanConfigs = {
  headers: [
    {
      name: "Tanggal (Dari - Sampai)",
      field: "tanggal_transaksi",
      type: "date_range",
      format: "dd/MM/yyyy",
      prime_format: "dd/mm/yy",
      default_value: [new Date(), new Date()],
      editor: true,
    },
    {
      name: "Pembanding (Dari - Sampai)",
      field: "tanggal_pembanding",
      type: "date_range",
      format: "dd/MM/yyyy",
      prime_format: "dd/mm/yy",
      default_value: [new Date(), new Date()],
      editor: true,
    },
    {
      name: "Jenis Nilai",
      field: "jenis",
      type: "text_radiobutton",
      default_value: "quantity",
      orientation: "horizontal",
      editor: true,
      options: [
        { name: "Quantity (Tin)", value: "quantity" },
        { name: "Rupiah (K)", value: "rupiah" },
      ],
    },
    {
      name: "Periode",
      field: "periode",
      type: "text_radiobutton",
      default_value: "bulanan",
      orientation: "horizontal",
      editor: true,
      options: [
        { name: "Bulanan", value: "bulanan" },
        { name: "Tahunan", value: "tahunan" },
      ],
    },
  ],
  columns: [
    {
      name: "No",
      field: "no",
      type: "number_plain",
      align: "center",
      table: true,
    },
    {
      name: "Periode",
      field: "periode",
      type: "text_plain",
      align: "center",
      table: true,
      filter: true,
    },
    {
      name: "Pembanding",
      field: "pembanding",
      type: "text_plain",
      align: "center",
      table: true,
      filter: true,
    },
    {
      name: "Nilai",
      field: "nilai",
      type: "number_plain",
      align: "right",
      table: true,
    },
    {
      name: "Nilai Banding",
      field: "nilai_pembanding",
      type: "number_plain",
      align: "right",
      table: true,
    },
    {
      name: "Persentase",
      field: "persen",
      type: "number_plain",
      align: "right",
      table: true,
    },
  ],
};

export default DetailSalesmanConfigs;

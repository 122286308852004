import { subDays, addDays } from "date-fns";

const BiayaConfigs = {
  master: {
    title: "Biaya",
    apiName: "masterdata/biaya",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: { 
      // add_params
      with_depot: 1,
      with_grup_biaya: 1,
    },
  },
  dialog: {
    title: "Biaya",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Depot",
      field: "depot_id",
      type: "object",
      object_name: "data_depot",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Grup Biaya",
      field: "grup_biaya_id",
      type: "object",
      object_name: "data_grup_biaya",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Tanggal Transaksi",
      field: "tanggal_transaksi",
      type: "date",
      align: "center",
      table: true,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
      object_name: "",
      object_field: "",
    },
    {
      name: "Jumlah",
      field: "jumlah",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: true,
    },
    {
      name: "Keterangan",
      field: "keterangan",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
  ],
};

export default BiayaConfigs;

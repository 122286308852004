const ManualInputConfig = [
  {
    name: "Bon Kredit",
    field: "bon_kredit_total",
    table: true,
    filter: true,
    editor: true,
    type: "number_plain",
    align: "right",
  },
  {
    name: "Bon Kredit Blm Ditagih (Hari Ini)",
    field: "bon_kredit_belum_tagih_hari_ini",
    table: true,
    filter: true,
    editor: true,
    type: "number_plain",
    align: "right",
  },
  {
    name: "Bon Kredit Blm Ditagih (Semalam)",
    field: "bon_kredit_belum_tagih_semalam",
    table: true,
    filter: true,
    editor: true,
    type: "number_plain",
    align: "right",
  },
  {
    name: "Bon Kredit Belum Jth Tempo",
    field: "bon_kredit_belum_jatuh_tempo",
    table: true,
    filter: true,
    editor: true,
    type: "number_plain",
    align: "right",
  },
  {
    name: "Bon Kredit Belum Jth Tempo (Hari Ini)",
    field: "bon_kredit_jatuh_tempo_hari_ini",
    table: true,
    filter: true,
    editor: true,
    type: "number_plain",
    align: "right",
  },
  {
    name: "Bon Kredit Belum Jth Tempo (Semalam)",
    field: "bon_kredit_jatuh_tempo_semalam",
    table: true,
    filter: true,
    editor: true,
    type: "number_plain",
    align: "right",
  },
];

export default ManualInputConfig;

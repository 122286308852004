import React, { useState, useEffect } from "react";
import generateEditorElement from "../utils/GenerateEditorElement";
import assignDataToMap from "../utils/AssignDataToMap";
import generateDefaultValue from "../utils/GenerateDefaultValue";
import { apiPost } from "../services/service-api";

const addEditorControlHOC = ({ configs, customElements, WrappedComponent }) => {
  const AddEditorControlHOC = (props) => {
    const [loading, setLoading] = useState("");
    const [error, setError] = useState({});

    const [value, setValue] = useState(
      generateDefaultValue({ tableConfigs: configs })
    );

    const handleInputChange = (field, newValue) => {
      const newMap = { ...value };
      if (!field.includes(".")) {
        newMap[field] = newValue;
      } else {
        assignDataToMap({ key: field, map: newMap, value: newValue });
      }
      setValue(newMap);
    };

    const handleMultipleInputChange = (mapsNewValue) => {
      const newMap = { ...value };
      for (const mapNewValue of mapsNewValue) {
        newMap[mapNewValue.field] = mapNewValue.newValue;
      }
      setValue(newMap);
    };

    const generateSelectorDialog = ({ field, componentFunction }) => {
      let fieldArr = [];
      if (field.includes(" ")) {
        fieldArr = field.split(" ");
      } else if (field.includes(",")) {
        fieldArr = field.split(",");
      } else {
        fieldArr.push(field);
      }
      for (const fieldSingle of fieldArr) {
        const config = configs.find((element) => element.field === fieldSingle);
        if (config) {
          config.selectorGenerator = componentFunction;
        }
      }
    };

    //auto generate element
    const defaultProps = {
      value: value,
      error: error,
      handleInputChange: handleInputChange,
      handleMultipleInputChange: handleMultipleInputChange,
    };

    const mapGeneratedElement = {};
    let firstElement = true;
    for (const config of configs) {
      // console.log("SEQUENCE CONFIG ", config);

      let foundEl;
      for (const custEl of customElements) {
        const { field, element } = custEl;
        if (config.field === field) {
          foundEl = element;
        }
      }
      if (foundEl) {
        mapGeneratedElement[config.field] = foundEl({
          ...defaultProps,
          autoFocus: firstElement,
          config: config,
        });
      } else {
        const generatedElement = generateEditorElement({
          ...defaultProps,
          autoFocus: firstElement,
          config: config,
        });
        if (generatedElement) {
          mapGeneratedElement[config.field] = generatedElement;
        }
      }
      if (firstElement) {
        firstElement = false;
      }
    }

    const onSave = async ({
      token,
      apiUrl,
      apiUrlSuffix,
      event,
      newValue,
      onError,
    }) => {
      const data = await apiPost({
        token: token,
        apiName: apiUrl,
        isForm: false,
        urlSuffix: apiUrlSuffix ?? "",
        onError: ({ message, data }) => {
          onError({ message: message, data: data });
        },
        value: newValue,
      });

      if (data) {
        return data;
      }

      return null;
    };

    return (
      <WrappedComponent
        {...props}
        //all method
        onSave={onSave}
        generateSelectorDialog={generateSelectorDialog}
        handleInputChange={handleInputChange}
        handleMultipleInputChange={handleMultipleInputChange}
        // all hooks
        value={value}
        setValue={setValue}
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
        // confirmLoading={props.confirmLoading}
        //all params
        elements={mapGeneratedElement}
      />
    );
  };
  return AddEditorControlHOC;
};

export default addEditorControlHOC;

import { Button } from "primereact/button";
import { format as dateFnsFormat, parse } from "date-fns";

const TaskListSingle = (props) => {
  // const {
  //   title,
  //   date,
  //   today,
  //   createdBy,
  //   done,
  //   showDetail,
  //   onEdit,
  //   onCheckChanged,
  // } = props;
  const { data, onCheckChanged, onEdit, onDelete, showDetail } = props;

  const {
    id,
    to_id,
    data_to,
    data_created_by,
    created_by_id,
    title,
    message,
    finished_at,
    created_at,
  } = data;

  const today = true;
  const done = finished_at ? true : false;
  const createdBy = data_created_by
    ? data_created_by.name + " - " + (data_created_by.title_label ?? "")
    : "-";
  // const parsedCreatedAt = parse(
  //   created_at,
  //   "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
  //   new Date()
  // );
  const parsedCreatedAt = new Date(created_at);
  const date = dateFnsFormat(parsedCreatedAt, "dd/MM/yy HH:mm");

  let bg = done ? "bg-green-50 border border-green-400" : "bg-white";
  let icon = done ? "pi pi-check-circle" : "pi pi-circle";
  let iconFormat = done ? "text-green-600" : "text-zinc-500";
  let text = done ? "text-zinc-400" : "text-zinc";

  if (!today) {
    bg = "bg-gray-100 border border-gray-300";
    iconFormat = "text-zinc-500";
  }

  return (
    <div className="col-start-start w-full gap-1">
      <div
        className={`row-between-center w-full ${bg} rounded-md px-3 py-0 !pl-1 gap-2`}
      >
        <div
          className="row-start-center cursor-pointer gap-1 w-full py-3"
          onClick={onCheckChanged}
        >
          <i className={`px-2 max-h-[2rem] ${iconFormat} ${icon}`} />

          {/* <Button
            className={`max-w-[2rem] max-h-[2rem] ${iconFormat}`}
            icon={icon}
            size="small"
            text
            rounded
            onClick={onCheckChanged}
          /> */}
          <span className={`text-sm ${text}`}> {title} </span>
        </div>
        <div className="row-start-center">
          <Button
            className="max-w-[2rem] max-h-[2rem] text-zinc-500"
            icon={"pi pi-pencil"}
            size="small"
            text
            rounded
            onClick={(event) => {
              event.preventDefault();
              if (onEdit) {
                onEdit();
              }
            }}
          />
          <Button
            className="max-w-[2rem] max-h-[2rem] text-zinc-500"
            icon={"pi pi-times"}
            size="small"
            text
            rounded
            onClick={(event) => {
              event.preventDefault();
              if (onDelete) {
                onDelete();
              }
            }}
          />
        </div>
      </div>
      {showDetail && (
        <div className="row-between-center w-full">
          <span className="text-xs text-zinc-400 pl-1">By: {createdBy}</span>
          <span className="text-xs text-zinc-400 pr-1">{date}</span>
        </div>
      )}
    </div>
  );
};

export default TaskListSingle;

import { Divider } from "primereact/divider";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { FaArrowDown, FaArrowUp, FaMinus } from "react-icons/fa6";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import { Skeleton } from "primereact/skeleton";

const BadgeCekStokKendaraan = (props) => {
  const { disabled, className, icon, color, onClick, title } = props;

  const [first, setFirst] = useState({ prompt: "Total Mobil", value: 100 });
  const [second, setSecond] = useState({ prompt: "Sudah Cek", value: 85 });
  // type = "neutral | up | down"
  const [notif, setNotif] = useState({
    type: "neutral",
    label: "",
    color: "text-zinc-600",
  });

  const user = useSelector((state) => state.auth.user);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);
  const { requestGet, loading } = useAPIRequest();

  //   notifType="up"
  //   notifLabel="+25 Outlet
  //   Dari Kemarin"

  const newIcon = React.cloneElement(icon ?? <FaMinus />, {
    color: "white",
  });
  const newColor = color ?? "var(--color-primary)";

  useEffect(() => {
    // reloadData();
  }, []);

  const generateFilter = () => {
    let filter = ``;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `data_outlet.data_jurusan.depot_id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `data_outlet.data_jurusan.data_depot.wilayah_id:=:${wilayah.id}`;
    }

    // console.log("FILTER", filter);
    return filter;
  };

  const reloadData = async () => {
    // const filter = generateFilter();
    // return await requestGet({
    //   fullUrl: "api/dashboard/kawil",
    //   params: {
    //     prefix: "outlet_aktif",
    //     filter,
    //     page: 1,
    //     take: 10,
    //   },
    //   onSuccess: ({ pagination, message, data }) => {
    //     if (data) {
    //       const { total_outlets, outlet_aktif, outlet_aktif_kemarin } = data;
    //       setFirst({ ...first, value: outlet_aktif });
    //       setSecond({ ...second, value: total_outlets });
    //       const diff = outlet_aktif - outlet_aktif_kemarin;
    //       if (diff > 0) {
    //         setNotif({
    //           type: "up",
    //           label: `+${diff.toFixed(0)} Outlet Dari Kemarin`,
    //           color: "text-green-500",
    //         });
    //       } else if (diff < 0) {
    //         setNotif({
    //           type: "down",
    //           label: `${diff.toFixed(0)} Outlet Dari Kemarin`,
    //           color: "text-red-400",
    //         });
    //       } else if (diff === 0) {
    //         setNotif({
    //           type: "neutral",
    //           label: `Tidak ada Perubahan`,
    //           color: "text-zinc-600",
    //         });
    //       }
    //     }
    //   },
    //   onError: ({ message, data }) => {},
    // });
  };

  const NotifComponent = () => {
    return (
      notif.type &&
      notif.label && (
        <div className="row-center-center w-full gap-2">
          {notif.type === "up" && (
            <FaArrowUp className={`${notif.color}`} size={"0.7rem"} />
          )}
          {notif.type === "down" && (
            <FaArrowDown className={`${notif.color}`} size={"0.7rem"} />
          )}
          <span
            className={`${
              notif.label ? notif.color : "text-[rgb(228,228,231)]"
            } text-sm text-center`}
          >
            {notif.label ?? "-"}
          </span>
        </div>
      )
    );
  };

  return (
    <div
      className={`elevated-card-tight-vertical col-start-start gap-3 cursor-pointer hover:translate-y-[-0.3rem] active:translate-y-0 active:shadow transition-all duration-300 ${className}`}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      style={
        disabled
          ? {
              boxShadow: "none",
              backgroundColor: "rgb(228,228,231)",
            }
          : {}
      }
    >
      <div className="row-start-center gap-2">
        {
          <div
            className={`row-center-center ${
              disabled ? `bg-zinc-400` : ``
            } w-[1.8rem] h-[1.8rem] rounded`}
            style={disabled ? {} : { backgroundColor: newColor }}
          >
            {newIcon}
          </div>
        }
        <span
          className={`font-semibold text-lg`}
          style={{ color: disabled ? "rgb(161,161,170)" : newColor }}
        >
          {title ?? "-"}
        </span>
      </div>
      <div className="row-center-center w-full gap-3">
        {/* first section */}
        <div className="col-center-center">
          <span className="text-xl font-semibold text-zinc-600">
            {first.value === null ? (
              <Skeleton height="1.8rem" width="4rem" />
            ) : (
              first.value.toLocaleString("id")
            )}
          </span>
          <span className="text-sm text-zinc-600 text-center">
            {first.prompt}
          </span>
        </div>
        {/* divider */}
        <Divider layout="vertical" className="h-[3.8rem]" />
        {/* second section */}
        <div className="col-center-center">
          <span className="text-xl font-semibold text-zinc-600">
            {second.value === null ? (
              <Skeleton height="1.8rem" width="4rem" />
            ) : (
              second.value.toLocaleString("id")
            )}
          </span>
          <span className="text-sm text-zinc-600 text-center">
            {second.prompt}
          </span>
        </div>
      </div>
      {/* <NotifComponent /> */}
    </div>
  );
};

export default BadgeCekStokKendaraan;

import { subDays, addDays } from "date-fns";

const UserConfigs = {
  master: {
    title: "User",
    apiName: "user",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: { 
      // add_params
      with_depot: 1,
      with_jabatan: 1,
    },
  },
  dialog: {
    title: "User",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Semua Gudang",
      field: "semua_gudang",
      align: "center",
      table: true,
      editor: true,
      type: "boolean_plain",
      filter: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Depot",
      field: "depot_id",
      type: "object",
      object_name: "data_depot",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Role",
      field: "role_id",
      type: "object",
      object_name: "data_role",
      object_field: "name",
      editor: true,
      table: false,
      filter: true,
    },
    {
      name: "Jabatan",
      field: "jabatan_id",
      type: "object",
      object_name: "data_jabatan",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Name",
      field: "name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Username",
      field: "username",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Nik",
      field: "nik",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Email",
      field: "email",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Phone",
      field: "phone",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Password",
      field: "password",
      type: "text_password",
      table: false,
      filter: false,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Confirmation Password",
      field: "cpassword",
      type: "text_password",
      table: false,
      filter: false,
      editor: true,
      object_name: "",
      object_field: "",
    },
  ],
};

export default UserConfigs;

const generateFilterConfigs = (tableConfigs) => {
  const filterConfigs = [];
  for (const config of tableConfigs) {
    const { name, field, filter } = config;
    if (filter) {
      filterConfigs.push({
        ...config,
        name: name,
        value: field,
      });
    }
  }
  return filterConfigs;
};

export default generateFilterConfigs;

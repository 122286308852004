import { decryptString, encryptString } from "../utils/EncryptDecryptData";
import { createSlice } from "@reduxjs/toolkit";

import { TAG } from "../configs/constants";

const tag = TAG;

const user = decryptString(localStorage.getItem(tag + "user"));
const listDepot = decryptString(localStorage.getItem(tag + "list-depot"));
const listWilayah = decryptString(localStorage.getItem(tag + "list-wilayah"));

const appSlice = createSlice({
  name: "app",
  initialState: {
    wilayah: user ? user.data_wilayah : {},
    depot: user ? user.data_depot : {},
    listDepot: listDepot ? JSON.parse(listDepot) : [],
    listWilayah: listWilayah ? JSON.parse(listWilayah) : [],
    valueType: "quantity", // 'quantity' || 'harga'
  },
  reducers: {
    setWilayah(state, action) {
      state.wilayah = action.payload.wilayah;
    },
    setDepot(state, action) {
      state.depot = action.payload.depot;
    },
    changeDepot(state, action) {
      const id = action.payload.id;

      for (const depot of state.listDepot) {
        if (depot.id === id) {
          state.depot = depot;
          break;
        }
      }
    },
    changeWilayah(state, action) {
      const id = action.payload.id;
      for (const wilayah of state.listWilayah) {
        if (wilayah.id === id) {
          state.wilayah = wilayah;
          break;
        }
      }
    },
    setListDepot(state, action) {
      state.listDepot = action.payload.listDepot;
      localStorage.setItem(
        tag + "list-depot",
        encryptString(JSON.stringify(action.payload.listDepot))
      );
    },
    setListWilayah(state, action) {
      state.listWilayah = action.payload.listWilayah;
      localStorage.setItem(
        tag + "list-wilayah",
        encryptString(JSON.stringify(action.payload.listWilayah))
      );
    },
    setValueType(state, action) {
      state.valueType = action.payload.valueType;
    },
  },
});

const appActions = appSlice.actions;

const setWilayah = (wilayah) => {
  return (dispatch) => {
    dispatch(appActions.setWilayah({ wilayah }));
  };
};

const setDepot = (depot) => {
  return (dispatch) => {
    dispatch(appActions.setDepot({ depot }));
  };
};

const changeDepot = (id) => {
  return (dispatch) => {
    dispatch(appActions.changeDepot({ id }));
  };
};

const changeWilayah = (id) => {
  return (dispatch) => {
    dispatch(appActions.changeWilayah({ id }));
  };
};

const setListDepot = (listDepot) => {
  return (dispatch) => {
    dispatch(appActions.setListDepot({ listDepot }));
  };
};

const setListWilayah = (listWilayah) => {
  return (dispatch) => {
    dispatch(appActions.setListWilayah({ listWilayah }));
  };
};

const setValueType = (valueType) => {
  return (dispatch) => {
    dispatch(appActions.setValueType({ valueType }));
  };
};

export {
  appActions,
  setDepot,
  setWilayah,
  changeDepot,
  changeWilayah,
  setListDepot,
  setListWilayah,
  setValueType,
};

export default appSlice;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";

const DialogDetailKodeProduksiDepot = (props) => {
  const { visible, onHide, title, data } = props;

  const [datasKodeProduksi, setDatasKodeProduksi] = useState([]);
  const [datasPenjualTerbaik, setDatasPenjualTerbaik] = useState([]);
  const { requestGet, loading } = useAPIRequest();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);
  const taskListUser = useSelector((state) => state.ui.taskListUsers);

  useEffect(() => {
    if (visible) {
      reloadDataPrestasi();
    }
  }, [visible]);

  const generateFilterPrestasi = () => {
    let filter = ``;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `depot_id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `wilayah_id:=:${wilayah.id}`;
    }

    filter += `;produk_id:=:${data.produk_id}`;

    return filter;
  };

  const reloadDataPrestasi = async () => {
    const filter = generateFilterPrestasi();
    setDatasPenjualTerbaik(null);

    return await requestGet({
      fullUrl: "api/dashboard/kadepot",
      params: {
        prefix: "prestasi_depot_penjualan_produk",
        filter,
        page: 1,
        take: 20,
        order: "total",
        order_method: "DESC",
        // with_produk: 1,
        // with_gudang: 1,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          const newListData = [];
          for (let i = 0; i < data.length; i++) {
            const { jurusan_id, data_jurusan, data_produk, total } = data[i];
            const { data_sales } = data_jurusan;
            newListData.push({
              id: i + 1,
              no: i + 1,
              salesman: data_sales.name,
              jurusan_kode: data_jurusan.kode,
              jurusan_nama: data_jurusan.nama,
              quantity: total / 100,
              satuan: "Tin",
              konversi: 100,
            });
          }
          setDatasPenjualTerbaik(newListData);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  // useEffect(() => {
  //   setDatasPenjualTerbaik([
  //     {
  //       id: 1,
  //       no: 1,
  //       jurusan: "P. Siantar",
  //       salesman: "Alexander Vance",
  //       outlet: "Bandung",
  //       jenis_outlet: "ATB",
  //       quantity: 128,
  //       satuan: "Tin",
  //       konversi: 100,
  //     },
  //     {
  //       id: 2,
  //       no: 2,
  //       jurusan: "Pagurawan",
  //       salesman: "William Bennett",
  //       outlet: "Bintang Baru",
  //       jenis_outlet: "AC",
  //       quantity: 120,
  //       satuan: "Tin",
  //       konversi: 100,
  //     },
  //     {
  //       id: 3,
  //       no: 3,
  //       jurusan: "Rambung Merah",
  //       salesman: "William Bennett",
  //       outlet: "Deli Raya",
  //       jenis_outlet: "AC",
  //       quantity: 100,
  //       satuan: "Tin",
  //       konversi: 100,
  //     },
  //     {
  //       id: 4,
  //       no: 4,
  //       jurusan: "Sibatu-batu",
  //       salesman: "Alexander Vance",
  //       outlet: "Go Ah Peng",
  //       jenis_outlet: "ATB",
  //       quantity: 96,
  //       satuan: "Tin",
  //       konversi: 100,
  //     },
  //     {
  //       id: 5,
  //       no: 5,
  //       jurusan: "Sei Buluh",
  //       salesman: "Elizabeth Lawson",
  //       outlet: "Karya Jaya",
  //       jenis_outlet: "NA",
  //       quantity: 95,
  //       satuan: "Tin",
  //       konversi: 100,
  //     },
  //     {
  //       id: 6,
  //       no: 6,
  //       jurusan: "Beringin",
  //       salesman: "Alexander Vance",
  //       outlet: "Makmur",
  //       jenis_outlet: "NA",
  //       quantity: 90,
  //       satuan: "Tin",
  //       konversi: 100,
  //     },
  //   ]);
  // }, []);

  const quantityBodyTemplate = (rowData) => {
    if (rowData.quantity) {
      return rowData.quantity.toLocaleString("id-ID", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      });
    }
    return 0;
  };

  return (
    <Dialog visible={visible} header={title} onHide={onHide}>
      <div className="col-start-start w-full gap-8">
        <div className="col-start-start w-full gap-2">
          <span className="text-sm font-semibold text-zinc-700">
            Prestasi Penjualan Produk Ini :
          </span>
          {datasPenjualTerbaik !== null ? (
            <DataTable
              className="w-full"
              value={datasPenjualTerbaik}
              resizableColumns
              stripedRows
              showGridlines
              scrollable
              scrollHeight="21.5rem"
            >
              <Column field="no" header="#" />
              <Column field="salesman" header="Salesman" />
              <Column field="jurusan_kode" header="Kode" />
              <Column field="jurusan_nama" header="Jurusan" />
              <Column
                field="quantity"
                header="Qty"
                body={quantityBodyTemplate}
                alignHeader={"right"}
                style={{ textAlign: "right" }}
              />
              <Column field="satuan" header="UOM" />
            </DataTable>
          ) : (
            <div className="col-start-start w-full gap-3">
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default DialogDetailKodeProduksiDepot;

import React from "react";
import TableAutoComponent from "../../components/complex/crud/TableAutoComponent";
import FilterComponent from "../../components/complex/crud/FilterComponent";
import generateFilterConfigs from "../../utils/FilterConfigGenerator";
import generateTableConfigs from "../../utils/TableConfigGenerator";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";

import useSelectorDialogControl from "../../custom_hooks/complex/useSelectorDialogControl";

const title = "Transport";
const apiName = "masterdata/transport";
const orderBy = "id";
const orderMethod = "ASC";
const additionalParams = {
  with_depot: 1,
  with_gudang: 1,
};

const customColumns = [];

const configs = [
  // add_configs
  {
    name: "Depot",
    field: "depot_id",
    type: "object",
    object_name: "data_depot",
    object_field: "nama",
    editor: true,
    table: true,
    filter: true,
  },
  // {
  //   name: "Gudang",
  //   field: "gudang_id",
  //   type: "object",
  //   object_name: "data_gudang",
  //   object_field: "nama",
  //   editor: true,
  //   table: true,
  //   filter: true,
  // },
  {
    name: "Jenis",
    field: "jenis_transport",
    type: "text_plain",
    table: true,
    filter: true,
    editor: true,
    object_name: "",
    object_field: "",
  },
  {
    name: "Merek",
    field: "merek",
    type: "text_plain",
    table: true,
    filter: true,
    editor: true,
    object_name: "",
    object_field: "",
  },
  // {
  //   name: "Nomor Mesin",
  //   field: "nomor_mesin",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Nomor Rangka",
  //   field: "nomor_rangka",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Tahun",
  //   field: "tahun",
  //   type: "date_year",
  //   align: "center",
  //   editor: true,
  //   format: "yyyy",
  //   object_name: "",
  //   object_field: "",
  //   table: true,
  // },
  // {
  //   name: "Tanggal Beli",
  //   field: "tanggal_beli",
  //   type: "date",
  //   align: "center",
  //   table: true,
  //   editor: true,
  //   format: "dd/MM/yyyy",
  //   prime_format: "d MM yy",
  //   showTime: 1,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Tanggal STNK",
  //   field: "tanggal_stnk",
  //   type: "date",
  //   align: "center",
  //   table: true,
  //   editor: true,
  //   format: "dd/MM/yyyy",
  //   prime_format: "d MM yy",
  //   showTime: 1,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Jenis BBM",
  //   field: "jenis_bbm",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Dalam Kota",
  //   field: "dalam_kota",
  //   type: "number_plain",
  //   editor: true,
  //   align: "right",
  //   object_name: "",
  //   object_field: "",
  //   table: true,
  // },
  // {
  //   name: "Speedo Awal",
  //   field: "speedo_awal",
  //   type: "number_plain",
  //   editor: true,
  //   align: "right",
  //   object_name: "",
  //   object_field: "",
  //   table: true,
  // },
  // {
  //   name: "Speedo Akhir",
  //   field: "speedo_akhir",
  //   type: "number_plain",
  //   editor: true,
  //   align: "right",
  //   object_name: "",
  //   object_field: "",
  //   table: true,
  // },
  // {
  //   name: "Keterangan",
  //   field: "keterangan",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Jenis Mobil",
  //   field: "jenis_mobil",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Nama",
  //   field: "nama",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Jumlah Roda",
  //   field: "jumlah_roda",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   type: "number_plain",
  //   align: "right",
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Luar Kota",
  //   field: "luar_kota",
  //   type: "number_plain",
  //   editor: true,
  //   align: "right",
  //   object_name: "",
  //   object_field: "",
  //   table: true,
  // },
  {
    name: "Nomor Polisi",
    field: "nomor_polisi",
    type: "text_plain",
    table: true,
    filter: true,
    editor: true,
    object_name: "",
    object_field: "",
  },
];

const filterConfigs = generateFilterConfigs(configs);
const tableConfigs = generateTableConfigs(configs);

const TransportSelectorDialog = (props) => {
  const single = props.single ?? true;

  const {
    toast,
    listData,
    paging,
    loading,
    setListData,
    selectedRows,
    setSelectedRows,
    refineValue,
    reloadListData,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
  } = useSelectorDialogControl({
    apiName,
    orderBy,
    orderMethod,
    additionalParams,
  });

  const dialogFooter = (
    <div className="col-center-center w-100">
      <Paginator
        first={paging.take * (paging.page - 1)}
        rows={paging.take}
        totalRecords={paging.total}
        rowsPerPageOptions={[5, 8, 10, 20, 50]}
        onPageChange={handleOnPage}
      />
      <div className="row-end-end w-100">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Pilih"
          icon="pi pi-check"
          onClick={(e) => {
            const refinedValue = refineValue(selectedRows);
            props.onConfirm(e, refinedValue);
          }}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[23rem] sm:w-[50rem] min-h-[40rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className={`col-start-start w-100 gap-3`}>
        {filterConfigs && filterConfigs.length > 0 && (
          <FilterComponent onFilter={handleOnFilter} configs={filterConfigs} />
        )}
        <div className={`col-center-center w-100 gap-2`}>
          <TableAutoComponent
            singleMode={single}
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            customColumns={customColumns}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default TransportSelectorDialog;

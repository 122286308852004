import { Outlet } from "react-router-dom";
import NavBarComponent from "./NavBarComponent";
import SideMenuComponent from "../../components/side-menu/SideMenuComponent";
import FloatingProfilePage from "./FloatingProfilePage";
import {
  FaBlackTie,
  FaBox,
  FaBoxesStacked,
  FaBullseye,
  FaCalendar,
  FaCar,
  FaChartColumn,
  FaChartLine,
  FaDatabase,
  FaGears,
  FaGlobe,
  FaGroupArrowsRotate,
  FaHouse,
  FaLocationCrosshairs,
  FaMapPin,
  FaPallet,
  FaPlaceOfWorship,
  FaTableCells,
  FaTableCellsLarge,
  FaSignsPost,
  FaSquare,
  FaTruck,
  FaTruckRampBox,
  FaUsers,
  FaWarehouse,
  FaQrcode,
  FaEnvelope,
  FaKeyboard,
  FaMoneyBillWheat,
  FaMoneyBillTrendUp,
} from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";
import classes from "./LayoutDashboardPage.module.css";
import { APP_NAME } from "../../configs/constants";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";

import { motion, AnimatePresence } from "framer-motion";
import NoteSidebar from "./note/NoteSidebar";
import TaskListSidebar from "./task-list/TaskListSidebar";

const title = APP_NAME;
const logoUrl = "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png";

const menus = [
  {
    label: "Home",
    to: "",
    icon: <FaHouse />,
  },
  {
    label: "Trace QR",
    to: "traceqr",
    icon: <FaQrcode />,
  },
  // {
  //   label: "Message",
  //   to: "message",
  //   icon: <FaEnvelope />,
  // },
  {
    label: "Transaksi",
    type: "menu-title",
  },
  {
    label: "Stok",
    icon: <FaPallet />,
    type: "menu-group",
    children: [
      {
        label: "Stok Masuk",
        to: "transaksi/stokmasuk",
        icon: <FaBlackTie />,
      },
      {
        label: "Stok Keluar",
        to: "transaksi/stokkeluar",
        icon: <FaTruck />,
      },
      {
        label: "Stok Mutasi",
        to: "transaksi/stokmutasi",
        icon: <FaTruckRampBox />,
      },
    ],
  },
  {
    label: "Stok Retur Baik",
    icon: <FaPallet />,
    type: "menu-group",
    children: [
      {
        label: "Stok Masuk",
        to: "transaksi/stokmasukreturbaik",
        icon: <FaBlackTie />,
      },
      {
        label: "Stok Keluar",
        to: "transaksi/stokkeluarreturbaik",
        icon: <FaTruck />,
      },
      {
        label: "Stok Mutasi",
        to: "transaksi/stokmutasireturbaik",
        icon: <FaTruckRampBox />,
      },
    ],
  },
  {
    label: "Stok Retur Usang",
    icon: <FaPallet />,
    type: "menu-group",
    children: [
      {
        label: "Stok Masuk",
        to: "transaksi/stokmasukreturusang",
        icon: <FaBlackTie />,
      },
      {
        label: "Stok Keluar",
        to: "transaksi/stokkeluarreturusang",
        icon: <FaTruck />,
      },
      {
        label: "Stok Mutasi",
        to: "transaksi/stokmutasireturusang",
        icon: <FaTruckRampBox />,
      },
    ],
  },
  {
    label: "Manual Input",
    to: "transaksi/manualinput",
    icon: <FaKeyboard />,
  },
  {
    label: "Kendaraan Pulang",
    to: "transaksi/kendaraanpulang",
    icon: <FaTruck />,
  },
  // {
  //   label: "Permintaan Barang",
  //   to: "#",
  //   icon: <FaCircleRight />,
  // },
  {
    label: "Laporan",
    type: "menu-title",
  },
  {
    label: "Laporan",
    to: "report/report-main",
    icon: <FaChartColumn />,
  },
  {
    label: "Target",
    type: "menu-title",
  },
  {
    label: "Realisasi",
    to: "target/target-realisasi",
    icon: <FaChartLine />,
  },
  {
    label: "Target Batang",
    to: "target/target-batang",
    icon: <FaTableCells />,
  },
  {
    label: "Target Bungkus",
    to: "target/target-bungkus",
    icon: <FaTableCellsLarge />,
  },
  {
    label: "Master & Setting",
    type: "menu-title",
  },
  {
    label: "Master",
    icon: <FaDatabase />,
    type: "menu-group",
    children: [
      {
        label: "Jabatan",
        to: "master/jabatan",
        icon: <FaBlackTie />,
      },
      {
        label: "User",
        to: "master/user",
        icon: <FaUsers />,
      },
      {
        label: "Gudang",
        to: "master/gudang",
        icon: <FaWarehouse />,
      },
      {
        label: "Wilayah",
        to: "master/wilayah",
        icon: <FaGlobe />,
      },
      {
        label: "Depot",
        to: "master/depot",
        icon: <FaPlaceOfWorship />,
      },
      {
        label: "Transport",
        to: "master/transport",
        icon: <FaCar />,
      },
      {
        label: "Jurusan",
        to: "master/jurusan",
        icon: <FaMapPin />,
      },
      {
        label: "Outlet",
        to: "master/outlet",
        icon: <FaHouse />,
      },
      {
        label: "Jadwal",
        to: "master/jadwal",
        icon: <FaCalendar />,
      },
      {
        label: "Produk Kategori",
        to: "master/produkkategori",
        icon: <FaBoxesStacked />,
      },
      {
        label: "Produk",
        to: "master/produk",
        icon: <FaBox />,
      },
      {
        label: "Kelompok Target",
        to: "master/kelompoktarget",
        icon: <FaBullseye />,
      },
      {
        label: "Grup Biaya",
        to: "master/grupbiaya",
        icon: <FaMoneyBillWheat />,
      },
      {
        label: "Biaya",
        to: "master/biaya",
        icon: <FaMoneyBillTrendUp />,
      },
    ],
  },
  {
    label: "Target",
    icon: <FaBullseye />,
    type: "menu-group",
    children: [
      {
        label: "Produk Kelompok",
        to: "target/produkkelompoktarget",
        icon: <FaGroupArrowsRotate />,
      },
      {
        label: "Batang Wilayah",
        to: "target/targetbatangwilayah",
        icon: <FaLocationCrosshairs />,
      },
      {
        label: "Batang Depot",
        to: "target/targetbatangdepot",
        icon: <FaWarehouse />,
      },
      {
        label: "Bungkus Depot",
        to: "target/targetbungkusdepot",
        icon: <FaSquare />,
      },
      {
        label: "Bungkus Jurusan",
        to: "target/targetbungkusjurusan",
        icon: <FaSignsPost />,
      },
    ],
  },
  {
    label: "Setting",
    icon: <FaGears />,
    type: "menu-group",
    children: [],
  },
];

const handleNotifClick = (e) => {
  console.log("New Notif Click");
};

const LayoutDashboardPage = () => {
  const user = useSelector((state) => state.auth.user);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const sideMenuIsVisible = useSelector((state) => state.ui.sideMenuIsVisible);

  const isSmallScreen = window.innerWidth < 640;

  const handleAvatarClick = (e) => {
    setShowProfileDialog((state) => !state);
  };

  return (
    <>
      <div className="row-start-start w-100">
        <AnimatePresence>
          {sideMenuIsVisible && (
            <SideMenuComponent
              menus={menus}
              title={title}
              logoUrl={logoUrl}
              onChangeProfile={handleAvatarClick}
            />
          )}
        </AnimatePresence>
        <NoteSidebar />
        <TaskListSidebar />
        {/* <NoteDialog /> */}
        <Dialog
          className="mt-6"
          showHeader={false}
          contentStyle={{ padding: "0", borderRadius: "0.5rem" }}
          position={"top-right"}
          visible={showProfileDialog}
          modal={false}
          style={{
            width: "50vw",
            marginTop: "calc(var(--height-nav-bar) + 12px )",
          }}
          draggable={false}
          resizable={false}
          onHide={() => setShowProfileDialog(false)}
        >
          <FloatingProfilePage hidden={false} />
        </Dialog>
        <motion.div
          initial={
            isSmallScreen
              ? {}
              : {
                  marginLeft: sideMenuIsVisible ? 0 : "var(--width-side-menu)",
                  width: "calc(100% - var(--width-side-menu))",
                }
          }
          animate={
            isSmallScreen
              ? {}
              : {
                  marginLeft: sideMenuIsVisible ? "var(--width-side-menu)" : 0,
                  width: sideMenuIsVisible
                    ? "calc(100% - var(--width-side-menu))"
                    : "100%",
                }
          }
          className={`col-start-start w-100`}
        >
          <NavBarComponent
            showNotif={true}
            onCLickAvatar={handleAvatarClick}
            onClickNotif={handleNotifClick}
            style={{
              width: sideMenuIsVisible
                ? "calc(100% - var(--width-side-menu))"
                : "100%",
            }}
            avatarSrc={user.image}
          />
          <main
            className={`row-start-start ${classes.mainContainer} w-full pt-[var(--height-nav-bar)]`}
          >
            <Outlet />
          </main>
        </motion.div>
      </div>
    </>
  );
};

export default LayoutDashboardPage;

import { subDays, addDays } from "date-fns";

const ProdukConfigs = {
  master: {
    title: "Produk",
    apiName: "masterdata/produk",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: { 
      // add_params
      with_kategori: 1,
      with_produk_kelompok_target: 1,
    },
  },
  dialog: {
    title: "Produk",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Kategori",
      field: "kategori_id",
      type: "object",
      object_name: "data_kategori",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Produk Kelompok Target",
      field: "produk_kelompok_target_id",
      type: "object",
      object_name: "data_produk_kelompok_target",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Kode",
      field: "kode",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Nama",
      field: "nama",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Rekening",
      field: "rekening",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Bandrol",
      field: "bandrol",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: true,
    },
    {
      name: "Saldo",
      field: "saldo",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: true,
    },
    {
      name: "Nama Lengkap",
      field: "nama_lengkap",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Semua Satuan",
      field: "data_konversis",
      type: "list",
      columns: [
        { name: "Satuan", field: "satuan", type: "text_plain", editable: true },
        { name: "Konversi", field: "konversi", type: "number_plain", editable: true },
        { name: "Agen", field: "agen", type: "number_plain", editable: true },
        { name: "Tunai", field: "tunai", type: "number_plain", editable: true },
        { name: "Kredit", field: "kredit", type: "number_plain", editable: true },
        { name: "Agen Lama", field: "agen_old", type: "number_plain", editable: true },
        { name: "Tunai Lama", field: "tunai_old", type: "number_plain", editable: true },
        { name: "Kredit Lama", field: "kredit_old", type: "number_plain", editable: true },
      ],
      width: "50rem",
      editor: true,
    },
  ],
};

export default ProdukConfigs;

import { createSlice } from "@reduxjs/toolkit";
import { decryptString, encryptString } from "../utils/EncryptDecryptData";
import { TAG } from "../configs/constants";

const tag = TAG;

const showSideMenuText = decryptString(
  localStorage.getItem(tag + "show_side_menu")
);
const showSideMenu = showSideMenuText === "true" ? true : false;

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    sideMenuIsVisible: showSideMenu ?? true,
    layoutDashboardTitle: "",

    //notes section
    noteDialogVisible: false,
    noteCount: 0,

    // task list section
    taskListEditingData: {},
    taskListAddDialogVisible: false,
    taskListDialogVisible: false,
    taskListUsers: [],
    taskListSelectedUser: null,
    taskListContents: [],
  },
  reducers: {
    toggleSideMenuVisibility(state) {
      const isVisible = !state.sideMenuIsVisible;
      state.sideMenuIsVisible = isVisible;
      localStorage.setItem(
        tag + "show_side_menu",
        encryptString(isVisible ? "true" : "false")
      );
    },
    setLayoutDashboardTitle(state, action) {
      state.layoutDashboardTitle = action.payload.title;
    },

    //note section
    showNoteDialog(state) {
      state.noteDialogVisible = true;
    },
    hideNoteDialog(state) {
      state.noteDialogVisible = false;
    },
    toggleNoteDialog(state) {
      const isVisible = !state.noteDialogVisible;
      state.noteDialogVisible = isVisible;
    },
    setNoteCount(state, action) {
      state.noteCount = action.payload.noteCount;
    },

    // task list section
    showTaskListDialog(state) {
      state.taskListDialogVisible = true;
    },
    hideTaskListDialog(state) {
      state.taskListDialogVisible = false;
    },
    toggleTaskListDialog(state) {
      const isVisible = !state.taskListDialogVisible;
      state.taskListDialogVisible = isVisible;
    },
    setTaskListEditingData(state, action) {
      state.taskListEditingData = action.payload.taskListEditingData;
    },
    showAddTaskListDialog(state) {
      state.taskListAddDialogVisible = true;
    },
    hideAddTaskListDialog(state) {
      state.taskListAddDialogVisible = false;
    },
    setTaskListUsers(state, action) {
      state.taskListUsers = action.payload.taskListUsers;
    },
    setTaskListSelectedUser(state, action) {
      state.taskListSelectedUser = action.payload.taskListSelectedUser;
    },
    setTaskListContents(state, action) {
      state.taskListContents = action.payload.taskListContents;
    },
  },
});

const uiActions = uiSlice.actions;

const toggleSideMenuVisibility = () => {
  return (dispatch) => {
    dispatch(uiActions.toggleSideMenuVisibility());
  };
};

const setLayoutDashboardTitle = ({ title }) => {
  return (dispatch) => {
    dispatch(uiActions.setLayoutDashboardTitle({ title }));
  };
};

// note section
const showNoteDialog = () => {
  return (dispatch) => {
    dispatch(uiActions.showNoteDialog());
  };
};

const hideNoteDialog = () => {
  return (dispatch) => {
    dispatch(uiActions.hideNoteDialog());
  };
};

const toggleNoteDialog = () => {
  return (dispatch) => {
    dispatch(uiActions.toggleNoteDialog());
  };
};

const setNoteCount = (value) => {
  return (dispatch) => {
    dispatch(uiActions.setNoteCount({ noteCount: value }));
  };
};

// task list section
const showTaskListDialog = () => {
  return (dispatch) => {
    dispatch(uiActions.showTaskListDialog());
  };
};

const hideTaskListDialog = () => {
  return (dispatch) => {
    dispatch(uiActions.hideTaskListDialog());
  };
};

const toggleTaskListDialog = () => {
  return (dispatch) => {
    dispatch(uiActions.toggleTaskListDialog());
  };
};

const setTaskListEditingData = ({ taskListEditingData }) => {
  return (dispatch) => {
    dispatch(uiActions.setTaskListEditingData({ taskListEditingData }));
  };
};

const showAddTaskListDialog = () => {
  return (dispatch) => {
    dispatch(uiActions.showAddTaskListDialog());
  };
};

const hideAddTaskListDialog = () => {
  return (dispatch) => {
    dispatch(uiActions.hideAddTaskListDialog());
  };
};

const setTaskListUsers = ({ taskListUsers }) => {
  return (dispatch) => {
    dispatch(uiActions.setTaskListUsers({ taskListUsers }));
  };
};

const setTaskListSelectedUser = ({ taskListSelectedUser }) => {
  return (dispatch) => {
    dispatch(uiActions.setTaskListSelectedUser({ taskListSelectedUser }));
  };
};

const setTaskListContents = ({ taskListContents }) => {
  return (dispatch) => {
    dispatch(uiActions.setTaskListContents({ taskListContents }));
  };
};

export {
  uiActions,
  toggleSideMenuVisibility,
  setLayoutDashboardTitle,
  // note section
  showNoteDialog,
  hideNoteDialog,
  toggleNoteDialog,
  setNoteCount,
  // task list section
  showTaskListDialog,
  hideTaskListDialog,
  setTaskListEditingData,
  showAddTaskListDialog,
  hideAddTaskListDialog,
  toggleTaskListDialog,
  setTaskListUsers,
  setTaskListSelectedUser,
  setTaskListContents,
};

export default uiSlice;

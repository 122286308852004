import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAPIRequest from "../../../custom_hooks/simple/useAPIRequest";
import {
  hideAddTaskListDialog,
  hideTaskListDialog,
  setTaskListContents,
  setTaskListEditingData,
  setTaskListSelectedUser,
  setTaskListUsers,
  showAddTaskListDialog,
} from "../../../store/ui-slice";
import onErrorToast from "../../../utils/OnErrorToast";
import onSuccessToast from "../../../utils/OnSuccessToast";
import TaskListEditorDialog from "./TaskListEditorDialog";
import TaskListSingle from "./TaskListSingle";
import { format } from "date-fns";

const defaultEditorData = { receiverId: 0, description: "" };
const defaultView = { id: 0, name: "Me", value: 0 };

const TaskListSidebar = (props) => {
  const toastRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);
  const showTaskListEditor = useSelector(
    (state) => state.ui.taskListAddDialogVisible
  );
  const contents = useSelector((state) => state.ui.taskListContents);
  const listView = useSelector((state) => state.ui.taskListUsers);
  const selectedView = useSelector((state) => state.ui.taskListSelectedUser);
  const visible = useSelector((state) => state.ui.taskListDialogVisible);
  const editorData = useSelector((state) => state.ui.taskListEditingData);

  const { requestGet, requestPost, requestDelete, loading } = useAPIRequest();

  const [oldContents, setOldContents] = useState([]);
  const [showDetail, setShowDetail] = useState(false);

  const generateFilter = () => {
    let filter = `created_by_id:=:${user.id}||to_id:=:${user.id}`;

    return filter;
  };

  const reloadData = async () => {
    const filter = generateFilter();
    dispatch(setTaskListContents({ taskListContents: null }));
    // setContents(null);

    return await requestGet({
      fullUrl: "api/tools/todo/data",
      params: {
        filter,
        page: 1,
        take: 40,
        order: "id",
        order_method: "DESC",
        with_to: 1,
        with_created_by: 1,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          const newContents = [];
          for (let i = 0; i < data.length; i++) {
            newContents.push(data[i]);
          }
          dispatch(setTaskListContents({ taskListContents: newContents }));
          // setContents(newContents);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const reloadViewData = async () => {
    if (user.title === "kawil") {
      const filter = `wilayah_id:=:${user.data_wilayah.id};title:=:kadepot`;
      // setListView([defaultView]);
      return await requestGet({
        fullUrl: "api/user/data",
        params: {
          filter,
          page: 1,
          take: 40,
          order: "name",
          order_method: "ASC",
          with_depot: 1,
        },
        onSuccess: ({ pagination, message, data }) => {
          if (data) {
            let newUsers = [];
            for (const d of data) {
              const { id, name, depot_id, data_depot, title_label } = d;
              newUsers.push({
                id: id,
                name: name + " - " + (title_label ?? ""),
                value: id,
                depot_id: depot_id,
              });
            }
            newUsers = [defaultView, ...newUsers];
            dispatch(setTaskListUsers({ taskListUsers: newUsers }));
            dispatch(
              setTaskListSelectedUser({
                taskListSelectedUser: newUsers[0].value,
              })
            );
          }
        },
        onError: ({ message, data }) => {},
      });
    } else if (user.title === "kadepot") {
      const newUsers = [defaultView];
      dispatch(setTaskListUsers({ taskListUsers: newUsers }));
      dispatch(
        setTaskListSelectedUser({
          taskListSelectedUser: newUsers[0].value,
        })
      );
    }
  };

  const saveData = async ({
    data,
    receiverId,
    description,
    checked,
    uniqueMarker,
  }) => {
    let newReceiverId = 0;
    if (receiverId <= 0) {
      newReceiverId = user.id;
    } else {
      newReceiverId = receiverId;
    }
    const isEdit = data.id && data.id > 0;
    const body = {
      to_id: newReceiverId,
      title: description,
      message: "-",
      finished_at: null,
      unique_marker: uniqueMarker,
    };
    if (isEdit) {
      body["id"] = data.id;
    }
    if (checked) {
      if (checked === true) {
        body["finished_at"] = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      } else {
        body["finished_at"] = null;
      }
    }

    return await requestPost({
      fullUrl: "api/tools/todo/save",
      isForm: false,
      body,
      onSuccess: ({ message, data }) => {
        if (data) {
          // const refinedData = convertContentData(data);
          if (isEdit) {
            const index = contents.findIndex((el) => el.id === data.id);
            const newListData = [...contents];
            newListData.splice(index, 1, data);
            dispatch(setTaskListContents({ taskListContents: newListData }));
            // setContents(newListData);
          } else {
            if (selectedView <= 0 && data.data_to.id === user.id) {
              dispatch(
                setTaskListContents({ taskListContents: [data, ...contents] })
              );
              // setContents([data, ...contents]);
            } else if (selectedView > 0 && data.data_to.id === selectedView) {
              dispatch(
                setTaskListContents({ taskListContents: [data, ...contents] })
              );
              // setContents([data, ...contents]);
            }
          }
          dispatch(hideAddTaskListDialog());
          // setShowTaskListEditor(false);
          onSuccessToast({
            toast: toastRef,
            message: "Task berhasil disimpan",
            data: {},
          });
        }
      },
      onError: ({ message, data }) => {
        onErrorToast({
          toast: toastRef,
          message: "Gagal menyimpan task",
          data: {},
        });
      },
    });
  };

  const deleteData = async (id) => {
    const listId = [id];
    return await requestDelete({
      fullUrl: "api/tools/todo/delete",
      isForm: false,
      ids: listId,
      onSuccess: ({ message, data }) => {
        if (data) {
          const newContents = contents.filter((el) => !listId.includes(el.id));
          dispatch(setTaskListContents({ taskListContents: newContents }));
          onSuccessToast({
            toast: toastRef,
            message: "Task berhasil dihapus",
            data: {},
          });
        }
      },
      onError: ({ message, data }) => {
        onErrorToast({
          toast: toastRef,
          message: "Gagal meghapus task",
          data: {},
        });
      },
    });
  };

  const newProcedure = () => {
    dispatch(
      setTaskListEditingData({ taskListEditingData: { ...defaultEditorData } })
    );

    // setEditorData(defaultEditorData);
    dispatch(showAddTaskListDialog());
  };

  const editProcedure = (data) => {
    let receiverId = data.data_to.id === user.id ? 0 : data.data_to.id;

    dispatch(
      setTaskListEditingData({
        taskListEditingData: {
          receiverId: receiverId,
          description: data.title,
          id: data.id,
          uniqueMarker: data.unique_marker,
        },
      })
    );
    dispatch(showAddTaskListDialog());
  };

  const checkChangedProcedure = (event, data) => {
    event.preventDefault();
    const accept = async () => {
      const finished = data.finished_at ? true : false;
      saveData({
        data,
        receiverId: data.to_id,
        description: data.title,
        checked: !finished,
      });
    };

    const reject = () => {};

    confirmDialog({
      message: data.finished_at
        ? `Batalkan penyelesaian task?`
        : `Task telah diselesaikan?`,
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const deleteProcedure = (data) => {
    const accept = async () => {
      deleteData(data.id);
    };

    const reject = () => {};

    confirmDialog({
      message: `Hapus task, lanjutkan?`,
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  useEffect(() => {
    reloadViewData();
    reloadData();
  }, []);

  const handleOnHide = () => {
    dispatch(hideTaskListDialog());
  };

  const handleNew = () => {
    newProcedure();
  };

  const handleRefresh = () => {
    reloadData();
  };

  const handleSave = (data) => {
    saveData({
      data,
      receiverId: editorData.receiverId,
      description: editorData.description,
      uniqueMarker: editorData.uniqueMarker,
    });
  };

  const customHeader = (
    <div className="row-between-center gap-2">
      <Button
        className="text-[#8D6E63] max-h-[2.4rem]"
        icon={showDetail ? "pi pi-eye" : "pi pi-eye-slash"}
        onClick={() => {
          setShowDetail(!showDetail);
        }}
        outlined
      />
      <Button
        className="text-[#8D6E63]"
        icon="pi pi-plus"
        label="Task"
        onClick={handleNew}
        outlined
      />
      <Dropdown
        value={selectedView}
        onChange={(e) =>
          dispatch(setTaskListSelectedUser({ taskListSelectedUser: e.value }))
        }
        options={listView}
        optionLabel="name"
        placeholder=""
        className="h-[2.5rem] border-[#8D6E63]"
        pt={{
          input: {
            className: "text-sm mt-[0.1rem] text-[#8D6E63] font-semibold",
          },
        }}
      />
      <Button
        className="text-[#8D6E63]"
        icon="pi pi-refresh"
        onClick={handleRefresh}
        text
      />
    </div>
  );

  return (
    <Sidebar
      className="w-full sm:w-[30rem] bg-neutral-100"
      visible={visible}
      position="right"
      onHide={handleOnHide}
      header={customHeader}
    >
      <ConfirmDialog />
      <Toast ref={toastRef} position="bottom-right" />
      <TaskListEditorDialog
        visible={showTaskListEditor}
        listReceiver={listView}
        editorData={editorData}
        onSave={handleSave}
        onHide={() => {
          // setShowTaskListEditor(false);
          dispatch(hideAddTaskListDialog());
        }}
      />
      {contents && contents.length > 0 && (
        <div className="col-start-start gap-3">
          {contents
            .filter((el) => {
              if (selectedView <= 0 && el.data_to.id === user.id) {
                return true;
              } else if (selectedView > 0 && el.data_to.id === selectedView) {
                return true;
              }
              return false;
            })
            .map((el) => (
              <TaskListSingle
                key={el.id}
                data={el}
                showDetail={showDetail}
                onCheckChanged={(event) => {
                  checkChangedProcedure(event, el);
                }}
                onDelete={() => deleteProcedure(el)}
                onEdit={() => {
                  editProcedure(el);
                }}
              />
            ))}
        </div>
      )}
      {oldContents && oldContents.length > 0 && (
        <div className="col-start-start gap-3 w-full">
          <div className="row-center-center py-2 gap-2 w-full">
            <Divider />
            <span className="font-semibold text-sm text-zinc-500 text-center min-w-[5rem]">
              Task Lalu
            </span>
            <Divider />
          </div>
          <div className="col-start-start gap-3 pb-10">
            {oldContents.map((el) => (
              <TaskListSingle
                key={el.id}
                data={el}
                showDetail={showDetail}
                onCheckChanged={(event) => {
                  checkChangedProcedure(event, el);
                }}
                onDelete={() => deleteProcedure(el)}
                onEdit={() => {
                  editProcedure(el);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </Sidebar>
  );
};

export default TaskListSidebar;

import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import TableAutoComponent from "../../../../../components/complex/crud/TableAutoComponent";
import { Paginator } from "primereact/paginator";

import configs from "./StokKeluarReturBaikConfigs";
import generateTableConfigs from "../../../../../utils/TableConfigGenerator";
import useMDArsipPageControl from "../../../../../custom_hooks/complex/useMDArsipPageControl";
import dateTextReformat from "../../../../../utils/DateTextReformat";

const customColumns = [
  {
    field: "nomor",
    component: (rowData) => {
      return (
        <div className="col-center-start">
          <span>{rowData.nomor}</span>
          <i className="font-semibold text-sm text-gray-400">
            {dateTextReformat(rowData.tanggal_transaksi)}
          </i>
        </div>
      );
    },
  },
  {
    field: "no_bpb",
    component: (rowData) => {
      return (
        <div className="col-center-start">
          <span>{rowData.no_bpb}</span>
          <i className="font-semibold text-sm text-gray-400">
            {dateTextReformat(rowData.tanggal_bpb)}
          </i>
        </div>
      );
    },
  },
  {
    field: "grand_total",
    component: (rowData) => {
      const config = configs.columns.find((e) => e.field === "payment_type");
      const optionsPayment = config && config.options ? config.options : {};
      let paymentText = "";
      if (optionsPayment) {
        const paymentTextObject = optionsPayment.find(
          (e) => e.value === rowData.payment_type
        );
        if (paymentTextObject) {
          paymentText = paymentTextObject.name;
        }
      }
      return (
        <div className="col-center-end">
          <span>
            {rowData.grand_total.toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
          </span>
          <i className="font-semibold text-sm text-gray-400">{paymentText}</i>
        </div>
      );
    },
  },
  {
    field: "outlet_id",
    component: (rowData) => {
      return (
        <div className="col-center-start">
          <span>{rowData.data_outlet.nama}</span>
          <i className="font-semibold text-sm text-gray-400">
            {rowData.data_jurusan.nama}
          </i>
        </div>
      );
    },
  },
];
const customFilters = [];

export const generateTabLabel = (row) => {
  return `Nomor: ${row.nomor}`;
};

const additionalParams = configs.master.additionalParams;
const orderBy = configs.master.orderBy;
const orderMethod = configs.master.orderMethod;
const apiName = configs.master.apiName;
const columns = generateTableConfigs(configs.columns);

const StokKeluarReturBaikArsipPage = (props) => {
  const { addNewEditor } = props;

  const {
    // UIs
    toast,
    // states
    paging,
    loading,
    listData,
    selectedRows,
    //methods
    elements,
    generateSelectorDialog,
    handleReload,
    handleOnPage,
    handleAddNew,
    handleDelete,
    handleDeleteMultiple,
    handleEdit,
    handleSelectionChange,
  } = useMDArsipPageControl({
    apiName,
    additionalParams,
    orderBy,
    orderMethod,
    addNewEditor,
    generateTabLabel,
    configFilters: configs.filters,
    customFilters,
  });

  return (
    <div className={`col-start-start p-0 gap-4 w-100`}>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div></div>
      <div className="elevated-card w-100 gap-3 row-between-center">
        <div className="row-start-center gap-3 wrap">
          {elements.tanggal_transaksi}
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-trash"
            size="small"
            severity="danger"
            onClick={handleDeleteMultiple}
            rounded
            outlined
          />
          <Button
            icon="pi pi-plus"
            size="small"
            severity="success"
            onClick={handleAddNew}
            rounded
            outlined
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={handleReload}
            rounded
            outlined
          />
        </div>
      </div>
      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            showControlButton
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={columns}
            loading={loading}
            value={listData}
            onEditRow={async (row) => handleEdit(row)}
            onDeleteRow={(row) => handleDelete(row)}
            customColumns={customColumns}
          />
          <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageChange={handleOnPage}
          />
        </div>
      </div>
    </div>
  );
};

export default StokKeluarReturBaikArsipPage;

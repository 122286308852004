import classes from "./NavBarComponent.module.css";
import { useSelector, useDispatch } from "react-redux";
import { FaBars, FaBell, FaEnvelope } from "react-icons/fa6";
import { toggleSideMenuVisibility } from "../../store/ui-slice";
import { useLocation } from "react-router-dom";
import ButtonAvatar from "../components/buttons/ButtonAvatar";
import ButtonIcon from "../components/buttons/ButtonIcon";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Dropdown } from "primereact/dropdown";

const NavBarComponent = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pathSegments = location.pathname.split("/");
  const lastPathSegment = pathSegments[pathSegments.length - 1];

  const bcHome = { icon: "pi pi-home", url: "/" };

  const handleHamburger = (e) => {
    dispatch(toggleSideMenuVisibility());
  };

  // const location = useLocation();
  // const pathSegments = location.pathname.split("/");
  // const lastPathSegment = pathSegments[pathSegments.length - 1];

  // const refineTitle = (rawTitle) => {
  //   let titleLabel = lastPathSegment.replace("-", " ");
  //   titleLabel = titleLabel.replace(/\w\S*/g, function (txt) {
  //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  //   });

  //   return titleLabel;
  // };

  // const title = refineTitle(lastPathSegment);
  const title = useSelector((state) => state.ui.layoutDashboardTitle);

  const handleDefaultAvatarClick = (e) => {
    console.log("Handle Avatar Click");
  };
  const handleAvatarClick = props.onCLickAvatar || handleDefaultAvatarClick;

  const handleDefaultNotifClick = (e) => {
    console.log("Handle Notif Click");
  };
  const handleNotifClick = props.onClickNotif || handleDefaultNotifClick;

  const handleChangeWilayah = () => {
    console.log("Change Wilayah");
  };

  const handleChangeDepot = () => {
    console.log("Change Depot");
  };

  return (
    <nav
      className={`${classes.wrapper} row-between-center ps-3 pe-3 w-100 ${
        props.className ?? ""
      } fixed`}
    >
      <div className={`row-start-center gap-3`}>
        <div
          className={`${classes.hamburgerButton} row-center-center`}
          onClick={handleHamburger}
        >
          <FaBars />
        </div>
        {lastPathSegment ? (
          <div className={`${classes.title} row-start-center`}>{title}</div>
        ) : (
          <BreadCrumb
            className="border-0 pl-1"
            model={[
              { label: "Wilayah 2", command: handleChangeWilayah },
              { label: "Depot 201", command: handleChangeDepot },
            ]}
            home={bcHome}
          />
        )}
      </div>
      <div className="row-end-center gap-5 hidden sm:block">
        {props.showNotif && (
          <Button
            className=""
            label="Messages"
            icon="pi pi-envelope"
            outlined
            badge="24"
            badgeClassName="p-badge-danger"
            style={{ height: "2.2rem" }}
          />
        )}
        <ButtonAvatar onClick={handleAvatarClick} src={props.avatarSrc} />
      </div>
    </nav>
  );
};

export default NavBarComponent;

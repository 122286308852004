import { Skeleton } from "primereact/skeleton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  FaBuilding,
  FaMotorcycle,
  FaTruck,
  FaUserLarge,
  FaUsers,
  FaUserTie,
} from "react-icons/fa6";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";

const BadgeInfoDepot = (props) => {
  const { title } = props;
  const { requestGet, loading } = useAPIRequest();
  const navigate = useNavigate();

  const [personil, setPersonil] = useState({
    icon: <FaUsers />,
    value: null,
    prompt: "Personil",
    iconSize: "32",
  });
  const [admin, setAdmin] = useState({
    icon: <FaUserLarge />,
    value: null,
    prompt: "Admin",
    iconSize: "30",
  });
  const [salesman, setSalesman] = useState({
    icon: <FaUserTie />,
    value: null,
    prompt: "Salesman",
    iconSize: "28",
  });
  const [mobil, setMobil] = useState({
    icon: <FaTruck />,
    value: null,
    prompt: "Mobil",
    iconSize: "34",
  });
  const [sm, setSM] = useState({
    icon: <FaMotorcycle />,
    value: null,
    prompt: "S.M.",
    iconSize: "34",
  });

  // const handleClickPersonil = () => {};
  const handleClickSalesman = () => {
    navigate("/detail/list-salesman");
  };
  const handleClickMobil = () => {
    navigate("/detail/list-transport");
  };
  const handleClickSepedaMotor = () => {};

  const contentClick = [
    null, // handleClickPersonil,
    null,
    handleClickSalesman,
    handleClickMobil,
    handleClickSepedaMotor,
  ];

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  useEffect(() => {
    reloadData();
  }, []);

  const generateFilter = () => {
    let filter = ``;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `depot_id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `wilayah_id:=:${wilayah.id}`;
    }

    // console.log("FILTER", filter);

    return filter;
  };

  const reloadData = async () => {
    const filter = generateFilter();

    return await requestGet({
      fullUrl: "api/dashboard/kawil",
      params: {
        prefix: "info",
        filter,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          const {
            total_staff,
            total_sales,
            total_personil,
            total_mobil,
            total_sepeda_motor,
          } = data;

          setPersonil({ ...personil, value: total_personil ?? 0 });
          setAdmin({ ...admin, value: total_staff ?? 0 });
          setSalesman({ ...salesman, value: total_sales ?? 0 });
          setMobil({ ...mobil, value: total_mobil ?? 0 });
          setSM({ ...sm, value: total_sepeda_motor ?? 0 });
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const generateContent = () => {
    const content = [];
    const contentElement = [personil, admin, salesman, mobil, sm];
    for (let i = 0; i < contentElement.length; i++) {
      const el = contentElement[i];
      content.push(
        generateElement({
          key: i + 1,
          icon: el.icon,
          value: el.value,
          prompt: el.prompt,
          iconSize: el.iconSize,
          onClick: contentClick[i],
        })
      );
    }
    return content;
  };

  const generateElement = ({ key, icon, value, prompt, iconSize, onClick }) => {
    const valueComponent =
      value || value === 0 ? (
        value.toLocaleString("id")
      ) : (
        <Skeleton width="3rem" height="1.7rem" />
      );
    return (
      <div
        key={key}
        className={`col-end-center px-2 rounded ${
          onClick ? " cursor-pointer active:bg-blue-100 hover:bg-blue-50" : ""
        }`}
        onClick={onClick}
      >
        {React.cloneElement(icon, {
          className: "text-zinc-500 h-10",
          size: iconSize ?? "22",
        })}
        <div className="col-center-center gap-1 pt-3">
          <span className="text-zinc-700 text-lg leading-4 font-semibold">
            {valueComponent}
          </span>
          <span className="text-zinc-700 text-sm ">{prompt}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="col-start-start grow-1 gap-4 w-full">
      <span className="text-sm font-semibold text-zinc-700 ">{title}</span>
      <div className="row-around-center w-full flex-wrap gap-2">
        {generateContent()}
      </div>
    </div>
  );
};

export default BadgeInfoDepot;

import { Divider } from "primereact/divider";
import { Image } from "primereact/image";
import { Knob } from "primereact/knob";
import { SelectButton } from "primereact/selectbutton";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import generateFilterConfigs from "../../../../utils/FilterConfigGenerator";
import generateTableConfigs from "../../../../utils/TableConfigGenerator";
import JurusanPerformanceTable from "../../home/component-home/JurusanPerformanceTable";
import OutletPerformanceTable from "../../home/component-home/OutletPerformanceTable";
import ReturBaikTahunBerjalan from "../../home/component-home/ReturBaikTahunBerjalan";
import ReturUsangTahunBerjalan from "../../home/component-home/ReturUsangTahunBerjalan";
import SalesBulanBerjalan from "../../home/component-home/SalesBulanBerjalan";
import TargetBulanBerjalan from "../../home/component-home/TargetBulanBerjalan";
import configs from "./DetailSalesmanConfigs";

const title = "D&P Bulan Berjalan Depot 201";
const apiName = "api/report";
const reportProcedureName = "summary-sisa-stok";
const additionalParams = {};
const defaultHeaderValue = { tanggal_transaksi: [new Date(), new Date()] };
const defaultTake = 20;
const showPagination = true;

const headerConfigs = configs.headers;
const tableConfigs = generateTableConfigs(configs.columns);
const filterConfigs = generateFilterConfigs(configs.columns);

const filterDetailOptions = ["Jurusan", "Outlet"];

const getReturColor = (value) => {
  if (value < 25) {
    // return "text-green-500";
    return "rgb(34 197 94)";
  } else if (value >= 25 && value < 50) {
    // return "text-yellow-500";
    return "rgb(234 179 8)";
  } else if (value >= 50 && value < 75) {
    // return "text-orange-500";
    return "rgb(249 115 22)";
  } else {
    // return "text-red-500";
    return "rgb(239 68 68)";
  }
};

const getReturClassColor = (value) => {
  if (value < 25) {
    return "text-green-500";
  } else if (value >= 25 && value < 50) {
    return "text-yellow-500";
  } else if (value >= 50 && value < 75) {
    return "text-orange-500";
  } else {
    return "text-red-500";
  }
};

const getSalesColor = (value) => {
  if (value < 25) {
    // return "text-red-500";
    return "rgb(239 68 68)";
  } else if (value >= 25 && value < 50) {
    // return "text-orange-500";
    return "rgb(249 115 22)";
  } else if (value >= 50 && value < 75) {
    // return "text-yellow-500";
    return "rgb(234 179 8)";
  } else {
    // return "text-green-500";
    return "rgb(34 197 94)";
  }
};

const getSalesClassColor = (value) => {
  if (value < 25) {
    return "text-red-500";
  } else if (value >= 25 && value < 50) {
    return "text-orange-500";
  } else if (value >= 50 && value < 75) {
    return "text-yellow-500";
  } else {
    return "text-green-500";
  }
};

// const generateRandomNumber = (min) => {
//   return Math.floor(Math.random() * (min - 100)) + Math.ceil(min / 10 - 1);
// };

const DetailSalesman = () => {
  let { id } = useParams();
  const [dpGlobal, setDpGlobal] = useState({ nilai: 0, pembanding: 0 });
  const [targetGlobal, setTargetGlobal] = useState({ nilai: 0, pembanding: 0 });
  const [returBaikGlobal, setReturBaikGlobal] = useState({
    nilai: 0,
    pembanding: 0,
  });
  const [returUsangGlobal, setReturUsangGlobal] = useState({
    nilai: 0,
    pembanding: 0,
  });
  const [filterDetail, setFilterDetail] = useState(filterDetailOptions[0]);
  const { requestGet, loading } = useAPIRequest();

  const [salesman, setSalesman] = useState({});
  const [jurusan, setJurusan] = useState({});
  const [outletAktif, setOutletAktif] = useState({});
  const [bon, setBon] = useState({});
  const [dp, setDp] = useState({});
  const [returBaik, setReturBaik] = useState({});
  const [returUsang, setReturUsang] = useState({});

  const getDataLabel = (title, content) => {
    return (
      <div className="col-start-start gap-1">
        <span className="font-bold text-sm text-zinc-400">{title}</span>
        <span>{content}</span>
      </div>
    );
  };

  useEffect(() => {
    reloadData();
  }, []);

  const generateFilter = () => {
    let filter = `sales_id:=:${id}`;

    return filter;
  };

  const getTanggalLahir = (date) => {
    return new Date(date).toLocaleDateString("id-ID", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const getUmur = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getMulaiKerja = (date) => {
    return new Date(date).toLocaleDateString("id-ID", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const getLamaKerja = (date) => {
    const today = new Date();
    const joinDate = new Date(date);
    let age = today.getFullYear() - joinDate.getFullYear();
    const m = today.getMonth() - joinDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < joinDate.getDate())) {
      age--;
    }
    return age;
  };

  const reloadData = async () => {
    const filter = generateFilter();

    // setValue(0);
    // setTotalTarget(0);
    // setTotalRealisasi(0);
    // setSubArcData([]);
    return await requestGet({
      fullUrl: "api/dashboard/kadepot",
      params: {
        prefix: "sales_detils",
        filter,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          setSalesman(data.sales);
          setJurusan({
            jurusan: data.total_jurusans,
            outlet: data.outlet_aktif.total_outlets,
          });
          setOutletAktif({
            aktif: data.outlet_aktif.total_outlets,
            total: data.outlet_aktif.outlet_aktif,
          });
          // setBon({
          //   bulan_lalu: data.bon.bulan_lalu,
          //   bulan_ini: data.bon.bulan_ini,
          // });
          setDp({
            bulan_lalu: data.total_dp_berjalan.bulan_lalu,
            bulan_ini: data.total_dp_berjalan.bulan_ini,
          });
          setReturBaik({
            tahun_lalu: data.total_retur_baik.tahun_lalu,
            tahun_ini: data.total_retur_baik.tahun_ini,
          });
          setReturUsang({
            tahun_lalu: data.total_retur_usang.tahun_lalu,
            tahun_ini: data.total_retur_usang.tahun_ini,
          });
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  // useEffect(() => {
  //   setDpGlobal({
  //     nilai: generateRandomNumber(100000),
  //     pembanding: generateRandomNumber(100000),
  //   });
  //   setTargetGlobal({
  //     nilai: generateRandomNumber(100000),
  //     pembanding: generateRandomNumber(100000),
  //   });
  //   setReturBaikGlobal({
  //     nilai: generateRandomNumber(100000),
  //     pembanding: generateRandomNumber(100000),
  //   });
  //   setReturUsangGlobal({
  //     nilai: generateRandomNumber(100000),
  //     pembanding: generateRandomNumber(100000),
  //   });
  // }, []);

  const getDataComponent = ({ title, datas }) => {
    const components = [];
    let key = 1;
    for (let data of datas) {
      const { value, label } = data;
      if (components.length >= 1) {
        components.push(
          <Divider key={key++} layout="vertical" className="h-[2.6rem]" />
        );
      }
      components.push(
        <div key={key++} className="col-center-center">
          <span className="text-xl font-semibold text-zinc-600">{value}</span>
          <span className="text-sm text-zinc-600 text-center">{label}</span>
        </div>
      );
    }
    return (
      <div className="elevated-card-tight-vertical col-start-start w-full gap-3 min-h-[8rem]">
        <span className="text-sm font-semibold text-zinc-700">{title}</span>
        <div className="row-center-center w-full gap-1">{components}</div>
      </div>
    );
  };

  const getComponentGlobalWidget = ({
    title,
    nilai,
    nilai_prefix,
    pembanding,
    getColor,
    getClassColor,
  }) => {
    let percent = 0;
    let hidden = false;
    if (nilai === 0 && pembanding === 0) {
      hidden = true;
      percent = 100;
    } else if (nilai !== 0 && pembanding === 0) {
      percent = 100;
    } else {
      percent = (nilai / pembanding) * 100.0;
    }
    return (
      <div className="elevated-card-tight-vertical col-start-center w-full min-h-[13rem] h-[13rem]">
        <span className="text-sm font-semibold text-zinc-700 pb-3">
          {title}
        </span>
        {!hidden && (
          <Knob
            value={percent > 100 ? 100 : percent.toFixed(0)}
            valueTemplate={`${percent.toFixed(0)}%`}
            valueColor={getColor(percent)}
          />
        )}
        <div className="row-center-center gap-2 font-semibold h-full">
          <span className={`${getClassColor(percent)}`}>
            {nilai.toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}{" "}
            {nilai_prefix}
          </span>
          /
          <span className="font-semibold text-zinc-400">
            {pembanding.toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}{" "}
            {nilai_prefix}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      {/* <Toast ref={toast} /> */}
      <div></div>
      <div className="elevated-card-tight-vertical w-full">
        <span className="text-lg font-semibold text-zinc-700">
          {salesman && salesman.name ? salesman.name : "-"}
        </span>
        <div className="row-start-center gap-3">
          <span className="text-sm font-semibold text-zinc-700">
            {salesman && salesman.title_label ? salesman.title_label : "-"}
          </span>
          <span className="text-sm font-semibold text-zinc-400">
            {`( ${salesman && salesman.nik ? salesman.nik : "-"} )`}
          </span>
        </div>
      </div>
      <div className="elevated-card row-start-start w-full gap-12">
        <div className="col-center-center">
          <Image
            src={`${salesman && salesman.image ? salesman.image : ""}`}
            width="160"
          />
        </div>
        <div className="col-start-start gap-6">
          {getDataLabel(
            "Nama Lengkap",
            salesman && salesman.name ? salesman.name : "-"
          )}
          {getDataLabel(
            "Telepon",
            salesman && salesman.phone ? salesman.phone : "-"
          )}
          {getDataLabel(
            "Agama",
            salesman && salesman.agama ? salesman.agama : "-"
          )}
        </div>
        <div className="col-start-start gap-6">
          {getDataLabel(
            "Ulang Tahun",
            salesman && salesman.tanggal_lahir
              ? getTanggalLahir(salesman.tanggal_lahir)
              : "-"
          )}
          {getDataLabel(
            "Umur",
            salesman && salesman.tanggal_lahir
              ? getUmur(salesman.tanggal_lahir)
              : "-"
          )}
          {getDataLabel(
            "Pendidikan",
            salesman && salesman.pendidikan ? salesman.pendidikan : "-"
          )}
        </div>
        <div className="col-start-start gap-6">
          {getDataLabel(
            "Alamat",
            salesman && salesman.alamat ? salesman.alamat : "-"
          )}
          {getDataLabel(
            "Mulai Kerja",
            salesman && salesman.masuk_kerja
              ? getMulaiKerja(salesman.masuk_kerja)
              : "-"
          )}
          {getDataLabel(
            "Lama Kerja",
            salesman && salesman.masuk_kerja
              ? getLamaKerja(salesman.masuk_kerja)
              : "-"
          )}
        </div>
      </div>
      <div className="row-start-start gap-4 w-full">
        <div className="col-start-start gap-4 w-full">
          <div className="row-start-start w-full gap-4">
            {getDataComponent({
              title: "Jurusan & Outlet",
              datas: [
                { value: jurusan.jurusan, label: "Jurusan" },
                { value: jurusan.outlet, label: "Outlet" },
              ],
            })}
            {getDataComponent({
              title: "Outlet Aktif",
              datas: [
                { value: outletAktif.aktif, label: "Aktif" },
                { value: outletAktif.total, label: "Total" },
              ],
            })}
            {getDataComponent({
              title: "Bon",
              datas: [
                { value: 255, label: "Bln Lalu" },
                { value: 300, label: "Bln Ini" },
              ],
            })}
          </div>
          <div className="row-start-start w-full gap-4 ">
            {getComponentGlobalWidget({
              title: "D&P vs Bulan Lalu",
              nilai: dp.bulan_ini / 1000,
              pembanding: dp.bulan_lalu / 1000,
              nilai_prefix: "K",
              getColor: getSalesColor,
              getClassColor: getSalesClassColor,
            })}
            {getComponentGlobalWidget({
              title: "Retur Baik vs Tahun Lalu",
              nilai: returBaik.tahun_ini / 100.0,
              pembanding: returBaik.tahun_lalu / 100.0,
              nilai_prefix: "Tin",
              getColor: getSalesColor,
              getClassColor: getSalesClassColor,
            })}
            {getComponentGlobalWidget({
              title: "Retur Usang vs Lalu",
              nilai: returUsang.tahun_ini / 100.0,
              pembanding: returUsang.tahun_lalu / 100.0,
              nilai_prefix: "Tin",
              getColor: getReturColor,
              getClassColor: getReturClassColor,
            })}
          </div>
        </div>
        <div className="elevated-card col-start-start sm:h-[24rem]">
          <TargetBulanBerjalan sales_id={id} title={"D&P Vs Target"} />
        </div>
      </div>

      <div className="row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <div className="elevated-card col-start-start grow-1">
          <SalesBulanBerjalan
            title={" D&P Bulan Berjalan"}
            aspectRatio={0.8}
            sales_id={id}
          />
        </div>
      </div>

      <div className="row-start-start w-full gap-4 flex-wrap md:flex-nowrap">
        <div className="elevated-card row-start-start w-full">
          <ReturBaikTahunBerjalan
            title={"Retur Baik Tahun Berjalan"}
            aspectRatio={0.8}
            sales_id={id}
          />
        </div>
        <div className="elevated-card row-start-start w-full">
          <ReturUsangTahunBerjalan
            title={"Retur Usang Berjalan"}
            aspectRatio={0.8}
            sales_id={id}
          />
        </div>
      </div>

      <div className="elevated-card-tight-vertical row-start-start w-full ">
        <div className="row-between-center w-full flex-wrap gap-2 ">
          <span className="text-sm font-semibold text-zinc-700">
            Jurusan Dan Outlet Yang Ditangani
          </span>
          <div className="row-end-center">
            <SelectButton
              value={filterDetail}
              onChange={(e) => setFilterDetail(e.value)}
              options={filterDetailOptions}
              onClick={(e) => e.preventDefault()}
            />
          </div>
        </div>{" "}
      </div>
      {filterDetail === "Jurusan" && (
        <div className="row-between-start wrap w-full gap-4">
          <JurusanPerformanceTable title={"List Jurusan"} sales_id={id} />
        </div>
      )}
      {filterDetail === "Outlet" && (
        <div className="row-between-start wrap w-full gap-4">
          <OutletPerformanceTable title={"List Outlet"} sales_id={id} />
        </div>
      )}

      {/* elevated-card-no-padding elevated-card-tight-vertical */}
      <div></div>
    </div>
  );
};

export default DetailSalesman;

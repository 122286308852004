const assignDataToMap = ({ key, map, value }) => {
  let newMap = map;
  if (key.includes(".")) {
    const keyArr = key.split(".");
    for (let i = 0; i < keyArr.length; i++) {
      const key = keyArr[i];
      if (i === keyArr.length - 1) {
        newMap[key] = value;
        return;
      }
      if (!newMap[key] && i < keyArr.length - 1) {
        newMap[key] = {};
      }
      newMap = newMap[key];
    }
  } else {
    newMap[key] = value;
  }
};
export default assignDataToMap;

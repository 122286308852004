import { Chart } from "primereact/chart";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LinkTitleComponent from "../../../../components/sales/LinkTitleComponent";
import RadioButtonGroup from "../../../../components/sales/RadioButtonGroup";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import generateTrendingLine, {
  getTrendingLineClassColor,
} from "../../../../utils/GenerateTrandingLine";

const arrMonthName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];

const SalesBulanBerjalan = (props) => {
  const inputWilayah = props.wilayah ?? null;

  const jenisPeriode = [
    {
      id: "bulanan",
      value: "bulanan",
      checked: false,
      label: "Bulanan",
    },
    {
      id: "tahunan",
      value: "tahunan",
      checked: true,
      label: "Tahunan",
    },
  ];
  const [selectedJenisPeriode, setSelectedJenisPeriode] = useState(
    jenisPeriode[0].value
  );

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  const { requestGet, loading } = useAPIRequest();

  const { aspectRatio, title, sales_id } = props;
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  const [salesChartData, setSalesChartData] = useState(null);
  const [salesChartOption, steSalesChartOption] = useState(null);

  useEffect(() => {
    steSalesChartOption({
      maintainAspectRatio: false,
      aspectRatio: aspectRatio ?? 0.7,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
            callback: (value) => {
              if (valueType === "harga") {
                let newValue = value / 1000000.0;
                return `Rp ${newValue.toLocaleString("id-ID")} Jt`;
              } else if (valueType === "quantity") {
                let newValue = value / 100.0;
                return `${newValue.toLocaleString("id-ID")} Tin`;
              }
              return value.toLocaleString("id-ID");
            },
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    });
    reloadData({ jenisPeriode: selectedJenisPeriode });
  }, [valueType]);

  // useEffect(() => {
  //   reloadData({ jenisPeriode: selectedJenisPeriode });
  // }, []);

  const generateFilter = ({ jenisPeriode, month, year }) => {
    let filter = "";

    switch (jenisPeriode) {
      case "bulanan":
        filter += `bulan:=:${month};tahun:=:${new Date().getFullYear()}`;
        break;
      case "tahunan":
        filter += `${filter.length === 0 ? "" : ";"}tahun:=:${year}`;
        break;
    }

    if (!sales_id) {
      let depot = null;
      let wilayah = null;
      switch (user.title) {
        case "kadepot":
          depot = user.data_depot;
          wilayah = user.data_wilayah;
          break;
        case "kawil":
          wilayah = user.data_wilayah;
          depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
          break;
        case "controller":
          if (inputWilayah) {
            wilayah = inputWilayah;
          } else {
            wilayah =
              selectedWilayah && selectedWilayah.id > 0
                ? selectedWilayah
                : null;
          }
          depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
          break;
      }
      if (depot) {
        filter += `;data_stok_keluar.data_depot.id:=:${depot.id}`;
      } else if (wilayah) {
        filter += `;data_stok_keluar.data_depot.wilayah_id:=:${wilayah.id}`;
      }
    }

    // console.log("FILTER SALES BULAN BERJALAN = ", filter);

    return filter;
  };

  const reloadNewData = async ({ jenisPeriode }) => {
    const today = new Date();

    const filter = generateFilter({
      jenisPeriode,
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    });

    return requestGet({
      fullUrl: "api/dashboard/kawil",
      params: {
        prefix: "dp_berjalan",
        method: valueType,
        filter,
      },
      onSuccess: ({ pagination, message, data }) => {
        // setNewSalesData(data);
      },
      onError: ({ message, data }) => {},
    });
  };

  const reloadOldData = async ({ jenisPeriode }) => {
    const today = new Date();

    const filter = generateFilter({
      jenisPeriode,
      month: today.getMonth(),
      year: today.getFullYear() - 1,
    });

    return requestGet({
      fullUrl: "api/dashboard/kawil",
      params: { prefix: "dp_berjalan", method: valueType, filter },
      onSuccess: ({ pagination, message, data }) => {
        // setOldSalesData(data);
      },
      onError: ({ message, data }) => {},
    });
  };

  const reloadDataBySales = async ({ jenisPeriode }) => {
    setSalesChartData(null);
    const today = new Date();

    let filter = generateFilter({
      jenisPeriode,
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    });
    filter += `;sales_id:=:${sales_id}`;

    // console.log("FILTER SALES BULAN BERJALAN (SALES) = ", filter);

    return requestGet({
      fullUrl: "api/dashboard/kadepot",
      params: { prefix: "sales_dp", filter },
      onSuccess: ({ pagination, message, data }) => {},
      onError: ({ message, data }) => {},
    });
  };

  const reloadData = async ({ jenisPeriode }) => {
    setSalesChartData(null);
    let newData = [];
    let oldData = [];

    if (sales_id) {
      const salesData = await reloadDataBySales({ jenisPeriode });
      // console.log("JENIS PERIODE = ", jenisPeriode);
      if (jenisPeriode === "tahunan") {
        newData = salesData.currentYear;
        oldData = salesData.lastYear;
      } else if (jenisPeriode === "bulanan") {
        newData = salesData.currentMonth;
        oldData = salesData.lastMonth;
      }
    } else {
      newData = await reloadNewData({ jenisPeriode });
      oldData = await reloadOldData({ jenisPeriode });
    }

    if (newData !== null && oldData !== null) {
      const arrLabel = [];
      const arrNew = [];
      const arrOld = [];
      const keyNews = Object.keys(newData);
      const keyOlds = Object.keys(oldData);
      const keys = keyNews.length > keyOlds.length ? keyNews : keyOlds;
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        arrLabel.push(key);

        if (valueType === "harga") {
          arrNew.push(newData[key] ?? 0);
          arrOld.push(oldData[key] ?? 0);
        } else if (valueType === "quantity") {
          arrNew.push(newData[key] ?? 0);
          arrOld.push(oldData[key] ?? 0);
        }
      }

      let labelNew = "";
      let labelOld = "";
      switch (jenisPeriode) {
        case "bulanan":
          const thisMonth = new Date();
          const lastMonth = new Date(
            thisMonth.getTime() - 30 * 24 * 60 * 60 * 1000
          );
          labelNew = `Bulan ini (${thisMonth.toLocaleString("id-ID", {
            month: "long",
          })} ${thisMonth.getFullYear()})`;
          labelOld = `Bulan lalu (${lastMonth.toLocaleString("id-ID", {
            month: "long",
          })} ${lastMonth.getFullYear()})`;
          break;
        case "tahunan":
          const thisYear = new Date();
          const lastYear = new Date(
            thisYear.getTime() - 365 * 24 * 60 * 60 * 1000
          );
          labelNew = `Tahun ini (${thisYear.getFullYear()})`;
          labelOld = `Tahun lalu (${lastYear.getFullYear()})`;
          break;
      }

      const arrTrendingLine = generateTrendingLine({
        arrX: keys.map((e) => parseInt(e)),
        arrY: arrNew,
      });

      setSalesChartData({
        labels:
          jenisPeriode === "tahunan"
            ? arrMonthName
            : arrLabel.map((e) => "" + e),
        datasets: [
          {
            label: labelNew,
            data: arrNew,
            tension: 0.3,
            borderColor: documentStyle.getPropertyValue("--blue-400"),
            backgroundColor: "rgba(96,165,250,0.2)",
          },
          {
            type: "bar",
            label: labelOld,
            data: arrOld,
            fill: false,
            tension: 0.3,
            borderColor: documentStyle.getPropertyValue("--blue-400"),
            backgroundColor: "rgba(63,63,70,0.18)",
          },
          {
            label: "Trending Line",
            data: arrTrendingLine,
            tension: 0.3,
            borderColor: documentStyle.getPropertyValue(
              getTrendingLineClassColor(arrTrendingLine)
            ),
            backgroundColor: "rgba(96,165,250,0.2)",
          },
        ],
      });
    }
  };

  return (
    <div className="col-start-start w-full gap-3">
      <div className="row-between-center w-full">
        <LinkTitleComponent
          title={title}
          href={"/detail/dp-bulan-berjalan"}
          suffixComponent={
            <RadioButtonGroup
              items={jenisPeriode}
              value={selectedJenisPeriode}
              name={"jenisPeriode"}
              onChange={(val) => {
                setSelectedJenisPeriode(val);
                reloadData({ jenisPeriode: val });
              }}
            />
          }
        />
      </div>
      {salesChartData ? (
        <Chart
          className={`w-full`}
          type="line"
          data={salesChartData}
          options={salesChartOption}
        />
      ) : (
        <div className="col-start-start w-full gap-3">
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
          <Skeleton className="w-full" height="2rem"></Skeleton>
        </div>
      )}
    </div>
  );
};

export default SalesBulanBerjalan;

import { subDays, addDays } from "date-fns";

const JadwalConfigs = {
  master: {
    title: "Jadwal",
    apiName: "masterdata/jadwal",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: { 
      // add_params
      with_depot: 1,
      with_sales: 1,
      with_transport: 1,
      with_supir: 1,
      with_jurusans: 1,
    },
  },
  dialog: {
    title: "Jadwal",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Depot",
      field: "depot_id",
      type: "object",
      object_name: "data_depot",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Sales",
      field: "sales_id",
      type: "object",
      object_name: "data_sales",
      object_field: "name",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Tanggal",
      field: "tanggal",
      type: "date",
      align: "center",
      table: true,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
      object_name: "",
      object_field: "",
    },
    {
      name: "Keterangan",
      field: "keterangan",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Transport",
      field: "transport_id",
      type: "object",
      object_name: "data_transport",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Supir",
      field: "supir_id",
      type: "object",
      object_name: "data_supir",
      object_field: "name",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Utai",
      field: "utai",
      align: "center",
      table: true,
      editor: true,
      type: "boolean_plain",
      filter: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Jurusan",
      field: "data_jurusans",
      type: "list_object",
      columns: [
        { name: "Nama", field: "nama", type: "text_plain", editable: false },
      ],
      width: "15rem",
      editor: true,
    },
  ],
};

export default JadwalConfigs;

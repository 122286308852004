import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format as dateFnsFormat, format, parse } from "date-fns";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import {
  setTaskListEditingData,
  showAddTaskListDialog,
  showTaskListDialog,
} from "../../../../store/ui-slice";

const DialogDetailKodeProduksiWilayah = (props) => {
  const { visible, onHide, title, data } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);
  const taskListUser = useSelector((state) => state.ui.taskListUsers);

  const [datasKodeProduksi, setDatasKodeProduksi] = useState([]);
  const [datasPenjualTerbaik, setDatasPenjualTerbaik] = useState([]);
  const { requestGet, loading } = useAPIRequest();

  useEffect(() => {
    if (visible) {
      reloadData();
    }
  }, [visible]);

  const generateFilterPersebaranStok = () => {
    const unformattedExpired = data.kode_produksi;
    const [date, month, year] = unformattedExpired.split("/");
    const expired = `${year}-${month}-${date}`;

    let filter = ``;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `depot_id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `wilayah_id:=:${wilayah.id}`;
    }

    filter += `;tanggal_produksi:=:${expired}`;

    return filter;
  };

  const reloadDataPersebaranStok = async () => {
    const filter = generateFilterPersebaranStok();
    setDatasKodeProduksi(null);

    return await requestGet({
      fullUrl: "api/dashboard/kawil",
      params: {
        prefix: "persebaran_stok",
        filter,
        page: 1,
        take: 400,
        order: "tanggal_produksi",
        order_method: "ASC",
        with_produk: 1,
        with_gudang: 1,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          const newListData = [];
          for (let i = 0; i < data.length; i++) {
            const d = data[i];
            const tanggalProduksi = parse(
              d.tanggal_produksi,
              "yyyy-MM-dd",
              new Date()
            );
            newListData.push({
              gudang_id: d.gudang_id,
              id: i + 1,
              no: i + 1,
              depot: d.data_gudang.nama,
              quantity: d.total / 100,
              satuan: "Tin",
              konversi: 100,
              kode_produksi: dateFnsFormat(tanggalProduksi, "dd/MM/yyyy"),
            });
          }
          setDatasKodeProduksi(newListData);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const generateFilterPrestasi = () => {
    let filter = ``;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `depot_id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `wilayah_id:=:${wilayah.id}`;
    }

    filter += `;produk_id:=:${data.produk_id}`;

    return filter;
  };

  const reloadDataPrestasi = async () => {
    const filter = generateFilterPrestasi();
    setDatasPenjualTerbaik(null);

    return await requestGet({
      fullUrl: "api/dashboard/kawil",
      params: {
        prefix: "prestasi_depot_penjualan_produk",
        filter,
        page: 1,
        take: 20,
        order: "total",
        order_method: "DESC",
        // with_produk: 1,
        with_gudang: 1,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          const newListData = [];
          for (let i = 0; i < data.length; i++) {
            const { gudang_id, data_gudang, data_produk, total } = data[i];
            newListData.push({
              id: i + 1,
              no: i + 1,
              depot: data_gudang.nama,
              quantity: total / 100,
              satuan: "Tin",
              konversi: 100,
            });
          }
          setDatasPenjualTerbaik(newListData);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const reloadData = async () => {
    reloadDataPersebaranStok();
    reloadDataPrestasi();
  };

  const getDepotByGudangId = async (gudangId) => {
    return await requestGet({
      fullUrl: "api/masterdata/depot/data",
      params: {
        filter: `gudang_id:=:${gudangId}`,
        page: 1,
        take: 1,
        order: "id",
        order_method: "ASC",
      },
      onSuccess: ({ pagination, message, data }) => {},
      onError: ({ message, data }) => {},
    });
  };

  const createTask = async (row) => {
    const extractedDepot = await getDepotByGudangId(row.gudang_id);
    if (extractedDepot && extractedDepot.length >= 1) {
      const depot = extractedDepot[0];

      for (const user of taskListUser) {
        if (user.depot_id) {
          if (depot.id === user.depot_id) {
            const uniqueMarker = `${user.id}#${data.produk_id}#${row.kode_produksi}`;
            dispatch(
              setTaskListEditingData({
                taskListEditingData: {
                  receiverId: user.id,
                  description: "",
                  uniqueMarker: uniqueMarker,
                },
              })
            );
          }
        }
      }

      // dispatch(
      //   setTaskListEditingData({
      //     taskListEditingData: {
      //       receiverId: 0,
      //       description: "",
      //     },
      //   })
      // );

      dispatch(showTaskListDialog());
      dispatch(showAddTaskListDialog());
    }
  };

  const handleCreateTask = (row) => {
    createTask(row);
  };

  const quantityBodyTemplate = (rowData) => {
    if (rowData.quantity) {
      return rowData.quantity.toLocaleString("id-ID", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      });
    }
    return 0;
  };

  const createTaskBodyTemplate = (rowData) => {
    return (
      <Button
        type="button"
        icon="pi pi-angle-double-right"
        className="p-button-sm p-button-text"
        size="small"
        label="Create Task"
        loading={loading}
        onClick={(e) => {
          e.preventDefault();
          handleCreateTask(rowData);
        }}
        style={{ width: "32px !important", height: "32px !important" }}
      />
    );
  };

  return (
    <Dialog visible={visible} header={title} onHide={onHide}>
      <div className="col-start-start w-full gap-8">
        <div className="col-start-start w-full gap-2">
          <span className="text-sm font-semibold text-zinc-700">
            Persebaran Stok :
          </span>
          {datasKodeProduksi !== null ? (
            <DataTable
              className="w-full"
              showGridlines
              value={datasKodeProduksi}
              resizableColumns
              scrollable
              scrollHeight="20.5rem"
            >
              <Column field="no" header="#" />
              <Column field="depot" header="Depot" />
              <Column
                field="kode_produksi"
                header="Kode Produksi"
                alignHeader={"center"}
                style={{ textAlign: "center" }}
              />
              <Column
                field="quantity"
                header="Qty"
                body={quantityBodyTemplate}
                alignHeader={"right"}
                style={{ textAlign: "right" }}
              />
              <Column field="satuan" header="UOM" />
              <Column field="" header="" body={createTaskBodyTemplate} />
            </DataTable>
          ) : (
            <div className="col-start-start w-full gap-3">
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
            </div>
          )}
        </div>
        <div className="col-start-start w-full gap-2">
          <span className="text-sm font-semibold text-zinc-700">
            Prestasi Depot Untuk Penjualan Produk Ini :
          </span>
          {datasPenjualTerbaik !== null ? (
            <DataTable
              className="w-full"
              value={datasPenjualTerbaik}
              resizableColumns
              stripedRows
              showGridlines
              scrollable
              scrollHeight="18.4rem"
            >
              <Column field="no" header="#" />
              <Column field="depot" header="Depot" />
              <Column
                field="quantity"
                header="Qty"
                body={quantityBodyTemplate}
                alignHeader={"right"}
                style={{ textAlign: "right" }}
              />
              <Column field="satuan" header="UOM" />
            </DataTable>
          ) : (
            <div className="col-start-start w-full gap-3">
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
              <Skeleton className="w-full" height="1.5rem"></Skeleton>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default DialogDetailKodeProduksiWilayah;

import { format } from "date-fns";

const DetailListSalesConfigs = {
  master: {
    title: "Bon Penjualan",
    apiName: "transaksi/stokkeluar",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: {
      // // add_params
      with_depot: 1,
      with_sales: 1,
      with_jurusan: 1,
      with_outlet: 1,
      filter: `tanggal_transaksi:=:${format(
        new Date(),
        "yyyy-MM-dd"
      )};jenis_keluar:=:stok`,
    },
  },
  dialog: {
    title: "Transport",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "No",
      field: "no",
      type: "text_plain",
      table: true,
    },
    {
      name: "Nomor",
      field: "nomor",
      type: "text_plain",
      align: "center",
      table: true,
      filter: true,
      editor: true,
    },
    // {
    //   name: "Bon Fisik",
    //   field: "no_bpb",
    //   type: "text_plain",
    //   table: true,
    //   filter: true,
    //   editor: true,
    // },
    {
      name: "Depot",
      field: "data_depot.nama",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Salesman",
      field: "sales_id",
      type: "object",
      object_name: "data_sales",
      object_field: "name",
      table: true,
      filter: true,
    },
    // {
    //   name: "Jurusan",
    //   field: "data_jurusan.nama",
    //   type: "text_plain",
    //   table: true,
    //   filter: true,
    //   editor: true,
    // },
    {
      name: "Outlet / Jurusan",
      field: "outlet_id",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Total",
      field: "grand_total",
      align: "right",
      type: "number_plain",
      table: true,
      editor: true,
    },
    // {
    //   name: "Depot",
    //   field: "depot_id",
    //   type: "object",
    //   object_name: "data_depot",
    //   object_field: "nama",
    //   editor: true,
    //   table: true,
    //   filter: true,
    // },
    // {
    //   name: "Gudang",
    //   field: "gudang_id",
    //   type: "object",
    //   object_name: "data_gudang",
    //   object_field: "nama",
    //   editor: true,
    //   table: false,
    //   filter: true,
    // },
    // {
    //   name: "Jenis",
    //   field: "jenis_transport",
    //   type: "text_plain",
    //   table: true,
    //   filter: true,
    //   editor: true,
    // },
    // {
    //   name: "Merek",
    //   field: "merek",
    //   type: "text_plain",
    //   table: true,
    //   filter: true,
    //   editor: true,
    // },
    // {
    //   name: "Nomor Polisi",
    //   field: "nomor_polisi",
    //   type: "text_plain",
    //   table: true,
    //   filter: true,
    //   editor: true,
    // },
    // {
    //   name: "Nama",
    //   field: "nama",
    //   type: "text_plain",
    //   table: false,
    //   filter: true,
    //   editor: true,
    // },
    // {
    //   name: "Nomor Mesin",
    //   field: "nomor_mesin",
    //   type: "text_plain",
    //   table: false,
    //   filter: true,
    //   editor: true,
    // },
    // {
    //   name: "Nomor Rangka",
    //   field: "nomor_rangka",
    //   type: "text_plain",
    //   table: false,
    //   filter: true,
    //   editor: true,
    // },
    // {
    //   name: "Tahun",
    //   field: "tahun",
    //   type: "date_year",
    //   align: "center",
    //   editor: true,
    //   format: "yyyy",
    //   table: true,
    // },
    // {
    //   name: "Tanggal Beli",
    //   field: "tanggal_beli",
    //   type: "date",
    //   align: "center",
    //   table: false,
    //   editor: true,
    //   format: "dd/MM/yyyy",
    //   prime_format: "d MM yy",
    //   showTime: 1,
    // },
    // {
    //   name: "Tanggal STNK",
    //   field: "tanggal_stnk",
    //   type: "date",
    //   align: "center",
    //   table: false,
    //   editor: true,
    //   format: "dd/MM/yyyy",
    //   prime_format: "d MM yy",
    //   showTime: 1,
    // },
    // {
    //   name: "Jenis BBM",
    //   field: "jenis_bbm",
    //   type: "text_plain",
    //   table: true,
    //   filter: true,
    //   editor: true,
    // },
    // {
    //   name: "Dalam Kota",
    //   field: "dalam_kota",
    //   type: "number_plain",
    //   editor: true,
    //   align: "right",
    //   table: true,
    // },
    // {
    //   name: "Speedo Awal",
    //   field: "speedo_awal",
    //   type: "number_plain",
    //   editor: true,
    //   align: "right",
    //   table: false,
    // },
    // {
    //   name: "Speedo Akhir",
    //   field: "speedo_akhir",
    //   type: "number_plain",
    //   editor: true,
    //   align: "right",
    //   table: false,
    // },
    // {
    //   name: "Keterangan",
    //   field: "keterangan",
    //   type: "text_plain",
    //   table: false,
    //   filter: true,
    //   editor: true,
    // },
    // {
    //   name: "Jenis Mobil",
    //   field: "jenis_mobil",
    //   type: "text_plain",
    //   table: false,
    //   filter: true,
    //   editor: true,
    // },
    // {
    //   name: "Jumlah Roda",
    //   field: "jumlah_roda",
    //   table: false,
    //   filter: true,
    //   editor: true,
    //   type: "number_plain",
    //   align: "right",
    // },
    // {
    //   name: "Luar Kota",
    //   field: "luar_kota",
    //   type: "number_plain",
    //   editor: true,
    //   align: "right",
    //   table: false,
    // },
  ],
};

export default DetailListSalesConfigs;

import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import useAPIRequest from "../../../../../custom_hooks/simple/useAPIRequest";
import TableAutoComponent from "../../../../../components/complex/crud/TableAutoComponent";
import { format as dateFnsFormat, parse } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import TransportSelectorDialog from "../../../../../dialogs/selectors/TransportSelectorDialog";
import DepotSelectorDialog from "../../../../../dialogs/selectors/DepotSelectorDialog";
import WilayahSelectorDialog from "../../../../../dialogs/selectors/WilayahSelectorDialog";

const defaultDate = new Date();
defaultDate.setDate(1);

const tableConfigs = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Kode",
    field: "data_produk.kode",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Nama Produk",
    field: "data_produk.nama_lengkap",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Quantity",
    field: "total",
    type: "number_plain",
    align: "right",
    table: true,
    filter: true,
  },
  {
    name: "Satuan",
    field: "satuan",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
];

const STOK_DEPOT = "stok-depot";
const STOK_TRANSPORT = "stok-transport";
const STOK_DEPOT_TRANSPORT = "stok-depot-transport";
const STOK_SEMUA_GUDANG = "stok-semua-gudang";
const STOK_WILAYAH = "stok-wilayah";
const STOK_SELURUH_WILAYAH = "stok-seluruh-wilayah";
const STOK_GUDANG_PRODUKSI = "stok-gudang-produksi";
const STOK_GUDANG_AB = "stok-gudang-ab";

const ReportPosisiStok = () => {
  let { prefix } = useParams();
  const navigate = useNavigate();
  const toast = useRef();

  const [title, setTitle] = useState("");
  const [listData, setListData] = useState([]);
  const [tanggal, setTanggal] = useState(new Date());

  const [visibleTransportSelector, setVisibleTransportSelector] =
    useState(false);
  const [selectedTransport, setSelectedTransport] = useState(null);

  const [visibleDepotSelector, setVisibleDepotSelector] = useState(false);
  const [selectedDepot, setSelectedDepot] = useState(null);

  const [visibleWilayahSelector, setVisibleWilayahSelector] = useState(false);
  const [selectedWilayah, setSelectedWilayah] = useState(null);

  const [totalNilai, setTotalNilai] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);

  const { requestGet, loading } = useAPIRequest();

  const generateFilter = () => {
    let filter = "";

    filter = `tanggal_transaksi:<=:${dateFnsFormat(tanggal, "yyyy-MM-dd")}`;

    if (prefix === STOK_DEPOT && selectedDepot) {
      filter += `;depot_id:=:${selectedDepot.id}`;
    } else if (prefix === STOK_DEPOT_TRANSPORT && selectedDepot) {
      filter += `;depot_id:=:${selectedDepot.id}`;
    } else if (prefix === STOK_WILAYAH) {
      filter += `;wilayah_id:=:${selectedWilayah.id}`;
    } else if (prefix === STOK_GUDANG_PRODUKSI) {
      filter += `;gudang_id:=:1`;
    } else if (prefix === STOK_GUDANG_AB) {
      filter += `;gudang_id:=:106`;
    }

    return filter;
  };

  useEffect(() => {
    if (prefix === STOK_TRANSPORT) {
      setTitle(
        `Stok Transport ${
          selectedTransport ? "- " + selectedTransport.nama : ""
        }`
      );
    } else if (prefix === STOK_DEPOT_TRANSPORT || prefix === STOK_DEPOT) {
      setTitle(`Stok Depot ${selectedDepot ? "- " + selectedDepot.nama : ""}`);
    } else if (prefix === STOK_WILAYAH) {
      setTitle(
        `Stok Wilayah ${selectedWilayah ? "- " + selectedWilayah.nama : ""}`
      );
    } else if (prefix === STOK_SELURUH_WILAYAH) {
      setTitle(`Stok Seluruh Wilayah`);
    } else if (prefix === STOK_SEMUA_GUDANG) {
      setTitle(`Stok Global`);
    } else if (prefix === STOK_GUDANG_PRODUKSI) {
      setTitle(`Stok Gudang Produksi`);
    } else if (prefix === STOK_GUDANG_AB) {
      setTitle(`Stok Gudang AB`);
    } else {
      setTitle(``);
    }
  }, [prefix, selectedTransport, selectedDepot, selectedWilayah]);

  const reloadData = async () => {
    setListData([]);
    setTotalNilai(0);

    if (
      (prefix === STOK_DEPOT || prefix === STOK_DEPOT_TRANSPORT) &&
      !selectedDepot
    )
      return;
    if (prefix === STOK_TRANSPORT && !selectedTransport) return;
    if (prefix === STOK_DEPOT && !selectedDepot) return;
    if (prefix === STOK_WILAYAH && !selectedWilayah) return;

    const filter = generateFilter();

    let newPrefix = "";

    if (prefix === STOK_SELURUH_WILAYAH) {
      newPrefix = "stok_wilayah";
    } else if (prefix === STOK_GUDANG_PRODUKSI) {
      newPrefix = "stok_semua_gudang";
    } else if (prefix === STOK_GUDANG_AB) {
      newPrefix = "stok_semua_gudang";
    } else {
      newPrefix = prefix.replaceAll("-", "_");
    }

    const params = {
      prefix: newPrefix,
      with_produk: 1,
      page: 1,
      take: 1000,
      filter,
    };

    return requestGet({
      fullUrl: "api/laporan",
      params,
      onSuccess: ({ pagination, message, data }) => {
        let newTotalNilai = 0;
        let no = 1;
        const editedListData = data;
        editedListData.sort((a, b) =>
          a.data_produk.nama.localeCompare(b.data_produk.nama)
        );
        for (const d of editedListData) {
          d.id = no;
          d.no = no++;
          d.satuan = "Tin";
          // convert to tin
          d.total = d.total / 100.0;
          newTotalNilai += d.total;
        }
        setListData(editedListData);
        setTotalNilai(newTotalNilai);
      },
      onError: ({ message, data }) => {},
    });
  };

  const handleReload = () => {
    reloadData();
  };

  const generateTotalLabel = ({ label, value }) => {
    return (
      <div className="col-center-end min-w-[7.2rem]">
        <span className="font-bold text-xl text-right">
          {value.toLocaleString("id-ID", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}
        </span>
        <span className="font-bold text-slate-400 text-xs"> {label} </span>
      </div>
    );
  };

  const customColumns = [
    {
      field: "persen",
      component: (rowData) => {
        let persen = (rowData.nilai / rowData.nilai_pembanding) * 100.0;
        let minus = rowData.nilai < rowData.nilai_pembanding;
        if (!rowData.nilai_pembanding) {
          persen = 100;
          minus = false;
        }
        return (
          <div
            className={`row-end-center font-semibold ${
              minus ? "text-red-400" : "text-green-600"
            } `}
          >
            <span className="">{`${minus ? "- " : ""}${persen.toFixed(
              0
            )} %`}</span>
          </div>
        );
      },
    },
  ];

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleConfirmTransportSelectorBox = (e, value) => {
    e.preventDefault();
    if (value && value.length > 0) {
      setSelectedTransport(value[0]);
      setVisibleTransportSelector(false);
    }
  };

  const handleConfirmDepotSelectorBox = (e, value) => {
    e.preventDefault();
    if (value && value.length > 0) {
      setSelectedDepot(value[0]);
      setVisibleDepotSelector(false);
    }
  };

  const handleConfirmWilayahSelectorBox = (e, value) => {
    e.preventDefault();
    if (value && value.length > 0) {
      setSelectedWilayah(value[0]);
      setVisibleWilayahSelector(false);
    }
  };

  const generateSelector = () => {
    if (prefix === STOK_TRANSPORT) {
      return (
        <TransportSelectorDialog
          visible={visibleTransportSelector}
          onHide={() => setVisibleTransportSelector(false)}
          onConfirm={handleConfirmTransportSelectorBox}
        />
      );
    } else if (prefix === STOK_DEPOT || prefix === STOK_DEPOT_TRANSPORT) {
      return (
        <DepotSelectorDialog
          visible={visibleDepotSelector}
          onHide={() => setVisibleDepotSelector(false)}
          onConfirm={handleConfirmDepotSelectorBox}
        />
      );
    } else if (prefix === STOK_WILAYAH) {
      return (
        <WilayahSelectorDialog
          visible={visibleWilayahSelector}
          onHide={() => setVisibleWilayahSelector(false)}
          onConfirm={handleConfirmWilayahSelectorBox}
        />
      );
    }
    return <></>;
  };

  const generateSelectorButton = () => {
    if (prefix === STOK_TRANSPORT) {
      return (
        <div className={`col-start-start w-100 gap-1`}>
          <label
            htmlFor={"tanggal"}
            className="font-bold text-sm text-zinc-500"
          >
            Plat Kendaraan
          </label>
          <div className="row-start-center gap-1">
            <Button
              className={`w-[12rem]`}
              outlined
              onClick={(e) => {
                e.preventDefault();
                setVisibleTransportSelector(true);
              }}
            >
              <div className="row-between-center w-100">
                {selectedTransport ? selectedTransport.nomor_polisi : "-"}{" "}
                <i className="pi pi-search" />
              </div>
            </Button>
            <Button icon="pi pi-ellipsis-v" text />
          </div>
        </div>
      );
    } else if (prefix === STOK_DEPOT || prefix === STOK_DEPOT_TRANSPORT) {
      return (
        <div className={`col-start-start w-100 gap-1`}>
          <label
            htmlFor={"tanggal"}
            className="font-bold text-sm text-zinc-500"
          >
            Depot
          </label>
          <div className="row-start-center gap-1">
            <Button
              className={`w-[12rem]`}
              outlined
              onClick={(e) => {
                e.preventDefault();
                setVisibleDepotSelector(true);
              }}
            >
              <div className="row-between-center w-100">
                {selectedDepot ? selectedDepot.nama : "-"}{" "}
                <i className="pi pi-search" />
              </div>
            </Button>
            <Button icon="pi pi-ellipsis-v" text />
          </div>
        </div>
      );
    } else if (prefix === STOK_WILAYAH) {
      return (
        <div className={`col-start-start w-100 gap-1`}>
          <label
            htmlFor={"tanggal"}
            className="font-bold text-sm text-zinc-500"
          >
            Wilayah
          </label>
          <div className="row-start-center gap-1">
            <Button
              className={`w-[12rem]`}
              outlined
              onClick={(e) => {
                e.preventDefault();
                setVisibleWilayahSelector(true);
              }}
            >
              <div className="row-between-center w-100">
                {selectedWilayah ? selectedWilayah.nama : "-"}{" "}
                <i className="pi pi-search" />
              </div>
            </Button>
            <Button icon="pi pi-ellipsis-v" text />
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <Toast ref={toast} />
      <div></div>
      {generateSelector()}
      <div className="elevated-card-no-padding w-100 row-start-start relative wrap gap-8">
        <div className="row-start-start wrap gap-8 w-100 p-[1rem]">
          <div className="col-start-start gap-3">
            <div className={`col-start-start w-100 gap-1`}>
              <label
                htmlFor={"tanggal"}
                className="font-bold text-sm text-zinc-500"
              >
                Tanggal
              </label>
              <Calendar
                id="tanggal"
                value={tanggal}
                showButtonBar
                selectionMode="single"
                hourFormat="24"
                showIcon
                onChange={(e) => {
                  setTanggal(e.value);
                }}
                className={`dateInput w-[12rem]`}
                locale="id"
              />
            </div>
          </div>
          <div className="col-start-start gap-3">
            {generateSelectorButton()}
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <div className="row-between-center">
          <div className="row-start-center gap-2">
            <Button
              icon="pi pi-arrow-left"
              text
              className="max-h-[1.4rem]"
              onClick={handleClickBack}
            />
            <span className="text-normal font-semibold text-zinc-700">
              {title}
            </span>
          </div>
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            onSelectionChange={() => {}}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={customColumns}
          />
        </div>
      </div>

      <div className="elevated-card-tight-vertical w-100 row-between-center">
        <span className="text-lg font-semibold">Grandtotal</span>
        <div className="row-end-center w-100 sm:gap-2 mr-2">
          {generateTotalLabel({ label: "TIN", value: totalNilai })}
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default ReportPosisiStok;

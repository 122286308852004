import classes from "./JadwalEditorDialog.module.css";

import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import useCrudEditorControl from "../../../../custom_hooks/complex/useCrudEditorControl";

import configs from "./JadwalConfigs";
import DepotSelectorDialog from "../../../../dialogs/selectors/DepotSelectorDialog";
import TransportSelectorDialog from "../../../../dialogs/selectors/TransportSelectorDialog";
import JurusanSelectorDialog from "../../../../dialogs/selectors/JurusanSelectorDialog";
import SalesSelectorDialog from "../../../../dialogs/selectors/SalesSelectorDialog";
import SupirSelectorDialog from "../../../../dialogs/selectors/SupirSelectorDialog";

const title = configs.dialog.title;

const JadwalEditorDialog = (props) => {
  const {
    generateSelectorDialog,
    handleInputChange,
    handleMultipleInputChange,
    elements,
    validator,
  } = useCrudEditorControl({
    value: props.value,
    setValue: props.setValue,
    configs: props.configs,
    error: props.error,
    customElements: props.customElements,
  });

  const convertValue = () => {
    const newValue = { ...props.value };
    if (newValue.data_jurusans) {
      const arrJurusanId = newValue.data_jurusans.map((element) => element.id);
      newValue.data_jurusans = arrJurusanId;
    }

    return { ...newValue };
  };

  const validation = () => {
    return validator({
      configs: props.configs,
      value: props.value,
      onError: (error) => props.setError(error),
      isEmpty: "depot_id sales_id transport_id supir_id ",
    });
  };

  // add_generate_selector_dialog
  generateSelectorDialog({
    field: "depot_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <DepotSelectorDialog
          single={false}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "sales_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <SalesSelectorDialog
          single={false}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "transport_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <TransportSelectorDialog
          single={false}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "supir_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <SupirSelectorDialog
          single={false}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "data_jurusans",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <JurusanSelectorDialog
          single={false}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });


  const dialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={props.onHide}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={(e) => {
          const valid = validation();
          if (valid) {
            const newValue = convertValue();
            props.onSave(e, newValue);
          }
        }}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "48rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={title}
      modal
      className={`p-fluid`}
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <div className="col-start-center relative h-100">
        <div className={`${classes.wrapper} row-start-start w-100`}>
          <div className="col-start-start w-100 gap-3">
            {/* {elements.example_fields} */}
            {/* add_fields */}
            {elements.depot_id}
            {elements.sales_id}
            {elements.tanggal}
            {elements.keterangan}
            {elements.transport_id}
            {elements.supir_id}
            {elements.utai}
          </div>
          <Divider style={{ height: "20rem" }} layout="vertical" />
          <div className="col-start-start w-100 gap-3">
            {elements.data_jurusans}
          </div>
        </div>
        {props.loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default JadwalEditorDialog;

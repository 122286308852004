import { subDays, addDays } from "date-fns";

const DepotConfigs = {
  master: {
    title: "Depot",
    apiName: "masterdata/depot",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: { 
      // add_params
      with_gudang: 1,
      with_wilayah: 1,
    },
  },
  dialog: {
    title: "Depot",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Gudang",
      field: "gudang_id",
      type: "object",
      object_name: "data_gudang",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Wilayah",
      field: "wilayah_id",
      type: "object",
      object_name: "data_wilayah",
      object_field: "nama",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Kode",
      field: "kode",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Nama",
      field: "nama",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
  ],
};

export default DepotConfigs;

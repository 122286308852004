import React, { useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { MY_URL } from "../../../../configs/constants";
import { InputText } from "primereact/inputtext";

const qrUrl = `${MY_URL}/qr-code-64.svg`;
const title = "Scan QR";

const ScanBarcodeDialog = (props) => {
  const { visible, onHide, onConfirm } = props;
  const [input, setInput] = useState("");

  useEffect(() => {
    if (visible) {
      setInput("");
    }
  }, [visible]);

  const handleConfirmScan = (barcode) => {
    if (barcode.trim()) {
      onHide();
      onConfirm(input.trim());
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleConfirmScan(input);
    }
  };

  const dialogFooter = (
    <div className="row-between-end w-100">
      <Button label="Cancel" icon="pi pi-times" outlined onClick={onHide} />
      <Button
        label="Okay"
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          handleConfirmScan(input);
        }}
      />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      header={title}
      modal
      className={`p-fluid w-[23rem] sm:w-[25rem] min-h-[32rem]`}
      footer={dialogFooter}
      onHide={onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className={`col-center-center w-100 gap-3 px-2`}>
        <div className={`col-center-center w-100 gap-3 pt-2`}>
          <Image src={qrUrl} width="250" />
        </div>
        <InputText
          className="text-center min-h-[3rem]"
          onChange={(event) => setInput(event.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      </div>
    </Dialog>
  );
};

export default ScanBarcodeDialog;

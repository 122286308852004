import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useEditorControl from "../../../custom_hooks/complex/useEditorControl";
import onErrorToast from "../../../utils/OnErrorToast";
import onSuccessToast from "../../../utils/OnSuccessToast";
import configs from "./ProfileEditorConfigs";

const title = "Profil Saya";

const editorConfigs = configs;
const apiName = "user";
let apiSuffix;
const customElements = [];
const isForm = false;

const ProfileEditorDialog = (props) => {
  const toast = useRef(null);
  const user = useSelector((state) => state.auth.user);

  const {
    // methods
    onSave,
    onFetchData,
    handleInputChange,
    handleMultipleInputChange,
    // states
    value,
    setValue,
    setError,
    loading,
    elements,
    //utils
    validator,
  } = useEditorControl({
    configs,
    isForm,
    apiName,
    apiSuffix,
    customElements,
  });

  const fetchData = async () => {
    onFetchData({
      id: user.id,
      onError: ({ message, data }) => {
        onErrorToast({ toast: toast, message: message, data: data });
      },
      onSuccess: ({ message, data }) => {
        if (data.data_user) {
          data.password = "";
          data.cpassword = "";
        }
        setValue(data);
      },
    });
  };

  useEffect(() => {
    if (props.visible) {
      fetchData();
    }
  }, [props.visible]);

  const convertValue = () => {
    const newValue = {
      ...value,
      id: user.id,
    };
    return newValue;
  };

  const validation = () => {
    return validator({
      configs: configs,
      value: value,
      onError: (error) => setError(error),
      isEmpty: "name username nip",
      mustEqual: ["password cpassword"],
    });
  };

  const handleOnSave = async (event) => {
    event.preventDefault();
    const valid = validation();
    if (valid) {
      const newValue = convertValue();

      const data = await onSave({
        newValue,
        onError: ({ message, data }) => {
          console.log("MESSAGE", message);
          onErrorToast({ toast: toast, message: message, data: data });
        },
        onSuccess: ({ data, message }) => {
          onSuccessToast({
            toast: toast,
            message: "Profil berhasil di-update",
            data: data,
          });
          props.onHide();
        },
      });
    }
  };

  const dialogFooter = (
    <React.Fragment>
      <div className="row-end-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Simpan"
          icon="pi pi-save"
          loading={loading}
          onClick={(e) => {
            handleOnSave(e);
          }}
        />
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "45rem" }}
      header={title}
      modal
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <Toast ref={toast} />
      <div className="col-start-center relative h-100">
        <div className="col-start-start w-100 gap-3">
          <div className={`row-start-start w-100`}>
            <div className="col-start-start w-100 gap-3">
              {elements.image}
              {elements.nik}
              {elements.name}
              {elements.username}
            </div>
            <Divider className="h-[28.5rem]" layout="vertical" />
            <div className="col-start-start w-100 gap-3">
              {elements.email}
              {elements.tanggal_lahir}
              {elements.agama}
              {elements.password}
              {elements.cpassword}
            </div>
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ProfileEditorDialog;

import RadioButtonGroup from "../../../../components/sales/RadioButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import { setValueType } from "../../../../store/app-slice";

const jenisNilai = [
  {
    id: "quantity",
    value: "quantity",
    checked: false,
    label: "Quantity (Tin)",
  },
  {
    id: "rupiah",
    value: "harga",
    checked: true,
    label: "Rupiah (K)",
  },
];

const HeaderValueType = (props) => {
  const { title } = props;

  const dispatch = useDispatch();
  const valueType = useSelector((state) => state.app.valueType);

  const handleOnChange = (event) => {
    dispatch(setValueType(event));
  };

  return (
    <div className="row-between-center w-full">
      <span className="text-sm font-semibold text-zinc-700">{title}</span>
      <div className="row-end-center">
        <RadioButtonGroup
          items={jenisNilai}
          name="jenisNilai"
          value={valueType}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

export default HeaderValueType;

const onSuccessToast = ({ toast, message, data }) => {
  if (toast) {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  } else {
    console.log("SUCCESS TOAST", message);
  }
};

export default onSuccessToast;

import { Avatar } from "primereact/avatar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Knob } from "primereact/knob";
import { useEffect, useState } from "react";
import RadialChart from "../../../../components/chart/RadialChart";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";

const getReturColor = (value) => {
  if (value < 25) {
    // return "text-green-500";
    return "rgb(34 197 94)";
  } else if (value >= 25 && value < 50) {
    // return "text-yellow-500";
    return "rgb(234 179 8)";
  } else if (value >= 50 && value < 75) {
    // return "text-orange-500";
    return "rgb(249 115 22)";
  } else {
    // return "text-red-500";
    return "rgb(239 68 68)";
  }
};

const getReturClassColor = (value) => {
  if (value < 25) {
    return "text-green-500";
  } else if (value >= 25 && value < 50) {
    return "text-yellow-500";
  } else if (value >= 50 && value < 75) {
    return "text-orange-500";
  } else {
    return "text-red-500";
  }
};

const getSalesColor = (value) => {
  if (value < 25) {
    // return "text-red-500";
    return "rgb(239 68 68)";
  } else if (value >= 25 && value < 50) {
    // return "text-orange-500";
    return "rgb(249 115 22)";
  } else if (value >= 50 && value < 75) {
    // return "text-yellow-500";
    return "rgb(234 179 8)";
  } else {
    // return "text-green-500";
    return "rgb(34 197 94)";
  }
};
const getSalesClassColor = (value) => {
  if (value < 25) {
    return "text-red-500";
  } else if (value >= 25 && value < 50) {
    return "text-orange-500";
  } else if (value >= 50 && value < 75) {
    return "text-yellow-500";
  } else {
    return "text-green-500";
  }
};

const JurusanPerformanceTable = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { title, sales_id } = props;

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);
  const { requestGet, loading } = useAPIRequest();

  const [searchText, setSearchText] = useState("");
  const [paging, setPaging] = useState({
    page: 1,
    take: 4,
    filter: "",
    total: 0,
  });

  const [datas, setDatas] = useState([]);

  useEffect(() => {
    reloadData({ page: paging.page, take: paging.take, filter: paging.filter });
  }, []);

  const generateFilter = () => {
    const today = new Date();

    let filter = `tahun:=:${today.getFullYear()};bulan:=:${
      today.getMonth() + 1
    }`;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `;depot_id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `;data_depot.wilayah_id:=:${wilayah.id}`;
    }

    if (sales_id) {
      filter += `;sales_id:=:${sales_id}`;
    }

    // console.log("FILTER JURUSAN", filter);

    return filter;
  };

  const reloadData = async ({ page, take, filter }) => {
    const newFilter = generateFilter();
    setDatas(null);

    return await requestGet({
      fullUrl: "api/dashboard/kadepot",
      params: {
        prefix: "jurusan",
        filter:
          newFilter +
          (filter && filter.length > 0
            ? `;nama:contains:${filter}||kode:contains:${filter}`
            : ""),
        page: page,
        take: take,
        order: "nama",
        order_method: "ASC",
        with_produk: 1,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          setPaging({
            ...paging,
            total: pagination["total"],
            page: pagination["page"],
            take: pagination["take"],
            filter: pagination["filter"],
          });

          const newDatas = [];

          for (let i = 0; i < data.length; i++) {
            const { d_p, retur_baik, retur_usang, id, nama, kode } = data[i];
            const {
              prediksi,
              prediksi_per_bulan,
              realisasi,
              total_target_batang,
              total_target_batang_per_bulan,
            } = d_p;
            const limitDivider = 1000.0;
            const newData = {
              jurusan_nama: nama,
              jurusan_kode: kode,
              id: id,
              no: (pagination.page - 1) * pagination.take + i + 1,
              sales_target: total_target_batang_per_bulan,
              sales_value: realisasi,
              sales_prediksi: prediksi_per_bulan,
              sales_percent:
                (realisasi / total_target_batang_per_bulan) * 100.0,
              retur_baik_value: retur_baik.current_year,
              retur_baik_target: retur_baik.last_year,
              retur_baik_percent:
                (retur_baik.current_year / retur_baik.last_year) * 100.0,
              retur_usang_value: retur_usang.current_year,
              retur_usang_target: retur_usang.last_year,
              retur_usang_percent:
                (retur_usang.current_year / retur_usang.last_year) * 100.0,
            };

            newDatas.push(newData);
          }
          setDatas(newDatas);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const jurusanBodyTemplate = (rowData) => {
    const { jurusan_nama, jurusan_kode } = rowData;
    return (
      <div className="col-start-start gap-0">
        <span className="font-semibold">{jurusan_nama}</span>
        <span className="text-sm text-zinc-400">{jurusan_kode}</span>
      </div>
    );
  };

  const salesTargetBodyTemplate = (rowData) => {
    let { sales_target, sales_prediksi, sales_value, sales_percent } = rowData;

    if (sales_target === 0 && sales_value === 0) {
      sales_percent = null;
    }

    return (
      <div className="col-center-center">
        <RadialChart
          radius={75}
          target={(sales_prediksi / sales_target) * 100.0}
          value={(sales_value / sales_target) * 100.0}
          dimension={100}
          color={getSalesColor(sales_percent)}
        />
        <div className={`row-center-center gap-2`}>
          <span
            className={`font-semibold ${getSalesClassColor(sales_percent)}`}
          >
            {(sales_value / 1000.0).toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
            K Btg
          </span>
          <span>/</span>
          <span className="font-semibold text-zinc-400">
            {(sales_target / 1000.0).toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
            K Btg
          </span>
        </div>
      </div>
    );
  };

  const returBaikBodyTemplate = (rowData) => {
    let { retur_baik_target, retur_baik_value, retur_baik_percent } = rowData;

    if (retur_baik_target === 0 && retur_baik_value === 0) {
      retur_baik_percent = null;
    } else if (retur_baik_target === 0 && retur_baik_value > 0) {
      retur_baik_percent = 100;
    }

    return (
      <div className="col-center-center">
        {retur_baik_percent && (
          <Knob
            value={
              retur_baik_percent > 100 ? 100 : retur_baik_percent.toFixed(0)
            }
            valueTemplate={`${retur_baik_percent.toFixed(0)}%`}
            valueColor={getSalesColor(retur_baik_percent)}
          />
        )}
        <div
          className={`row-center-center gap-2 font-semibold ${getSalesClassColor(
            retur_baik_percent
          )}`}
        >
          <span>
            {(retur_baik_value / 100.0).toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}{" "}
            Tin
          </span>
          <span>/</span>
          <span className="font-semibold text-zinc-400">
            {(retur_baik_target / 100.0).toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}{" "}
            Tin
          </span>
        </div>
      </div>
    );
  };

  const returUsangBodyTemplate = (rowData) => {
    let { retur_usang_target, retur_usang_value, retur_usang_percent } =
      rowData;

    if (retur_usang_target === 0 && retur_usang_value === 0) {
      retur_usang_percent = null;
    } else if (retur_usang_target === 0 && retur_usang_value > 0) {
      retur_usang_percent = 100;
    }

    return (
      <div className="col-center-center">
        {retur_usang_percent && (
          <Knob
            value={
              retur_usang_percent > 100 ? 100 : retur_usang_percent.toFixed(0)
            }
            valueTemplate={`${retur_usang_percent.toFixed(0)}%`}
            valueColor={getReturColor(retur_usang_percent)}
          />
        )}
        <div
          className={`row-center-center gap-2 font-semibold ${getReturClassColor(
            retur_usang_percent
          )}`}
        >
          <span>
            {(retur_usang_value / 100.0).toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
          </span>
          <span>/</span>
          <span className="font-semibold text-zinc-400">
            {(retur_usang_target / 100.0).toLocaleString("id-ID", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
          </span>
        </div>
      </div>
    );
  };

  const handlePageChange = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadData({ page: newPage, take: newTake, filter: paging.filter });
  };

  const handleSearch = () => {
    reloadData({ page: paging.page, take: paging.take, filter: searchText });
  };

  const handleSearchOnEnter = (e) => {
    if (e.key === "Enter") {
      reloadData({ page: paging.page, take: paging.take, filter: searchText });
    }
  };

  return (
    <div className="col-start-start elevated-card gap-3 w-full">
      <div className="row-between-start w-full">
        <span className="text-sm font-semibold text-zinc-700">{title}</span>
        <div className="row-end-center gap-1">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className=" w-[14rem]"
              placeholder="Cari ..."
              style={{ paddingLeft: "2.5rem" }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyUp={(e) => handleSearchOnEnter(e)}
            />
          </span>
          <Button icon="pi pi-refresh" label="Refresh" onClick={handleSearch} />
        </div>
      </div>

      {datas ? (
        <div className="col-start-start gap-3 w-full">
          <DataTable
            className="w-full"
            value={datas}
            resizableColumns
            scrollable
            scrollHeight="40.5rem"
          >
            <Column field="no" header="#" />
            <Column
              field="jurusan_nama"
              header="Jurusan"
              body={jurusanBodyTemplate}
            />
            <Column
              field="sales_value"
              alignHeader={"center"}
              header="D&P vs Target Bulanan"
              body={salesTargetBodyTemplate}
            />
            <Column
              field="retur_baik_value"
              alignHeader={"center"}
              header="Retur Baik Tahunan"
              body={returBaikBodyTemplate}
            />
            <Column
              field="retur_usang_value"
              alignHeader={"center"}
              header="Retur Usang Tahunan"
              body={returUsangBodyTemplate}
            />
          </DataTable>
          <div className="row-center-start w-full">
            <Paginator
              first={paging.take * (paging.page - 1)}
              rows={paging.take}
              totalRecords={paging.total}
              rowsPerPageOptions={[4, 10, 20, 30]}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <div className="col-start-start w-full gap-3">
          <Skeleton className="w-full" height="5rem"></Skeleton>
          <Skeleton className="w-full" height="5rem"></Skeleton>
          <Skeleton className="w-full" height="5rem"></Skeleton>
          <Skeleton className="w-full" height="5rem"></Skeleton>
          <Skeleton className="w-full" height="5rem"></Skeleton>
        </div>
      )}
    </div>
  );
};

export default JurusanPerformanceTable;

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { OrganizationChart } from "primereact/organizationchart";
import { SelectButton } from "primereact/selectbutton";
import { useEffect, useState } from "react";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

const wilayahs = [
  "Wilayah 1",
  "Wilayah 2",
  "Wilayah 3",
  "Wilayah 4",
  "Wilayah 5",
  "Wilayah 6",
  "Wilayah 7",
];

const depots = [
  "Depot 201",
  "Depot 202",
  "Depot 203",
  "Depot 204",
  "Depot 205",
];

const kelompoks = [
  "Kelompok 1",
  "Kelompok 2",
  "Kelompok 3",
  "Kelompok 4",
  "Kelompok 5",
  "Kelompok 6",
];

const semesters = ["Semester 1", "Semester 2"];

const configJurusans = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Produk",
    field: "nama",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Bln 1",
    field: "b1",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Bln 2",
    field: "b2",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Bln 3",
    field: "b3",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Bln 4",
    field: "b4",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Bln 5",
    field: "b5",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Bln 6",
    field: "b6",
    type: "number_plain",
    align: "right",
    table: true,
  },
];

const generateTotalLabel = ({ label, value }) => {
  return (
    <div className="col-center-end min-w-[6.5rem]">
      <span className="font-bold text-lg text-right">
        {value.toLocaleString("id-ID", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })}
      </span>
      <span className="font-bold text-slate-400 text-xs"> {label} </span>
    </div>
  );
};

const TargetBungkusPage = () => {
  const [selectedWilayah, setSelectedWilayah] = useState(wilayahs[0]);
  const [selectedDepot, setSelectedDepot] = useState(depots[0]);
  const [selectedSemester, setSelectedSemester] = useState(semesters[0]);
  const [selectedSemesterPembanding, setSelectedSemesterPembanding] = useState(
    semesters[0]
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDatePembanding, setSelectedPembanding] = useState(null);

  const [listOutlets, setListOutlets] = useState([]);
  const [listTotals, setListTotals] = useState([]);
  const [listTargetJurusan, setListTargetJurusan] = useState([]);

  const [selectedKelompok, setSelectedKelompok] = useState(kelompoks[0]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);

  const [listData, setListData] = useState([]);
  const [listDataPembanding, setListDataPembanding] = useState([]);

  useEffect(() => {
    setListOutlets([
      { id: 1, no: 1, nama: "SIBAYAK", jenis_agen: "ATB" },
      { id: 2, no: 2, nama: "USAHA MAJU", jenis_agen: "AC" },
      { id: 3, no: 3, nama: "SAHABAT/ABADI (SEI BULUH)", jenis_agen: "ATB" },
      { id: 4, no: 4, nama: "ABANG ADIK", jenis_agen: "AC" },
      { id: 5, no: 5, nama: "ASIA BARU", jenis_agen: "AT" },
      { id: 6, no: 6, nama: "BANDUNG (P.RAKYAT)", jenis_agen: "ATB" },
      { id: 7, no: 7, nama: "BANDUNG", jenis_agen: "ATB" },
      { id: 8, no: 8, nama: "BANGUN BARU (SEI PIRING)", jenis_agen: "ATB" },
      { id: 9, no: 9, nama: "BINTANG BARU A", jenis_agen: "ATB" },
      { id: 10, no: 10, nama: "BUDI JAYA", jenis_agen: "AT" },
      { id: 11, no: 11, nama: "BUKIT MAS", jenis_agen: "ATB" },
      { id: 12, no: 12, nama: "DELI BARU", jenis_agen: "AC" },
      { id: 13, no: 13, nama: "DELI RAYA", jenis_agen: "ATB" },
      { id: 14, no: 14, nama: "GO AH PENG / TIMBUL I", jenis_agen: "ATB" },
      { id: 15, no: 15, nama: "HARMONI", jenis_agen: "ATB" },
    ]);

    setListData([
      {
        key: "0",
        data: { name: "Kelompok 1", batang: 2500, bungkus: 156, header: true },
        children: [
          {
            key: "0-0",
            data: { no: 1, name: "Hero Filter Long", batang: 500, bungkus: 31 },
          },
          {
            key: "0-1",
            data: {
              no: 2,
              name: "Union Filter Long",
              batang: 600,
              bungkus: 38,
            },
          },
          {
            key: "0-2",
            data: {
              no: 3,
              name: "Union Export Filter Long",
              batang: 400,
              bungkus: 25,
            },
          },
          {
            key: "0-3",
            data: {
              no: 4,
              name: "Rolex Filter Long",
              batang: 500,
              bungkus: 32,
            },
          },
          {
            key: "0-4",
            data: {
              no: 5,
              name: "Nero Filter Long",
              batang: 200,
              bungkus: 13,
            },
          },
          {
            key: "0-5",
            data: {
              no: 6,
              name: "Prince Filter Long",
              batang: 300,
              bungkus: 19,
            },
          },
        ],
      },
      {
        key: "1",
        data: { name: "Kelompok 2", batang: 2500, bungkus: 156, header: true },
        children: [
          {
            key: "1-0",
            data: {
              no: 1,
              name: "Gudang Prambanan King Size",
              batang: 500,
              bungkus: 31,
            },
          },
          {
            key: "1-1",
            data: {
              no: 2,
              name: "Panama Signature King Size",
              batang: 600,
              bungkus: 38,
            },
          },
          {
            key: "1-2",
            data: {
              no: 3,
              name: "Panama Mild Balance King Size",
              batang: 400,
              bungkus: 25,
            },
          },
          {
            key: "1-3",
            data: {
              no: 4,
              name: "Hero Casual Gentle King Size",
              batang: 500,
              bungkus: 32,
            },
          },
          {
            key: "1-4",
            data: {
              no: 5,
              name: "Hero Casual Bold King Size",
              batang: 200,
              bungkus: 13,
            },
          },
          {
            key: "1-5",
            data: {
              no: 6,
              name: "Panama King Size",
              batang: 300,
              bungkus: 19,
            },
          },
        ],
      },
      {
        key: "2",
        data: { name: "Kelompok 3", batang: 2500, bungkus: 156, header: true },
        children: [
          {
            key: "2-0",
            data: {
              no: 1,
              name: "West 100",
              batang: 500,
              bungkus: 31,
            },
          },
          {
            key: "2-1",
            data: {
              no: 2,
              name: " Union Classic",
              batang: 600,
              bungkus: 38,
            },
          },
          {
            key: "2-2",
            data: {
              no: 3,
              name: "Marcopolo",
              batang: 400,
              bungkus: 25,
            },
          },
          {
            key: "2-3",
            data: {
              no: 4,
              name: "Kennedy",
              batang: 500,
              bungkus: 32,
            },
          },
          {
            key: "2-4",
            data: {
              no: 5,
              name: "Johnson",
              batang: 200,
              bungkus: 13,
            },
          },
          {
            key: "2-5",
            data: {
              no: 6,
              name: "Gunung Gula International",
              batang: 300,
              bungkus: 19,
            },
          },
        ],
      },
    ]);

    setListTargetJurusan([
      {
        key: "0",
        data: { name: "D. MASIHUL", batang: 2500, bungkus: 156, header: true },
        children: [
          {
            key: "0-0",
            data: {
              name: "Kelompok 1",
              batang: 500,
              bungkus: 31,
              header: true,
            },
            children: [
              {
                key: "0-0-1",
                data: {
                  no: 1,
                  name: "Hero Filter Long",
                  batang: 500,
                  bungkus: 31,
                },
                children: [],
              },
              {
                key: "0-0-2",
                data: {
                  no: 2,
                  name: "Union Filter Long",
                  batang: 600,
                  bungkus: 38,
                },
              },
              {
                key: "0-0-3",
                data: {
                  no: 3,
                  name: "Union Export Filter Long",
                  batang: 400,
                  bungkus: 25,
                },
              },
              {
                key: "0-0-4",
                data: {
                  no: 4,
                  name: "Rolex Filter Long",
                  batang: 500,
                  bungkus: 32,
                },
              },
              {
                key: "0-0-5",
                data: {
                  no: 5,
                  name: "Nero Filter Long",
                  batang: 200,
                  bungkus: 13,
                },
              },
              {
                key: "0-0-6",
                data: {
                  no: 6,
                  name: "Prince Filter Long",
                  batang: 300,
                  bungkus: 19,
                },
              },
            ],
          },
          {
            key: "0-1",
            data: {
              name: "Kelompok 2",
              batang: 500,
              bungkus: 31,
              header: true,
            },
            children: [
              {
                key: "0-1-0",
                data: {
                  no: 1,
                  name: "Gudang Prambanan King Size",
                  batang: 500,
                  bungkus: 31,
                },
              },
              {
                key: "0-1-1",
                data: {
                  no: 2,
                  name: "Panama Signature King Size",
                  batang: 600,
                  bungkus: 38,
                },
              },
              {
                key: "0-1-2",
                data: {
                  no: 3,
                  name: "Panama Mild Balance King Size",
                  batang: 400,
                  bungkus: 25,
                },
              },
              {
                key: "0-1-3",
                data: {
                  no: 4,
                  name: "Hero Casual Gentle King Size",
                  batang: 500,
                  bungkus: 32,
                },
              },
              {
                key: "0-1-4",
                data: {
                  no: 5,
                  name: "Hero Casual Bold King Size",
                  batang: 200,
                  bungkus: 13,
                },
              },
              {
                key: "0-1-5",
                data: {
                  no: 6,
                  name: "Panama King Size",
                  batang: 300,
                  bungkus: 19,
                },
              },
            ],
          },
          {
            key: "0-2",
            data: {
              name: "Kelompok 3",
              batang: 500,
              bungkus: 31,
              header: true,
            },
            children: [
              {
                key: "0-2-0",
                data: {
                  no: 1,
                  name: "West 100",
                  batang: 500,
                  bungkus: 31,
                },
              },
              {
                key: "0-2-1",
                data: {
                  no: 2,
                  name: " Union Classic",
                  batang: 600,
                  bungkus: 38,
                },
              },
              {
                key: "0-2-2",
                data: {
                  no: 3,
                  name: "Marcopolo",
                  batang: 400,
                  bungkus: 25,
                },
              },
              {
                key: "0-2-3",
                data: {
                  no: 4,
                  name: "Kennedy",
                  batang: 500,
                  bungkus: 32,
                },
              },
              {
                key: "0-2-4",
                data: {
                  no: 5,
                  name: "Johnson",
                  batang: 200,
                  bungkus: 13,
                },
              },
              {
                key: "0-2-5",
                data: {
                  no: 6,
                  name: "Gunung Gula International",
                  batang: 300,
                  bungkus: 19,
                },
              },
            ],
          },
        ],
      },
      {
        key: "0",
        data: { name: "PERDAGANGAN", batang: 2500, bungkus: 156, header: true },
        children: [
          {
            key: "0-0",
            data: {
              name: "Kelompok 1",
              batang: 500,
              bungkus: 31,
              header: true,
            },
            children: [
              {
                key: "0-0-1",
                data: {
                  no: 1,
                  name: "Hero Filter Long",
                  batang: 500,
                  bungkus: 31,
                },
                children: [],
              },
              {
                key: "0-0-2",
                data: {
                  no: 2,
                  name: "Union Filter Long",
                  batang: 600,
                  bungkus: 38,
                },
              },
              {
                key: "0-0-3",
                data: {
                  no: 3,
                  name: "Union Export Filter Long",
                  batang: 400,
                  bungkus: 25,
                },
              },
              {
                key: "0-0-4",
                data: {
                  no: 4,
                  name: "Rolex Filter Long",
                  batang: 500,
                  bungkus: 32,
                },
              },
              {
                key: "0-0-5",
                data: {
                  no: 5,
                  name: "Nero Filter Long",
                  batang: 200,
                  bungkus: 13,
                },
              },
              {
                key: "0-0-6",
                data: {
                  no: 6,
                  name: "Prince Filter Long",
                  batang: 300,
                  bungkus: 19,
                },
              },
            ],
          },
          {
            key: "0-1",
            data: {
              name: "Kelompok 2",
              batang: 500,
              bungkus: 31,
              header: true,
            },
            children: [
              {
                key: "0-1-0",
                data: {
                  no: 1,
                  name: "Gudang Prambanan King Size",
                  batang: 500,
                  bungkus: 31,
                },
              },
              {
                key: "0-1-1",
                data: {
                  no: 2,
                  name: "Panama Signature King Size",
                  batang: 600,
                  bungkus: 38,
                },
              },
              {
                key: "0-1-2",
                data: {
                  no: 3,
                  name: "Panama Mild Balance King Size",
                  batang: 400,
                  bungkus: 25,
                },
              },
              {
                key: "0-1-3",
                data: {
                  no: 4,
                  name: "Hero Casual Gentle King Size",
                  batang: 500,
                  bungkus: 32,
                },
              },
              {
                key: "0-1-4",
                data: {
                  no: 5,
                  name: "Hero Casual Bold King Size",
                  batang: 200,
                  bungkus: 13,
                },
              },
              {
                key: "0-1-5",
                data: {
                  no: 6,
                  name: "Panama King Size",
                  batang: 300,
                  bungkus: 19,
                },
              },
            ],
          },
          {
            key: "0-2",
            data: {
              name: "Kelompok 3",
              batang: 500,
              bungkus: 31,
              header: true,
            },
            children: [
              {
                key: "0-2-0",
                data: {
                  no: 1,
                  name: "West 100",
                  batang: 500,
                  bungkus: 31,
                },
              },
              {
                key: "0-2-1",
                data: {
                  no: 2,
                  name: " Union Classic",
                  batang: 600,
                  bungkus: 38,
                },
              },
              {
                key: "0-2-2",
                data: {
                  no: 3,
                  name: "Marcopolo",
                  batang: 400,
                  bungkus: 25,
                },
              },
              {
                key: "0-2-3",
                data: {
                  no: 4,
                  name: "Kennedy",
                  batang: 500,
                  bungkus: 32,
                },
              },
              {
                key: "0-2-4",
                data: {
                  no: 5,
                  name: "Johnson",
                  batang: 200,
                  bungkus: 13,
                },
              },
              {
                key: "0-2-5",
                data: {
                  no: 6,
                  name: "Gunung Gula International",
                  batang: 300,
                  bungkus: 19,
                },
              },
            ],
          },
        ],
      },
      {
        key: "0",
        data: { name: "P. SIANTAR", batang: 2500, bungkus: 156, header: true },
        children: [
          {
            key: "0-0",
            data: {
              name: "Kelompok 1",
              batang: 500,
              bungkus: 31,
              header: true,
            },
            children: [
              {
                key: "0-0-1",
                data: {
                  no: 1,
                  name: "Hero Filter Long",
                  batang: 500,
                  bungkus: 31,
                },
                children: [],
              },
              {
                key: "0-0-2",
                data: {
                  no: 2,
                  name: "Union Filter Long",
                  batang: 600,
                  bungkus: 38,
                },
              },
              {
                key: "0-0-3",
                data: {
                  no: 3,
                  name: "Union Export Filter Long",
                  batang: 400,
                  bungkus: 25,
                },
              },
              {
                key: "0-0-4",
                data: {
                  no: 4,
                  name: "Rolex Filter Long",
                  batang: 500,
                  bungkus: 32,
                },
              },
              {
                key: "0-0-5",
                data: {
                  no: 5,
                  name: "Nero Filter Long",
                  batang: 200,
                  bungkus: 13,
                },
              },
              {
                key: "0-0-6",
                data: {
                  no: 6,
                  name: "Prince Filter Long",
                  batang: 300,
                  bungkus: 19,
                },
              },
            ],
          },
          {
            key: "0-1",
            data: {
              name: "Kelompok 2",
              batang: 500,
              bungkus: 31,
              header: true,
            },
            children: [
              {
                key: "0-1-0",
                data: {
                  no: 1,
                  name: "Gudang Prambanan King Size",
                  batang: 500,
                  bungkus: 31,
                },
              },
              {
                key: "0-1-1",
                data: {
                  no: 2,
                  name: "Panama Signature King Size",
                  batang: 600,
                  bungkus: 38,
                },
              },
              {
                key: "0-1-2",
                data: {
                  no: 3,
                  name: "Panama Mild Balance King Size",
                  batang: 400,
                  bungkus: 25,
                },
              },
              {
                key: "0-1-3",
                data: {
                  no: 4,
                  name: "Hero Casual Gentle King Size",
                  batang: 500,
                  bungkus: 32,
                },
              },
              {
                key: "0-1-4",
                data: {
                  no: 5,
                  name: "Hero Casual Bold King Size",
                  batang: 200,
                  bungkus: 13,
                },
              },
              {
                key: "0-1-5",
                data: {
                  no: 6,
                  name: "Panama King Size",
                  batang: 300,
                  bungkus: 19,
                },
              },
            ],
          },
          {
            key: "0-2",
            data: {
              name: "Kelompok 3",
              batang: 500,
              bungkus: 31,
              header: true,
            },
            children: [
              {
                key: "0-2-0",
                data: {
                  no: 1,
                  name: "West 100",
                  batang: 500,
                  bungkus: 31,
                },
              },
              {
                key: "0-2-1",
                data: {
                  no: 2,
                  name: " Union Classic",
                  batang: 600,
                  bungkus: 38,
                },
              },
              {
                key: "0-2-2",
                data: {
                  no: 3,
                  name: "Marcopolo",
                  batang: 400,
                  bungkus: 25,
                },
              },
              {
                key: "0-2-3",
                data: {
                  no: 4,
                  name: "Kennedy",
                  batang: 500,
                  bungkus: 32,
                },
              },
              {
                key: "0-2-4",
                data: {
                  no: 5,
                  name: "Johnson",
                  batang: 200,
                  bungkus: 13,
                },
              },
              {
                key: "0-2-5",
                data: {
                  no: 6,
                  name: "Gunung Gula International",
                  batang: 300,
                  bungkus: 19,
                },
              },
            ],
          },
        ],
      },
    ]);

    setListDataPembanding([
      {
        key: "0",
        data: { name: "Kelompok 1", batang: 2500, bungkus: 156, header: true },
        children: [
          {
            key: "0-0",
            data: { no: 1, name: "Hero Filter Long", batang: 500, bungkus: 31 },
          },
          {
            key: "0-1",
            data: {
              no: 2,
              name: "Union Filter Long",
              batang: 600,
              bungkus: 38,
            },
          },
          {
            key: "0-2",
            data: {
              no: 3,
              name: "Union Export Filter Long",
              batang: 400,
              bungkus: 25,
            },
          },
          {
            key: "0-3",
            data: {
              no: 4,
              name: "Rolex Filter Long",
              batang: 500,
              bungkus: 32,
            },
          },
          {
            key: "0-4",
            data: {
              no: 5,
              name: "Nero Filter Long",
              batang: 200,
              bungkus: 13,
            },
          },
          {
            key: "0-5",
            data: {
              no: 6,
              name: "Prince Filter Long",
              batang: 300,
              bungkus: 19,
            },
          },
        ],
      },
      {
        key: "1",
        data: { name: "Kelompok 2", batang: 2500, bungkus: 156, header: true },
        children: [
          {
            key: "1-0",
            data: {
              no: 1,
              name: "Gudang Prambanan King Size",
              batang: 500,
              bungkus: 31,
            },
          },
          {
            key: "1-1",
            data: {
              no: 2,
              name: "Panama Signature King Size",
              batang: 600,
              bungkus: 38,
            },
          },
          {
            key: "1-2",
            data: {
              no: 3,
              name: "Panama Mild Balance King Size",
              batang: 400,
              bungkus: 25,
            },
          },
          {
            key: "1-3",
            data: {
              no: 4,
              name: "Hero Casual Gentle King Size",
              batang: 500,
              bungkus: 32,
            },
          },
          {
            key: "1-4",
            data: {
              no: 5,
              name: "Hero Casual Bold King Size",
              batang: 200,
              bungkus: 13,
            },
          },
          {
            key: "1-5",
            data: {
              no: 6,
              name: "Panama King Size",
              batang: 300,
              bungkus: 19,
            },
          },
        ],
      },
      {
        key: "2",
        data: { name: "Kelompok 3", batang: 2500, bungkus: 156, header: true },
        children: [
          {
            key: "2-0",
            data: {
              no: 1,
              name: "West 100",
              batang: 500,
              bungkus: 31,
            },
          },
          {
            key: "2-1",
            data: {
              no: 2,
              name: " Union Classic",
              batang: 600,
              bungkus: 38,
            },
          },
          {
            key: "2-2",
            data: {
              no: 3,
              name: "Marcopolo",
              batang: 400,
              bungkus: 25,
            },
          },
          {
            key: "2-3",
            data: {
              no: 4,
              name: "Kennedy",
              batang: 500,
              bungkus: 32,
            },
          },
          {
            key: "2-4",
            data: {
              no: 5,
              name: "Johnson",
              batang: 200,
              bungkus: 13,
            },
          },
          {
            key: "2-5",
            data: {
              no: 6,
              name: "Gunung Gula International",
              batang: 300,
              bungkus: 19,
            },
          },
        ],
      },
    ]);
  }, []);

  const batangTemplate = (node, options) => {
    const contentValue = node.data.batang.toLocaleString("id-ID", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });
    if (node.data && node.data.header) {
      return <span className="font-semibold ">{contentValue}</span>;
    } else {
      return contentValue;
    }
  };

  const bungkusTemplate = (node, options) => {
    const contentValue = node.data.bungkus.toLocaleString("id-ID", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });
    if (node.data && node.data.header) {
      return <span className="font-semibold ">{contentValue}</span>;
    } else {
      return contentValue;
    }
  };

  const nameTemplate = (node, options) => {
    if (node.data && node.data.header) {
      return <span className="font-semibold ">{node.data.name}</span>;
    } else {
      return (
        <span>
          <span className="text-sm ">{node.data.no}. </span> {node.data.name}{" "}
        </span>
      );
    }
  };

  const rowClassName = (node) => {
    if (node.data && node.data.header) {
      return { "bg-zinc-50": true };
    } else {
      return "";
    }
  };

  const customColumnOutlets = [
    {
      field: "select",
      component: (rowData) => {
        return (
          <div className="row-center-center">
            <Button
              className="max-w-[30px] max-h-[30px]"
              icon="pi pi-arrow-right"
              size="small"
              onClick={(event) => {
                setSelectedOutlet(
                  listOutlets.find(({ id }) => id === rowData.id)
                );
              }}
              outlined
            />
          </div>
        );
      },
    },
    {
      field: "nama",
      component: (rowData) => {
        return (
          <div className="col-center-start">
            <span>{rowData.nama}</span>
            <span className="text-sm text-zinc-400 font-semibold">
              {rowData.jenis_agen}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <div />
      {/* judul */}
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <span className="text-normal font-semibold text-zinc-700">
          Target ( Bungkus )
        </span>
        <div className="row-end-center gap-2">
          <Dropdown
            value={selectedWilayah}
            onChange={(e) => setSelectedWilayah(e.value)}
            options={wilayahs}
          />
          <Dropdown
            value={selectedDepot}
            onChange={(e) => setSelectedDepot(e.value)}
            options={depots}
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            // loading={loading}
            // onClick={() => handleReload()}
            outlined
          />
        </div>
      </div>

      <div className="row-center-start gap-4 w-full">
        <div className="col-center-start gap-4 w-full">
          <div
            className={`elevated-card-tight-vertical row-center-center w-100 wrap gap-20`}
          >
            <span className="text-normal font-semibold text-zinc-700">
              TARGET
            </span>
          </div>
          {/* target */}
          {/* per depot */}
          <div className={`elevated-card col-start-start w-100  wrap gap-4`}>
            <div className="row-center-center w-100 gap-4">
              <SelectButton
                value={selectedSemester}
                onChange={(e) => setSelectedSemester(e.value)}
                options={semesters}
              />
              <Calendar
                className="w-[8rem]"
                showIcon
                value={selectedDate}
                view="year"
                dateFormat="yy"
                onChange={(e) => setSelectedDate(e.value)}
              />
              <Button
                icon="pi pi-refresh"
                size="small"
                // loading={loading}
                // onClick={() => handleReload()}
                outlined
              />
            </div>

            <TreeTable
              value={listData}
              rowClassName={rowClassName}
              showGridlines
            >
              <Column
                body={nameTemplate}
                field="name"
                header="Name"
                expander
              ></Column>
              <Column
                body={batangTemplate}
                field="batang"
                alignHeader={"right"}
                align={"right"}
                header="Batang"
                className="w-[7rem]"
              ></Column>
              <Column
                body={bungkusTemplate}
                field="bungkus"
                alignHeader={"right"}
                align={"right"}
                header="Bungkus"
                className="w-[7rem]"
              ></Column>
            </TreeTable>
          </div>
        </div>

        {/* pembanding */}
        <div className="col-start-center gap-4 w-full ">
          <div
            className={`elevated-card-tight-vertical row-center-center w-100 wrap gap-20 !bg-gray-100`}
          >
            <span className="text-normal font-semibold text-zinc-700">
              PEMBANDING
            </span>
          </div>

          {/* per depot */}
          <div
            className={`elevated-card row-start-start w-100 wrap gap-4 !bg-gray-100`}
          >
            <div className="row-center-center w-100 gap-4">
              <SelectButton
                value={selectedSemester}
                onChange={(e) => setSelectedSemester(e.value)}
                options={semesters}
              />
              <Calendar
                className="w-[8rem]"
                showIcon
                value={selectedDate}
                view="year"
                dateFormat="yy"
                onChange={(e) => setSelectedDate(e.value)}
              />
              <Button
                icon="pi pi-refresh"
                size="small"
                // loading={loading}
                // onClick={() => handleReload()}
                outlined
              />
            </div>
            <TreeTable
              value={listDataPembanding}
              rowClassName={rowClassName}
              showGridlines
            >
              <Column
                body={nameTemplate}
                field="name"
                header="Name"
                expander
              ></Column>
              <Column
                body={batangTemplate}
                field="batang"
                alignHeader={"right"}
                align={"right"}
                header="Batang"
                className="w-[7rem]"
              ></Column>
              <Column
                body={bungkusTemplate}
                field="bungkus"
                alignHeader={"right"}
                align={"right"}
                header="Bungkus"
                className="w-[7rem]"
              ></Column>
            </TreeTable>{" "}
          </div>
        </div>
      </div>

      <div className="elevated-card col-center-start gap-4 w-full">
        <div className="row-start-end gap-6 w-full">
          <div className="col-start-start gap-1">
            <span className="text-sm text-zinc-400 font-semibold pl-1">
              Jurusan
            </span>
            <InputText className="w-48" />
          </div>
          <div className="col-start-start gap-1">
            <span className="text-sm text-zinc-400 font-semibold pl-1">
              Kelompok
            </span>
            <InputText className="w-48" />
          </div>
          <div className="col-start-start gap-1">
            <span className="text-sm text-zinc-400 font-semibold pl-1">
              Rokok
            </span>
            <InputText className="w-48" />
          </div>
          <Button
            icon="pi pi-refresh"
            size="small"
            // loading={loading}
            // onClick={() => handleReload()}
            outlined
          />
        </div>

        <TreeTable
          value={listTargetJurusan}
          rowClassName={rowClassName}
          showGridlines
        >
          <Column
            body={nameTemplate}
            field="name"
            header="Keterangan"
            expander
          />
          <Column
            body={batangTemplate}
            field="b1"
            alignHeader={"right"}
            align={"right"}
            header="Bulan 1"
            className="w-[7rem]"
          />
          <Column
            body={bungkusTemplate}
            field="b2"
            alignHeader={"right"}
            align={"right"}
            header="Bulan 2"
            className="w-[7rem]"
          />
          <Column
            body={bungkusTemplate}
            field="b3"
            alignHeader={"right"}
            align={"right"}
            header="Bulan 3"
            className="w-[7rem]"
          />
          <Column
            body={bungkusTemplate}
            field="b4"
            alignHeader={"right"}
            align={"right"}
            header="Bulan 4"
            className="w-[7rem]"
          />
          <Column
            body={bungkusTemplate}
            field="b5"
            alignHeader={"right"}
            align={"right"}
            header="Bulan 5"
            className="w-[7rem]"
          />
          <Column
            body={bungkusTemplate}
            field="b6"
            alignHeader={"right"}
            align={"right"}
            header="Bulan 6"
            className="w-[7rem]"
          />
          <Column
            body={bungkusTemplate}
            field="total"
            alignHeader={"right"}
            align={"right"}
            header="Total"
            className="w-[7rem]"
          />
        </TreeTable>

        <div className="w-100 gap-3 row-between-center">
          <span className="text-lg font-semibold">Grandtotal</span>
          <div className="row-end-center w-100 gap-2 mr-2">
            {generateTotalLabel({ label: "BULAN 1", value: 7500 })}
            {generateTotalLabel({ label: "BULAN 2", value: 468 })}
            {generateTotalLabel({ label: "BULAN 3", value: 468 })}
            {generateTotalLabel({ label: "BULAN 4", value: 468 })}
            {generateTotalLabel({ label: "BULAN 5", value: 468 })}
            {generateTotalLabel({ label: "BULAN 6", value: 468 })}
            {generateTotalLabel({ label: "TOTAL", value: 468 })}
          </div>
        </div>
      </div>
      <div />
    </div>
  );
};

export default TargetBungkusPage;

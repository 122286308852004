import classes from "./SignUpPage.module.css";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { MY_URL } from "../../configs/constants";
import { Divider } from "primereact/divider";
import { useNavigate, useSearchParams } from "react-router-dom";
import addEditorControlHOC from "../../component_hocs/AddEditorControlHOC";
import configs from "./SignUpConfigs";
import onErrorToast from "../../utils/OnErrorToast";
import onSuccessToast from "../../utils/OnSuccessToast";
import { useDispatch } from "react-redux";
import { login } from "../../store/auth-slice";
import useEditorControl from "../../custom_hooks/complex/useEditorControl";
import { ProgressSpinner } from "primereact/progressspinner";
import JabatanSelectorDialog from "../../dialogs/selectors/JabatanSelectorDialog";

const backgroundUrl = `${MY_URL}/background.png`;

const editorConfigs = configs;
const apiName = "masterdata/employee";
const apiSuffix = "register";
const customElements = [];
const isForm = false;

const SignUpPage = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultValue, setDefaultValue] = useState({});

  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    // methods
    onSave,
    onFetchData,
    handleInputChange,
    handleMultipleInputChange,
    generateSelectorDialog,
    // states
    value,
    setValue,
    setError,
    loading,
    elements,
    //utils
    validator,
  } = useEditorControl({
    configs,
    isForm,
    apiName,
    apiSuffix,
    customElements,
  });

  useEffect(() => {
    const name = searchParams.get("name");
    const username = searchParams.get("username");
    const nip = searchParams.get("nip");
    const email = searchParams.get("email");
    const token = searchParams.get("token");

    setDefaultValue({ name, username, email, token, nip });

    setValue({
      name: name,
      nip: nip,
      data_user: {
        username: username,
        email: email,
        password: "",
        cpassword: "",
      },
    });
  }, [searchParams]);

  const convertValue = () => {
    const newValue = { ...value, data_positions: [] };
    return newValue;
  };

  const validation = () => {
    return validator({
      configs: configs,
      value: value,
      onError: (error) => setError(error),
      isEmpty:
        "date_of_birth religion nip data_user.password data_user.cpassword",
      mustEqual: ["data_user.password data_user.cpassword"],
    });
  };

  generateSelectorDialog({
    field: "jabatan_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <JabatanSelectorDialog
          single={false}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  const handleOnSave = async (event) => {
    event.preventDefault();
    const valid = validation();
    if (valid) {
      const newValue = convertValue();

      const data = await onSave({
        newValue,
        onError: ({ message, data }) => {
          onErrorToast({ toast: toast, message: message, data: data });
        },
        onSuccess: ({ data, message }) => {
          onSuccessToast({
            toast: toast,
            message: "Pendaftaran berhasil",
            data: data,
          });
          dispatch(login({ loginToken: defaultValue.token })).then((result) => {
            if (!result || result === null) {
              navigate(
                `/sign-up?name=${defaultValue.name.replace(
                  " ",
                  "%20"
                )}&username=${defaultValue.username}&email=${
                  defaultValue.email
                }&token=${defaultValue.token}`
              );
            } else if (result && result !== null) {
              navigate("/");
            }
          });
        },
      });
    }
  };

  return (
    <div
      className={`${classes.wrapper} row-center-center w-100 `}
      style={{ backgroundImage: `url(${backgroundUrl})` }}
    >
      <Toast ref={toast} />

      <div
        className={`${classes.floatingContainer} row-center-center relative`}
      >
        <section
          className={`col-start-start py-4 gap-4`}
          style={{ width: "46rem", paddingLeft: "3rem", paddingRight: "3rem" }}
        >
          <span className={`${classes.subtitle}`}>Daftarkan akun Anda,</span>
          <div className="row-start-start w-100 gap-3">
            <div className="col-start-start w-100 gap-3">
              {elements.name}
              {elements["data_user.username"]}
              {elements["data_user.email"]}
              {elements["data_user.password"]}
              {elements["data_user.cpassword"]}
            </div>
            <Divider style={{ height: "24.2rem" }} layout="vertical" />
            <div className="col-start-start w-100 gap-3">
              {elements.nip}
              {elements.date_of_birth}
              {elements.religion}
              {elements.jabatan_id}
              {elements.permanent}
            </div>
          </div>
          <div className="row-end-center w-100">
            <Button
              icon="pi pi-arrow-right"
              iconPos="right"
              loading={loading}
              className={`${classes.registerButton}`}
              style={{ width: "8.5rem" }}
              label="DAFTAR"
              onClick={handleOnSave}
            />
          </div>
        </section>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default addEditorControlHOC({
  configs: editorConfigs,
  customElements: customElements,
  WrappedComponent: SignUpPage,
});

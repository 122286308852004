import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import TableAutoComponent from "../../../../../components/complex/crud/TableAutoComponent";

import configs from "./StokMutasiReturBaikConfigs";
import generateTableConfigs from "../../../../../utils/TableConfigGenerator";
import { ProgressSpinner } from "primereact/progressspinner";
import generateDefaultValue from "../../../../../utils/GenerateDefaultValue";
import { generateTabLabel } from "./StokMutasiReturBaikArsipPage";
import EditorDialog from "./StokMutasiReturBaikDetilEditorDialog";
import useMDEditorPageControl from "../../../../../custom_hooks/complex/useMDEditorPageControl";
// add_import_selector_dialog
import ProdukSelectorDialog from "../../../../../dialogs/selectors/ProdukSelectorDialog";
import DepotSelectorDialog from "../../../../../dialogs/selectors/DepotSelectorDialog";
import GudangSelectorDialog from "../../../../../dialogs/selectors/GudangSelectorDialog";
import TransportSelectorDialog from "../../../../../dialogs/selectors/TransportSelectorDialog";
import SalesSelectorDialog from "../../../../../dialogs/selectors/SalesSelectorDialog";
import { SplitButton } from "primereact/splitbutton";
import { useState } from "react";
import BarcodeDialog from "./barcode/barcode-dialog";
import onErrorToast from "../../../../../utils/OnErrorToast";
import BarcodeButton from "./barcode/barcode-button";

const customColumns = [];

const tableConfigs = generateTableConfigs(configs.detail.columns);
const apiName = configs.master.apiName;
const detailField = configs.detail.detail_field;

const generateNumbering = (listData, start) => {
  let no = start ?? 1;
  for (const el of listData) el.urutan = no++;
  return listData;
};

let customHandleEdit = undefined;

const generateAddDialog = ({ visible, setVisible, onConfirm }) => {
  return (
    <ProdukSelectorDialog
      single={true}
      visible={visible}
      onHide={() => setVisible(false)}
      onConfirm={(e, data) => {
        let newDataArr = onConfirm({
          event: e,
          data: data,
          field: "produk_id",
          dataField: "data_produk",
        });
        if (newDataArr && newDataArr.length >= 1) {
          customHandleEdit(newDataArr[0]);
        }
      }}
    />
  );
};

const convertAddDetailData = (detailData, headerValue) => {
  const newDetailData = {
    ...detailData,
  };
  return newDetailData;
};

const StokMutasiReturBaikEditorPage = (props) => {
  const { removeSelectedEditor, changeLabeEditor, requestFocus, id } = props;

  const {
    // UIs
    toast,
    elements,
    addDialogSelector,
    // states
    showDetailEditorDialog,
    setShowDetailEditorDialog,
    detailEditorValue,
    setDetailEditorValue,
    detailEditorError,
    setDetailEditorError,
    setHeaderError,
    listData,
    setListData,
    selectedRows,
    headerValue,
    loading,
    edited,
    //methods
    generateSelectorDialog,
    handleDelete,
    handleDeleteMultiple,
    handleEdit,
    handleSelectionChange,
    handleClose,
    generateSaveBody,
    validator,
    handleReload,
    handleSave,
    handleAdd,
    handleEditorSave,
    //others
    configHeaders,
  } = useMDEditorPageControl({
    generateDefaultValue,
    configs,
    apiName,
    generateNumbering,
    id,
    detailField,
    removeSelectedEditor,
    changeLabeEditor,
    generateTabLabel,
    requestFocus,
    generateAddDialog,
    convertAddDetailData,
  });

  const [showBarcodeDialog, setShowBarcodeDialog] = useState(false);
  const [editingRow, setEditingRow] = useState({});

  const convertSaveBody = () => {
    const newBody = generateSaveBody();
    return newBody;
  };

  const onChangeListData = ({ detailId, newRow }) => {
    const newList = [...listData];
    const newIndex = newList.findIndex((e) => e.id === detailId);
    if (newIndex >= 0) {
      newList.splice(newIndex, 1, newRow);
    } else {
      newList.push(newRow);
    }
    generateNumbering(newList);
    setListData(newList);
    if (newRow) {
      setEditingRow(newRow);
    }
  };

  const customColumns = [
    {
      field: "quantity_barcode",
      component: (rowData) => {
        return (
          <div className="row-start-center gap-2">
            <BarcodeButton
              edited={edited}
              partial={rowData.parcial ? true : false}
              rowData={rowData}
              // onChangeListData={onChangeListData}
              onClick={(e) => {
                e.preventDefault();
                if (rowData.id && rowData.id > 0 && !edited) {
                  setEditingRow(rowData);
                  // setEditingListBarcode(rowData.data_barcodes);
                  setShowBarcodeDialog(true);
                } else {
                  onErrorToast({
                    toast,
                    message: "Bon harus di-save terlebih dahulu",
                    data: {},
                  });
                }
              }}
            />
            <span className="px-2 font-semibold">
              {rowData.quantity_barcode}
            </span>
          </div>
        );
      },
    },
  ];

  const validation = () => {
    return validator({
      configs: configHeaders,
      value: headerValue,
      onError: (error) => setHeaderError(error),
      isEmpty: "tanggal_transaksi gudang_dari_id gudang_ke_id nomor",
    });
  };

  customHandleEdit = (row) => {
    const newRow = row;
    newRow.satuan_dropdown = {};
    newRow.satuan_dropdown.konversi = row.konversi;
    newRow.satuan_dropdown.satuan = row.satuan;
    handleEdit(newRow);
  };

  const handleAddByBarcodeScanner = (e) => {
    e.preventDefault();

    const id = headerValue.id;
    setEditingRow({});
    // setEditingListBarcode([]);
    if (id && id > 0 && !edited) {
      setShowBarcodeDialog(true);
    } else {
      onErrorToast({
        toast,
        message: "Bon harus di-save terlebih dahulu",
        data: {},
      });
    }
  };

  const handleAddByBarcodeCamera = (e) => {
    console.log("ADD BARCODE CAMERA");
  };

  // add_generate_selector_dialog
  generateSelectorDialog({
    field: "depot_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <DepotSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "gudang_dari_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <GudangSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "gudang_ke_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <GudangSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "transport_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <TransportSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "sales_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <SalesSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  return (
    <div className={`col-start-start p-0 gap-4 w-100`}>
      <Toast ref={toast} />
      {addDialogSelector}
      <EditorDialog
        value={detailEditorValue}
        setValue={setDetailEditorValue}
        error={detailEditorError}
        setError={setDetailEditorError}
        visible={showDetailEditorDialog}
        onHide={() => setShowDetailEditorDialog(false)}
        onSave={handleEditorSave}
      />
      <BarcodeDialog
        visible={showBarcodeDialog}
        lockSalesMode={editingRow}
        rowData={editingRow}
        onHide={(e) => setShowBarcodeDialog(false)}
        id={headerValue.id}
        onChangeListData={onChangeListData}
      />

      <div></div>
      <div className="elevated-card-no-padding w-100 row-start-start relative wrap gap-8">
        <div className="row-start-start wrap gap-8 w-100 p-[1rem]">
          <div className="col-start-start gap-3 grow sm:w-48">
            {elements.nomor}
            {elements.tanggal_transaksi}
          </div>
          <div className="col-start-start gap-3 grow sm:w-48">
            {elements.gudang_dari_id}
            {elements.gudang_ke_id}
          </div>
          <div className="col-start-start gap-3 grow sm:w-48">
            {elements.jenis_mutasi}
            {elements.catatan}
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
      <div className={`elevated-card row-between-center w-100 wrap`}>
        <div className="row-start-center gap-2">
          <Button
            icon="pi pi-plus"
            label="Tambah Baru"
            loading={loading}
            onClick={handleAdd}
          />
          <SplitButton
            label="Scan QR"
            icon="pi pi-qrcode"
            loading={loading}
            size="small"
            onClick={handleAddByBarcodeScanner}
            model={[
              {
                label: "Phone Camera",
                icon: "pi pi-camera",
                command: handleAddByBarcodeCamera,
              },
            ]}
          />
          <Button
            icon="pi pi-trash"
            severity="danger"
            text
            loading={loading}
            label="Hapus"
            onClick={handleDeleteMultiple}
          />
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-save"
            size="small"
            loading={loading}
            severity="success"
            onClick={() => {
              const valid = validation();
              if (valid) {
                const body = convertSaveBody();
                handleSave(body);
              }
            }}
            rounded
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
            rounded
          />
          <Button
            icon="pi pi-times"
            size="small"
            loading={loading}
            onClick={handleClose}
            rounded
            outlined
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            rounded
            outlined
          />
        </div>
      </div>
      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            showControlButton
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            onEditRow={async (row) => customHandleEdit(row)}
            onDeleteRow={(row) => handleDelete(row)}
            customColumns={customColumns}
          />
        </div>
      </div>
    </div>
  );
};

export default StokMutasiReturBaikEditorPage;

import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth-slice";

const ProtectedRouteKawil = ({ children }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  if (user.title !== "kawil") {
    dispatch(logout());
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRouteKawil;

import validatorLib from "validator";
import extractDataFromMap from "./ExtractDataFromMap";

const validator = ({
  configs,
  value,
  onError,
  isEmpty,
  isEmptyMessage,
  isZero,
  isZeroMessage,
  mustEqual,
  mustEqualMessage,
}) => {
  let valid = true;
  const error = {};

  // check if is empty
  if (isEmpty) {
    const isEmptyList = Array.isArray(isEmpty) ? isEmpty : isEmpty.split(" ");
    for (const field of isEmptyList) {
      const configArr = configs.filter((element) => element.field === field);

      if (configArr && configArr.length > 0) {
        const config = configArr[0];
        let elValid = true;
        if (config.type.startsWith("text_")) {
          const elValue = extractDataFromMap({ key: field, map: value });
          if (elValue) {
            elValid = !validatorLib.isEmpty(elValue);
          } else {
            elValid = false;
          }
        } else if (
          config.type.startsWith("object") ||
          config.type.startsWith("date")
        ) {
          const elValue = extractDataFromMap({ key: field, map: value });
          if (!elValue || elValue === null) {
            elValid = false;
          }
        }
        if (!elValid) {
          error[field] = `${config.name} ${
            isEmptyMessage ?? "tidak boleh kosong"
          }`;
          valid = false;
        }
      }
    }
  }

  // check if is zero
  if (isZero) {
    const isZeroList = Array.isArray(isZero) ? isZero : isZero.split(" ");
    for (const field of isZeroList) {
      const configArr = configs.filter((element) => element.field === field);

      if (configArr && configArr.length > 0) {
        const config = configArr[0];
        let elValid = true;
        if (config.type.startsWith("number_")) {
          const elValue = extractDataFromMap({ key: field, map: value });
          if (elValue) {
            elValid = elValue > 0;
          } else {
            elValid = false;
          }
        }
        if (!elValid) {
          error[field] = `${config.name} ${isZeroMessage ?? "tidak boleh < 0"}`;
          valid = false;
        }
      }
    }
  }

  // check if not equals
  if (mustEqual) {
    for (const pairText of mustEqual) {
      const pairArray = pairText.split(" ");
      if (pairArray.length < 2) continue;
      const firstValue = extractDataFromMap({ key: pairArray[0], map: value });
      let elValid = true;
      const names = [];
      for (let i = 0; i < pairArray.length; i++) {
        const configArr = configs.filter(
          (element) => element.field === pairArray[i]
        );
        if (configArr && configArr.length > 0) {
          const config = configArr[0];
          names.push(config.name);
        }
        if (i === 0) continue;
        const nextValue = extractDataFromMap({ key: pairArray[i], map: value });
        if (firstValue !== nextValue) {
          elValid = false;
        }
      }
      if (!elValid) {
        for (const field of pairArray) {
          error[field] = `${names.join(" & ")} ${
            mustEqualMessage ?? "tidak sama"
          }`;
        }
        valid = false;
      }
    }
  }

  onError(error);

  return valid;
};

export default validator;

import assignDataToMap from "./AssignDataToMap";
import extractDataFromMap from "./ExtractDataFromMap";

const generateDefaultValueByType = (type) => {
  if (type) {
    switch (type) {
      case "text_plain":
      case "text_password":
      case "text_dropdown":
      case "text_radiobutton":
        return "";
      case "boolean_plain":
        return false;
      case "date":
      case "date_time":
      case "date_datetime":
      case "date_min_max":
        return null;
      case "number_plain":
      case "number_formatted":
      case "number_min_max":
      case "number_dropdown":
      case "number_radiobutton":
        return 0;
      case "image":
      case "zirvu_file_manager":
        return null;
      case "list":
        return [];
      default:
        return "";
    }
  } else {
    return "";
  }
};

const generateDefaultValue = ({ tableConfigs, editValue }) => {
  let result = {};
  for (let config of tableConfigs) {
    const { field, default_value, type, editor_ignore, default_value_on_edit } =
      config;
    let value;

    if (editor_ignore) {
      continue;
    }

    //overwrite by editing value
    if (editValue) {
      if (!field.includes(".")) {
        if (!default_value_on_edit) {
          const fieldValue = editValue[field];
          if (fieldValue) {
            value = fieldValue;
          }
        } else {
          value = default_value;
        }
        result[field] = value;
      } else {
        if (!default_value_on_edit) {
          const newValue = extractDataFromMap({ key: field, map: editValue });
          value = newValue;
        } else {
          value = default_value;
        }
        assignDataToMap({ key: field, map: result, value });
      }
    }

    if (!value) {
      if (default_value) {
        value = default_value;
      } else {
        value = generateDefaultValueByType(type);
      }
      assignDataToMap({ key: field, map: result, value });
    }
  }

  //add all field that is not in config
  if (editValue) {
    for (const key in editValue) {
      if (!result.hasOwnProperty(key)) {
        result[key] = editValue[key];
      }
    }
  }

  // console.log("GEN DEFAULT VALUE", result);

  return result;
};
export default generateDefaultValue;

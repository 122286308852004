import classes from "./TransportPage.module.css";

import generateFilterConfigs from "../../../../utils/FilterConfigGenerator";
import generateTableConfigs from "../../../../utils/TableConfigGenerator";
import generateEditorConfigs from "../../../../utils/EditorConfigGenerator";
import React from "react";
import FilterComponent from "../../../../components/complex/crud/FilterComponent";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import TableControlButtonComponent from "../../../../components/complex/crud/TableControlButtonComponent";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import useCrudControl from "../../../../custom_hooks/complex/useCrudControl";

import configs from "./TransportConfigs";
import EditorDialog from "./TransportEditorDialog";

const title = configs.master.title;
const apiName = configs.master.apiName;
const orderBy = configs.master.orderBy;
const orderMethod = configs.master.orderMethod;
const additionalParams = configs.master.additionalParams;
const isForm = configs.dialog.isForm;

const customEditorElements = [];
const customColumns = [];
// const customColumns = [
//   {
//     field: "status",
//     component: (rowData) => {
//       let statusComponent;
//       if (rowData.status === 0) {
//         statusComponent = (
//           <span className="font-semibold text-zinc-300">OFF</span>
//         );
//       } else if (rowData.status === 1) {
//         statusComponent = (
//           <span className="font-semibold text-green-600">OK</span>
//         );
//       } else if (rowData.status === 2) {
//         statusComponent = (
//           <span className="font-semibold text-red-400">Rusak</span>
//         );
//       }
//       return <div className="col-center-center">{statusComponent}</div>;
//     },
//   },
// ];

const filterConfigs = generateFilterConfigs(configs.columns);
const tableConfigs = generateTableConfigs(configs.columns);
const editorConfigs = generateEditorConfigs(configs.columns);

const TransportPage = (props, { className }) => {
  const {
    toast,
    paging,
    editorShowed,
    setEditorShowed,
    loading,
    editorLoading,
    confirmEditorLoading,
    editorError,
    setEditorError,
    listData,
    selectedValue,
    setSelectedValue,
    selectedRows,
    handleReload,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
    handleEditorSave,
    handleAddNew,
    handleDeleteMultiple,
    handleEditRow,
    handleDeleteRow,
  } = useCrudControl({
    title,
    configs: configs.columns,
    isForm,
    apiName,
    orderBy,
    orderMethod,
    additionalParams,
  });

  return (
    <div className={`${classes.wrapper} col-start-start p-4 gap-4 w-100`}>
      <Toast ref={toast} />
      <ConfirmDialog />{" "}
      <EditorDialog
        customElements={customEditorElements}
        configs={editorConfigs}
        value={selectedValue}
        setValue={setSelectedValue}
        loading={editorLoading}
        error={editorError}
        setError={setEditorError}
        confirmLoading={confirmEditorLoading}
        visible={editorShowed}
        onHide={() => setEditorShowed(false)}
        onSave={handleEditorSave}
      />
      {filterConfigs && filterConfigs.length > 0 && (
        <div className="elevated-card w-100">
          <FilterComponent onFilter={handleOnFilter} configs={filterConfigs} />
        </div>
      )}
      <div className="elevated-card col-center-center w-100 gap-3">
        <TableControlButtonComponent
          onAdd={handleAddNew}
          onDelete={handleDeleteMultiple}
          onReload={handleReload}
        />
        <TableAutoComponent
          showControlButton={true}
          selection={selectedRows}
          onSelectionChange={handleSelectionChange}
          configs={tableConfigs}
          loading={loading}
          value={listData}
          onEditRow={handleEditRow}
          onDeleteRow={handleDeleteRow}
          customColumns={customColumns}
        />
        <Paginator
          first={paging.take * (paging.page - 1)}
          rows={paging.take}
          totalRecords={paging.total}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={handleOnPage}
        />
      </div>
    </div>
  );
};

export default TransportPage;

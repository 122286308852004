import { Skeleton } from "primereact/skeleton";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  FaBuilding,
  FaDollarSign,
  FaMotorcycle,
  FaReceipt,
  FaTruck,
  FaUserLarge,
  FaUsers,
  FaUserTie,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";

const BadgeAktivitasDepot = (props) => {
  const { title } = props;
  const { requestGet, loading } = useAPIRequest();
  const navigate = useNavigate();

  const [bon, setBon] = useState({
    icon: <FaReceipt />,
    value: null,
    prompt: "Bon",
  });
  const [totalBon, setTotalBon] = useState({
    icon: <FaDollarSign />,
    value: null,
    prompt: "Nilai Rupiah",
    suffix: "K",
  });
  const [salesman, setSalesman] = useState({
    icon: <FaUserTie />,
    value: null,
    prompt: "Salesman",
    iconSize: "28",
  });
  const [mobil, setMobil] = useState({
    icon: <FaTruck />,
    value: null,
    prompt: "Mobil",
    iconSize: "34",
  });
  const [sm, setSM] = useState({
    icon: <FaMotorcycle />,
    value: null,
    prompt: "S.M.",
    iconSize: "34",
  });

  const handleClickBon = () => {
    navigate("/detail/list-sales?today=true");
  };

  const handleClickTotal = () => {
    navigate("/detail/list-sales?today=true");
  };

  const handleClickSalesman = () => {
    navigate("/detail/list-salesman?today=true");
  };

  const handleClickMobil = () => {
    navigate("/detail/list-transport?today=true");
  };

  const contentClick = [
    handleClickBon,
    handleClickTotal,
    handleClickSalesman,
    handleClickMobil,
    handleClickMobil,
  ];

  const user = useSelector((state) => state.auth.user);
  const valueType = useSelector((state) => state.app.valueType);
  const selectedDepot = useSelector((state) => state.app.depot);
  const selectedWilayah = useSelector((state) => state.app.wilayah);

  useEffect(() => {
    reloadData();
  }, []);

  const generateFilter = () => {
    let filter = ``;
    let depot = null;
    let wilayah = null;

    switch (user.title) {
      case "kadepot":
        depot = user.data_depot;
        wilayah = user.data_wilayah;
        break;
      case "kawil":
        wilayah = user.data_wilayah;
        depot = selectedDepot && selectedDepot.id > 0 ? selectedDepot : null;
        break;
    }
    if (depot) {
      filter += `data_depot.id:=:${depot.id}`;
    } else if (wilayah) {
      filter += `data_depot.wilayah_id:=:${wilayah.id}`;
    }

    return filter;
  };

  const reloadData = async () => {
    const filter = generateFilter();

    return await requestGet({
      fullUrl: "api/dashboard/kawil",
      params: {
        prefix: "aktivitas",
        filter,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          const {
            total_bon,
            grand_total,
            total_sales,
            total_mobil,
            total_sepeda_motor,
            jumlah_mobil,
            jumlah_sales,
            jumlah_sepeda_motor,
          } = data;

          setBon({ ...bon, value: total_bon });
          setTotalBon({ ...totalBon, value: grand_total / 1000 });
          setSalesman({ ...salesman, value: [total_sales, jumlah_sales] });
          setMobil({ ...mobil, value: [total_mobil, jumlah_mobil] });
          setSM({ ...sm, value: [total_sepeda_motor, jumlah_sepeda_motor] });
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const generateContent = () => {
    const content = [];
    const contentElement = [bon, totalBon, salesman, mobil, sm];
    for (let i = 0; i < contentElement.length; i++) {
      const el = contentElement[i];
      content.push(
        generateElement({
          key: i + 1,
          icon: el.icon,
          value: el.value,
          prompt: el.prompt,
          iconSize: el.iconSize,
          suffix: el.suffix,
          onClick: contentClick[i],
        })
      );
    }
    return content;
  };

  const generateElement = ({
    key,
    icon,
    value,
    prompt,
    iconSize,
    suffix,
    onClick,
  }) => {
    const valueComponent =
      value || value === 0 ? (
        typeof value === "number" ? (
          value.toLocaleString("id") + (suffix ? ` ${suffix}` : "")
        ) : typeof value === "object" ? (
          <div className="row-center-center gap-1 p-0 m-0">
            <span className="">{value[0]}</span>
            <span className="text-xs text-zinc-400">/</span>
            <span className="text-xs text-zinc-400"> {value[1]}</span>
          </div>
        ) : (
          value
        )
      ) : (
        <Skeleton width="3rem" height="1.7rem" />
      );
    return (
      <div
        key={key}
        className={`col-end-center px-2 rounded ${
          onClick ? " cursor-pointer active:bg-blue-100 hover:bg-blue-50" : ""
        }`}
        onClick={onClick}
      >
        {React.cloneElement(icon, {
          className: "text-zinc-500 h-10",
          size: iconSize ?? "22",
        })}
        <div className="col-center-center gap-1 pt-3">
          <span className="text-zinc-700 text-lg leading-4 font-semibold">
            {valueComponent}
          </span>
          <span className="text-zinc-700 text-sm ">{prompt}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="col-start-start grow-1 gap-4 w-full">
      <span className="text-sm font-semibold text-zinc-700 ">{title}</span>
      <div className="row-around-center w-full flex-wrap gap-5">
        {generateContent()}
      </div>
    </div>
  );
};

export default BadgeAktivitasDepot;

import classes from "./MenuHeaderComponent.module.css";

const MenuHeaderComponent = (props) => {
  const { label } = props;

  return (
    <li className={`${classes.wrapper} row-start-center w-100 pt-4 pb-2 gap-2`}>
      <div className={`${classes.line}`}></div> {label} <div className={`${classes.lineEnd}`}></div> 
    </li>
  );
};

export default MenuHeaderComponent;

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTaskListEditingData } from "../../../store/ui-slice";
import useAPIRequest from "../../../custom_hooks/simple/useAPIRequest";

const TaskListEditorDialog = (props) => {
  const {
    visible,
    onHide,
    editorData,
    // setEditorData,
    loading,
    listReceiver,
    onSave,
  } = props;

  const { requestGet, requestPost, requestDelete } = useAPIRequest();

  const dispatch = useDispatch();

  const editingData = useSelector((state) => state.ui.taskListEditingData);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (visible) {
      if (editingData.uniqueMarker && !editingData.id) {
        loadDataByUniqueMarker(editingData.uniqueMarker);
      }
    }
  }, [visible]);

  const loadDataByUniqueMarker = async (uniqueMarker) => {
    return await requestGet({
      fullUrl: "api/tools/todo/data",
      params: {
        filter: `created_by_id:=:${user.id};to_id:=:${editingData.receiverId};unique_marker:=:${uniqueMarker}`,
        page: 1,
        take: 1,
        order: "id",
        order_method: "ASC",
        with_to: 1,
        with_created_by: 1,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data && data.length > 0) {
          const dataSingle = data[0];
          let receiverId =
            dataSingle.data_to.id === user.id ? 0 : dataSingle.data_to.id;

          dispatch(
            setTaskListEditingData({
              taskListEditingData: {
                receiverId: receiverId,
                description: dataSingle.title,
                id: dataSingle.id,
                uniqueMarker: dataSingle.unique_marker,
              },
            })
          );

          console.log("LOADED DATA", data);
          //   const newContents = [];
          //   for (let i = 0; i < data.length; i++) {
          //     newContents.push(data[i]);
          //   }
          //   dispatch(setTaskListContents({ taskListContents: newContents }));
          //   // setContents(newContents);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const handleOnSave = async (event) => {
    event.preventDefault();
    if (onSave) {
      onSave(editorData);
    }
  };

  const dialogFooter = (
    <React.Fragment>
      <div className="row-end-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Simpan"
          icon="pi pi-save"
          loading={loading}
          onClick={(e) => {
            handleOnSave(e);
          }}
        />
      </div>
    </React.Fragment>
  );

  const handleChangeDescription = (event) => {
    dispatch(
      setTaskListEditingData({
        taskListEditingData: { ...editorData, description: event.target.value },
      })
    );
  };

  const handleChangeReceiver = (event) => {
    dispatch(
      setTaskListEditingData({
        taskListEditingData: { ...editorData, receiverId: event.value },
      })
    );
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "45rem" }}
      header={"Tambah / Edit Task"}
      modal
      footer={dialogFooter}
      onHide={onHide}
    >
      <div className="col-start-center relative h-100 w-full gap-3">
        <div className="col-start-start w-100 gap-5 w-full">
          <div className="col-start-start w-full gap-1">
            <label
              htmlFor="judul"
              className="font-semibold text-sm text-zinc-400"
            >
              Untuk
            </label>
            <Dropdown
              value={editorData.receiverId}
              onChange={handleChangeReceiver}
              options={listReceiver}
              optionLabel="name"
              placeholder="Select a Receiver"
              className="w-full md:w-14rem"
            />{" "}
          </div>
          <div className="col-start-start w-full gap-1">
            <label
              htmlFor="judul"
              className="font-semibold text-sm text-zinc-400"
            >
              Deskripsi
            </label>
            <InputTextarea
              className="w-full h-[8rem]"
              value={editorData.description}
              autoFocus
              onChange={handleChangeDescription}
            />
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default TaskListEditorDialog;
